import { Box, Button, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { useHistory, useParams } from "react-router-dom";

import HubIcon from "../icons/Hub";

function TroubleshootCategoryCard({
  sx,
  size,
  icon: Icon,
  imageUrl,
  label,
  link,
  description,
  hub,
  fontSizeLabel,
  disabled,
}) {
  const history = useHistory();
  const { hubId } = useParams();

  return (
    <Button
      sx={[
        {
          padding: 0,
          width: "240px",
          height: "240px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "16px",
          borderRadius: "4px",
          background: "#fff",
          color: "#000",
          boxShadow:
            "0px 2px 1px -1px #00000033, 0px 1px 1px 0px #00000024, 0px 1px 3px 0px #0000001F",
        },
        ...sx,
      ]}
      onClick={() => {
        history.push(`/hubs/${hubId}/troubleshoot#${link}`);
      }}
      disabled={disabled}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "8px",
        }}
      >
        <Box sx={[{ width: 56, height: 56, position: "relative" }, size]}>
          {Icon && (
            <Icon
              sx={[
                {
                  color: disabled ? "rgba(0, 0, 0, 0.26)" : "#000",
                  width: 56,
                  height: 56,
                },
                size,
              ]}
            />
          )}
          {imageUrl && (
            <img
              style={{
                width: size.width || "56px",
                height: size.height || "56px",
              }}
              src={imageUrl}
              alt="troubleshoot-icon"
            />
          )}
          {!Icon && !imageUrl && (
            <>
              <HubIcon {...hub} {...size} />
              {hub.isAnimation && (
                <>
                  <Box
                    sx={[
                      {
                        position: "absolute",
                        width: "10px",
                        height: "10px",
                        right: "6.5px",
                        bottom: "13px",
                        backgroundColor: "#F00",
                        borderRadius: "50%",
                        cursor: "pointer",
                        animation: "colorChange 1s infinite alternate",
                        "@keyframes colorChange": {
                          "0%": {
                            backgroundColor: "#F00",
                          },
                          "100%": {
                            backgroundColor: "transparent",
                          },
                        },
                      },
                      size && {
                        width: "8px",
                        height: "8px",
                        right: "5.25px",
                        bottom: "10.5px",
                      },
                    ]}
                  />
                  <Box
                    sx={[
                      {
                        position: "absolute",
                        width: "10px",
                        height: "10px",
                        right: "6.5px",
                        bottom: "13px",
                        backgroundColor: "#F00",
                        borderRadius: "50%",
                        cursor: "pointer",
                        animation: "colorChange 1s infinite alternate",
                        "@keyframes colorChange": {
                          "0%": {
                            backgroundColor: "#F00",
                          },
                          "100%": {
                            backgroundColor: "transparent",
                          },
                        },
                      },
                      size
                        ? {
                            right: "29.25px",
                            width: "3.2px",
                            bottom: "15px",
                            height: "3.2px",
                          }
                        : {
                            width: "3.7px",
                            height: "3.7px",
                            right: "36.5px",
                            bottom: "18.75px",
                          },
                    ]}
                  />
                </>
              )}
            </>
          )}
          {label === "Active Return" && !disabled && (
            <Box
              sx={{
                width: "28px",
                height: "28px",
                position: "absolute",
                background: "#FFB342",
                borderRadius: "50%",
                top: -6,
                right: -6,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography sx={{ color: "white", fontWeight: 600 }}>
                !
              </Typography>
            </Box>
          )}
        </Box>
        <Typography
          sx={[
            {
              fontSize: 13,
              fontWeight: "bold",
              textTransform: "capitalize",
            },
            fontSizeLabel,
          ]}
        >
          {label}
        </Typography>
        {description && (
          <Typography
            sx={{
              fontSize: "14px",
              color: "#0000008F",
              padding: "0px 24px",
              textAlign: "center",
              textTransform: "none",
            }}
          >
            {description}
          </Typography>
        )}
      </Box>
    </Button>
  );
}

TroubleshootCategoryCard.propTypes = {
  sx: PropTypes.arrayOf(PropTypes.shape()),
  size: PropTypes.shape(),
  icon: PropTypes.elementType,
  imageUrl: PropTypes.string,
  label: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  description: PropTypes.string,
  hub: PropTypes.shape({
    lampTop: PropTypes.string,
    lampBottom: PropTypes.string,
    isAnimation: PropTypes.bool,
  }),
  fontSizeLabel: PropTypes.shape({}),
  disabled: PropTypes.bool,
};
TroubleshootCategoryCard.defaultProps = {
  sx: [],
  icon: undefined,
  imageUrl: "",
  size: "",
  description: "",
  hub: {
    lampTop: "",
    lampBottom: "",
    isAnimation: false,
  },
  fontSizeLabel: {},
  disabled: false,
};

export default TroubleshootCategoryCard;
