import { Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import StelDataGrid from "../StelDataGrid";

function CustomHeader({ title }) {
  if (!title) return null;
  return (
    <Typography sx={{ fontSize: 20, fontWeight: 600, padding: "12px 10px" }}>
      {title}
    </Typography>
  );
}

CustomHeader.propTypes = {
  title: PropTypes.string,
};
CustomHeader.defaultProps = {
  title: "",
};

function TroubleshootTable({
  title,
  columns,
  hideFooter,
  rows,
  pagination,
  setPagination,
  onRowClick,
}) {
  return (
    <StelDataGrid
      columns={columns}
      rows={rows || []}
      loading={false}
      pageSize={pagination.pageSize}
      onPageSizeChange={(pageSize) =>
        setPagination((pre) => ({ ...pre, pageSize }))
      }
      rowsPerPageOptions={[10, 25, 50, 100]}
      rowCount={pagination.totalCount}
      onPageChange={(page) => setPagination((pre) => ({ ...pre, page }))}
      paginationMode="server"
      onRowClick={onRowClick}
      components={{
        Header: CustomHeader,
      }}
      componentsProps={{
        header: { title },
      }}
      hideFooter={hideFooter}
    />
  );
}

TroubleshootTable.propTypes = {
  title: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  pagination: PropTypes.shape({
    pageSize: PropTypes.number,
    page: PropTypes.number,
    totalCount: PropTypes.number,
  }),
  setPagination: PropTypes.func,
  hideFooter: PropTypes.bool,
  onRowClick: PropTypes.func,
};
TroubleshootTable.defaultProps = {
  title: "",
  hideFooter: false,
  pagination: { pageSize: 10, page: 0, totalCount: 0 },
  setPagination: () => {},
  onRowClick: () => {},
};

export default TroubleshootTable;
