import {
  ArrowBackIosNew as ArrowBackIosNewIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
} from "@mui/icons-material";
import { Box } from "@mui/material";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import useStyles from "./styles";

export default function NavHeader({ setIsOpenNav, isOpenNav }) {
  const classes = useStyles();

  return (
    <Box
      sx={{
        position: "relative",
        height: "40px",
        m: "8px 16px 12px 16px",
        pb: "16px",
        borderBottom: "1px solid #CDD6DC",
      }}
    >
      <Link to="/" className={classNames(classes.textCenter, classes.logo)}>
        <Box
          component="img"
          src="/stel.png"
          alt=""
          sx={{ height: 40, width: 40 }}
        />
        <h1 className={classNames(classes.textLogo)}>Stel</h1>
      </Link>
      {!isOpenNav ? (
        <ArrowForwardIosIcon
          className={classNames(
            classes.absolute,
            classes.arrowIcon,
            " arrowIcon"
          )}
          onClick={() => setIsOpenNav(true)}
        />
      ) : (
        <ArrowBackIosNewIcon
          className={classNames(
            classes.absolute,
            classes.arrowIcon,
            " arrowIcon"
          )}
          onClick={() => setIsOpenNav(false)}
        />
      )}
    </Box>
  );
}
NavHeader.propTypes = {
  setIsOpenNav: PropTypes.func.isRequired,
  isOpenNav: PropTypes.bool,
};
NavHeader.defaultProps = {
  isOpenNav: false,
};
