import CommentIcon from "@mui/icons-material/Comment";
import {
  Autocomplete,
  Button,
  MenuItem,
  Container,
  TextField,
  Box,
  Typography,
  Stack,
} from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import classNames from "classnames";
import { enqueueSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router";
import {
  HUB_RETURN_DETERMINATIONS,
  HUB_RETURN_STATUS,
} from "../../utils/constants";
import { get, post } from "../../utils/io";
import MainContentContainer from "../Layouts/MainContentContainer";
import StelDataGrid from "../StelDataGrid";
import StelModal from "../StelModal";
import useStyles from "./styles";

function ReturnEvaluation() {
  const classes = useStyles();

  const location = useLocation().pathname.split("/");
  const history = useHistory();
  const [returnId] = useState(location[2]);

  const [hubReturn, setHubReturn] = useState({
    hubs: [],
    org_alias: "",
    status: "",
    created_by: {
      first_name: "",
      last_name: "",
    },
    shipping_info: {
      recipient_address: "",
    },
    created_time: "",
  });
  const [isConfirmingComplete, setIsConfirmingComplete] = useState(false);
  const [isConfirmingReceive, setIsConfirmingReceive] = useState(false);
  const [loading, setLoading] = useState(false);
  const defaultCommentModalState = {
    open: false,
    initialComment: "",
    hubId: "",
    rowId: "",
  };
  const [commentModalState, setCommentModalState] = useState(
    defaultCommentModalState
  );

  useEffect(() => {
    const getReturnDetail = async () => {
      const res = await get(`/hub_returns/${returnId}`);
      if (res.status === 200) {
        if (res.data.status === HUB_RETURN_STATUS.CREATED) {
          history.push(`/hub-returns/${returnId}`);
        }
        setHubReturn(res.data);
      }
    };
    getReturnDetail();
  }, [returnId, history]);

  const changeReturnedHub = (row, value) => {
    setHubReturn((pre) => {
      const existingHub = pre.hubs.find((hub) => hub.id === row.id);
      Object.assign(existingHub, { ...existingHub, ...value });
      return { ...pre };
    });
  };

  const changeDetermination = (row, value) => {
    // Clear replacement hub if determination is no longer "replace"
    const updatedValue =
      row.determination === HUB_RETURN_DETERMINATIONS.REPLACE
        ? {
            ...value,
            replacement_hub_id: null,
          }
        : value;
    changeReturnedHub(row, updatedValue);
  };

  const changeReplacementHub = (row, value, reason) => {
    const updatedValue = {
      replacement_hub_id: reason === "clear" ? null : value,
    };
    changeReturnedHub(row, updatedValue);
  };

  const isReceived = () => hubReturn.status === HUB_RETURN_STATUS.RECEIVED;

  const isCompleted = () => hubReturn.status === HUB_RETURN_STATUS.COMPLETED;

  const isCreated = () => hubReturn.status === HUB_RETURN_STATUS.CREATED;

  const getRowActions = (params) => [
    <GridActionsCellItem
      disabled={hubReturn.status === HUB_RETURN_STATUS.SUBMITTED}
      icon={
        <CommentIcon
          color={params.row.internal_note.length > 0 ? "primary" : "inherit"}
        />
      }
      label="Comments"
      onClick={() => {
        const hubInfo = params.row;
        setCommentModalState({
          open: true,
          initialComment: hubInfo.internal_note,
          hubId: hubInfo.hub_id,
          rowId: hubInfo.id,
        });
      }}
    />,
  ];

  const columns = [
    {
      field: "hub",
      headerName: "Hub",
      flex: 1.5,
      renderCell: (params) => (
        <HubInfo
          hubId={params.row.hub_id}
          reason={params.row.reason}
          reasonDetail={params.row.reason_detail}
        />
      ),
    },
    {
      field: "determination",
      headerName: "Determination",
      flex: 0.4,
      renderCell: (params) => (
        <DeterminationSelection
          value={params.value}
          onChange={(e) =>
            changeDetermination(params.row, { determination: e.target.value })
          }
          disabled={!isReceived()}
        />
      ),
    },
    {
      field: "replacement_hub_id",
      headerName: "Replacement Hub",
      flex: 0.5,
      renderCell: (params) => (
        <ReplaceHubIdSelection
          hubId={params.value}
          onChange={(_, value, reason) =>
            changeReplacementHub(params.row, value, reason)
          }
          disabled={
            params.row.determination !== HUB_RETURN_DETERMINATIONS.REPLACE ||
            !isReceived()
          }
        />
      ),
    },
    {
      field: "actions",
      type: "actions",
      getActions: (params) => getRowActions(params),
    },
  ];

  const updateHubReturn = async (body) => {
    const res = await post(`admin/hub_returns/${returnId}`, body);
    const snackbarInfo =
      res.status === 200
        ? {
            message: "Changes saved",
            variant: "success",
          }
        : {
            message: res.data.detail,
            variant: "error",
            persist: true,
          };
    enqueueSnackbar(snackbarInfo);
    return res;
  };

  const getUpdateBody = () => ({
    hubs: hubReturn.hubs.map((hub) => ({
      hub_id: hub.hub_id,
      determination: hub.determination,
      replacement_hub_id: hub.replacement_hub_id,
      internal_note: hub.internal_note,
    })),
  });

  const handleSaveHubReturn = async () => {
    setLoading(true);
    await updateHubReturn(getUpdateBody());
    setLoading(false);
  };

  const completeHubReturn = async () => {
    setLoading(true);
    setIsConfirmingComplete(false);
    const updateRes = await updateHubReturn(getUpdateBody());
    if (updateRes.status === 200) {
      const completeRes = await post(`admin/hub_returns/${returnId}/complete`);
      if (completeRes.status === 201) {
        enqueueSnackbar({
          message: `Return completed, order ${completeRes.data.id} created`,
          variant: "success",
        });
        history.push("/hub-orders");
      } else if (completeRes.status === 200) {
        enqueueSnackbar({
          message:
            "Return completed, no order created because no hubs need to be returned",
          variant: "warning",
        });
        history.push("/hub-orders");
      } else {
        const isErrorSnackbar = !!completeRes.data?.data;
        enqueueSnackbar({
          message: completeRes.data.detail,
          variant: isErrorSnackbar ? "collapse" : "error",
          persist: true,
          ...(isErrorSnackbar && {
            type: "error",
            children: completeRes.data.data.map((hubId) => (
              <Typography variant="body2" key={hubId}>
                {hubId}
              </Typography>
            )),
          }),
        });
      }
    }
    setLoading(false);
  };

  const receiveHubReturn = async () => {
    setLoading(true);
    setIsConfirmingReceive(false);
    const res = await post(`admin/hub_returns/${returnId}/receive`);
    if (res.status !== 200) {
      enqueueSnackbar({
        message: res.data.detail,
        variant: "error",
      });
    } else {
      setHubReturn(res.data);
    }
    setLoading(false);
  };

  const closeCommentModal = () => {
    setCommentModalState(defaultCommentModalState);
  };

  const saveComment = async (comment) => {
    setLoading(true);
    closeCommentModal();
    const updatedHubs = hubReturn.hubs.map((hubInfo) => ({
      ...hubInfo,
      internal_note:
        hubInfo.id === commentModalState.rowId
          ? comment
          : hubInfo.internal_note,
    }));
    await updateHubReturn({
      hubs: updatedHubs.map((hub) => ({
        hub_id: hub.hub_id,
        determination: hub.determination,
        replacement_hub_id: hub.replacement_hub_id,
        internal_note: hub.internal_note,
      })),
    });
    setHubReturn((pre) => ({ ...pre, hubs: updatedHubs }));
    setLoading(false);
  };

  return (
    <MainContentContainer
      title={`Evaluate Hub Return #${returnId}`}
      analyticsTitle="Evaluate Hub Return"
    >
      <CommentModal
        open={commentModalState.open}
        onClose={closeCommentModal}
        initialComment={commentModalState.initialComment}
        hubId={commentModalState.hubId}
        saveComment={(comment) => saveComment(comment)}
        loading={loading}
      />
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 2, md: 5 }}
        mb={1}
      >
        {[
          {
            label: "Status",
            value: hubReturn.status,
          },
          {
            label: "Organization",
            value: hubReturn.org_alias,
          },
          {
            label: "Requested By",
            value: `${hubReturn.created_by?.first_name} ${hubReturn.created_by?.last_name}`,
          },
          {
            label: "Created On",
            value: new Date(hubReturn.created_time).toLocaleDateString(),
          },
          {
            label: "Shipping Address",
            value: hubReturn.shipping_info?.recipient_address,
          },
          {
            label: "Carrier",
            value: hubReturn.shipping_info?.carrier,
          },
          {
            label: "Tracking Numbers",
            value: hubReturn.shipping_info?.tracking_numbers?.join(", "),
          },
        ].map(({ value, label }) => (
          <Box key={label}>
            <Typography fontWeight="bold">{label}</Typography>
            <Typography>{value}</Typography>
          </Box>
        ))}
      </Stack>
      <StelDataGrid
        rows={hubReturn.hubs || []}
        columns={columns}
        hideFooter
        getRowHeight={() => "auto"}
      />
      <div className={classNames(classes.flex, classes.groupBtn, classes.mt16)}>
        {!isCompleted() &&
          !isCreated() &&
          (!isReceived() ? (
            <Button
              variant="contained"
              onClick={() => setIsConfirmingReceive(true)}
              disabled={loading}
            >
              Receive
            </Button>
          ) : (
            <>
              <Button
                variant="contained"
                onClick={handleSaveHubReturn}
                className={classes.mr16}
                disabled={loading}
              >
                Save
              </Button>
              <Button
                variant="contained"
                onClick={() => setIsConfirmingComplete(true)}
                disabled={loading}
              >
                Complete
              </Button>
            </>
          ))}
      </div>
      <ConfirmationModal
        open={isConfirmingComplete}
        onClose={() => setIsConfirmingComplete(false)}
        title="Complete Hub Return"
        text={
          <p>
            Completing this return will generate an order of hubs to be shipped
            back to{" "}
            <span className={classes.bold}>{hubReturn.org_alias}. </span>
            Completed returns cannot be updated. All current changes will be
            saved before completing.
            <br />
            <br />
            Are you ready to save &amp; complete this return?
          </p>
        }
        onConfirm={completeHubReturn}
      />
      <ConfirmationModal
        open={isConfirmingReceive}
        onClose={() => setIsConfirmingReceive(false)}
        title="Receive Hub Return"
        text={
          <p>
            Receiving this return will move all the returned hubs out of{" "}
            <span className={classes.bold}>{hubReturn.org_alias}</span> and
            it&apos;s thing group.
            <br />
            <br />
            Are you ready to receive this return?
          </p>
        }
        onConfirm={receiveHubReturn}
      />
    </MainContentContainer>
  );
}

function DeterminationSelection(props) {
  const { value, onChange, disabled, ...rest } = props;

  return (
    <TextField
      select
      name="determination"
      value={value || ""}
      onChange={onChange}
      fullWidth
      size="small"
      disabled={disabled}
      {...rest}
    >
      {Object.keys(HUB_RETURN_DETERMINATIONS).map((key) => {
        const label = HUB_RETURN_DETERMINATIONS[key];
        return (
          <MenuItem key={key} value={label}>
            {label}
          </MenuItem>
        );
      })}
    </TextField>
  );
}

DeterminationSelection.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

DeterminationSelection.defaultProps = {
  value: null,
};

function ReplaceHubIdSelection(props) {
  const { hubId, onChange, disabled } = props;
  const [hubs, setHubs] = useState([]);
  const [input, setInput] = useState("");

  useEffect(() => {
    const getHubs = async () => {
      const res = await get("/hubs", { hub_id: input, limit: 10, org_id: "" });
      if (res.status !== 200)
        return enqueueSnackbar("Unable to retrieve hubs", { variant: "error" });
      return setHubs(res.data.hubs.map((hub) => hub.hub_id));
    };
    getHubs();
  }, [input]);

  return (
    <Autocomplete
      size="small"
      value={hubId}
      options={hubs}
      getOptionLabel={(option) => option || ""}
      onChange={onChange}
      onInputChange={(_, value) => setInput(value)}
      renderInput={(params) => <TextField {...params} />}
      fullWidth
      autoHighlight
      disabled={disabled}
    />
  );
}

ReplaceHubIdSelection.propTypes = {
  hubId: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

ReplaceHubIdSelection.defaultProps = {
  hubId: null,
};

function HubInfo(props) {
  const { hubId, reason, reasonDetail } = props;

  const classes = useStyles();

  return (
    <Container fixed className={classes.hubInfoDisplay}>
      <div>
        <div className="hubId">{hubId}</div>
        <div className="reason">{reason}</div>
        <div className="reasonDetail">{reasonDetail}</div>
      </div>
    </Container>
  );
}

HubInfo.propTypes = {
  hubId: PropTypes.string.isRequired,
  reason: PropTypes.string.isRequired,
  reasonDetail: PropTypes.string.isRequired,
};

function ConfirmationModal(props) {
  const { open, onClose, title, text, onConfirm } = props;

  const classes = useStyles();

  return (
    <StelModal open={open} onClose={onClose} width={400} title={title}>
      {text}
      <div className={classes.groupBtn}>
        <Button
          className={classes.mr10}
          variant="contained"
          color="error"
          onClick={onClose}
        >
          No
        </Button>
        <Button variant="contained" onClick={onConfirm}>
          Yes
        </Button>
      </div>
    </StelModal>
  );
}

ConfirmationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  onConfirm: PropTypes.func.isRequired,
};

function CommentModal(props) {
  const { open, onClose, hubId, initialComment, saveComment, loading } = props;

  const [comment, setComment] = useState("");

  useEffect(() => {
    setComment(initialComment);
  }, [initialComment, open]);

  return (
    <StelModal
      open={open}
      onClose={onClose}
      title={`Comment for ${hubId}`}
      width={500}
    >
      <TextField
        multiline
        minRows={5}
        maxRows={5}
        fullWidth
        value={comment}
        onChange={(event) => setComment(event.target.value)}
      />
      <Stack marginTop="10px" flexDirection="row" justifyContent="flex-end">
        <Button
          variant="contained"
          onClick={() => saveComment(comment)}
          disabled={loading}
        >
          Save
        </Button>
      </Stack>
    </StelModal>
  );
}

CommentModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  saveComment: PropTypes.func.isRequired,
  hubId: PropTypes.string.isRequired,
  initialComment: PropTypes.string.isRequired,
  loading: PropTypes.bool,
};

CommentModal.defaultProps = {
  loading: false,
};

export default ReturnEvaluation;
