import PropTypes from "prop-types";
import React from "react";

function Hub(props) {
  const { width, height, lampTop, lampBottom } = props;
  return (
    <svg
      width={width || 56}
      height={height || 56}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Frame 1724679319">
        <g id="Group 3">
          <path
            id="Vector 1"
            d="M2.85693 41.9994V46.0824C2.85693 46.503 3.24222 46.7732 3.43487 46.8557C10.0964 46.8454 23.614 46.831 24.3927 46.8557C25.1714 46.8804 25.5891 46.5773 25.7006 46.4227C25.7108 45.2472 25.725 42.7913 25.7006 42.3706C25.6763 41.9499 24.636 33.7612 24.1189 29.7194H10.218V26.1932L11.3435 25.4508C11.3536 19.9037 11.3678 8.56202 11.3435 7.5722C11.3131 6.33492 10.3701 4.54087 8.36254 4.57181C6.75648 4.59655 5.90884 6.12871 5.68578 6.8917L5.53369 25.1106L6.71998 26.1932V29.7194H4.83409C4.56641 29.7194 4.31698 30.0287 4.22573 30.1834L2.85693 41.9994Z"
            fill="#001737"
          />
          <g id="Group 1">
            <ellipse
              id="Ellipse 2675"
              cx="20.1893"
              cy="34.1714"
              rx="2.10088"
              ry="2.11429"
              fill={lampTop}
            />
            <ellipse
              id="Ellipse 2676"
              cx="20.1893"
              cy="40.5143"
              rx="2.10088"
              ry="2.11429"
              fill={lampBottom}
            />
          </g>
          <g id="Group 2">
            <rect
              id="Rectangle 5"
              x="40.5713"
              y="10.2856"
              width="20.5714"
              height="43.4286"
              rx="1"
              fill="#001737"
            />
            <circle
              id="Ellipse 2675_2"
              cx="50.8569"
              cy="20.5713"
              r="5.71429"
              fill={lampTop}
            />
            <circle
              id="Ellipse 2676_2"
              cx="50.8569"
              cy="43.4285"
              r="5.71429"
              fill={lampBottom}
            />
          </g>
        </g>
        <line
          id="Line 335"
          x1="23.7335"
          y1="32.577"
          x2="41.1936"
          y2="21.5769"
          stroke="#001737"
        />
        <line
          id="Line 336"
          x1="23.0742"
          y1="41.5055"
          x2="43.0742"
          y2="44.5055"
          stroke="#001737"
        />
      </g>
    </svg>
  );
}

Hub.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  lampTop: PropTypes.string,
  lampBottom: PropTypes.string,
};
Hub.defaultProps = {
  lampTop: "#D9D9D9",
  lampBottom: "#D9D9D9",
};

export default Hub;
