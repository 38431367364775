import React from "react";

export default function ManualInputIcon() {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6 12H0V54C0 57.3 2.7 60 6 60H48V54H6V12Z" fill="#1F79E2" />
      <path
        d="M54 0H18C14.7 0 12 2.7 12 6V42C12 45.3 14.7 48 18 48H54C57.3 48 60 45.3 60 42V6C60 2.7 57.3 0 54 0ZM43.89 37.5L41.49 30.6H30.6L28.14 37.5H23.43L33.57 10.5H38.4L48.54 37.5H43.89Z"
        fill="#1F79E2"
      />
      <path
        d="M35.8792 15.51L31.9492 26.67H40.0192L36.1192 15.51H35.8792Z"
        fill="#1F79E2"
      />
    </svg>
  );
}
