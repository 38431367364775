import { Box, Typography } from "@mui/material";
import React from "react";

import HubCellularHelpfulTipCard from "./HubCellularHelpfulTipCard";

function HubCellularHelpfulTip() {
  return (
    <Box
      sx={{
        padding: "24px",
        justifyContent: "center",
      }}
    >
      <Typography
        fontSize={24}
        fontWeight={600}
        textAlign="center"
        marginBottom="24px"
      >
        Helpful Tips
      </Typography>
      <Box
        sx={{ display: "flex", gap: "12px", justifyContent: "space-between" }}
      >
        <HubCellularHelpfulTipCard
          title="Searching for Cellular"
          description="Blinking Red"
          image="/stel-hub-blinking-red.png"
        />
        <HubCellularHelpfulTipCard
          title="Connected to Cellular"
          description="Solid Red"
          image="/stel-hub-solid-red.png"
        />
      </Box>
      <Typography
        sx={{
          fontSize: "16px",
          fontWeight: 600,
          color: "#0000008f",
          textAlign: "center",
          margin: "40px 0px",
        }}
      >
        Try to move Hub to another location + Confirm Antenna is securely
        attached add not damaged.
      </Typography>
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Box sx={{ width: "50%" }}>
          <HubCellularHelpfulTipCard image="/stel-hub-antenna.png" />
        </Box>
      </Box>
    </Box>
  );
}

export default HubCellularHelpfulTip;
