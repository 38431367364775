import {
  Box,
  CardContent,
  Grid,
  Stack,
  Tab,
  Tabs,
  Typography,
  Card,
  CircularProgress,
} from "@mui/material";
import { blue, grey } from "@mui/material/colors";
import { startOfDay, endOfDay } from "date-fns";
import { debounce } from "lodash";
import { enqueueSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import {
  CartesianGrid,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { MEASURE_TYPES } from "../utils/constants";
import { get } from "../utils/io";
import { DeviceStatusIndicator } from "./Devices";
import MainContentContainer from "./Layouts/MainContentContainer";
import MeasureList from "./MeasureList";

const tabs = [{ label: "Raw data" }];

const startOfToday = startOfDay(new Date());
const endofToday = endOfDay(startOfToday);

function Device() {
  const { deviceId } = useParams();
  const [device, setDevice] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [measures, setMeasures] = useState([]);

  const [tabIndex, setTabIndex] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  useEffect(() => {
    if (device?.mac_address) {
      const updateMeasureGrid = debounce(async () => {
        setIsLoading(true);
        const res = await get("/measures", {
          device_mac: device.mac_address,
          taken_after: startOfToday.toISOString(),
          taken_before: endofToday.toISOString(),
          limit: 288,
        });
        setIsLoading(false);
        if (res.status === 200) {
          setMeasures(res.data.measures);
        } else {
          enqueueSnackbar("Unable to retrieve measurements", {
            variant: "error",
          });
        }
      }, 300);
      updateMeasureGrid();
      return updateMeasureGrid.cancel;
    }
    return null;
  }, [device]);

  useEffect(() => {
    async function getDeviceDetails() {
      const res = await get(`/devices/${deviceId}`);
      if (res.status === 200) {
        setDevice(res.data);
      } else {
        return enqueueSnackbar("Unable to retrieve device details", {
          variant: "error",
        });
      }
      return null;
    }
    getDeviceDetails();
  }, [deviceId]);

  const isCGMDevice = device.make === "Dexcom";

  const chartData = isCGMDevice
    ? measures.map((measure) => ({
        // convert timestamp measurement to local timestamp
        x: new Date(new Date(measure.timestamp).toLocaleString()).valueOf(),
        y: measure.value.mg_dl,
      }))
    : [];

  const hubLink = (hubId) => (
    <Link
      to={`/hubs/${hubId.substring(0, 10)}`}
      style={{
        fontSize: 14,
        textDecoration: "none",
        cursor: "pointer",
        color: blue[600],
      }}
    >
      {hubId.substring(0, 10)}
    </Link>
  );

  const getHubLink = (hubId) => (hubId?.length > 0 ? hubLink(hubId) : "-");

  const deviceInfo = [
    {
      label: "Mac Address",
      value: device.mac_address || "-",
    },
    {
      label: "Serial Number",
      value: device.serial_number || "-",
    },
    {
      label: "Type",
      value: MEASURE_TYPES[device.type]?.label || "-",
    },
    {
      label: "Registered Hub ID",
      value: getHubLink(device.registered_hub),
    },
    {
      label: "Bound Hub ID",
      value: getHubLink(device.bound_hub),
    },
  ];
  return (
    <MainContentContainer
      title={
        <Grid container alignItems="center">
          <Typography
            component="h1"
            sx={{ fontSize: 24, fontWeight: 700, margin: 0 }}
          >
            {device.make} {device.model}
          </Typography>
        </Grid>
      }
      analyticsTitle={`${device.make} ${device.model}`}
    >
      <Grid container sx={{ flexDirection: "column" }} spacing={2}>
        <Grid item>
          <Card>
            <CardContent
              sx={{
                m: 2,
                mr: 0,
                display: "flex",
                alignItems: "center",
              }}
            >
              {device.model_image_url?.url && (
                <Box
                  sx={{
                    height: 100,
                    mr: 8,
                    ml: 6,
                  }}
                >
                  <img src={device.model_image_url.url} alt="" height={100} />
                </Box>
              )}

              {[
                ...deviceInfo,
                device.status
                  ? {
                      label: "Status",
                      value: <DeviceStatusIndicator status={device.status} />,
                    }
                  : {},
              ].map((info) => {
                if (!info) return null;
                return (
                  <Box sx={{ mr: 8 }} key={info.label}>
                    <Typography
                      sx={{
                        fontWeight: 700,
                        fontSize: 16,
                        mb: 1,
                        color: "#1e1e1e",
                      }}
                    >
                      {info.label}
                    </Typography>
                    {typeof info.value !== "string" ? (
                      info.value
                    ) : (
                      <Typography sx={{ fontSize: 14, color: "#545454" }}>
                        {info.value}
                      </Typography>
                    )}
                  </Box>
                );
              })}
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          sx={{
            m: 2,
            mr: 0,
            borderRadius: 1,
            border: "1px solid rgb(224, 224, 224)",
            "&.MuiGrid-item": {
              pl: 0,
              pt: 0,
            },
          }}
        >
          <Tabs value={tabIndex} onChange={handleTabChange}>
            {(isCGMDevice ? [{ label: "Chart" }, ...tabs] : tabs).map(
              (item, index) => (
                <Tab label={item.label} value={index} />
              )
            )}
          </Tabs>
          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
              p: 2,
              pl: 3,
            }}
          >
            {/* Leaving here in case we decide to re-enable date range filters */}
            {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                format="MM/dd/yyyy"
                label="Taken After"
                maxDate={new Date()}
                name="takenAfterDate"
                onChange={(val) =>
                  setSearchInputs({
                    ...searchInputs,
                    takenAfterDate: startOfDay(val),
                  })
                }
                value={searchInputs.takenAfterDate}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    variant="standard"
                    sx={{ mr: 4 }}
                  />
                )}
              />
              <DatePicker
                format="MM/dd/yyyy"
                label="Taken Before"
                maxDate={new Date()}
                name="takenBeforeDate"
                onChange={(val) =>
                  setSearchInputs({ ...searchInputs, takenBeforeDate: val })
                }
                value={searchInputs.takenBeforeDate}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    variant="standard"
                    sx={{ mr: 4 }}
                  />
                )}
              />
            </LocalizationProvider> */}
          </Stack>

          {isCGMDevice && (
            <TabPanel value={tabIndex} index={0}>
              {measures.length > 0 ? (
                <ResponsiveContainer width="100%" height={350}>
                  <ScatterChart
                    margin={{
                      top: 20,
                      right: 20,
                      bottom: 20,
                      left: 20,
                    }}
                  >
                    <CartesianGrid horizontal vertical={false} />
                    <Tooltip
                      formatter={(value, name) =>
                        name === "Date"
                          ? new Date(value).toLocaleString()
                          : value
                      }
                    />
                    <XAxis
                      type="number"
                      name="Date"
                      dataKey="x"
                      tickCount={25}
                      domain={[
                        // Localize time range
                        new Date(startOfToday.toLocaleDateString()).valueOf(),
                        new Date(endofToday.toLocaleDateString()).valueOf(),
                      ]}
                      tickFormatter={(tickItem) =>
                        new Date(tickItem).toLocaleString(navigator.language, {
                          month: "2-digit",
                          day: "2-digit",
                          hour: "numeric",
                          minute: "2-digit",
                        })
                      }
                      interval={2}
                      minTickGap={-150}
                      angle={25}
                      textAnchor="top"
                      height={100}
                    />
                    <YAxis
                      type="number"
                      axisLine={false}
                      dataKey="y"
                      tickCount={6}
                      domain={[0, 250]}
                      name="mg/dL"
                      orientation="right"
                    />
                    <Scatter name="Measure" data={chartData} fill="#1b6ede" />
                  </ScatterChart>
                </ResponsiveContainer>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    margin: "30px 0 30px 0",
                  }}
                >
                  {isLoading ? (
                    <CircularProgress />
                  ) : (
                    <Typography color={grey[600]}>No Data</Typography>
                  )}
                </Box>
              )}
            </TabPanel>
          )}
          <TabPanel value={tabIndex} index={isCGMDevice ? 1 : 0}>
            <MeasureList
              showToolbar={false}
              loading={isLoading}
              rows={measures}
              rowCount={measures.length}
              paginationMode="client"
            />
          </TabPanel>
        </Grid>
      </Grid>
    </MainContentContainer>
  );
}

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default Device;
