const devicesData = [
  // glucose
  { mac: "C4:64:E3:89:76:9E", make: "Contour", model: "Next One", type: "Glucometer" },
  { mac: "C2:56:05:B9:67:15", make: "Contour", model: "Next One", type: "Glucometer" },
  { mac: "C4:07:85:04:92:34", make: "Contour", model: "Next One", type: "Glucometer" },
  { mac: "C4:64:E3:89:C7:28", make: "Contour", model: "Next One", type: "Glucometer" },
  { mac: "04:EE:03:0D:B7:ED", make: "Glucocard", model: "Shine Connex", type: "Glucometer" },
  { mac: "58:93:D8:42:DF:90", make: "i-SENS USA", model: "Caresense N Plus BT", type: "Glucometer" },
  { mac: "58:93:D8:42:DB:90", make: "i-SENS USA", model: "Caresense N Plus BT", type: "Glucometer" },
  { mac: "F8:33:31:D5:75:37", make: "Accu-Chek", model: "Guide Me 897", type: "Glucometer" },
  { mac: "9C:35:83:15:F6:9A", make: "Trividia", model: "True Metrix Air", type: "Glucometer" },
  { mac: "9C:35:83:26:DA:5B", make: "Trividia", model: "True Metrix Air", type: "Glucometer" },
  { mac: "9C:35:83:16:B6:DD", make: "Trividia", model: "True Metrix Air", type: "Glucometer" },
  { mac: "74:D2:85:8E:A5:90", make: "Walmart", model: "Reli On Premier BLU", type: "Glucometer" },
  // CGM
  { mac: "D7:97:6B:1D:80:70", make: "Abbott", model: "Free Style Libre 2", type: "Glucometer" },
  { mac: "D7:13:5F:E6:24:C1", make: "Abbott", model: "Free Style Libre 3", type: "Glucometer" },
  // blood pressure
  { mac: "FF:86:9B:8B:09:D6", make: "A&D", model: "UA-651BLE (Black)", type: "Blood Pressure" },
  { mac: "FF:3E:42:FB:ED:49", make: "A&D", model: "UA-651BLE (Black)", type: "Blood Pressure" },
  { mac: "FF:32:D9:50:A2:E9", make: "A&D", model: "UA-651BLE (Black)", type: "Blood Pressure" },
  { mac: "FE:D8:56:F7:D5:0D", make: "A&D", model: "UA-651BLE (Black)", type: "Blood Pressure" },
  { mac: "FE:C9:93:4E:69:64", make: "A&D", model: "UA-651BLE (Black)", type: "Blood Pressure" },
  { mac: "90:E2:02:2B:59:E3", make: "Indie Health", model: "51-1490", type: "Blood Pressure" },
  { mac: "A8:10:87:74:F6:3B", make: "Indie Health", model: "51-1490", type: "Blood Pressure" },
  { mac: "C0:0E:1C:79:4B:6E", make: "Indie Health", model: "51-1490", type: "Blood Pressure" },
  { mac: "12:34:56:AB:BC:CD", make: "Omron", model: "Series 5 BP7250", type: "Blood Pressure" },
  { mac: "CD:8E:06:06:15:03", make: "Withings", model: "BPM Connect", type: "Blood Pressure" },
  { mac: "FB:D2:B6:56:8F:7B", make: "Vive Health", model: "BP Model BT-S", type: "Blood Pressure" },
  // pulse ox
  { mac: "10:08:2C:52:A2:ED", make: "Jumper", model: "JPD-500F", type: "Pulse Ox" },
  { mac: "10:08:2C:52:A5:36", make: "Jumper", model: "JPD-500F", type: "Pulse Ox" },
  { mac: "10:08:2C:52:A5:53", make: "Jumper", model: "JPD-500F", type: "Pulse Ox" },
  { mac: "10:08:2C:52:A5:79", make: "Jumper", model: "JPD-500F", type: "Pulse Ox" },
  { mac: "EE:6F:BD:41:20:C9", make: "Wellue", model: "PC60FW - Black", type: "Pulse Ox" },
  { mac: "E3:1B:2B:33:18:F6", make: "Wellue", model: "PC60FW - Black", type: "Pulse Ox" },
  { mac: "DF:DE:79:6D:ED:F9", make: "Wellue", model: "PC60FW - Black", type: "Pulse Ox" },
  { mac: "00:1C:05:FC:E7:0D", make: "Nonin", model: "NoninConnect 3230", type: "Pulse Ox" },
  { mac: "00:1C:05:FD:3B:7E", make: "Nonin", model: "NoninConnect 3230", type: "Pulse Ox" },
  { mac: "00:1C:05:FD:41:5D", make: "Nonin", model: "NoninConnect 3230", type: "Pulse Ox" },
  // spirometer
  { mac: "00:26:33:90:32:E7", make: "MIR", model: "Smart One", type: "Spirometer" },
  { mac: "00:26:33:90:72:3C", make: "MIR", model: "Smart One", type: "Spirometer" },
  { mac: "00:26:33:CD:F6:BA", make: "MIR", model: "Smart One", type: "Spirometer" },
  { mac: "25:CE:93:1B:3F:80", make: "Vitalograph", model: "Lung Monitor BT Smart", type: "Spirometer" },
  { mac: "25:CE:93:1B:3F:7D", make: "Vitalograph", model: "Lung Monitor BT Smart", type: "Spirometer" },
  { mac: "25:CE:93:1B:3F:75", make: "Vitalograph", model: "Lung Monitor BT Smart", type: "Spirometer" },
  { mac: "F3:F8:75:98:56:18", make: "MIR", model: "Spirobank", type: "Spirometer" },
  { mac: "EC:9A:AB:74:10:6B", make: "MIR", model: "Spirobank", type: "Spirometer" },
  { mac: "E4:50:C9:99:90:54", make: "MIR", model: "Spirobank", type: "Spirometer" },
  // wearable
  { mac: "29:9B:3E:9D:86:DA", make: "Indie Health", model: "51-M6", type: "Wearable" },
  { mac: "46:D9:C1:D2:B5:90", make: "Indie Health", model: "51-M6", type: "Wearable" },
  { mac: "49:19:CF:7A:04:66", make: "Indie Health", model: "51-M6", type: "Wearable" },
  { mac: "56:0D:1F:C4:21:66", make: "Indie Health", model: "51-M6", type: "Wearable" },
  { mac: "58:B1:0D:A2:02:F3", make: "Indie Health", model: "51-M6", type: "Wearable" },
  { mac: "59:6B:FB:9D:F3:59", make: "Indie Health", model: "51-M6", type: "Wearable" },
  // INR
  {mac: "DF:57:46:7A:DC:F8", make: "Coagusense", model: "PT/INR Meter", type: "INR"},
  {mac: "F8:DC:7A:46:59:6B", make: "Coagusense", model: "PT/INR Meter", type: "INR"},
  {mac: "F8:DC:7A:47:F2:4D", make: "Coagusense", model: "PT/INR Meter", type: "INR"},
  {mac: "F8:DC:7A:4C:58:25", make: "Coagusense", model: "PT/INR Meter", type: "INR"},
  {mac: "F8:DC:7A:4C:69:69", make: "Coagusense", model: "PT/INR Meter", type: "INR"},
  // INR
  {mac: "CE:64:43:CD:D7:FC", make: "Biofourmis", model: "Everion S", type: "Wearable"},
  {mac: "CE:6F:68:92:FA:9C", make: "Biofourmis", model: "Everion S", type: "Wearable"},
];

export default devicesData;
