import PropTypes from "prop-types";
import React from "react";

export default function TabPanel(props) {
  const { value, index, children } = props;
  return <div hidden={value !== index}>{value === index && children}</div>;
}

TabPanel.propTypes = {
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  children: PropTypes.oneOfType([PropTypes.node]).isRequired,
};
