import {
  Button,
  TextField,
  Box,
  InputLabel,
  Stack,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import StelModal from "../../StelModal";

export default function ChoicesModal(props) {
  const {
    onClose,
    onConfirm,
    quantities,
    devices,
    isDisplayed,
    width,
    inputAdditionalInformation,
    setInputAdditionalInformation,
  } = props;

  const deviceOrders = devices.filter((device) =>
    Object.keys(quantities).includes(device.id.toString())
  );

  const formatPrice = (price) =>
    Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: 2,
    }).format(price);

  const getTotalPrice = () => {
    const totalPrice = deviceOrders.reduce(
      (total, device) => total + quantities[device.id] * device.price,
      0
    );
    return formatPrice(totalPrice);
  };

  return (
    <StelModal
      title="Review Order"
      open={isDisplayed}
      onClose={onClose}
      width={width}
    >
      <Box>
        <Typography sx={{ mb: "10px" }}>
          The following devices will be included in your order:
        </Typography>
        <Box sx={{ maxHeight: 150, overflowY: "auto", mb: "10px" }}>
          {deviceOrders.map((device) => (
            <li key={device.id}>
              {quantities[device.id]} {`${device.make} ${device.display_name}`}{" "}
              @ {formatPrice(device.price)}
            </li>
          ))}
        </Box>
        <Typography sx={{ textAlign: "center", fontWeight: "bold" }}>
          Total: {getTotalPrice()}
        </Typography>
        <Box sx={{ mt: "20px", mb: "10px" }}>
          <InputLabel sx={{ fontWeight: "bold", color: "black" }}>
            Additional Information
          </InputLabel>
          <TextField
            sx={{ width: "100%" }}
            size="small"
            multiline
            value={inputAdditionalInformation}
            onChange={(e) => setInputAdditionalInformation(e.target.value)}
            rows={3}
          />
        </Box>
        <Stack flexDirection="row" justifyContent="flex-end">
          <Button
            sx={{ mr: "10px" }}
            color="error"
            variant="contained"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button color="primary" variant="contained" onClick={onConfirm}>
            Submit
          </Button>
        </Stack>
      </Box>
    </StelModal>
  );
}

ChoicesModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isDisplayed: PropTypes.bool.isRequired,
  width: PropTypes.number,
  quantities: PropTypes.objectOf.isRequired,
  devices: PropTypes.objectOf.isRequired,
  inputAdditionalInformation: PropTypes.string.isRequired,
  setInputAdditionalInformation: PropTypes.func.isRequired,
};

ChoicesModal.defaultProps = {
  width: 450,
};
