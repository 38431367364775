import { Link } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

import {
  FormField,
  SensitiveFormField,
  getMaxLengthRule,
  urlFieldRules,
} from "./EndpointFormUtils";

function TokenEndpointDescription() {
  return (
    <>
      The absolute URL that Stel will request a token from. Must accept a Basic
      auth header with the base64 encoded value of
      &apos;&lt;client_id&gt;:&lt;client_secret&gt;&apos;. Must respond
      according to the{" "}
      <Link
        href="https://www.oauth.com/oauth2-servers/access-tokens/access-token-response/"
        target="_blank"
      >
        Oauth2 specifications
      </Link>
      .
    </>
  );
}

export default function OauthClientCredentialsForm(props) {
  const { control, errors, sensitiveFieldProps } = props;

  return (
    <>
      <FormField
        name="tokenEndpoint"
        label="Token Endpoint"
        description={TokenEndpointDescription}
        rules={urlFieldRules}
        control={control}
        errors={errors}
      />
      <FormField
        name="clientId"
        label="Client ID"
        description="The value representing Stel's client ID sent in the request to the token endpoint."
        rules={getMaxLengthRule(50)}
        control={control}
        errors={errors}
      />
      <SensitiveFormField
        name="clientSecret"
        label="Client Secret"
        description="The value representing Stel's client secret sent in the request to the token endpoint."
        rules={getMaxLengthRule(100)}
        control={control}
        errors={errors}
        {...sensitiveFieldProps}
      />
    </>
  );
}

OauthClientCredentialsForm.propTypes = {
  control: PropTypes.shape().isRequired,
  errors: PropTypes.shape().isRequired,
  sensitiveFieldProps: PropTypes.shape({
    showExistingTokenMessage: PropTypes.bool,
    validateToken: PropTypes.bool,
  }),
};

OauthClientCredentialsForm.defaultProps = {
  sensitiveFieldProps: {
    showExistingTokenMessage: false,
    validateToken: true,
  },
};
