import {
  Autocomplete,
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

export function SimulatedVitalTextField(props) {
  const { value, label, name, onChange, unit } = props;

  return (
    <Stack
      alignItems="center"
      flexDirection="row"
      sx={{ mb: "10px", mr: "10px" }}
    >
      <Typography sx={{ m: 0, mr: "15px" }}>{label}</Typography>
      <TextField
        sx={{
          width: "100px",
          textAlign: "center",
        }}
        name={name}
        type="number"
        size="small"
        value={value}
        onChange={onChange}
      />
      <Box
        component="span"
        sx={{
          ml: "20px",
          fontWeight: 500,
          fontStyle: "italic",
        }}
      >
        {unit}
      </Box>
    </Stack>
  );
}

SimulatedVitalTextField.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
  ]).isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  unit: PropTypes.string,
};

SimulatedVitalTextField.defaultProps = {
  unit: null,
  value: "",
};

export function SimulatedVitalSelect(props) {
  const { value, label, name, onChange, options, unit } = props;

  return (
    <FormControl
      variant="outlined"
      size="small"
      sx={{
        width: "250px",
        mr: "10px",
        mb: "10px",
      }}
    >
      <InputLabel>{label}</InputLabel>
      <Select value={value} label={label} name={name} onChange={onChange}>
        {options.map((optionValue) => (
          <MenuItem key={optionValue} value={optionValue}>
            {`${optionValue}`}
          </MenuItem>
        ))}
      </Select>
      <Box
        component="span"
        sx={{
          ml: "20px",
          fontWeight: 500,
          fontStyle: "italic",
        }}
      >
        {unit}
      </Box>
    </FormControl>
  );
}

SimulatedVitalSelect.propTypes = {
  ...SimulatedVitalTextField.propTypes,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number])
  ).isRequired,
};

SimulatedVitalSelect.defaultProps = SimulatedVitalTextField.defaultProps;

export function DevicesDropdown(props) {
  const { devices, onChange } = props;

  return (
    <FormControl variant="outlined" size="small" sx={{ width: "250px" }}>
      <Autocomplete
        size="small"
        options={devices}
        getOptionLabel={(option) => option?.mac_address || ""}
        onChange={onChange}
        isOptionEqualToValue={(option, value) => value?.id === option?.id}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Which Device? (optional)"
            name="device"
          />
        )}
      />
      <FormHelperText>
        A new virtual device will be created if an existing MAC address is not
        selected.
      </FormHelperText>
    </FormControl>
  );
}

DevicesDropdown.propTypes = {
  devices: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onChange: PropTypes.func.isRequired,
};
