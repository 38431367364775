import { grey } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";
import theme from "../../../utils/theme";

const useStyles = makeStyles(() => ({
  relative: {
    position: "relative",
  },
  arrowIcon: {
    display: "none",
    right: -30,
    top: 16,
    padding: 8,
    borderRadius: "50%",
    fontSize: 10,
    background: "#fff",
    color: theme.colors.blue,
    zIndex: 5,
    boxShadow:
      "rgb(9 30 66 / 15%) 0px 0px 0px 1px, rgb(9 30 66 / 8%) 0px 2px 0px",
    "&:hover": {
      cursor: "pointer",
      background: theme.colors.blue,
      color: "#fff",
    },
  },
  absolute: {
    position: "absolute",
  },
  logo: {
    textDecoration: "none",
    color: "#000",
    cursor: "pointer",
  },
  link: {
    padding: "12.4px 12px",
    margin: "0 8px",
    borderRadius: 4,
    color: theme.colors.darkGrey,
    textDecoration: "none",
    cursor: "pointer",
    "&:hover": {
      background: theme.colors.lighterBlue,
      color: theme.colors.blue,
      "& > svg": {
        color: theme.colors.blue,
      },
    },
  },
  selectedItem: {
    "&:before": {
      content: `''`,
      width: 4,
      height: 16,
      position: "absolute",
      borderTopRightRadius: 3,
      borderBottomRightRadius: 3,
      background: theme.colors.darkBlue,
      left: 8,
    },
  },
  textCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  textStart: {
    display: "flex",
    justifyContent: "flex-start",
  },
  bgLightBlue: {
    background: theme.colors.lightBlue,
  },
  blue: {
    color: theme.colors.blue,
  },
  lighterBlue: {
    background: theme.colors.lighterBlue,
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    height: "calc(100% - 80px)",
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
  expandIcon: {
    fontSize: theme.fontSize.small,
    marginLeft: 10,
  },
  dropdown: {
    "& .MuiMenu-paper	": {
      left: "190px !important",
    },
  },
  dropdownCollapse: {
    "& .MuiMenu-paper	": {
      left: "50px !important",
    },
  },
  menuItem: {
    "&:hover ": {
      color: theme.colors.blue,
    },
  },
  textLogo: {
    fontWeight: 400,
    color: theme.colors.grey,
    fontSize: 24,
    overflow: "hidden",
  },
  sandboxBtn: {
    padding: "10px 12px",
    margin: "0 8px",
    borderRadius: 4,
    color: theme.colors.darkGrey,
    textDecoration: "none",
    "& .MuiFormControlLabel-root": {
      margin: 0,
    },
    "& .MuiTypography-root": {
      fontSize: theme.fontSize.medium,
    },
  },
  lighterGrey: {
    background: grey[100],
  },
  mrb15: {
    marginBottom: 15,
  },
  orgName: {
    height: 28,
    fontSize: 14,
    color: grey[400],
    paddingBottom: 10,
    paddingLeft: 12,
    display: "flex",
    alignItems: "center",
    margin: "0 8px",
    "& svg": {
      marginRight: 8,
    },
  },
  navItem: {
    fontSize: theme.fontSize.medium,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    height: 16.01,
  },
  spaceBetween: {
    justifyContent: "space-between",
  },
  w100: {
    width: "100%",
  },
  isOrg: {
    cursor: "pointer",
    "&:hover": {
      background: theme.colors.lightBlue,
      color: theme.colors.darkBlue,
      "& > svg": {
        color: theme.colors.darkBlue,
      },
    },
  },
  notOrg: {
    cursor: "no-drop",
    background: grey[100],
  },
  noPointerEvents: {
    pointerEvents: "none",
  },
}));

export default useStyles;
