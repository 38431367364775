import { enqueueSnackbar } from "notistack";
import React, { useState, useEffect } from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { get } from "../../../utils/io";
import TroubleshootDetail from "../../TroubleshootTickets/TroubleshootDetail";

const defaultTicket = {
  details: {
    triage: {
      type: "",
      status: "",
      context: {},
      form: {},
      return_type: "",
    },
    intake: {
      form: {},
      replacement_hub_id: "",
      resolution: "",
    },
  },
};

function useQuery() {
  const location = useLocation();
  const search = location.hash.includes("?")
    ? location.hash.substring(location.hash.indexOf("?"))
    : "";

  return new URLSearchParams(search);
}

function HistoryDetailTabContent() {
  const history = useHistory();
  const { hubId } = useParams();
  const ticketId = useQuery().get("ticket");

  const [ticket, setTicket] = useState(defaultTicket);

  const onReplaceHistory = () => {
    history.replace(`/hubs/${hubId}/troubleshoot#history`);
  };

  useEffect(() => {
    const getTicket = async () => {
      const res = await get(`/troubleshoot/tickets/${ticketId}`);
      if (res.status === 200) {
        const { details, ...rest } = res.data;
        setTicket(() => ({
          ...rest,
          // Merging with default values so existential operator doesn't have to be used
          // everywhere
          // details: { ...pre.details, ...details },
          details,
        }));
      } else {
        enqueueSnackbar(res.data?.detail || "Unable to retrieve ticket", {
          variant: "error",
        });
      }
    };

    getTicket();
  }, [ticketId]);

  return <TroubleshootDetail ticket={ticket} onClose={onReplaceHistory} />;
}

export default HistoryDetailTabContent;
