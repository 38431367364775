import DeleteIcon from "@mui/icons-material/Delete";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import theme from "../../utils/theme";

export default function DeviceMasterDataGrid(props) {
  const { selectedDevices, onRemoveSelectedDevice } = props;
  const [rows, setRows] = useState([]);

  const getRowActions = (params) => [
    <GridActionsCellItem
      icon={<DeleteIcon />}
      label="Delete"
      onClick={() => onRemoveSelectedDevice(params.row?.id)}
    />,
  ];

  const columns = [
    {
      field: "serial_number",
      flex: 1,
      headerName: "Serial Number",
      valueGetter: (params) => params.row?.serial_number,
    },
    {
      field: "bluetooth_code",
      flex: 1,
      headerName: "PIN Code",
      valueGetter: (params) => params.row?.device_master?.bluetooth_code || "",
    },
    {
      field: "actions",
      type: "actions",
      flex: 0.5,
      getActions: (params) => getRowActions(params),
    },
  ];

  useEffect(() => {
    setRows(selectedDevices);
  }, [selectedDevices]);

  return (
    <DataGrid
      sx={{
        "& .MuiDataGrid-columnHeaderTitleContainer": {
          color: theme.colors.grey,
        },
        "& .MuiDataGrid-row:hover": {
          backgroundColor: "rgba(0,0,0,0)",
        },
        height: 200,
      }}
      rowHeight={40}
      headerHeight={40}
      hideFooter
      columns={columns}
      rows={rows}
      getRowId={(row) => row?.id}
      rowsPerPageOptions={[20, 25, 50, 100]}
    />
  );
}

DeviceMasterDataGrid.propTypes = {
  selectedDevices: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.object,
        PropTypes.array,
        PropTypes.bool,
      ])
    )
  ).isRequired,
  onRemoveSelectedDevice: PropTypes.func.isRequired,
};
