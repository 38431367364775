import { red } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  container: {
    maxWidth: 700,
    width: "100%",
    padding: 15,
    "& .MuiDrawer-paper": {
      width: 400,
    },
  },
  flex: {
    display: "flex",
  },
  alignCenter: {
    alignItems: "center",
  },
  header: (props) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: `calc(${props.width}px - 48px)`,
    background: "#fff",
    padding: "15px 24px 0px",
    zIndex: 2,
  }),
  boxShadow: {
    boxShadow: "0px 1px 2px rgba(0,0,0,0.39)",
  },
  content: {
    marginTop: 0,
  },
  spacebetween: {
    justifyContent: "space-between",
  },
  mrr15: {
    marginRight: 15,
  },
  backIcon: {
    color: red[500],
    fontSize: 20,
    padding: 2,
    "&:hover": {
      background: "#ECECF3",
    },
  },
  pdb15: {
    paddingBottom: 15,
  },
  mr0: {
    margin: 0,
  },
  pointer: {
    cursor: "pointer",
  },
  pd15: {
    padding: 15,
  },
}));

export default useStyles;
