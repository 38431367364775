import AddIcon from "@mui/icons-material/Add";
import { Button, TextField, Typography } from "@mui/material";
import classNames from "classnames";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

import { formatXealthUrl } from "../../utils";
import { DOCS } from "../../utils/constants";
import { get } from "../../utils/io";
import { ProtectedButton } from "../Authz/Protected";
import MainContentContainer from "../Layouts/MainContentContainer";
import StelDataGrid from "../StelDataGrid";
import StelModal from "../StelModal";
import ConfirmationModal from "../StelModal/ConfirmationModal";
import EndpointForm from "./EndpointForm";
import { PAYLOAD_SCHEMA } from "./EndpointFormUtils";

import useStyles from "./styles";

const MODAL_MODE = Object.freeze({
  CREATE: "create",
  EDIT: "edit",
  OFFER_HUB_GROUP: "confirm",
});

const AUTH_METHOD_LABELS = Object.freeze({
  STATIC_HEADER_TOKEN: "Static Header Token",
  STATIC_QUERY_TOKEN: "Static Query Token",
  OAUTH_CLIENT_CREDENTIALS: "Oauth Client Credentials",
});

function EndpointContainer() {
  const classes = useStyles();
  const history = useHistory();

  const [endpoints, setEndpoints] = useState([]);
  const [inputSearch, setInputSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState("");
  const [selectedEndpoint, setSelectedEndpoint] = useState({});

  const updateEndpointGrid = useCallback(async (url) => {
    setLoading(true);
    const res = await get("/endpoints", { url });
    if (res.status === 200) {
      setEndpoints(
        res.data.endpoints.map((item) => ({ ...item, ...item.auth?.config }))
      );
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    updateEndpointGrid();
  }, [updateEndpointGrid]);

  const onViewEndpoint = (_e, value) => {
    setSelectedEndpoint(value);
    setModal(MODAL_MODE.EDIT);
  };

  const onChangeSearchInput = (e) => {
    setInputSearch(e.target.value);
  };

  const columns = [
    { field: "id", headerName: "ID", flex: 0.5 },
    {
      field: "url",
      headerName: "URL",
      valueGetter: (params) =>
        params.row.payload_schema === PAYLOAD_SCHEMA.XEALTH_v1_0
          ? formatXealthUrl(params.row.url)
          : params.row.url,
      flex: 1,
    },
    {
      field: "auth_type",
      headerName: "Authentication",
      valueGetter: (params) => AUTH_METHOD_LABELS[params.row.auth?.type],
      flex: 0.8,
    },
    { field: "payload_schema", headerName: "Payload Schema", flex: 0.3 },
  ];

  const onSearch = () => updateEndpointGrid(inputSearch);

  const handleModalClose = () => {
    setModal("");
  };

  const modalTitle = useMemo(() => {
    switch (modal) {
      case MODAL_MODE.CREATE:
        return "Create Endpoint";
      case MODAL_MODE.EDIT:
        return `Endpoint ${selectedEndpoint.id}`;
      case MODAL_MODE.OFFER_HUB_GROUP:
        return "Configure Hub Group";
      default:
        return "";
    }
  }, [modal, selectedEndpoint.id]);

  return (
    <MainContentContainer title="Endpoints" docsUrl={DOCS.ENDPOINTS.url}>
      <div className={classNames(classes.header, classes.mrb15)}>
        <div className={classNames(classes.flex)}>
          <TextField
            className={classes.input}
            value={inputSearch}
            onChange={onChangeSearchInput}
            label="URL"
            name="url"
            size="small"
          />
          <Button color="primary" onClick={onSearch} variant="contained">
            Search
          </Button>
        </div>
        <ProtectedButton
          permission="CreateEndpoints"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setModal(MODAL_MODE.CREATE)}
          variant="contained"
        >
          Create
        </ProtectedButton>
      </div>
      <StelDataGrid
        rows={endpoints}
        columns={columns}
        loading={loading}
        rowClickPermission="UpdateEndpoints"
        onRowClick={(params, e) =>
          params.row.payload_schema === PAYLOAD_SCHEMA.XEALTH_v1_0
            ? null
            : onViewEndpoint(e, params.row)
        }
      />
      {modal &&
        (modal === MODAL_MODE.OFFER_HUB_GROUP ? (
          <ConfirmationModal
            open
            title={modalTitle}
            onClose={handleModalClose}
            onConfirm={() => history.push("/hub-group")}
            message={
              <Typography>
                Do you want to add this endpoint to a Hub Group?
              </Typography>
            }
          />
        ) : (
          <StelModal
            open={!!modal}
            onClose={handleModalClose}
            title={modalTitle}
            width={650}
          >
            <EndpointForm
              isEditMode={modal === MODAL_MODE.EDIT}
              handleModalAfterAction={() => {
                updateEndpointGrid();
                setModal(MODAL_MODE.OFFER_HUB_GROUP);
              }}
              selectedEndpoint={selectedEndpoint}
            />
          </StelModal>
        ))}
    </MainContentContainer>
  );
}

export default EndpointContainer;
