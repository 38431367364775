import { Button, MenuItem, Stack } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

import { useForm } from "react-hook-form";
import { DEVICE_STATE_STATUS } from "../../../utils/constants";
import RhfCheckBox from "../../form/RhfCheckBox";
import RhfTextField from "../../form/RhfTextField";

const emptySearchInputs = {
  macAddress: "",
  serialNumber: "",
  status: "",
  makeId: "",
  modelId: "",
  hubId: "",
  isBlocked: false,
  includeImage: true,
};

function DeviceSearchForm({ onSubmit, isSearchDisabled }) {
  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty },
  } = useForm({
    defaultValues: emptySearchInputs,
  });

  const resetForm = () => {
    reset();
    onSubmit();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack flexDirection="row">
        <RhfTextField
          label="MAC Address"
          name="macAddress"
          control={control}
          size="small"
          sx={{ mr: 2, mb: 2 }}
          variant="standard"
        />
        <RhfTextField
          label="Serial Number"
          name="serialNumber"
          control={control}
          sx={{ mr: 2, mb: 2 }}
          size="small"
          variant="standard"
        />
        <RhfTextField
          label="Hub ID"
          name="hubId"
          control={control}
          size="small"
          sx={{ mr: 2, mb: 2, width: 140 }}
          variant="standard"
          inputProps={{ maxLength: 31 }}
        />
        <RhfTextField
          name="status"
          control={control}
          select
          label="Status"
          size="small"
          variant="standard"
          sx={{ mr: 2, mb: 2, width: 140 }}
        >
          {Object.keys(DEVICE_STATE_STATUS).map((key) => {
            const status = DEVICE_STATE_STATUS[key];
            return (
              <MenuItem value={status.value} key={status.value}>
                {status.label}
              </MenuItem>
            );
          })}
        </RhfTextField>
        <RhfCheckBox
          name="isBlocked"
          label="Blocked"
          control={control}
          sx={{ mr: 2, mb: 2 }}
        />
        <Button
          sx={{ mr: 2, mb: 2 }}
          color="primary"
          type="submit"
          variant="contained"
          disabled={isSearchDisabled}
        >
          Search
        </Button>
        <Button
          color="error"
          variant="contained"
          onClick={resetForm}
          disabled={!isDirty}
          sx={{ mb: 2 }}
        >
          Clear
        </Button>
      </Stack>
    </form>
  );
}

DeviceSearchForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isSearchDisabled: PropTypes.bool,
};

DeviceSearchForm.defaultProps = {
  isSearchDisabled: false,
};

export default DeviceSearchForm;
