import { makeStyles } from "@mui/styles";
import theme from "../../../utils/theme";

const useStyles = makeStyles(() => ({
  form: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  container: {
    display: "flex",
  },
  formField: {
    marginTop: "20px",
    "&:first-child": {
      marginTop: 0,
    },
  },
  btnContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "20px",
  },
  errorContainer: {
    color: "#d32f2f",
    display: "flex",
    flexDirection: "row",
  },
  errorMessage: {
    margin: "0 0 0 5px",
    fontSize: theme.fontSize.small,
  },
  errorIcon: {
    marginTop: "5px",
  },
  hubCountMessage: {
    margin: "0 0 16px 0",
  },
  hubImg: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 40,
    flex: 1,
  },
}));

export default useStyles;
