import { Stack } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import TroubleshootFormSelect from "./TroubleshootForm/components/TroubleshootFormSelect";

function TroubleshootSelectDeviceModelGroup(props) {
  const {
    errors,
    control,
    deviceType,
    deviceMakeId,
    deviceTypeOptions,
    makeOptions,
    modelOptions,
  } = props;

  return (
    <Stack sx={{ gap: "24px" }}>
      <TroubleshootFormSelect
        rules={{
          required: "Device Type is required",
        }}
        label="Device Type *"
        labelId="deviceType"
        options={deviceTypeOptions}
        name="device_type"
        control={control}
        errors={errors}
        sx={{ width: "100%" }}
      />
      <TroubleshootFormSelect
        rules={{
          required: "Device Make Id is required",
        }}
        label="Make *"
        labelId="make"
        options={makeOptions}
        name="device_make_id"
        disabled={!deviceType}
        control={control}
        errors={errors}
        sx={{ width: "100%" }}
      />
      <TroubleshootFormSelect
        rules={{
          required: "Device Model Id is required",
        }}
        label="Model *"
        labelId="model"
        options={modelOptions}
        name="device_model_id"
        disabled={!deviceMakeId}
        control={control}
        errors={errors}
        sx={{ width: "100%" }}
      />
    </Stack>
  );
}

TroubleshootSelectDeviceModelGroup.propTypes = {
  deviceTypeOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  makeOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  modelOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  control: PropTypes.shape({}).isRequired,
  deviceType: PropTypes.string,
  deviceMakeId: PropTypes.number,
  errors: PropTypes.shape({
    device_type: {},
    device_make_id: {},
    device_model_id: {},
  }),
};
TroubleshootSelectDeviceModelGroup.defaultProps = {
  deviceType: "",
  deviceMakeId: null,
  errors: {},
};

export default TroubleshootSelectDeviceModelGroup;
