import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import { Box, Grid, Link, Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { format } from "date-fns";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { ORDER_STATUS } from "../../../utils/constants";
import { get } from "../../../utils/io";
import theme from "../../../utils/theme";
import DownloadButton from "../../DownloadButton";
import MainContentContainer from "../../Layouts/MainContentContainer";

function HubOrder() {
  const { orderId } = useParams();

  const [hubOrder, setHubOrder] = useState({
    id: "",
    created_by: "",
    status: "",
    number_of_hubs: null,
    created_time: null,
    processed_time: null,
    estimated_ship_date: null,
    tracking_numbers: [],
    carrier: "",
    cust_additional_info: "",
    shipping_address: "",
    images: [],
    hub_ids: [],
  });

  const validOrderStatuses = [
    ORDER_STATUS.PENDING_CONFIRM,
    ORDER_STATUS.CONFIRMED,
    ORDER_STATUS.PROCESSED,
    ORDER_STATUS.SHIPPED,
    ORDER_STATUS.COMPLETE,
  ];

  useEffect(() => {
    const getHubOrder = async () => {
      const res = await get(`/hub_orders/${orderId}`);
      return res.status !== 200
        ? enqueueSnackbar(`Unable to retrieve order ${orderId}`, {
            variant: "error",
          })
        : setHubOrder(res.data);
    };

    getHubOrder();
  }, [orderId]);

  const hubOrderInfo = [
    { label: "Number of Hubs", data: hubOrder.number_of_hubs },
    {
      label: "Created On",
      data: new Date(hubOrder.created_time).toLocaleString(),
    },
    {
      label: "Created By",
      data: `${hubOrder.created_by?.first_name} ${hubOrder.created_by?.last_name}`,
    },
    ...(hubOrder.hub_return_id
      ? [
          {
            label: "Generated From Return",
            data: (
              <Link href={`/hub-returns/${hubOrder.hub_return_id}`}>
                {hubOrder.hub_return_id}
              </Link>
            ),
          },
        ]
      : []),
    ...(hubOrder.processed_time
      ? [
          {
            label: "Shipped On",
            data: new Date(hubOrder.processed_time).toLocaleString(),
          },
        ]
      : []),
    ...(hubOrder.estimated_ship_date
      ? [
          {
            label: "Estimated Ship Date",
            data: format(new Date(hubOrder.estimated_ship_date), "MM/dd/yyyy"),
          },
        ]
      : []),
    { label: "Shipping Address", data: hubOrder.shipping_address },
    { label: "Carrier", data: hubOrder.carrier },
    {
      label: "Tracking Number(s)",
      data: hubOrder.tracking_numbers.join(", "),
    },
  ];

  return (
    <MainContentContainer
      title={`Hub Order #${orderId}`}
      analyticsTitle="Hub Order Detail"
    >
      <Stack direction="row" spacing={2}>
        <Stack>
          <Typography
            sx={{ fontWeight: "bold", fontSize: theme.fontSize.large }}
          >
            Status
          </Typography>
          {hubOrder.status === ORDER_STATUS.CANCELLED ? (
            <Typography sx={{ color: "red" }}>Cancelled</Typography>
          ) : (
            <Timeline
              sx={{
                // Left align: https://mui.com/material-ui/react-timeline/#left-aligned-with-no-opposite-content
                [`& .${timelineItemClasses.root}:before`]: {
                  flex: 0,
                  padding: 0,
                },
                mt: 0,
                p: 0,
              }}
            >
              {validOrderStatuses.map((status, index) => (
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot
                      color={status === hubOrder.status ? "primary" : "grey"}
                    />
                    {index !== validOrderStatuses.length - 1 && (
                      <TimelineConnector />
                    )}
                  </TimelineSeparator>
                  <TimelineContent>{status}</TimelineContent>
                </TimelineItem>
              ))}
            </Timeline>
          )}
        </Stack>
        <Stack sx={{ width: "100%" }}>
          <Box
            sx={{
              pl: "10px",
              pt: "10px",
              pb: "10px",
              backgroundColor: grey[200],
              borderRadius: "5px",
            }}
          >
            <Grid
              container
              rowSpacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              {hubOrderInfo.map(({ label, data }) =>
                data ? (
                  <Grid item key={label} xs={8} sm={6} md={3}>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: theme.fontSize.large,
                      }}
                    >
                      {label}
                    </Typography>
                    <Typography sx={{ mt: "5px" }}>{data}</Typography>
                  </Grid>
                ) : null
              )}
            </Grid>
          </Box>
          {[
            ORDER_STATUS.PROCESSED,
            ORDER_STATUS.SHIPPED,
            ORDER_STATUS.COMPLETE,
          ].includes(hubOrder.status) && (
            <Box
              sx={{
                mt: "10px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <DownloadButton
                getData={() => `Hub ID\n${hubOrder.hub_ids.join("\n")}`}
                fileType="text/csv"
                fileName={`hub_order_${
                  hubOrder.id
                }_${new Date().toISOString()}.csv`}
              >
                Export Hub ID List
              </DownloadButton>
            </Box>
          )}
          {hubOrder.images.length > 0 && (
            <Box sx={{ mt: "10px" }}>
              <Typography
                sx={{ fontWeight: "bold", fontSize: theme.fontSize.large }}
              >
                Delivery Images
              </Typography>
              <ImageList
                sx={{
                  overflow: "scroll",
                  width: "100%",
                  height: 300,
                  mt: "10px",
                }}
                cols={4}
                gap={5}
                rowHeight={150}
                variant="quilted"
              >
                {hubOrder.images.map((image) => (
                  <ImageListItem
                    key={image.key}
                    component="a"
                    href={image.presigned_url}
                    target="_blank"
                  >
                    <img
                      src={image.presigned_url}
                      alt={image.key}
                      loading="lazy"
                    />
                  </ImageListItem>
                ))}
              </ImageList>
            </Box>
          )}
        </Stack>
      </Stack>
    </MainContentContainer>
  );
}

export default HubOrder;
