import {
  Box,
  Button,
  Stack,
  Autocomplete,
  TextField,
  Chip,
  Typography,
} from "@mui/material";
import { debounce } from "lodash";
import { enqueueSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { DEVICE_STATE_STATUS } from "../../utils/constants";
import { post, get } from "../../utils/io";
import DeviceMasterDataGrid from "./DeviceMasterDataGrid";

const initialFormValues = {
  devices: [],
  hub: {},
};

const initialInput = {
  serialNumber: "",
  hubId: "",
};

export default function BulkRegisterNewDevicesForm(props) {
  const { onClose, g7SteloModelIds, onSuccess } = props;
  const [input, setInput] = useState(initialInput);
  const [hubs, setHubs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [devices, setDevices] = useState([]);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [isRegistrationSuccessful, setIsRegistrationSuccessful] =
    useState(false);

  const { handleSubmit, control, watch, reset, formState } = useForm({
    defaultValues: initialFormValues,
  });

  const disableSubmitButtons =
    Object.keys(watch("hub")).length === 0 || selectedDevices.length === 0;

  const onSubmit = async (formData, event) => {
    const closeOnSubmit = event.nativeEvent.submitter.id !== "no-close";
    const { hub } = formData;
    const deviceIds = selectedDevices?.map((item) => item?.id);
    const payload = {
      device_ids: deviceIds,
      hub_id: hub.hub_id,
    };
    const registerDexcomsResponse = await post("/dexcom/register", payload);
    if (registerDexcomsResponse.status !== 204) {
      const erroredDeviceIds = registerDexcomsResponse.data?.device_ids || [];
      const snackbarConfig =
        erroredDeviceIds.length > 0
          ? {
              variant: "collapse",
              persist: true,
              type: "error",
              children: erroredDeviceIds.map((deviceId) => (
                <Typography key={deviceId}>
                  {
                    selectedDevices.find((device) => device.id === deviceId)
                      .device_master.serial_number
                  }
                </Typography>
              )),
            }
          : { variant: "error" };
      enqueueSnackbar(registerDexcomsResponse.data.detail, snackbarConfig);
    } else {
      enqueueSnackbar(
        `Registered ${selectedDevices.length} devices to hub ${hub.hub_id}`,
        { variant: "success" }
      );
      onSuccess();
      if (closeOnSubmit) {
        onClose();
      } else {
        setInput(initialInput);
        setSelectedDevices([]);
        setIsRegistrationSuccessful(true);
      }
    }
  };

  const handleSelectDevice = (device) => {
    setSelectedDevices((prev) => {
      const filterDuplicateDevice = prev.find((item) => item.id === device.id);
      if (filterDuplicateDevice) {
        return [...prev];
      }
      return [...prev, device];
    });
    setInput((pre) => ({ ...pre, serialNumber: "" }));
  };

  const handleRemoveSelectedDevice = (deviceId) => {
    const updatedSelectedDevices = selectedDevices.filter(
      (item) => item.id !== deviceId
    );
    setSelectedDevices(updatedSelectedDevices);
  };

  useEffect(() => {
    const getHubs = async () => {
      setIsLoading(true);
      const { status, data } = await get("/hubs", {
        offset: 0,
        limit: 10,
        hub_id: input.hubId,
      });
      if (status === 200) {
        setHubs(data.hubs);
      } else {
        enqueueSnackbar(data.detail, { variant: "error" });
      }
      setIsLoading(false);
    };
    getHubs();
  }, [input.hubId]);

  useEffect(() => {
    const getDevices = debounce(async () => {
      setIsLoading(true);
      const deviceListResponse = await get("/devices", {
        model_ids: g7SteloModelIds,
        serial_number: input.serialNumber,
      });
      if (deviceListResponse?.status === 200) {
        const devicesWithSerialNumber = deviceListResponse.data.devices.filter(
          (device) => device?.serial_number
        );
        setDevices(devicesWithSerialNumber);
      } else {
        enqueueSnackbar("Unable to retrieve devices", {
          variant: "error",
        });
      }
      setIsLoading(false);
    }, 300);
    getDevices();
    return getDevices.cancel;
  }, [input.serialNumber, g7SteloModelIds]);

  useEffect(() => {
    // Only clear form if API call to register devices was successful
    if (formState.isSubmitSuccessful && isRegistrationSuccessful) {
      reset(initialFormValues);
      setIsRegistrationSuccessful(false);
    }
  }, [formState, isRegistrationSuccessful, reset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ mb: 2 }}>
        <Box
          sx={{
            width: 170,
            fontWeight: 700,
            display: "flex",
            mb: "10px",
            alignItems: "center",
          }}
        >
          Hub ID <Box sx={{ color: "red" }}> *</Box>
        </Box>
        <Controller
          name="hub"
          control={control}
          render={({ field: { onChange, value: hubValue } }) => (
            <Autocomplete
              loading={isLoading}
              options={hubs || []}
              size="small"
              getOptionLabel={(option) => option?.hub_id || ""}
              onInputChange={(_, value) =>
                setInput((prev) => ({ ...prev, hubId: value }))
              }
              value={hubValue}
              onChange={(_, value) => onChange(value || {})}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Start typing to search for a hub"
                />
              )}
              isOptionEqualToValue={(option, value) =>
                option?.esn === value?.esn
              }
              clearOnBlur={false}
            />
          )}
        />
      </Box>
      <Box sx={{ mb: 2 }}>
        <Box
          sx={{
            width: 170,
            fontWeight: 700,
            display: "flex",
            mb: "10px",
            alignItems: "center",
          }}
        >
          Serial Number <Box sx={{ color: "red" }}>*</Box>
        </Box>
        <Controller
          name="devices"
          control={control}
          render={({ field: { onChange, value: serialNumberValue } }) => (
            <Autocomplete
              id="devices"
              size="small"
              options={devices || []}
              getOptionLabel={(option) => option?.serial_number || ""}
              onInputChange={(_, value, reason) =>
                reason !== "reset" &&
                setInput((prev) => ({ ...prev, serialNumber: value }))
              }
              inputValue={input.serialNumber}
              value={serialNumberValue}
              renderOption={(renderProps, option) => (
                <DeviceIndicator option={option} {...renderProps} />
              )}
              onChange={(_, value, reason) => {
                if (reason !== "selectOption") onChange(value);
                if (reason !== "clear") handleSelectDevice(value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Start typing to search for a device"
                />
              )}
              getOptionDisabled={(option) =>
                option?.status !== DEVICE_STATE_STATUS.INVENTORY.value ||
                selectedDevices.some((selected) => selected?.id === option?.id)
              }
              clearOnBlur={false}
              isOptionEqualToValue={(option, value) =>
                option.id === value?.id || ""
              }
            />
          )}
        />
        <Box sx={{ mt: "10px" }}>
          <DeviceMasterDataGrid
            selectedDevices={selectedDevices}
            onRemoveSelectedDevice={handleRemoveSelectedDevice}
          />
        </Box>
        <Stack flexDirection="row" justifyContent="flex-end" mt="10px">
          <Typography
            sx={{ color: "gray", fontSize: "14px", lineHeight: "10px" }}
          >
            {selectedDevices.length} Devices Selected
          </Typography>
        </Stack>
      </Box>
      <Stack
        sx={{ flexDirection: "row", justifyContent: "flex-end", mt: "10px" }}
      >
        <Button
          color="primary"
          type="submit"
          sx={{ width: "fit-content" }}
          variant="contained"
          disabled={disableSubmitButtons}
        >
          Save
        </Button>
        <Button
          color="primary"
          type="submit"
          id="no-close"
          sx={{ width: "fit-content", ml: "10px" }}
          variant="contained"
          disabled={disableSubmitButtons}
        >
          Save & Register More
        </Button>
      </Stack>
    </form>
  );
}

export function DeviceIndicator(props) {
  const { option, ...rest } = props;
  return (
    <Box
      {...rest}
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "5px",
        borderBottom: "0.5px solid #C0C0C0",
        padding: "5px",
      }}
    >
      <div>
        <div>
          {option?.serial_number}
          {option?.device_master?.bluetooth_code &&
            ` (${option.device_master.bluetooth_code})`}
        </div>
      </div>
      <Chip
        label={DEVICE_STATE_STATUS[option?.status].label}
        size="small"
        color={DEVICE_STATE_STATUS[option?.status].color}
      />
    </Box>
  );
}

DeviceIndicator.propTypes = {
  option: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object,
      PropTypes.array,
      PropTypes.bool,
    ])
  ).isRequired,
};

BulkRegisterNewDevicesForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  g7SteloModelIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  onSuccess: PropTypes.func.isRequired,
};
