import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import { DOCS } from "../../../utils/constants";
import { post } from "../../../utils/io";
import BindHubModal from "../../BindHubModal";
import ButtonWithTooltip from "../../ButtonWithTooltip";
import showMultiResultsSnackbar from "./utils";

function BindDevicesButton(props) {
  const {
    disabled,
    bindingsToCreate,
    handleSuccessfulBind,
    buttonText,
    buttonProps,
  } = props;
  const anchorRef = useRef(null);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleBindDevices = async (bindings) => {
    setLoading(true);
    const bindDeviceCalls = bindings.map(({ hubId, macAddress }) =>
      post("/device_bindings", { hub_id: hubId, device_mac: macAddress })
    );
    const resBindDevices = await Promise.all(bindDeviceCalls);
    const allSuccessful = showMultiResultsSnackbar(
      resBindDevices,
      201,
      (successCount) => `${successCount} device(s) bound`,
      (failureCount) => `${failureCount} device(s) failed to bind`
    );
    if (allSuccessful && handleSuccessfulBind) handleSuccessfulBind();
    setLoading(false);
    return allSuccessful;
  };

  const handleCloseModal = () => setOpenModal(false);

  const handleBindDevicesToOtherHub = async (hubId) => {
    const success = await handleBindDevices(
      bindingsToCreate.map(({ macAddress }) => ({
        hubId,
        macAddress,
      }))
    );
    if (success) handleCloseModal();
  };

  const BIND_DEVICE_OTIONS = [
    {
      id: 1,
      title: "Bind to transmitting hub",
      description:
        "Bind the devices used for these measurements to their respective transmitting hubs",
      onClick: () => handleBindDevices(bindingsToCreate),
    },
    {
      id: 2,
      title: "Bind to a different hub",
      description:
        "Select a different hub to create a binding with these devices",
      onClick: () => setOpenModal(true),
    },
  ];

  const handleToggleDropdown = () => {
    setOpenDropdown((prevOpen) => !prevOpen);
  };

  const handleClickDropdownOption = async (onClick) => {
    await onClick();
    handleToggleDropdown();
  };

  return (
    <>
      <ButtonWithTooltip
        ref={anchorRef}
        description={DOCS.BIND_DEVICE.description}
        url={DOCS.BIND_DEVICE.url}
        onClick={handleToggleDropdown}
        endIcon={<ArrowDropDownIcon />}
        disabled={disabled}
        title={buttonText}
        component={LoadingButton}
        loading={loading}
        sx={{
          padding: "5px 10px",
          mr: "10px",
          "& .MuiButton-endIcon": { ml: "4px" },
          textTransform: "capitalize",
        }}
        variant="outlined"
        {...buttonProps}
      />
      <Popper
        open={openDropdown}
        anchorEl={anchorRef.current}
        transition
        placement="bottom-start"
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleToggleDropdown}>
                <MenuList
                  autoFocusItem
                  sx={{
                    padding: 0,
                    marginTop: "4px",
                  }}
                >
                  {BIND_DEVICE_OTIONS.map((option) => (
                    <MenuItem
                      key={option.id}
                      onClick={() => handleClickDropdownOption(option.onClick)}
                      sx={{
                        display: "block",
                        borderBottom: "1px solid #CDD6DC",
                        width: 310,
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        lineHeight: 1.25,
                        "&:last-child": {
                          border: "none",
                        },
                        "& > span": {
                          whiteSpace: "normal",
                          fontWeight: 400,
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          margin: "5px 0",
                          color: "#42526E",
                          fontWeight: "bold",
                        }}
                      >
                        {option.title}
                      </Typography>
                      <span style={{ fontSize: "13px", width: "280px" }}>
                        {option.description}
                      </span>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <BindHubModal
        onClose={handleCloseModal}
        title="Bind to Other Hub"
        open={openModal}
        handleBindDevices={(hubId) => handleBindDevicesToOtherHub(hubId)}
      />
    </>
  );
}

BindDevicesButton.propTypes = {
  disabled: PropTypes.bool,
  bindingsToCreate: PropTypes.arrayOf(
    PropTypes.shape({
      macAddress: PropTypes.string.isRequired,
      hubId: PropTypes.string.isRequired,
    })
  ).isRequired,
  handleSuccessfulBind: PropTypes.func,
  buttonText: PropTypes.string,
  buttonProps: PropTypes.shape({}),
};

BindDevicesButton.defaultProps = {
  disabled: false,
  handleSuccessfulBind: null,
  buttonText: "Bind Devices",
  buttonProps: {},
};

export default BindDevicesButton;
