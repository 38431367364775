import { TextField } from "@mui/material";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import useStyles from "../OrderModal/styles";

export default function InputText(props) {
  const classes = useStyles();
  const {
    value,
    name,
    onChangeField,
    label,
    multiline = false,
    rows = 1,
    ...rest
  } = props;

  return (
    <>
      <div
        className={classNames(
          classes.w170px,
          classes.bold,
          classes.flex,
          classes.alignCenter,
          classes.mrb10
        )}
      >
        {label}
      </div>
      <TextField
        id={`${name}-filed`}
        InputLabelProps={{ shrink: true }}
        value={value}
        onChange={onChangeField}
        name={name}
        size="small"
        className={classes.input400}
        variant="outlined"
        required
        multiline={multiline}
        rows={rows}
        autoComplete="new-input"
        {...rest}
      />
    </>
  );
}

InputText.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  multiline: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  rows: PropTypes.number,
  onChangeField: PropTypes.func.isRequired,
};
InputText.defaultProps = {
  multiline: false,
  rows: 1,
};
