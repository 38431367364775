import { grey } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  mrb15: {
    marginBottom: "15px",
  },
  mrr10: {
    marginRight: "10px",
  },
  mrl24: {
    marginLeft: 24,
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
  spaceBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
  w100: {
    width: "100%",
  },
  mr0: {
    margin: 0,
  },

  mrb10: {
    marginBottom: 10,
  },
  mrb5: {
    marginBottom: 5,
  },
  mrl15: {
    marginLeft: 15,
  },
  fontMonospace: {
    "& > .MuiInputBase-root > textarea": {
      fontFamily: "monospace",
    },
  },
  capitalize: {
    textTransform: "capitalize",
  },
  fontItalic: {
    fontStyle: "italic",
  },
  borderLeft: {
    boxShadow: "rgb(0 0 0 / 75%) -15px 0px 0px 0px",
    borderRadius: "10px",
    marginLeft: 40,
    background: "transparent",
  },
  hoverBg: {
    color: "#545454",
    margin: "4px 0",
    "&:hover": {
      background: "#ECECF3",
    },
  },
  measureType: {
    width: 150,
    fontWeight: "bold",
  },
  alignItems: {
    alignItems: "center",
  },
  noneBorder: {
    "& .MuiInputBase-root": {
      background: "#ECECF3",
      borderRadius: 0,
      fontSize: 14,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#fff !important",
      border: 0,
    },
    "& .MuiInputBase-root-MuiOutlinedInput-root": {
      borderRadius: 0,
    },
  },
  relative: {
    position: "relative",
  },
  absolute: {
    position: "absolute",
  },
  copyIcon: {
    right: 15,
    top: 15,
    cursor: "pointer",
    fontSize: 20,
  },
  smallText: {
    fontSize: 15,
    color: grey[500],
    margin: 0,
  },
  weightBold: {
    fontWeight: "bold",
  },
  boxShadown: {
    boxShadow: "0px 1px 2px rgba(0,0,0,0.39)",
  },
  fs14: {
    fontSize: 14,
  },
  fs16: {
    fontSize: 16,
  },
  fs20: {
    fontSize: 20,
  },
  w150: {
    width: 150,
  },
  inlineBreakword: {
    display: "inline",
    wordBreak: "break-all",
  },
  arrowIcon: {
    "&:hover": {
      background: "#ECECF3",
    },
  },
  mrt15: {
    marginTop: 15,
  },
}));

export default useStyles;
