import { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { successfulLoginHandler } from "../utils/io";

function OauthTokenCallback() {
  const location = useLocation();
  const { hash } = location;
  const history = useHistory();
  const p = new URLSearchParams(hash.substring(1));
  const idToken = p.get("id_token");
  const state = JSON.parse(p.get("state"));
  const { redirect } = state;

  useEffect(() => {
    const handleLoginAndRedirect = async () => {
      await successfulLoginHandler({ access: idToken });
      history.push(redirect);
    };
    handleLoginAndRedirect();
  });

  return null;
}

export default OauthTokenCallback;
