import { Autocomplete, TextField } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { get } from "../../utils/io";

function HubAutoComplete(props) {
  const { onChange, ...rest } = props;
  const [loading, setLoading] = useState(false);
  const [hubs, setHubs] = useState([]);
  const [input, setInput] = useState("");

  useEffect(() => {
    const getHubs = async () => {
      setLoading(true);
      const { status, data } = await get("/hubs", {
        offset: 0,
        limit: 10,
        hub_id: input,
      });
      if (status !== 200) {
        enqueueSnackbar("Unable to retrieve hubs", { variant: "error" });
      } else {
        setHubs(data.hubs);
      }
      setLoading(false);
    };
    getHubs();
  }, [input]);

  return (
    <Autocomplete
      loading={loading}
      options={hubs}
      size="small"
      getOptionLabel={(option) => option?.hub_id || ""}
      onInputChange={(_, value) => setInput(value)}
      onChange={onChange}
      renderInput={(params) => (
        <TextField {...params} placeholder="Start typing to search for a hub" />
      )}
      isOptionEqualToValue={(option, value) =>
        option?.esn === value?.esn || option?.hub_id === value?.hub_id
      }
      clearOnBlur={false}
      {...rest}
    />
  );
}

HubAutoComplete.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default HubAutoComplete;
