import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { omit, toLower } from "lodash";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toCamelCase, toSnakeCase } from "../../utils";
import { post } from "../../utils/io";
import {
  EndpointFormLabel,
  PAYLOAD_SCHEMA,
  PayloadSchemaFormField,
  URLFormField,
} from "./EndpointFormUtils";
import OauthClientCredentialsForm from "./OauthClientCredentialsForm";
import StaticHeaderTokenForm from "./StaticHeaderTokenForm";
import StaticQueryTokenForm from "./StaticQueryTokenForm";

export const AUTH_METHODS = Object.freeze({
  STATIC_HEADER_TOKEN: {
    value: "STATIC_HEADER_TOKEN",
    label: "Static Header Token",
    form: StaticHeaderTokenForm,
    sensitiveFieldName: "value",
    defaultValues: {
      scheme: "Token",
      headerName: "Authorization",
      value: "",
    },
  },
  STATIC_QUERY_TOKEN: {
    value: "STATIC_QUERY_TOKEN",
    label: "Static Query Token",
    form: StaticQueryTokenForm,
    sensitiveFieldName: "value",
    defaultValues: {
      paramName: "token",
      value: "",
    },
  },
  OAUTH_CLIENT_CREDENTIALS: {
    value: "OAUTH_CLIENT_CREDENTIALS",
    label: "Oauth Client Credentials",
    form: OauthClientCredentialsForm,
    sensitiveFieldName: "clientSecret",
    defaultValues: {
      tokenEndpoint: "",
      clientId: "",
      clientSecret: "",
    },
  },
});

const getDefaultFormValues = (authMethodConfig, endpoint) => ({
  ...authMethodConfig.defaultValues,
  ...(endpoint?.url
    ? {
        url: endpoint.url,
        // Don't include the current auth config if switching to a different
        // auth method
        ...(authMethodConfig.value === endpoint.auth?.type &&
          toCamelCase(endpoint.auth.config)),
        payloadSchema: endpoint.payload_schema,
      }
    : { url: "", payloadSchema: PAYLOAD_SCHEMA.STEL_V2_0 }),
});

function EndpointForm(props) {
  const { isEditMode, handleModalAfterAction, selectedEndpoint } = props;

  const [selectedAuthMethod, setSelectedAuthMethod] = useState(
    selectedEndpoint?.auth?.type || AUTH_METHODS.STATIC_HEADER_TOKEN.value
  );
  const [loading, setLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const selectedAuthMethodConfig = AUTH_METHODS[selectedAuthMethod];

  const currentPayloadSchema =
    selectedEndpoint?.payload_schema || PAYLOAD_SCHEMA.STEL_V2_0;

  const isSwitchingAuthMethod =
    selectedAuthMethodConfig.value !== selectedEndpoint?.auth?.type;

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, dirtyFields },
  } = useForm({
    defaultValues: getDefaultFormValues(
      selectedAuthMethodConfig,
      selectedEndpoint
    ),
  });

  const isSensitiveFieldDirty =
    selectedAuthMethodConfig.sensitiveFieldName in dirtyFields;

  useEffect(() => {
    // Updating the default form values when switching auth method to clear existing
    // form values for controlled components (react-hook-form requires this)
    reset(getDefaultFormValues(selectedAuthMethodConfig, selectedEndpoint));
  }, [selectedAuthMethodConfig, selectedEndpoint, reset]);

  const onSubmit = async (formData) => {
    setLoading(true);
    const { url, payloadSchema, ...authConfig } = formData;
    const body = {
      url,
      auth: {
        type: selectedAuthMethodConfig.value,
        // Only update the sensitive value (token/client secret) if switching to a new
        // auth method, updating the value for an existing auth method, or creating a
        // new endpoint.
        config: toSnakeCase(
          isSwitchingAuthMethod || isSensitiveFieldDirty
            ? authConfig
            : omit(authConfig, selectedAuthMethodConfig.sensitiveFieldName)
        ),
      },
      ...(payloadSchema !== PAYLOAD_SCHEMA.STEL_V1_0 && {
        payload_schema: toLower(payloadSchema).replace(" ", "-"),
      }),
    };
    const res = await post(
      `/endpoints${isEditMode ? `/${selectedEndpoint.id}` : ""}`,
      body
    );
    if ([200, 201].includes(res.status)) {
      enqueueSnackbar(
        isEditMode
          ? `Saved endpoint ${selectedEndpoint.id}`
          : `Created endpoint ${res.data.id}`,
        { variant: "success" }
      );
      handleModalAfterAction();
    } else {
      enqueueSnackbar(res.data?.detail || "Failed to save endpoint", {
        variant: "error",
        persist: true,
      });
    }
    setLoading(false);
  };

  return (
    <Box>
      <Grid container sx={{ mb: "15px" }} columnSpacing="15px">
        <Grid item xs={6}>
          <EndpointFormLabel
            value="Authentication Method"
            description="The method in which Stel will authenticate with your endpoint. Please select the option most appropriate to your system. All values are required."
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel>Authentication Method</InputLabel>
            <Select
              size="small"
              value={selectedAuthMethod}
              label="Authentication Method"
              onChange={(e) => setSelectedAuthMethod(e.target.value)}
            >
              {Object.keys(AUTH_METHODS).map((authMethod) => (
                <MenuItem
                  key={authMethod}
                  value={AUTH_METHODS[authMethod].value}
                >
                  {AUTH_METHODS[authMethod].label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Divider sx={{ mb: "15px" }} flexItem />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container rowSpacing="15px" columnSpacing="15px">
          <URLFormField control={control} errors={errors} />
          <selectedAuthMethodConfig.form
            control={control}
            errors={errors}
            sensitiveFieldProps={{
              showExistingTokenMessage: isEditMode,
              validateToken: isSwitchingAuthMethod || isSensitiveFieldDirty,
            }}
          />
          <PayloadSchemaFormField
            payloadSchema={currentPayloadSchema}
            control={control}
          />
        </Grid>
        <Stack sx={{ mt: "15px" }} alignItems="flex-end">
          <Button
            color="primary"
            type="submit"
            variant="contained"
            disabled={loading}
          >
            Save
          </Button>
        </Stack>
      </form>
    </Box>
  );
}

EndpointForm.propTypes = {
  isEditMode: PropTypes.bool.isRequired,
  handleModalAfterAction: PropTypes.func.isRequired,
  selectedEndpoint: PropTypes.shape({
    id: PropTypes.string,
    url: PropTypes.string,
    payload_schema: PropTypes.string,
    auth: PropTypes.shape(),
  }),
};

EndpointForm.defaultProps = {
  selectedEndpoint: {},
};

export default EndpointForm;
