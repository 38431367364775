import { Stack } from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import MeasureList from "../MeasureList";

function RecentMeasures(props) {
  const [autoRefreshMeasure, setAutoRefreshMeasure] = useState(false);
  const {
    loading,
    measures,
    measureSelection,
    setMeasureSelection,
    onChangeBindings,
  } = props;
  return (
    <Stack>
      <h2>Recent Device Measures</h2>
      <MeasureList
        checkbox
        loading={loading}
        onSelectionChanged={setMeasureSelection}
        selectedMeasures={measureSelection}
        searchResults={measures}
        rows={measures}
        paginationMode="client"
        hideColumns={["hub_id", "device_make", "device_model"]}
        rowCount={measures?.length || 0}
        onChangeBindings={onChangeBindings}
        setAutoRefreshMeasure={setAutoRefreshMeasure}
        autoRefreshMeasure={autoRefreshMeasure}
      />
    </Stack>
  );
}

export default RecentMeasures;

RecentMeasures.propTypes = {
  loading: PropTypes.bool.isRequired,
  measures: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  measureSelection: PropTypes.arrayOf(PropTypes.string).isRequired,
  setMeasureSelection: PropTypes.func.isRequired,
  onChangeBindings: PropTypes.func.isRequired,
};
