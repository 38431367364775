import PropTypes from "prop-types";
import React from "react";

import {
  FormField,
  SensitiveFormField,
  getMaxLengthRule,
} from "./EndpointFormUtils";

export default function StaticQueryTokenForm(props) {
  const { control, errors, sensitiveFieldProps } = props;

  return (
    <>
      <FormField
        name="paramName"
        label="Param Name"
        description="The query parameter name sent with every request as 'https://example.com?<paramName>=<token>'"
        rules={getMaxLengthRule(60)}
        control={control}
        errors={errors}
      />
      <SensitiveFormField
        name="value"
        label="Token"
        description="The token sent in the header for every request. Your system can use this to verify the request is coming from Stel"
        rules={getMaxLengthRule(4000)}
        control={control}
        errors={errors}
        {...sensitiveFieldProps}
      />
    </>
  );
}

StaticQueryTokenForm.propTypes = {
  control: PropTypes.shape().isRequired,
  errors: PropTypes.shape().isRequired,
  sensitiveFieldProps: PropTypes.shape({
    showExistingTokenMessage: PropTypes.bool,
    validateToken: PropTypes.bool,
  }),
};

StaticQueryTokenForm.defaultProps = {
  sensitiveFieldProps: {
    showExistingTokenMessage: false,
    validateToken: true,
  },
};
