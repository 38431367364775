import BlockIcon from "@mui/icons-material/Block";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import { Tooltip, Chip, Stack, Typography, Box } from "@mui/material";
import { blue, yellow, green, red } from "@mui/material/colors";
import PropTypes from "prop-types";
import React from "react";
import { useHistory } from "react-router-dom";
import { DOCS, FEATURE_FLAGS } from "../../../utils/constants";
import { getFeatureFlag, isAdmin } from "../../../utils/io";
import ButtonWithTooltip from "../../ButtonWithTooltip";
import DocsTooltip from "../../DocsTooltip";
import HubIcon from "../../icons/Hub";
import TroubleshootIcon from "../../icons/Troubleshoot";
import VirtualHubIndicator from "../../VirtualHubIndicator";

function ConnectionStatusIcon({ isConnected }) {
  return (
    <Tooltip title="Connection Status">
      {isConnected ? (
        <CheckCircleIcon sx={{ color: green[700] }} />
      ) : (
        <CancelIcon sx={{ color: red[700] }} />
      )}
    </Tooltip>
  );
}

ConnectionStatusIcon.propTypes = {
  isConnected: PropTypes.bool,
};
ConnectionStatusIcon.defaultProps = {
  isConnected: false,
};

function CustomMenuItem({ label, description, hub }) {
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
      <Box sx={{ width: 56, height: 56, position: "relative" }}>
        <HubIcon {...hub} />
        {hub.isAnimation && (
          <>
            <Box
              sx={{
                position: "absolute",
                width: "10px",
                height: "10px",
                right: "6.5px",
                bottom: "13px",
                backgroundColor: "#F00",
                borderRadius: "50%",
                cursor: "pointer",
                animation: "colorChange 1s infinite alternate",
                "@keyframes colorChange": {
                  "0%": {
                    backgroundColor: "#F00",
                  },
                  "100%": {
                    backgroundColor: "transparent",
                  },
                },
              }}
            />
            <Box
              sx={{
                position: "absolute",
                width: "3.7px",
                height: "3.7px",
                right: "36.5px",
                bottom: "18.75px",
                backgroundColor: "#F00",
                borderRadius: "50%",
                cursor: "pointer",
                animation: "colorChange 1s infinite alternate",
                "@keyframes colorChange": {
                  "0%": {
                    backgroundColor: "#F00",
                  },
                  "100%": {
                    backgroundColor: "transparent",
                  },
                },
              }}
            />
          </>
        )}
      </Box>
      <Box>
        <Typography fontWeight={600}>{label}</Typography>
        <Typography fontSize={14} color="#00000099">
          {description}
        </Typography>
      </Box>
    </Box>
  );
}

CustomMenuItem.propTypes = {
  label: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  hub: PropTypes.shape().isRequired,
};

function VersionStatusChip({ version }) {
  return (
    <>
      {version && (
        <Tooltip title="Version Status">
          <Chip
            label={version}
            size="small"
            variant="outlined"
            sx={[
              { fontWeight: "bold", textTransform: "capitalize" },
              version === "latest" && {
                color: green[700],
                background: green[300],
                borderColor: green[700],
              },
              version === "updating" && {
                color: yellow[700],
                background: yellow[200],
                borderColor: yellow[700],
              },
              version === "requires update" && {
                color: blue[800],
                background: blue[300],
                borderColor: blue[800],
              },
            ]}
          />
        </Tooltip>
      )}
    </>
  );
}

VersionStatusChip.propTypes = {
  version: PropTypes.string,
};
VersionStatusChip.defaultProps = {
  version: "",
};

function ExpectingDevicesChip(props) {
  const { expectingDevices } = props;
  const chipData = {
    false: {
      icon: <BlockIcon style={{ color: yellow[800] }} />,
      label: "Holding new devices",
      style: {
        color: yellow[800],
        background: "rgba(245, 127, 23, 0.08)",
        borderColor: yellow[800],
      },
      tooltipInfo: DOCS.HUB_HOLDING_DEVICES,
    },
    true: {
      icon: <MoreTimeIcon style={{ color: "1F79E2" }} />,
      label: "Awaiting new devices",
      style: {
        color: "#1F79E2",
        background: "rgba(31, 121, 226, 0.08)",
        borderColor: "#1F79E2",
      },
      tooltipInfo: DOCS.HUB_EXPECTING_DEVICES,
    },
  };
  const chipInfo = chipData[expectingDevices];

  return (
    <DocsTooltip
      description={chipInfo.tooltipInfo.description}
      docsUrl={chipInfo.tooltipInfo.url}
    >
      <Chip
        icon={chipInfo.icon}
        label={chipInfo.label}
        variant="outlined"
        sx={[
          { fontWeight: "bold", textTransform: "capitalize" },
          chipInfo.style,
        ]}
      />
    </DocsTooltip>
  );
}

ExpectingDevicesChip.propTypes = {
  expectingDevices: PropTypes.bool.isRequired,
};

function HubDetailHeader(props) {
  const { hubId, hub, showActiveTicketIndicator, showExpectingDevicesChip } =
    props;
  const history = useHistory();

  const handleMenuClick = () => {
    history.push(`/hubs/${hubId}/troubleshoot`);
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        gap: "12px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "10px",
        }}
      >
        <Typography
          sx={{
            fontSize: 24,
            fontWeight: 700,
          }}
        >
          {hubId.substring(0, 10)}
        </Typography>
        <Stack direction="row" spacing={1}>
          {hub.is_virtual ? (
            <VirtualHubIndicator />
          ) : (
            <>
              {hub?.connected !== null && (
                <ConnectionStatusIcon isConnected={hub.connected} />
              )}
              <VersionStatusChip version={hub.version_status} />
            </>
          )}
        </Stack>
        {showExpectingDevicesChip &&
          typeof hub.expecting_devices === "boolean" && (
            <ExpectingDevicesChip expectingDevices={hub.expecting_devices} />
          )}
      </Box>

      {(getFeatureFlag(FEATURE_FLAGS.TROUBLESHOOT) || isAdmin()) && (
        <Box>
          <ButtonWithTooltip
            startIcon={<TroubleshootIcon color="#1F79E2" />}
            variant="outlined"
            onClick={handleMenuClick}
            url={DOCS.TROUBLESHOOT.url}
            title="Troubleshoot"
            description={DOCS.TROUBLESHOOT.description}
          >
            {showActiveTicketIndicator && (
              <Box
                sx={{
                  width: "20px",
                  height: "20px",
                  position: "absolute",
                  background: "#FFB342",
                  borderRadius: "50%",
                  top: -6,
                  right: -6,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{ color: "white", fontWeight: 600, fontSize: 14 }}
                >
                  !
                </Typography>
              </Box>
            )}
          </ButtonWithTooltip>
        </Box>
      )}
    </Box>
  );
}

HubDetailHeader.propTypes = {
  hubId: PropTypes.string.isRequired,
  hub: PropTypes.shape({
    connected: PropTypes.bool,
    is_virtual: PropTypes.bool,
    version_status: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.oneOf([null]),
    ]),
    expecting_devices: PropTypes.bool,
  }),
  showActiveTicketIndicator: PropTypes.bool,
  showExpectingDevicesChip: PropTypes.bool,
};
HubDetailHeader.defaultProps = {
  hub: {
    connected: false,
    is_virtual: false,
    version_status: null,
    expecting_devices: null,
  },
  showActiveTicketIndicator: false,
  showExpectingDevicesChip: false,
};

export default HubDetailHeader;
