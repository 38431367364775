import { ErrorMessage } from "@hookform/error-message";
import { MenuItem, Stack, Typography } from "@mui/material";
import { red } from "@mui/material/colors";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import RhfTextField from "../../../form/RhfTextField";

function TroubleshootFormSelect(props) {
  const { label, options, name, disabled, control, errors, ...rest } = props;

  return (
    <Stack sx={{ flex: 1 }}>
      <RhfTextField
        sx={{ minWidth: 150, maxWidth: 280 }}
        size="small"
        control={control}
        disabled={disabled}
        select
        name={name}
        label={label}
        error={!isEmpty(errors[name])}
        {...rest}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            sx={{ textTransform: "capitalize" }}
          >
            {option.label}
          </MenuItem>
        ))}
      </RhfTextField>
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => (
          <Typography sx={{ color: red[500], fontSize: "12px", ml: "10px" }}>
            {message}
          </Typography>
        )}
      />
    </Stack>
  );
}

TroubleshootFormSelect.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  control: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}),
};
TroubleshootFormSelect.defaultProps = {
  disabled: false,
  errors: {},
};

export default TroubleshootFormSelect;
