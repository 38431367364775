import { ErrorMessage } from "@hookform/error-message";
import { Visibility, VisibilityOff, WarningAmber } from "@mui/icons-material";
import {
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  FormHelperText,
  Box,
  Select,
  MenuItem,
  Link,
} from "@mui/material";
import { blue, grey, red, yellow } from "@mui/material/colors";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Controller } from "react-hook-form";
import RhfTextField from "../form/RhfTextField";

export const PAYLOAD_SCHEMA = Object.freeze({
  STEL_V1_0: "Stel v1.0",
  STEL_V2_0: "Stel v2.0",
  HL7_V2_4: "HL7 v2.4",
  XEALTH_v1_0: "Xealth v1.0",
});

export function EndpointFormLabel(props) {
  const { value, description } = props;

  return (
    <Stack height="100%" width="100%" direction="row" alignItems="flex-start">
      <Stack>
        <Typography sx={{ fontWeight: "bold" }}>{value}</Typography>
        {description && (
          <Typography sx={{ fontSize: "12px", color: grey[700] }}>
            {typeof description === "function" ? description() : description}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
}

EndpointFormLabel.propTypes = {
  value: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

EndpointFormLabel.defaultProps = {
  description: "",
};

function FormErrorText(props) {
  const { value } = props;

  return (
    <Typography sx={{ color: red[700], fontSize: "12px" }}>{value}</Typography>
  );
}

FormErrorText.propTypes = {
  value: PropTypes.string,
};

FormErrorText.defaultProps = {
  value: "",
};

export function FormField(props) {
  const { name, label, description, control, rules, errors } = props;

  return (
    <>
      <Grid item xs={6}>
        <EndpointFormLabel value={label} description={description} />
      </Grid>
      <Grid item xs={6}>
        <RhfTextField
          size="small"
          name={name}
          label={label}
          control={control}
          controllerProps={{ shouldUnregister: true }}
          fullWidth
          rules={{
            required: {
              value: true,
              message: "This field is required.",
            },
            ...rules,
          }}
        />
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => <FormErrorText value={message} />}
        />
      </Grid>
    </>
  );
}

FormField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  control: PropTypes.shape().isRequired,
  rules: PropTypes.shape(),
  errors: PropTypes.shape().isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

FormField.defaultProps = {
  rules: {},
  description: "",
};

export function SensitiveFormField(props) {
  const {
    name,
    label,
    control,
    rules,
    errors,
    showExistingTokenMessage,
    validateToken,
    description,
  } = props;

  const [showSensitiveValue, setShowSensitiveValue] = useState(false);

  return (
    <>
      <Grid item xs={6}>
        <EndpointFormLabel value={label} description={description} />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name={name}
          control={control}
          rules={{
            ...rules,
            validate: (v) => (validateToken ? v.length > 0 : true),
          }}
          shouldUnregister
          render={({ field }) => (
            <FormControl size="small" fullWidth>
              <InputLabel>{label}</InputLabel>
              <OutlinedInput
                type={showSensitiveValue ? "text" : "password"}
                label={label}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowSensitiveValue((pre) => !pre)}
                      edge="end"
                      size="large"
                    >
                      {showSensitiveValue ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                {...field}
              />
              <ErrorMessage
                errors={errors}
                name={name}
                render={({ message }) => (
                  <FormErrorText
                    value={
                      (message || "").length > 0
                        ? message
                        : "This field is required."
                    }
                  />
                )}
              />
              {showExistingTokenMessage && (
                <FormHelperText>
                  Existing token values are never exposed, but you can update
                  the token by entering a new value here.
                </FormHelperText>
              )}
            </FormControl>
          )}
        />
      </Grid>
    </>
  );
}

SensitiveFormField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  control: PropTypes.shape().isRequired,
  rules: PropTypes.shape(),
  errors: PropTypes.shape().isRequired,
  showExistingTokenMessage: PropTypes.bool,
  validateToken: PropTypes.bool,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

SensitiveFormField.defaultProps = {
  rules: {},
  showExistingTokenMessage: false,
  validateToken: true,
  description: "",
};

export const getMaxLengthRule = (maxLength) => ({
  maxLength: {
    value: maxLength,
    message: `This field cannot be more than ${maxLength} characters.`,
  },
});

export const urlFieldRules = {
  pattern: {
    value: /^https:\/\/.*$/,
    message:
      "Make sure that this URL is accessible over the public internet and include the 'https://' prefix.",
  },
  ...getMaxLengthRule(256),
};

export function URLFormField(props) {
  const { control, errors } = props;
  return (
    <FormField
      name="url"
      label="Endpoint URL"
      description="The absolute URL that Stel will send measurements to. Must have a commercially-signed SSL certifice and include the 'https://' prefix."
      control={control}
      errors={errors}
      rules={urlFieldRules}
    />
  );
}

URLFormField.propTypes = {
  control: PropTypes.shape().isRequired,
  errors: PropTypes.shape().isRequired,
};

function PayloadSchemaFieldDescription() {
  return (
    <>
      The structure the measurement data will be sent in.{" "}
      <Link
        href="https://stellife.notion.site/Payload-Schemas-1145a94af55b4621bf9a63f62a326425"
        target="_blank"
      >
        Learn more.
      </Link>
    </>
  );
}

export function PayloadSchemaFormField(props) {
  const { payloadSchema, control } = props;

  const isPayloadSchemaV1 = payloadSchema === PAYLOAD_SCHEMA.STEL_V1_0;

  const payloadSchemaMenuItems = [
    PAYLOAD_SCHEMA.STEL_V2_0,
    PAYLOAD_SCHEMA.HL7_V2_4,
    ...(isPayloadSchemaV1 ? [PAYLOAD_SCHEMA.STEL_V1_0] : []),
  ];

  return (
    <>
      <Grid item xs={6}>
        <EndpointFormLabel
          value="Payload Schema"
          description={PayloadSchemaFieldDescription}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="payloadSchema"
          control={control}
          render={({ field }) => (
            <FormControl size="small" fullWidth>
              <Select disabled={isPayloadSchemaV1} size="small" {...field}>
                {payloadSchemaMenuItems.map((schema) => (
                  <MenuItem key={schema} value={schema}>
                    {schema}
                  </MenuItem>
                ))}
              </Select>
              {isPayloadSchemaV1 && (
                <FormHelperText>
                  <WarningAmber
                    sx={{
                      color: yellow[700],
                      fontSize: "16px",
                      mb: "-3px",
                      mr: "4px",
                    }}
                  />
                  <span>
                    This endpoint is using an older payload schema version.
                    Contact{" "}
                    <Box
                      component="span"
                      sx={{ display: "inline", color: blue[500] }}
                    >
                      support@stel.life
                    </Box>{" "}
                    for more information about upgrading.
                  </span>
                </FormHelperText>
              )}
            </FormControl>
          )}
        />
      </Grid>
    </>
  );
}

PayloadSchemaFormField.propTypes = {
  payloadSchema: PropTypes.string,
  control: PropTypes.shape().isRequired,
};

PayloadSchemaFormField.defaultProps = {
  payloadSchema: PAYLOAD_SCHEMA.STEL_V2_0,
};
