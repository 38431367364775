import { Button, CircularProgress, TextField } from "@mui/material";
import classNames from "classnames";
import React, { useState } from "react";
import { post } from "../../utils/io";
import PasswordVerification from "../Auth/PasswordVerification";
import useStyles from "./styles";

export default function ChangePassword() {
  const classes = useStyles();

  const [isPasswordError, setIsPasswordError] = useState(false);
  const [input, setInput] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [errMessage, setErrMessage] = useState("");
  const [isRequesting, setIsRequesting] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const setNewPassword = (value) =>
    setInput((pre) => ({ ...pre, newPassword: value }));

  const setConfirmPassword = (value) =>
    setInput((pre) => ({ ...pre, confirmPassword: value }));

  const onCurrentPasswordChange = (e) => {
    setInput((pre) => ({ ...pre, currentPassword: e.target.value }));
  };

  const isButtonDisabled = () =>
    isRequesting ||
    !input.confirmPassword ||
    !input.currentPassword ||
    input.confirmPassword !== input.newPassword ||
    isPasswordError;

  const onSubmitForm = async () => {
    setIsRequesting(true);
    setErrMessage("");
    setSuccessMessage("");

    try {
      const payload = {
        current_password: input.currentPassword,
        new_password: input.newPassword,
      };
      const response = await post("/users/me/change_password", payload);
      setIsRequesting(false);
      if (response.status !== 204) {
        return setErrMessage(
          response.data.detail || "Failed to update password."
        );
      }
      setSuccessMessage("Password changed successfully.");
    } catch (error) {
      setErrMessage("Failed to update password.");
      setIsRequesting(false);
    }
    return null;
  };

  return (
    <div className={classes.container}>
      <div className={classes.form}>
        <h1>Change Password</h1>
        <TextField
          size="small"
          variant="outlined"
          placeholder="Current Password"
          label="Current Password"
          onChange={onCurrentPasswordChange}
          value={input.currentPassword}
          type="password"
          className={classNames(classes.mrb10)}
        />
        <PasswordVerification
          password={input.newPassword}
          setPassword={setNewPassword}
          confirmPassword={input.confirmPassword}
          setConfirmPassword={setConfirmPassword}
          passwordLabel="New Password"
          setError={setIsPasswordError}
        />
        {errMessage && <p className={classes.errText}>{errMessage}</p>}
        {successMessage && (
          <p className={classes.successText}>{successMessage}</p>
        )}
        <Button
          onClick={onSubmitForm}
          variant="contained"
          color="primary"
          className={classes.w100}
          disabled={isButtonDisabled()}
        >
          {isRequesting && (
            <CircularProgress size={16} className={classes.progress} />
          )}{" "}
          Submit
        </Button>
      </div>
    </div>
  );
}
