import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Stack } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useForm } from "react-hook-form";

import { put } from "../../utils/io";
import { Protected } from "../Authz/Protected";
import RhfTextField from "../form/RhfTextField";
import { SelectFormField } from "./UserFormUtils";

export default function UserForm({ user, rolesStatic, onUpdateUser }) {
  const [loading, setLoading] = useState(false);
  const updateUser = async ({ id, roles }) => {
    try {
      setLoading(true);
      const res = await put(`/users/${id}/roles`, { roles });
      if (res.status === 200) {
        enqueueSnackbar("Update user's roles", { variant: "success" });
      } else {
        throw res;
      }
    } catch (error) {
      const errorMessage = error.data.detail || "Failed to update user";
      enqueueSnackbar(errorMessage, { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  const submitUserForm = async (formData) => {
    await updateUser({ id: user.id, roles: formData.roles });
    onUpdateUser();
  };

  const { control, handleSubmit } = useForm({
    defaultValues: { ...user },
  });

  return (
    <Box>
      <form onSubmit={handleSubmit(submitUserForm)}>
        <Stack sx={{ gap: 2 }}>
          <RhfTextField
            label="Email"
            name="email"
            disabled
            size="small"
            variant="outlined"
            control={control}
          />
          <RhfTextField
            label="First Name"
            name="first_name"
            disabled
            size="small"
            variant="outlined"
            control={control}
          />
          <RhfTextField
            label="Last Name"
            name="last_name"
            disabled
            size="small"
            variant="outlined"
            control={control}
          />

          {rolesStatic.length > 0 && (
            <Protected permission="AssignUserRoles">
              <SelectFormField
                control={control}
                label="Roles"
                name="roles"
                options={rolesStatic}
              />
            </Protected>
          )}
          <Stack direction="row" sx={{ gap: 1, justifyContent: "flex-end" }}>
            <LoadingButton
              sx={{ "&": { height: "32px" } }}
              loading={loading}
              type="submit"
              variant="contained"
              color="primary"
            >
              Save
            </LoadingButton>
          </Stack>
        </Stack>
      </form>
    </Box>
  );
}

const rolesPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  })
);

UserForm.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    email: PropTypes.string.isRequired,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    is_active: PropTypes.bool.isRequired,
    roles: rolesPropTypes.isRequired,
  }).isRequired,
  rolesStatic: rolesPropTypes.isRequired,
  onUpdateUser: PropTypes.func.isRequired,
};
