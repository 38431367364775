import { blue, green, grey, red } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";
import { orderStatusStyles } from "../styles";

const useStyles = makeStyles(() => ({
  ...orderStatusStyles,
  container: {
    textAlign: "left",
  },
  mrb15: {
    marginBottom: 15,
  },
  mrb10: {
    marginBottom: 10,
  },
  input400: {
    width: "100%",
    borderRadius: 4,
  },
  input150: {
    width: 150,
    borderRadius: 2,
  },
  flex: {
    display: "flex",
  },
  alignCenter: {
    alignItems: "center",
  },
  textCenter: {
    textAlign: "center",
  },
  btnSearch: {
    margin: 5,
  },
  w170px: {
    width: 170,
  },
  bold: {
    fontWeight: "bold",
  },
  outline: {
    border: "1px solid #c4c4c4",
    borderRadius: 4,
    padding: 15,
  },
  btnCreate: {
    marginTop: 20,
    display: "flex",
    justifyContent: "flex-end",
    "& button:last-child": {
      margin: 0,
    },
  },
  helpIcon: {
    fontSize: 19,
    marginLeft: 10,
  },
  customBtn: {
    margin: 0,
    padding: "5px 10px",
    borderRadius: 4,
    marginLeft: 20,
    background: blue[500],
    color: "#fff",
    cursor: "pointer",
  },
  redColor: {
    color: red[500],
  },
  greenColor: {
    color: green[500],
  },
  verifyIcon: {
    fontSize: 19,
    marginRight: 5,
  },
  errorText: {
    fontSize: 15,
    fontStyle: "Italic",
    justifyContent: "center",
    textAlign: "center",
    color: red[500],
  },
  mrr16: {
    marginRight: 16,
  },
  mrb16: {
    marginBottom: 16,
  },
  mrr20: {
    marginRight: 20,
  },
  ml170: {
    marginLeft: 170,
  },
  mrt0: {
    marginTop: 0,
  },
  mrb0: {
    marginBottom: 0,
  },
  mrl15: {
    marginLeft: 15,
  },
  url: {
    display: "flex",
    alignItems: "center",
    color: blue[500],
    textDecoration: "none",
    "&:hover": {
      color: blue[700],
      "& > svg": {
        color: blue[700],
      },
    },
  },
  icon: {
    fontSize: 16,
    marginLeft: 5,
  },
  spaceBetween: {
    justifyContent: "space-between",
  },
  justifyCenter: {
    justifyContent: "center",
  },
  border: {
    border: "1px solid #c4c4c4",
  },
  inputPadding: {
    padding: "0 12px",
  },
  input: {
    borderBottom: "none",
    "& > .MuiIput-root:hover:not(.Mui-disabled)": {
      borderBottom: "none !important",
    },
    "& > .MuiInput-root::before": {
      borderBottom: "none !important",
    },
    "& > .MuiInput-root::after": {
      borderBottom: "none !important",
    },
  },
  borderBottom: {
    borderBottom: "1px solid #c4c4c4",
  },
  pointer: {
    cursor: "pointer",
  },
  pd12: {
    padding: 12,
  },
  chip: {
    margin: "0 6px 10px 0",
    fontFamily: "monospace",
    fontSize: 14,
    userSelect: "auto",
  },
  scroll: {
    height: "auto",
    maxHeight: 1000,
    overflowY: "auto",
  },
  grey: {
    color: grey[500],
    fontSize: 15,
    "&:nth-child(1)": {
      marginBottom: 5,
    },
    "&:nth-child(2)": {
      marginTop: 0,
    },
  },
  relative: {
    position: "relative",
  },
  absolute: {
    position: "absolute",
  },
  pdt30: {
    paddingTop: 30,
  },
  warning: {
    margin: 0,
    top: 0,
    fontSize: 14,
    width: "100%",
    left: 0,
    background: red[300],
    color: "#fff",
  },
  text: {
    margin: "6px 10px",
  },
  iconClose: {
    marginRight: 10,
  },
  imagesContainer: {
    width: "100%",
    overflowX: "auto",
    paddingTop: "20px",
  },
  whiteBg: {
    background: "#fff",
  },
}));

export default useStyles;
