import { Button } from "@mui/material";
import PropTypes from "prop-types";
import React, { forwardRef } from "react";
import DocsTooltip from "../DocsTooltip";

const ButtonWithTooltip = forwardRef((props, ref) => {
  const { onClick, description, url, title, component, children, ...rest } =
    props;
  const ButtonComponent = component || Button;
  return (
    <DocsTooltip description={description} docsUrl={url} placement="bottom">
      <span>
        <ButtonComponent
          variant="outlined"
          onClick={onClick}
          ref={ref}
          {...rest}
        >
          {title}
          {children}
        </ButtonComponent>
      </span>
    </DocsTooltip>
  );
});

ButtonWithTooltip.propTypes = {
  onClick: PropTypes.func.isRequired,
  description: PropTypes.string,
  url: PropTypes.string,
  title: PropTypes.string.isRequired,
  component: PropTypes.elementType,
  children: PropTypes.node,
};

ButtonWithTooltip.defaultProps = {
  description: "",
  url: "",
  component: null,
  children: null,
};

export default ButtonWithTooltip;
