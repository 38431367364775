import ClearIcon from "@mui/icons-material/Clear";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import Papa from "papaparse";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import UploadCSVFile from "../icons/UploadCSVFile";

export default function CSVUploadAndDisplay(props) {
  const {
    onSelectFile,
    setRowImports,
    setColumnsImports,
    handleShowFileCSV,
    files,
    onRemoveFile,
    handleImportDexcom,
    organizations,
    organization,
    setOrganization,
    dexcomModels,
    dexcomModel,
    setDexcomModel,
  } = props;

  const onDropFile = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (onSelectFile) {
        onSelectFile(file);
      }
      Papa.parse(file, {
        complete: (result) => {
          if (!result) return;
          const columns = Object.keys(result.data[0]).map((col) => ({
            field: col,
            headerName: col,
            flex: 1,
            id: Math.random(),
            editable: true,
          }));
          const rows = result.data.map((row) => ({
            ...row,
            id: Math.random(),
          }));
          setColumnsImports(columns);
          setRowImports(rows);
        },
        header: true,
        skipEmptyLines: true,
      });
    },
    [onSelectFile, setColumnsImports, setRowImports]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDropFile,
    accept: "text/csv",
  });

  return (
    <Stack padding="15px 24px" gap={3}>
      <Stack gap={2}>
        <FormControl size="small" fullWidth>
          <InputLabel>Organization *</InputLabel>
          <Select
            size="small"
            value={organization}
            label="Organization *"
            onChange={(e) => setOrganization(e.target.value)}
          >
            {organizations.map((org) => (
              <MenuItem key={org.id} value={org.alias}>
                {org.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl size="small" fullWidth>
          <InputLabel>Dexcom Model *</InputLabel>
          <Select
            size="small"
            value={dexcomModel}
            label="Dexcom Model *"
            onChange={(e) => setDexcomModel(e.target.value)}
          >
            {dexcomModels.map((dexcom) => (
              <MenuItem key={dexcom.value} value={dexcom.value}>
                {dexcom.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
      <Box
        sx={{ background: "#F9FAFB", borderRadius: 2, cursor: "pointer" }}
        /* eslint-disable react/jsx-props-no-spreading */
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <Stack
          sx={{
            alignItems: "center",
            padding: "20px 10px",
            borderRadius: 1,
            gap: 1,
          }}
        >
          <CloudUploadIcon />
          <Typography color="#667085" fontSize="16px" fontWeight="600">
            Drop the file or click here to upload
          </Typography>
          <Typography color="#667085" fontSize="14px">
            Only CSV files (3MB) are accepted.
          </Typography>
        </Stack>
      </Box>
      {files.map((file) => (
        <Stack
          key={file.name}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
              backgroundColor: "#F9FAFB",
              padding: "6px 10px",
              borderRadius: 1,
              gap: 1,
            }}
          >
            <UploadCSVFile />
            <Typography
              sx={{
                color: "#667085",
                fontSize: 14,
                fontWeight: 600,
              }}
            >
              {file.name}
            </Typography>
            <Button
              onClick={onRemoveFile}
              sx={{ padding: 0, margin: 0, minWidth: 0 }}
            >
              <ClearIcon sx={{ color: "#A9A9A9", width: 16, height: 16 }} />
            </Button>
          </Stack>
          <Button
            sx={{ backgroundColor: "#EAF4FF" }}
            onClick={handleShowFileCSV}
          >
            <Typography
              sx={{
                color: "#667085",
                fontSize: 12,
                fontWeight: 600,
                marginRight: 1,
                textTransform: "capitalize",
              }}
            >
              Preview
            </Typography>
            <VisibilityIcon sx={{ color: "#667085", width: 16, height: 16 }} />
          </Button>
        </Stack>
      ))}
      <Button
        variant="contained"
        disabled={!files.length || !organization}
        onClick={handleImportDexcom}
      >
        Import
      </Button>
    </Stack>
  );
}

CSVUploadAndDisplay.propTypes = {
  onSelectFile: PropTypes.func.isRequired,
  onRemoveFile: PropTypes.func.isRequired,
  rowImports: PropTypes.arrayOf.isRequired,
  setRowImports: PropTypes.func.isRequired,
  setColumnsImports: PropTypes.func.isRequired,
  files: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    )
  ),
  handleShowFileCSV: PropTypes.func,
  handleImportDexcom: PropTypes.func.isRequired,
  organizations: PropTypes.arrayOf.isRequired,
  organization: PropTypes.objectOf.isRequired,
  setOrganization: PropTypes.func.isRequired,
  dexcomModels: PropTypes.arrayOf.isRequired,
  dexcomModel: PropTypes.number.isRequired,
  setDexcomModel: PropTypes.func.isRequired,
};

CSVUploadAndDisplay.defaultProps = {
  files: [],
  handleShowFileCSV: () => {},
};
