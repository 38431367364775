import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  mrb15: {
    marginBottom: 15,
  },
  input: {
    width: 250,
    marginRight: "20px !important",
  },
  flex: {
    display: "flex",
  },
}));

export default useStyles;
