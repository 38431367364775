import React from "react";
import { DOCS } from "../../utils/constants";
import TooltipChip from "../TooltipChip";

function VirtualHubIndicator() {
  return (
    <TooltipChip
      description={DOCS.VIRTUAL_HUB.description}
      docsUrl={DOCS.VIRTUAL_HUB.url}
      color="info"
      label="Virtual"
    />
  );
}

export default VirtualHubIndicator;
