import PropTypes from "prop-types";
import React from "react";
import StelModal from "../../StelModal";

export default function MessageModal(props) {
  const { onClose, message, isDisplayed, title, width } = props;

  return (
    <StelModal open={isDisplayed} onClose={onClose} title={title} width={width}>
      <p>{message}</p>
    </StelModal>
  );
}

MessageModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  message: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
    .isRequired,
  isDisplayed: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  width: PropTypes.number,
};

MessageModal.defaultProps = {
  width: 450,
};
