import { sortBy } from "lodash";
import React from "react";
import { useHistory } from "react-router";
import { DOCS } from "../../utils/constants";
import MainContentContainer from "../Layouts/MainContentContainer";
import StelDataGrid from "../StelDataGrid";
import devicesData from "./data";

function DeviceList() {
  const history = useHistory();

  return (
    <MainContentContainer title="Devices" docsUrl={DOCS.DEVICE_METRICS.url}>
      <StelDataGrid
        sx={{
          "& .MuiDataGrid-row": {
            cursor: "pointer",
          },
        }}
        columns={[
          { field: "mac", headerName: "MAC Address", flex: 0.2 },
          { field: "make", headerName: "Manufacturer", flex: 0.2 },
          { field: "model", headerName: "Model", flex: 0.4 },
          {
            field: "type",
            headerName: "Type",
            flex: 0.2,
            valueGetter: (params) =>
              params.row.make === "Abbott" ? "CGM" : params.row.type,
          },
        ]}
        pagination="client"
        onRowClick={({ row }) =>
          history.push({
            pathname: `/demo-devices/${row.mac}`,
            state: { type: row.type, make: row.make },
          })
        }
        rows={sortBy(devicesData, (data) => data.make)}
        getRowId={(row) => row.mac}
      />
    </MainContentContainer>
  );
}

export default DeviceList;
