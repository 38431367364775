import axios from "axios";
import { getTime } from "date-fns";
import { isEmpty } from "lodash";

import config from "../config";
import { addBufferToExpTime, base64ToObject } from ".";

const client = axios.create({
  baseURL: config.host,
});

const USER_CACHE_KEY = "User";
export const AUTO_REFRESH_MEASURE = "auto-refresh-measure";

// FIXME: Need a proper auth implementation
// This is a hack in the interim to retain
// a user's session between page refreshes.
// This implementation does not track a token's expiry.
const CachedUser = localStorage.getItem(USER_CACHE_KEY);
let User = CachedUser ? JSON.parse(CachedUser) : {};

const CachedAutoRefresh = localStorage.getItem(AUTO_REFRESH_MEASURE);
export const isAutoRefresh = CachedAutoRefresh
  ? JSON.parse(CachedAutoRefresh)
  : false;

const SANDBOX_CACHE_KEY = "sandbox";

export const isSandbox = () =>
  localStorage.getItem(SANDBOX_CACHE_KEY) === "true";

export const getAuthHeader = () => {
  const token = JSON.parse(
    localStorage.getItem(USER_CACHE_KEY) || "{}"
  ).accessToken;
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

const getRefreshToken = () => User?.refreshToken;

export const setSandbox = (value) => {
  localStorage.setItem(SANDBOX_CACHE_KEY, value);
};

// FIXME: Need a proper auth implementation
export const setUser = (data) => {
  if (isEmpty(data)) {
    User = {};
    localStorage.removeItem(USER_CACHE_KEY);
  } else {
    User = { ...data };
    localStorage.setItem(USER_CACHE_KEY, JSON.stringify(User));
  }

  return User;
};

export const decodeJWT = (data) => {
  const [header, payload, signature] = data.split(".");

  return {
    header: base64ToObject(header),
    payload: base64ToObject(payload),
    signature,
  };
};

const hasRefreshToken = () => !!getRefreshToken();

const isAccessTokenExpired = () =>
  User?.accessTokenExpires < new Date().valueOf();

const isRefreshTokenExpired = () =>
  User?.refreshTokenExpires < new Date().valueOf();

const redirectToLogin = () => {
  setUser({});
  document.location.href = `/login`;
};

let authRefreshReqPromise = null;
export const refreshAccessToken = async () => {
  if (authRefreshReqPromise) {
    return authRefreshReqPromise;
  }

  async function doRefresh() {
    const refresh = getRefreshToken();

    if (!refresh || isRefreshTokenExpired()) {
      redirectToLogin();
      return;
    }

    const res = await client.post(
      "auth/token/refresh",
      { refresh },
      {
        headers: {
          "X-STEL-SANDBOX": isSandbox(),
        },
      }
    );

    if (res.status !== 200) {
      redirectToLogin();
      return;
    }

    const accessToken = res.data.access;
    const {
      payload: { exp: accessTokenExp },
    } = decodeJWT(accessToken);

    setUser({
      ...User,
      accessToken,
      accessTokenExpires: getTime(addBufferToExpTime(accessTokenExp)),
    });
  }

  authRefreshReqPromise = doRefresh();
  await authRefreshReqPromise;
  authRefreshReqPromise = null;
  return null;
};

// Not 100% sure how to fix the consistent return linter error
/* eslint-disable consistent-return */
export const request = async (method, path, customOpts = {}) => {
  if (isAccessTokenExpired() && hasRefreshToken()) {
    await refreshAccessToken();
  }

  const { noAuth, ...axiosOps } = customOpts;
  const opts = {
    ...(noAuth ? {} : getAuthHeader()),
    ...axiosOps,
  };

  if (isSandbox()) {
    if (!opts.headers) {
      opts.headers = {};
    }
    opts.headers["X-STEL-SANDBOX"] = "true";
  }

  try {
    const res = await client.request({
      method,
      url: path,
      ...opts,
    });
    return res;
  } catch (err) {
    if (err.response?.status === 401) redirectToLogin();
    return err.response;
  }
};

/**
 * Updated version of `request` function. Actually throws error so that higher level
 * try/catch statements can make use of the catch statement.
 * @param {string} method - Request method.
 * @param {string} path - Request url.
 * @param {object} customOpts - Contains request options, i.e. body, headers.
 * @param {bool} redirectOnUnauthorized - Reroutes to login if user is unauthorized. If set
 * to false, throws the 401 error instead. Defaults to true.
 * @throws {object} Any non 2xx response error.
 * @returns {object} Request response if successful.
 */
export const requestV2 = async (
  method,
  path,
  customOpts = {},
  redirectOnUnauthorized = true
) => {
  if (isAccessTokenExpired() && hasRefreshToken()) {
    await refreshAccessToken();
  }

  const { noAuth, ...axiosOps } = customOpts;
  const opts = {
    ...(noAuth ? {} : getAuthHeader()),
    ...axiosOps,
  };

  if (isSandbox()) {
    if (!opts.headers) {
      opts.headers = {};
    }
    opts.headers["X-STEL-SANDBOX"] = "true";
  }

  try {
    const res = await client.request({
      method,
      url: path,
      ...opts,
    });
    return res;
  } catch (err) {
    if (redirectOnUnauthorized && err.response?.status === 401)
      return redirectToLogin();
    throw err;
  }
};

export const get = async (path, params = {}, opts = {}) =>
  request("get", path, { ...opts, params });

export const post = async (path, body, opts = {}) =>
  request("post", path, { ...opts, data: body });

export const put = async (path, body, opts = {}) =>
  request("put", path, { ...opts, data: body });

export const del = async (path, params, body) =>
  request("delete", path, { params, data: body });

export const isOrgActive = () => User?.org?.is_active;

export const userIsLoggedIn = () => !isEmpty(User);

export const isOrgUser = () => !!User.org?.id;

export const isOrgEnterprise = () => User.org?.license_type === "Enterprise";

export const isAdmin = () => User.is_admin;

export const isEnableDeviceRegistration = () =>
  User.org?.config?.feature_flags?.device_registration;

export const isSsoUser = () => User.org?.is_sso;

export const getUserDetail = async (token) => {
  const res = await get(
    "users/me",
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "X-STEL-SANDBOX": isSandbox(),
      },
    }
  );
  return res;
};

export const successfulLoginHandler = async (authTokens) => {
  const { access, refresh = null } = authTokens;

  const userResp = await getUserDetail(authTokens.access);
  const user = userResp.data;

  const {
    payload: { exp: accessTokenExp },
  } = decodeJWT(access);

  let localUserData = {
    accessToken: access,
    accessTokenExpires: getTime(addBufferToExpTime(accessTokenExp)),
    ...user,
  };

  if (refresh) {
    const {
      payload: { exp: refreshTokenExp },
    } = decodeJWT(refresh);
    localUserData = {
      ...localUserData,
      refreshToken: authTokens.refresh,
      refreshTokenExpires: getTime(addBufferToExpTime(refreshTokenExp)),
    };
  }

  setUser(localUserData);

  if (user.org?.id && !user?.org.is_active) {
    setSandbox(true);
  }
};

export const verifyCurrentSession = async () => {
  if (User?.accessToken) {
    try {
      // Can't use this endpoint right now because of Cognito tokens
      // await post("/auth/token/verify", { token: User.accessToken });
      await getUserDetail(User.accessToken);
      return true;
    } catch (err) {
      return false;
    }
  }
  return false;
};

export const getUserName = () =>
  User.first_name || User.last_name
    ? `${User.first_name} ${User.last_name}`
    : User.email;

export const getUserOrg = () => User?.org?.name;

export const getUserId = () => User?.id;

export const getFeatureFlag = (flagName) =>
  User?.org?.config?.feature_flags?.[flagName] || null;

export const hasPermission = (permission) =>
  !User?.org?.config?.feature_flags?.api_permissions ||
  User?.is_org_owner ||
  User?.permissions?.includes(permission);

export const regexValidateEmail = /^[\w.+-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/;

export const validateEmail = (email) =>
  regexValidateEmail.test(String(email).toLowerCase());

export const validatePassword = (password) => password.length > 7;
