import React from "react";

export default function Inr() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="10 0 22 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5 21.5H15.5H16.5V22.5H20C20.5 22.5 21 22 21 21.5V10C21 9.5 20.5 9 20 9H11C10.5 9 10.065 9.38414 10 10V21.5C10 22 10.5 22.5 11 22.5H14.5V21.5Z"
        stroke="#545454"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5 20V18H13.5V20H11.5Z"
        stroke="#545454"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5 20V18H17.5V20H19.5Z"
        stroke="#545454"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5 27V22V21.5H16.5V27H14.5Z"
        stroke="#545454"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5 24.5H16.5"
        stroke="#545454"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5 22V24M15.5 22L15 22.5M15.5 22L16 22.5"
        stroke="#545454"
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M14.29 15.5781V17H14.0215V15.5781H14.29Z" fill="#545454" />
      <path
        d="M15.7227 15.5781V17H15.4531L14.8486 16.0137V17H14.5801V15.5781H14.8486L15.4551 16.5654V15.5781H15.7227Z"
        fill="#545454"
      />
      <path
        d="M15.9961 15.5781H16.5127C16.6208 15.5781 16.7135 15.5944 16.791 15.627C16.8685 15.6595 16.9281 15.7077 16.9697 15.7715C17.0114 15.8353 17.0322 15.9137 17.0322 16.0068C17.0322 16.0804 17.0192 16.1442 16.9932 16.1982C16.9671 16.2523 16.9303 16.2975 16.8828 16.334C16.8359 16.3704 16.7806 16.3991 16.7168 16.4199L16.6328 16.4629H16.1768L16.1748 16.251H16.5156C16.571 16.251 16.6169 16.2412 16.6533 16.2217C16.6898 16.2021 16.7171 16.1751 16.7354 16.1406C16.7542 16.1061 16.7637 16.0667 16.7637 16.0225C16.7637 15.9749 16.7546 15.9339 16.7363 15.8994C16.7181 15.8643 16.6904 15.8372 16.6533 15.8184C16.6162 15.7995 16.5693 15.79 16.5127 15.79H16.2646V17H15.9961V15.5781ZM16.7988 17L16.4697 16.3633L16.7539 16.3623L17.0869 16.9863V17H16.7988Z"
        fill="#545454"
      />
    </svg>
  );
}
