import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { useHistory, useParams } from "react-router-dom";

function TroubleshootLayout({ children, title, actions }) {
  const history = useHistory();
  const { hubId } = useParams();

  return (
    <Box sx={{ minHeight: "calc(100vh - 80px)" }}>
      <Box
        sx={{
          width: "calc(100% - 32px)",
          position: "fixed",
          top: 0,
          background: "#fff",
          height: "79px",
          padding: "0px 16px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: "1px solid #0000003B",
          zIndex: 10,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: "600",
              overflow: "hidden",
              maxWidth: "fit-content",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {title}
          </Typography>
          {!actions && (
            <Button
              sx={{
                minWidth: "max-content",
                "& .MuiButton-startIcon": {
                  margin: 0,
                },
              }}
              onClick={() => history.replace(`/hubs/${hubId}`)}
              startIcon={<CloseIcon style={{ color: "#001737" }} />}
            />
          )}
        </Box>
        <Box>{actions}</Box>
      </Box>
      <Box sx={{ marginTop: "80px" }}>{children}</Box>
    </Box>
  );
}
TroubleshootLayout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]).isRequired,
  actions: PropTypes.node,
};
TroubleshootLayout.defaultProps = {
  actions: undefined,
};

export default TroubleshootLayout;
