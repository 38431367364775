import React from "react";

export default function PendingAction() {
  return (
    <svg
      width="18"
      height="21"
      viewBox="0 0 18 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 16C8 13.24 10.24 11 13 11C15.76 11 18 13.24 18 16C18 18.76 15.76 21 13 21C10.24 21 8 18.76 8 16ZM12.5 16.2L14.65 18.35L15.35 17.64L13.5 15.79V13H12.5V16.2Z"
        fill="#F3B821"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 2H10.82C10.4 0.84 9.3 0 8 0C6.7 0 5.6 0.84 5.18 2H2C0.9 2 0 2.9 0 4V19C0 20.1 0.9 21 2 21H8.11C7.52 20.43 7.04 19.75 6.69 19H2V4H4V7H12V4H14V9.08C14.71 9.18 15.38 9.39 16 9.68V4C16 2.9 15.1 2 14 2ZM7 3C7 3.55 7.45 4 8 4C8.55 4 9 3.55 9 3C9 2.45 8.55 2 8 2C7.45 2 7 2.45 7 3Z"
        fill="#4FC6F9"
      />
    </svg>
  );
}
