import { Close } from "@mui/icons-material";
import { Fade, IconButton, Modal, Paper } from "@mui/material";
import classNames from "classnames";
import PropTypes from "prop-types";

import React from "react";
import useStyles from "./styles";

function StelModal(props) {
  const classes = useStyles();

  const { open, onClose, title = "", width, children, ...rest } = props;

  return (
    <Modal
      open={open}
      onClose={onClose}
      className={classNames(classes.modal)}
      style={{ width }}
      aria-labelledby="simple-modal-title"
      {...rest}
    >
      <Fade in={open}>
        <Paper className={classNames(classes.paper)}>
          <div className={classes.header}>
            <div className={classes.title}>{title}</div>
            <IconButton
              onClick={onClose}
              className={classes.iconBtn}
              size="large"
            >
              <Close />
            </IconButton>
          </div>
          {children}
        </Paper>
      </Fade>
    </Modal>
  );
}

StelModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  width: PropTypes.number.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
    .isRequired,
};

export default StelModal;
