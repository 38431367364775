import { Button, CircularProgress, Link, TextField } from "@mui/material";
import classNames from "classnames";
import React, { useState } from "react";
import { post } from "../../../utils/io";
import useStyles from "./styles";

export default function ForgotPassword() {
  const classes = useStyles();

  const [username, setUserName] = useState("");
  const [isRequesting, setIsRequesting] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);

  const onChangeInput = (e) => {
    const { value } = e.target;
    setUserName(value);
  };

  const onSubmitForm = async () => {
    setIsRequesting(true);
    await post("/users/password_reset", { username });
    setIsSuccessful(true);
    setIsRequesting(false);
  };
  return (
    <>
      {isSuccessful && (
        <div className={classes.panel}>
          <h2>Request Successful</h2>
          <p>Check your email for a link to reset your password.</p>
          <p>
            Back to <Link href="/login">Login</Link> page
          </p>
        </div>
      )}
      {!isSuccessful && (
        <div className={classes.form}>
          <h1>Forgot your password?</h1>
          <h4>Enter your username/email.</h4>
          <TextField
            size="small"
            variant="outlined"
            placeholder="Username"
            label="Username"
            onChange={onChangeInput}
            value={username}
            className={classNames(classes.mrb20, classes.w100)}
          />
          <Button
            onClick={onSubmitForm}
            variant="contained"
            color="primary"
            className={classes.w100}
            disabled={!username || isRequesting}
          >
            {isRequesting && (
              <CircularProgress size={16} className={classes.progress} />
            )}{" "}
            Submit
          </Button>
          <div className={classes.link}>
            <Link href="/login">Go back</Link>
          </div>
        </div>
      )}
    </>
  );
}
