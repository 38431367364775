import Queue from "@mui/icons-material/Queue";
import {
  Autocomplete,
  Button,
  Box,
  TextField,
  Chip,
  Typography,
  Stack,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { differenceWith } from "lodash";
import { enqueueSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { formatXealthUrl } from "../utils";
import { get, isAdmin, post } from "../utils/io";
import { Protected } from "./Authz/Protected";
import BulkHubsActionModal from "./BulkHubsActionModal";
import DefaultHubGroupIndicator from "./DefaultHubGroupIndicator";
import { PAYLOAD_SCHEMA } from "./Endpoint/EndpointFormUtils";
import AllowedModelsTable from "./HubGroupList/AllowedModelsTable";
import MainContentContainer from "./Layouts/MainContentContainer";

function HubGroup() {
  const { id: hubGroupId } = useParams();

  const [title, setTitle] = useState("");
  const [hubGroup, setHubGroup] = useState({
    id: "",
    name: "",
    endpoints: [],
    isDefault: false,
    targetCellularVersion: "",
    targetBluetoothVersion: "",
  });
  const [hubsToAdd, setHubsToAdd] = useState([]);
  const [loading, setLoading] = useState(false);

  // Dummy state variable to initiate refresh after bulk adding hubs
  const [refresh, setRefresh] = useState();

  const BASE_URL = `hub_groups/${hubGroupId}`;

  useEffect(() => {
    const getHubGroup = async () => {
      const hubGroupRes = await get(`/hub_groups/${hubGroupId}`);
      if (hubGroupRes.status !== 200) {
        enqueueSnackbar("Unable to retrieve hub group", {
          variant: "error",
        });
        return;
      }
      const resData = hubGroupRes.data;
      setTitle(`${resData.name} (${hubGroupId})`);
      setHubGroup({
        endpoints: resData.endpoints.map((endpoint) =>
          endpoint.payload_schema === PAYLOAD_SCHEMA.XEALTH_v1_0
            ? { ...endpoint, url: formatXealthUrl(endpoint.url) }
            : endpoint
        ),
        name: resData.name,
        id: resData.id,
        isDefault: resData.is_default,
        allowedModels: resData.allowed_models,
        targetCellularVersion: resData.target_cellular_version,
        targetBluetoothVersion: resData.target_bluetooth_version,
      });
    };
    getHubGroup();
  }, [hubGroupId]);

  const updateHubGroup = async (e) => {
    e.preventDefault();

    setLoading(true);

    const updateRes = await post(BASE_URL, {
      name: hubGroup.name,
      endpoint_ids: hubGroup.endpoints.map((endpoint) => endpoint.id),
      target_cellular_version: hubGroup.targetCellularVersion,
      target_bluetooth_version: hubGroup.targetBluetoothVersion,
    });
    if (updateRes.status !== 200) {
      setLoading(false);
      enqueueSnackbar("Unable to update hub group", {
        variant: "error",
      });
      return;
    }

    if (hubsToAdd.length > 0) {
      const hubsRes = await post(`${BASE_URL}/hubs`, {
        hub_ids: hubsToAdd.map(({ hub_id: hubId }) => hubId),
      });
      if (hubsRes.status !== 200) {
        setLoading(false);
        enqueueSnackbar(hubsRes.data.detail, { variant: "error" });
      }
    }
    setHubsToAdd([]);

    enqueueSnackbar(`Saved changes to ${hubGroup.name}`, {
      variant: "success",
    });
    setLoading(false);
    setRefresh({});
  };

  return (
    <MainContentContainer
      title={
        <Box>
          <Box component="span" sx={{ mr: "10px" }}>
            {title}
          </Box>
          {hubGroup.isDefault && <DefaultHubGroupIndicator />}
        </Box>
      }
      analyticsTitle="Hub Group Detail"
    >
      <Box sx={{ width: "600px" }}>
        <form onSubmit={updateHubGroup}>
          <TextField
            sx={{ mb: "20px" }}
            label="Name"
            onChange={(e) =>
              setHubGroup((pre) => ({ ...pre, name: e.target.value }))
            }
            value={hubGroup.name}
            name="name"
            fullWidth
            variant="outlined"
            required
          />
          <Protected permission="ViewEndpoints">
            <EndpointAutocomplete
              currValue={hubGroup.endpoints}
              onChange={(_, value) =>
                setHubGroup((pre) => ({ ...pre, endpoints: value }))
              }
            />
          </Protected>
          <Protected permission="MoveHubsToHubGroup">
            <HubsFormSection
              hubGroupId={hubGroupId}
              setHubsToAdd={setHubsToAdd}
              refresh={refresh}
              setRefresh={setRefresh}
            />
          </Protected>

          {isAdmin() && (
            <Stack spacing={2} sx={{ p: 2, my: 4, background: grey[200] }}>
              <Typography variant="h6">Stel Admins</Typography>
              <TextField
                sx={{ mb: "20px" }}
                label="Target Cellular (9160) Version"
                onChange={(e) =>
                  setHubGroup((pre) => ({
                    ...pre,
                    targetCellularVersion: e.target.value,
                  }))
                }
                value={hubGroup.targetCellularVersion}
                name="targetCellularVersion"
                fullWidth
                variant="outlined"
              />
              <TextField
                sx={{ mb: "20px" }}
                label="Target Bluetooth (52840) Version (defaults to cellular version)"
                onChange={(e) =>
                  setHubGroup((pre) => ({
                    ...pre,
                    targetBluetoothVersion: e.target.value,
                  }))
                }
                value={hubGroup.targetBluetoothVersion}
                name="targetBluetoothVersion"
                fullWidth
                variant="outlined"
                placeholder="Leave blank to match target cellular version"
              />
            </Stack>
          )}
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              disabled={loading}
            >
              Save
            </Button>
          </Box>
        </form>
        {(hubGroup.allowedModels || []).length > 0 && (
          <AllowedModelsTable rows={hubGroup.allowedModels} loading={loading} />
        )}
      </Box>
    </MainContentContainer>
  );
}

function EndpointAutocomplete(props) {
  const { currValue, onChange } = props;
  const [endpoints, setEndpoints] = useState([]);

  useEffect(() => {
    const getEndpoints = async () => {
      const res = await get("/endpoints");
      if (res.status !== 200)
        return enqueueSnackbar("Unable to retrieve endpoints", {
          variant: "error",
        });

      return setEndpoints(res.data.endpoints);
    };
    getEndpoints();
  }, []);

  return (
    <Autocomplete
      sx={{ mb: "20px" }}
      options={endpoints}
      value={currValue}
      getOptionLabel={(option) => option.url}
      renderOption={(optionProps, option) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <li {...optionProps} key={option.id}>
          {option.url}
        </li>
      )}
      onChange={onChange}
      isOptionEqualToValue={(option, value) => value.id === option.id}
      disableCloseOnSelect
      multiple
      renderInput={(params) => (
        <TextField
          {...params}
          label="Endpoints"
          name="endpoint"
          variant="outlined"
          fullWidth
        />
      )}
    />
  );
}

EndpointAutocomplete.propTypes = {
  currValue: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onChange: PropTypes.func.isRequired,
};

function HubsFormSection({ hubGroupId, setHubsToAdd, refresh, setRefresh }) {
  const [typeAheadHubs, setTypeAheadsHubs] = useState([]);
  const [existingHubs, setExistingHubs] = useState([]);
  const [selectedHubs, setSelectedHubs] = useState([]);
  const [inputHubId, setInputHubId] = useState("");
  const [showBulkAddModal, setShowBulkAddModal] = useState(false);

  const isHubInGroup = (hubId) =>
    existingHubs.find((hub) => hub.hub_id === hubId);

  const selectHub = (_, value, reason, details) => {
    if (reason === "clear" && existingHubs.length > 0) {
      setSelectedHubs(existingHubs);
      setHubsToAdd([]);
      return;
    }

    // Do not allow removal of hub if already in hub group
    if (reason === "removeOption" && isHubInGroup(details.option.hub_id)) {
      return;
    }

    setSelectedHubs(value);
    setHubsToAdd(
      differenceWith(value, existingHubs, (a, b) => b.hub_id === a.hub_id)
    );
  };

  const handleBulkAddHubs = async (hubIds) => {
    const hubIdsToAdd = differenceWith(hubIds, existingHubs, (a, b) =>
      b.hub_id.startsWith(a)
    );

    if (hubIdsToAdd.length > 0) {
      const res = await post(`hub_groups/${hubGroupId}/hubs`, {
        hub_ids: hubIdsToAdd,
      });
      if (res.status !== 200) {
        enqueueSnackbar(res.data.detail, { variant: "error" });
      } else {
        setShowBulkAddModal(false);
        setRefresh({});
      }
    } else {
      setShowBulkAddModal(false);
    }
  };

  useEffect(() => {
    async function getHubs() {
      const hubsRes = await get(`/hubs`, { group_id: hubGroupId, limit: 1000 });
      if (hubsRes.status !== 200)
        return enqueueSnackbar("Unable to retrieve hubs", { variant: "error" });

      const hubGroupHubs = hubsRes.data.hubs;

      setSelectedHubs(hubGroupHubs);
      return setExistingHubs(hubGroupHubs);
    }
    getHubs();
  }, [hubGroupId, refresh]);

  useEffect(() => {
    const getHubsMatchingSearch = async () => {
      const res = await get("/hubs", { hub_id: inputHubId, limit: 10 });
      if (res.status !== 200)
        return enqueueSnackbar("Unable to retrieve hubs", { variant: "error" });

      return setTypeAheadsHubs([...res.data.hubs]);
    };
    getHubsMatchingSearch();
  }, [inputHubId]);

  return (
    <>
      <BulkHubsActionModal
        isVisible={showBulkAddModal}
        onClose={() => setShowBulkAddModal(false)}
        hubGroupId={hubGroupId}
        onSubmit={handleBulkAddHubs}
      />
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<Queue />}
          onClick={() => setShowBulkAddModal(true)}
        >
          Bulk Add
        </Button>
      </Box>
      <Autocomplete
        sx={{ mt: "10px", mb: "20px" }}
        options={typeAheadHubs}
        value={selectedHubs}
        onInputChange={(_, value) => setInputHubId(value)}
        limitTags={10}
        getOptionLabel={(option) => option.hub_id}
        onChange={selectHub}
        isOptionEqualToValue={(option, value) => value.hub_id === option.hub_id}
        disableCloseOnSelect
        multiple
        renderInput={(params) => {
          // The autcomplete component does not natively allow scrolling, it will
          // automatically display all tags regardless of any maxHeight/overflow
          // css set directly on it. Maybe a datagrid is better here when hub groups
          // can have hundreds of hubs. This solution allows scrolling. Found on this
          // github issue:
          // https://github.com/mui/material-ui/issues/19137#issuecomment-583209943
          const { InputProps, ...restParams } = params;
          const { startAdornment, ...restInputProps } = InputProps;
          return (
            <TextField
              {...restParams}
              InputProps={{
                ...restInputProps,
                startAdornment: (
                  <div
                    style={{
                      maxHeight: 100,
                      overflowY: "auto",
                    }}
                  >
                    {startAdornment}
                  </div>
                ),
              }}
              label="Hubs"
              name="hubs"
              variant="outlined"
              fullWidth
            />
          );
        }}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              label={option.hub_id}
              {...getTagProps({ index })}
              disabled={isHubInGroup(option.hub_id)}
            />
          ))
        }
      />
    </>
  );
}

HubsFormSection.propTypes = {
  hubGroupId: PropTypes.string.isRequired,
  setHubsToAdd: PropTypes.func.isRequired,
  refresh: PropTypes.shape().isRequired,
  setRefresh: PropTypes.func.isRequired,
};

export default HubGroup;
