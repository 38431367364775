import { Alert, Button, MenuItem, TextField } from "@mui/material";
import classNames from "classnames";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router";
import { CARRIERS, HUB_RETURN_STATUS } from "../../utils/constants";
import { get, isAdmin, post } from "../../utils/io";
import { ProtectedButton, Protected } from "../Authz/Protected";
import MainContentContainer from "../Layouts/MainContentContainer";
import StelModal from "../StelModal";
import TrackingNumberFormField from "../TrackingNumberFormField";
import ListHubReturn from "./ListHubReturn";

import useStyles from "./styles";

export default function HubReturn() {
  const classes = useStyles();
  const { returnId } = useParams();
  const history = useHistory();

  const [isShowDupeTrackingNum, setIsShowDupeTrackingNum] = useState(false);
  const [isConfirmingSubmit, setIsConfirmingSubmit] = useState(false);
  const [loading, setLoading] = useState(false);

  const [hubReturn, setHubReturn] = useState({
    hubs: [],
  });
  const [trackingNumber, setTrackingNumber] = useState("");

  useEffect(() => {
    const getHubReturn = async () => {
      const res = await get(`/hub_returns/${returnId}`);
      if (res.status === 200) {
        const { data } = res;
        const p = {
          ...data,
          shipping_info: data.shipping_info || {
            carrier: "",
            tracking_numbers: [],
            recipient_address: "",
          },
          hubs: data.hubs || [],
        };
        setHubReturn(p);
      }
    };
    getHubReturn();
  }, [returnId]);

  const updateHubs = (value) =>
    setHubReturn((pre) => ({
      ...pre,
      hubs: value.map((hub) => ({
        hub_id: hub.hub_id,
        reason: hub.reason,
        reason_detail: hub.reason_detail,
      })),
    }));

  const onRemoveHub = (hubId) => () => {
    setHubReturn((pre) => ({
      ...pre,
      hubs: pre.hubs.filter((hub) => hub.hub_id !== hubId),
    }));
  };

  const handleChangeShippingInfo = (e) => {
    const { name, value } = e.target;
    setHubReturn((pre) => ({
      ...pre,
      shipping_info: {
        ...pre.shipping_info,
        [name]: value,
      },
    }));
  };

  const updateHubReturn = async () => {
    const {
      recipient_address: recipientAddress,
      carrier,
      tracking_numbers: trackingNumbers,
    } = hubReturn.shipping_info;

    if (!recipientAddress) {
      enqueueSnackbar({
        message: "Shipping address is required",
        variant: "error",
      });
      return null;
    }

    const res = await post(`/hub_returns/${returnId}`, {
      ...(hubReturn.status === HUB_RETURN_STATUS.CREATED && {
        shipping_address: recipientAddress,
        hubs: hubReturn.hubs,
      }),
      ...(carrier && { carrier }),
      tracking_numbers: trackingNumbers,
    });
    setIsConfirmingSubmit(false);
    if (res.status !== 200) {
      enqueueSnackbar({ message: res.data.detail, variant: "error" });
      return null;
    }
    enqueueSnackbar({ message: "Changes saved", variant: "success" });
    return res;
  };

  const handleSaveHubReturn = async () => {
    setLoading(true);
    await updateHubReturn();
    setLoading(false);
  };

  const submitHubReturn = async () => {
    setLoading(true);
    const updateRes = await updateHubReturn(returnId);
    if (!updateRes) {
      return null;
    }
    const submitRes = await post(`/hub_returns/${returnId}/submit`);
    if (submitRes.status !== 200) {
      enqueueSnackbar({ message: submitRes.data.detail, variant: "error" });
    } else {
      setHubReturn(submitRes.data);
      enqueueSnackbar({ message: "Return submitted", variant: "success" });
      if (isAdmin()) {
        history.push(`/hub-returns/${returnId}/evaluate`);
      }
    }
    return setLoading(false);
  };

  const handleOnKeyUp = (e, value) => {
    setIsShowDupeTrackingNum(false);
    if (e.keyCode !== 13 || value.length === 0) return null;

    if (
      hubReturn.shipping_info.tracking_numbers.filter(
        (number) => value === number
      ).length > 0
    )
      return setIsShowDupeTrackingNum(true);

    setTrackingNumber("");
    return setHubReturn((pre) => ({
      ...pre,
      shipping_info: {
        ...pre.shipping_info,
        tracking_numbers: pre.shipping_info.tracking_numbers.concat(value),
      },
    }));
  };

  const onChangeTrackingNumber = (e) => {
    const { value } = e.target;
    setTrackingNumber(value);
    return handleOnKeyUp(e, value);
  };

  const onRemoveTrackingNumber = (trackingId) => () => {
    setHubReturn((pre) => ({
      ...pre,
      shipping_info: {
        ...pre.shipping_info,
        tracking_numbers: pre.shipping_info.tracking_numbers.filter(
          (number) => number !== trackingId
        ),
      },
    }));
  };

  const isNotCreated = () => hubReturn.status !== HUB_RETURN_STATUS.CREATED;

  const isReceived = () => hubReturn.status === HUB_RETURN_STATUS.RECEIVED;

  const isCompleted = () => hubReturn.status === HUB_RETURN_STATUS.COMPLETED;

  return (
    <MainContentContainer
      title={`Hub Return #${returnId}`}
      analyticsTitle="Hub Return Detail"
    >
      <div className={classes.flex}>
        <div className={classes.flex1}>
          <Alert severity="warning">
            Please do not supply any names, addresses, or other identifying
            patient information to Stel. Repaired and replaced hubs should not
            be sent back directly to patients.
          </Alert>
          <p>
            <strong>Where should we send repaired and replaced hubs?</strong>
          </p>
          <div>
            <TextField
              label="Shipping Address"
              onChange={handleChangeShippingInfo}
              value={hubReturn.shipping_info?.recipient_address || ""}
              name="recipient_address"
              fullWidth
              required
              size="small"
              className={classes.mb16}
              disabled={isNotCreated()}
            />
            <div className={classes.mt10}>
              <strong>Please include tracking information</strong>
              <p className={classes.shippingText}>
                Carrier and tracking numbers can be updated after submitting the
                return.
              </p>
            </div>
            <TextField
              select
              value={hubReturn.shipping_info?.carrier || ""}
              onChange={handleChangeShippingInfo}
              className={classes.mb16}
              label="Carrier"
              name="carrier"
              fullWidth
              variant="outlined"
              size="small"
              disabled={isReceived() || isCompleted()}
            >
              {CARRIERS.map((carrier) => (
                <MenuItem key={carrier.value} value={carrier.label}>
                  {carrier.label}
                </MenuItem>
              ))}
            </TextField>
            <div
              className={classNames(
                classes.flex,
                classes.alignCenter,
                classes.mb16
              )}
            >
              {hubReturn.shipping_info?.carrier && (
                <TrackingNumberFormField
                  value={trackingNumber}
                  onChangeTrackingNumber={onChangeTrackingNumber}
                  isShowDupeTrackingNum={isShowDupeTrackingNum}
                  onRemoveTrackingNumberId={onRemoveTrackingNumber}
                  trackingNumberIds={hubReturn.shipping_info.tracking_numbers}
                  disabled={isReceived() || isCompleted()}
                />
              )}
            </div>
          </div>
        </div>
        <div className={classNames(classes.flex1, classes.pl68)}>
          <p>
            <strong>Please ship hubs to:</strong>
          </p>
          <span>
            Stel Life, Inc.
            <br />
            2401 Walnut Street STE#102
            <br />
            Philadelphia, PA 19103, United States
          </span>
        </div>
      </div>
      <Protected permission="ViewHubs">
        <ListHubReturn
          updateHubs={updateHubs}
          hubs={hubReturn.hubs}
          onRemoveHub={onRemoveHub}
          disabled={isNotCreated()}
        />
      </Protected>
      <div className={classNames(classes.flex, classes.groupBtn, classes.mt16)}>
        {[HUB_RETURN_STATUS.CREATED, HUB_RETURN_STATUS.SUBMITTED].includes(
          hubReturn.status
        ) && (
          <Button
            className={!isNotCreated() ? classes.mr10 : ""}
            variant="contained"
            onClick={handleSaveHubReturn}
            disabled={loading}
          >
            Save
          </Button>
        )}
        {!isNotCreated() && (
          <ProtectedButton
            permission="ManageHubReturns"
            variant="contained"
            disabled={
              !hubReturn.hubs.length ||
              !hubReturn.shipping_info.recipient_address ||
              loading
            }
            onClick={() => setIsConfirmingSubmit(true)}
          >
            Submit
          </ProtectedButton>
        )}
      </div>
      <StelModal
        title="Submit Return"
        open={isConfirmingSubmit}
        onClose={() => setIsConfirmingSubmit(false)}
        width={500}
      >
        <p>
          All current changes will be saved before submitting. Submitted returns
          can no longer be updated. Once Stel receives the return shipment, the
          hubs will be removed from your organization temporarily and all
          bindings cleared.
          <br />
          <br />
          Are you ready to submit this return?
        </p>
        <div
          className={classNames(classes.flex, classes.groupBtn, classes.mt16)}
        >
          <Button
            variant="contained"
            className={classes.mr10}
            color="error"
            onClick={() => setIsConfirmingSubmit(false)}
          >
            No
          </Button>
          <Button
            variant="contained"
            disabled={!hubReturn.hubs.length}
            onClick={submitHubReturn}
          >
            Yes
          </Button>
        </div>
      </StelModal>
    </MainContentContainer>
  );
}
