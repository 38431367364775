import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { blue, green, red } from "@mui/material/colors";
import { parseInt } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DOCS, MEASURE_TYPES } from "../../utils/constants";
import { get, post } from "../../utils/io";
import MainContentContainer from "../Layouts/MainContentContainer";
import {
  DevicesDropdown,
  SimulatedVitalSelect,
  SimulatedVitalTextField,
} from "./SimulatorFormControls";

function SimulateMeasures() {
  const [hubs, setHubs] = useState([]);
  const [input, setInput] = useState({
    hub: "",
    type: "",
    device: {},
  });
  const [refreshDevices, setRefreshDevices] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errMessage, setErrMessage] = useState();
  const [measureData, setMeasureData] = useState({});
  const [devices, setDevices] = useState([]);
  const [loading, setLoading] = useState(false);

  const getHubs = useCallback(async () => {
    const res = await get("/hubs", { is_virtual: true });
    if (res.status === 200) {
      setHubs(res.data.hubs);
    } else {
      setErrMessage("Failed to retrieve hubs.");
    }
  }, []);

  useEffect(() => {
    getHubs();
  }, [getHubs]);

  useEffect(() => {
    const getDevices = async () => {
      setLoading(true);
      const res = await get("/devices", {
        is_virtual: true,
        device_type: input.type,
      });
      if (res.status === 200) {
        setDevices(res.data.devices);
      } else {
        setErrMessage("Failed to retrieve devices.");
      }
      setLoading(false);
    };
    if (input.type.length > 0) getDevices();
  }, [input.type, refreshDevices]);

  const handleChangeSelect = async (e) => {
    const { name, value } = e.target;
    if (name === "type") {
      const res = await get("/measures/simulate", { type: value });
      if (res.status === 200) {
        setMeasureData(res.data.measures);
      } else {
        setMeasureData({});
      }
    }
    // Resetting device to clear any previously selected device
    setInput((pre) => ({ ...pre, [name]: value, device: {} }));
  };

  const selectDevice = async (e, value) => {
    setInput((pre) => ({ ...pre, device: value }));
  };

  const handleSetMeasureType = (e) => {
    const { name, value } = e.target;
    const field = MEASURE_TYPES[input.type].fields[name];

    const getError = () => {
      if (
        field &&
        !field.options?.length &&
        (value === "" || value > field.max || value < 0)
      )
        return `Value of ${name} invalid`;
      if (
        (name === "sys" && parseInt(value) < measureData.dia) ||
        (name === "dia" && parseInt(value) > measureData.sys)
      )
        return "Diastolic should never be greater than systolic";
      return null;
    };
    setErrMessage(getError());
    setMeasureData((prev) => ({ ...prev, [name]: value }));
  };

  const onSendMeasure = async () => {
    setErrMessage("");
    if (!input.hub) return setErrMessage("Hub is required");
    if (!input.type) return setErrMessage("Measure type is required.");
    const payload = {
      hub_id: input.hub,
      type: input.type,
      measure_data: measureData,
      ...(input.device && { mac_address: input.device.mac_address }),
    };
    setIsSuccess(false);
    setIsLoading(true);
    const res = await post("/measures/simulate", payload);
    if (res.status === 201) {
      setIsSuccess(true);
      // Force refresh devices to get any new mac addresses that were created if an
      // existing mac address was not supplied
      if (!input.device?.mac_address) setRefreshDevices({});
    } else {
      setErrMessage("Failed to simulate measure. Please try again.");
    }
    setIsLoading(false);
    return null;
  };

  return (
    <MainContentContainer
      title="Simulate Measures"
      docsUrl={DOCS.SIMULATOR.url}
    >
      <Typography sx={{ mb: "20px" }}>
        In your sandbox you can simulate measurements from your virtual hubs.
        <br />
        The data will even get sent to your endpoint if it&apos;s configured on
        the hub&apos;s group.
        <br />
        <br />
        Try it out!
      </Typography>
      <Box sx={{ mb: "10px" }}>
        <FormControl sx={{ width: "250px" }} variant="outlined" size="small">
          <InputLabel required>Which hub?</InputLabel>
          <Select
            value={input.hub}
            label="Which hub?"
            name="hub"
            onChange={handleChangeSelect}
          >
            {hubs.map((hub) => (
              <MenuItem key={hub.hub_id} value={hub.hub_id}>
                {hub.hub_id}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ mb: "10px" }}>
        <FormControl
          variant="outlined"
          size="small"
          sx={{ width: "250px" }}
          required
        >
          <InputLabel>What type of measure?</InputLabel>
          <Select
            value={input.type}
            label="What type of measure?"
            name="type"
            onChange={handleChangeSelect}
          >
            {Object.values(MEASURE_TYPES).map((type) => (
              <MenuItem key={type.value} value={type.value}>
                {type.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      {MEASURE_TYPES[input.type] && (
        <Box sx={{ mt: "10px" }}>
          {!loading && devices.length > 0 && (
            <Box sx={{ mb: "10px" }}>
              <DevicesDropdown devices={devices} onChange={selectDevice} />
            </Box>
          )}
          {Object.keys(MEASURE_TYPES[input.type].fields || {}).length > 0 && (
            <Stack>
              <Typography sx={{ mb: "10px" }}>
                We generated some reasonable data for you,
                <br /> but you can change it if you&apos;d like
              </Typography>
              {Object.values(MEASURE_TYPES[input.type].fields).map((field) => (
                <React.Fragment key={field.key}>
                  {field.options ? (
                    <SimulatedVitalSelect
                      value={
                        measureData[field.key] !== null
                          ? measureData[field.key]
                          : ""
                      }
                      label={field.label}
                      name={field.key}
                      onChange={handleSetMeasureType}
                      options={field.options}
                      unit={field.unit}
                    />
                  ) : (
                    <SimulatedVitalTextField
                      value={measureData[field.key] || ""}
                      label={field.label}
                      name={field.key}
                      onChange={handleSetMeasureType}
                      unit={field.unit}
                    />
                  )}
                </React.Fragment>
              ))}
            </Stack>
          )}
        </Box>
      )}

      <Stack flexDirection="row" alignItems="center">
        <Button
          sx={{ mr: "15px", textTransform: "capitalize" }}
          variant="contained"
          color="primary"
          onClick={onSendMeasure}
          disabled={isLoading || errMessage}
        >
          Send it!
        </Button>
        {isLoading && <CircularProgress size={18} color="primary" />}
      </Stack>
      {(isSuccess || errMessage) && (
        <Typography
          sx={{
            mt: "10px",
            ...(isSuccess
              ? { color: green[500] }
              : {
                  color: red[500],
                  fontStyle: "italic",
                }),
          }}
        >
          {isSuccess ? (
            <>
              Success! Check the{" "}
              <Link
                sx={{
                  color: blue[500],
                  textDecoration: "none",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
                to="/measures"
              >
                Measures
              </Link>{" "}
              tab to see the result.
            </>
          ) : (
            errMessage
          )}
        </Typography>
      )}
    </MainContentContainer>
  );
}
export default SimulateMeasures;
