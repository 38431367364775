import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    maxWidth: 1000,
    margin: "auto",
  },
  paper: {
    boxShadow: theme.shadows[5],
    outline: "none",
    width: "100%",
    padding: 15,
    position: "relative",
    borderRadius: 2,
    height: "auto",
    maxHeight: "90vh",
    overflowY: "auto",
  },
  iconBtn: {
    position: "absolute",
    top: 5,
    right: 5,
    padding: 8,
    zIndex: 2,
    "& svg": {
      fontSize: 25,
    },
  },
  title: {
    fontSize: 18,
    fontWeight: 500,
    color: theme.palette.text.primary,
    textTransform: "capitalize",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 20,
  },
}));

export default useStyles;
