import { red, green } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  container: {
    padding: "27px 56px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    width: 350,
    margin: "auto",
  },
  mrb10: {
    marginBottom: 10,
  },
  errText: {
    color: red[500],
    fontStyle: "italic",
    textAlign: "center",
    marginTop: 0,
  },
  progress: {
    marginRight: "10px !important",
  },
  successText: {
    color: green[500],
    fontStyle: "italic",
    textAlign: "center",
    marginTop: 0,
  },
}));

export default useStyles;
