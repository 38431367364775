import PropTypes from "prop-types";

import React from "react";

function ContractIcon(props) {
  const { width, height } = props;

  return (
    <svg
      width={width || 205}
      height={height || 179}
      viewBox="0 0 205 179"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M183.213 86.0316C183.213 104.971 177.02 122.402 166.753 136.481C161.049 144.024 154.204 150.728 146.381 155.924C133.67 164.472 118.513 169.5 102.216 169.5C57.3996 169.5 21.2202 132.124 21.2202 86.1992C21.2202 40.1072 57.5625 2.89844 102.216 2.89844C118.513 2.89844 133.67 7.92665 146.381 16.307C154.204 21.5028 161.049 28.2071 166.753 35.7494C177.183 49.6608 183.213 67.092 183.213 86.0316Z"
        fill="#EAEEF9"
      />

      <path
        opacity="0.3"
        d="M166.751 35.5819V136.314C161.047 143.856 154.203 150.56 146.38 155.756H64.0801V29.8833C64.243 26.196 65.7098 22.8438 67.9914 20.4973C70.5989 17.8156 74.0213 16.3071 77.9325 16.3071H146.38C154.203 21.5029 161.21 28.0396 166.751 35.5819Z"
        fill="#CED7E2"
      />

      <path
        d="M151.923 34.2411H105.64V16.3071H166.427C166.59 16.3071 166.753 16.3071 166.753 16.3071C176.206 16.4747 183.865 24.5199 183.865 34.4087H163.82"
        fill="#CED7E2"
      />

      <path
        d="M168.057 16.3071C164.471 16.4747 161.212 18.1508 158.768 20.4973C158.605 20.6649 158.279 21.0001 158.116 21.3353C155.997 23.8495 154.53 27.2016 154.53 30.7213V137.99C154.53 148.046 146.708 156.091 136.93 156.091H51.5332V29.8833C51.6962 26.196 53.1629 22.8438 55.4445 20.4973C58.052 17.8156 61.4744 16.3071 65.3857 16.3071H151.108H167.405C167.731 16.3071 167.894 16.3071 168.057 16.3071Z"
        fill="white"
      />

      <path
        d="M137.418 40.7774H112.158C111.18 40.7774 110.528 39.9394 110.528 39.1014C110.528 38.2633 111.343 37.4253 112.158 37.4253H137.581C138.559 37.4253 139.211 38.2633 139.211 39.1014C139.211 39.9394 138.396 40.7774 137.418 40.7774Z"
        fill="#CED7E2"
      />

      <path
        d="M100.261 40.7774H67.1785C66.2007 40.7774 65.5488 39.9394 65.5488 39.1014C65.5488 38.2633 66.3637 37.4253 67.1785 37.4253H100.261C101.239 37.4253 101.891 38.2633 101.891 39.1014C101.891 39.9394 101.239 40.7774 100.261 40.7774Z"
        fill="#CED7E2"
      />

      <path
        d="M121.447 57.7061H88.5272C87.5493 57.7061 86.8975 56.8681 86.8975 56.0301C86.8975 55.0244 87.7123 54.354 88.5272 54.354H121.447C122.425 54.354 123.077 55.192 123.077 56.0301C123.077 57.0357 122.425 57.7061 121.447 57.7061Z"
        fill="#CED7E2"
      />

      <path
        d="M77.9346 57.7061H67.1785C66.2007 57.7061 65.5488 56.8681 65.5488 56.0301C65.5488 55.0244 66.3637 54.354 67.1785 54.354H77.9346C78.9124 54.354 79.5643 55.192 79.5643 56.0301C79.5643 57.0357 78.7494 57.7061 77.9346 57.7061Z"
        fill="#E3EAF2"
      />

      <path
        d="M137.418 74.8023H117.21C116.232 74.8023 115.58 73.9643 115.58 73.1263C115.58 72.1206 116.395 71.4502 117.21 71.4502H137.418C138.396 71.4502 139.048 72.2882 139.048 73.1263C139.211 73.9643 138.396 74.8023 137.418 74.8023Z"
        fill="#E3EAF2"
      />

      <path
        d="M107.595 74.8023H67.1785C66.2007 74.8023 65.5488 73.9643 65.5488 73.1263C65.5488 72.1206 66.3637 71.4502 67.1785 71.4502H107.595C108.573 71.4502 109.225 72.2882 109.225 73.1263C109.225 73.9643 108.573 74.8023 107.595 74.8023Z"
        fill="#CED7E2"
      />

      <path
        d="M121.447 91.7306H99.1199C98.1421 91.7306 97.4902 90.8925 97.4902 90.0545C97.4902 89.2165 98.3051 88.3784 99.1199 88.3784H121.447C122.425 88.3784 123.077 89.2165 123.077 90.0545C123.077 90.8925 122.425 91.7306 121.447 91.7306Z"
        fill="#CED7E2"
      />

      <path
        d="M89.5054 91.7306H67.1785C66.2007 91.7306 65.5488 90.8925 65.5488 90.0545C65.5488 89.2165 66.3637 88.3784 67.1785 88.3784H89.5054C90.4833 88.3784 91.1351 89.2165 91.1351 90.0545C91.1351 90.8925 90.4833 91.7306 89.5054 91.7306Z"
        fill="#E3EAF2"
      />

      <path
        d="M137.418 108.826H121.936C120.958 108.826 120.306 107.988 120.306 107.15C120.306 106.145 121.121 105.474 121.936 105.474H137.418C138.396 105.474 139.048 106.312 139.048 107.15C139.211 107.988 138.396 108.826 137.418 108.826Z"
        fill="#CED7E2"
      />

      <path
        d="M111.832 108.826H85.7571C84.7793 108.826 84.1274 107.988 84.1274 107.15C84.1274 106.145 84.9423 105.474 85.7571 105.474H111.832C112.81 105.474 113.462 106.312 113.462 107.15C113.625 107.988 112.81 108.826 111.832 108.826Z"
        fill="#E3EAF2"
      />

      <path
        d="M76.1427 108.826H67.0164C66.0386 108.826 65.3867 107.988 65.3867 107.15C65.3867 106.145 66.2016 105.474 67.0164 105.474H76.1427C77.1206 105.474 77.7724 106.312 77.7724 107.15C77.7724 107.988 77.1206 108.826 76.1427 108.826Z"
        fill="#CED7E2"
      />

      <path
        d="M121.447 125.754H105.965C104.987 125.754 104.335 124.916 104.335 124.078C104.335 123.24 105.15 122.402 105.965 122.402H121.447C122.425 122.402 123.077 123.24 123.077 124.078C123.077 124.916 122.425 125.754 121.447 125.754Z"
        fill="#CED7E2"
      />

      <path
        d="M95.8613 125.754H67.1785C66.2007 125.754 65.5488 124.916 65.5488 124.078C65.5488 123.24 66.3637 122.402 67.1785 122.402H95.8613C96.8391 122.402 97.491 123.24 97.491 124.078C97.491 124.916 96.6761 125.754 95.8613 125.754Z"
        fill="#E3EAF2"
      />

      <path
        d="M99.4463 141.677H121.936C121.936 145.532 123.403 149.22 126.01 151.734C128.455 154.248 131.877 155.924 135.789 155.924H34.2582C30.5099 155.924 26.9246 154.415 24.48 151.734C22.0355 149.22 20.4058 145.7 20.4058 141.677H87.0605C87.2235 141.677 99.6092 141.845 99.4463 141.677Z"
        fill="#CED7E2"
      />

      <path
        d="M35.6985 20.4719C37.8384 19.7566 39.0093 17.3926 38.3138 15.1919C37.6182 12.9911 35.3197 11.7869 33.1798 12.5022C31.0399 13.2175 29.869 15.5814 30.5645 17.7822C31.26 19.983 33.5586 21.1872 35.6985 20.4719Z"
        fill="#EAEEF9"
      />

      <path
        d="M44.5921 6.79991C46.0472 6.3135 46.8434 4.70601 46.3705 3.20948C45.8975 1.71296 44.3345 0.894095 42.8794 1.38051C41.4242 1.86692 40.628 3.47441 41.101 4.97094C41.5739 6.46746 43.137 7.28633 44.5921 6.79991Z"
        fill="#EAEEF9"
      />

      <path
        d="M7.36619 97.0937C8.8963 97.0937 10.1367 95.818 10.1367 94.2443C10.1367 92.6707 8.8963 91.395 7.36619 91.395C5.83609 91.395 4.5957 92.6707 4.5957 94.2443C4.5957 95.818 5.83609 97.0937 7.36619 97.0937Z"
        fill="url(#paint0_linear_579_2335)"
      />

      <path
        d="M199.928 61.7615C202.453 59.3886 202.63 55.3599 200.322 52.7633C198.015 50.1666 194.098 49.9853 191.573 52.3582C189.048 54.7311 188.872 58.7598 191.179 61.3564C193.486 63.9531 197.404 64.1344 199.928 61.7615Z"
        fill="#EAEEF9"
      />

      <path
        d="M198.693 33.9059C200.134 33.9059 201.301 32.7053 201.301 31.2242C201.301 29.7431 200.134 28.5425 198.693 28.5425C197.253 28.5425 196.086 29.7431 196.086 31.2242C196.086 32.7053 197.253 33.9059 198.693 33.9059Z"
        fill="url(#paint1_linear_579_2335)"
      />

      <path
        d="M1.5 158.773H167.892"
        stroke="#EBF0F6"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <g filter="url(#filter0_d_579_2335)">
        <path
          d="M196.109 141.861C195.62 144.04 194.968 146.052 194.153 148.063C193.99 148.566 193.828 148.901 193.502 149.404C190.731 154.935 186.331 159.46 180.953 162.142C180.301 162.477 179.649 162.812 179.16 162.98C173.945 165.159 168.078 165.997 162.211 164.656C146.729 161.304 136.788 145.716 140.047 129.793C143.307 113.871 158.463 103.647 173.945 106.999C179.486 108.173 184.375 111.022 188.449 115.044C195.131 122.084 198.065 132.14 196.109 141.861Z"
          fill="#EAEEF9"
        />
      </g>

      <path
        d="M194.13 148.214C193.968 148.717 193.805 149.052 193.479 149.555C190.708 155.086 186.308 159.611 180.93 162.293C180.278 162.628 179.626 162.963 179.137 163.131L158.929 142.348L163.818 142.515L169.033 142.683L172.781 140.001C172.781 140.001 175.715 134.805 175.552 134.47C175.389 134.135 172.13 128.939 172.13 128.939L175.715 129.609L194.13 148.214Z"
        fill="url(#paint2_linear_579_2335)"
      />

      <path
        d="M179.79 145.364L175.553 141.006C178.812 135.978 177.508 129.274 172.619 125.922C167.73 122.569 161.211 123.91 157.952 128.939C154.692 133.967 155.996 140.671 160.885 144.023C164.471 146.37 169.034 146.37 172.456 144.023L176.693 148.381C177.508 149.219 178.812 149.219 179.627 148.381C180.605 147.543 180.605 146.202 179.79 145.364ZM162.352 139.498C159.908 136.984 159.908 132.793 162.352 130.279C164.797 127.765 168.871 127.765 171.315 130.279C173.76 132.793 173.76 136.984 171.315 139.498C168.708 142.012 164.797 142.012 162.352 139.498Z"
        fill="white"
      />

      <defs>
        <filter
          id="filter0_d_579_2335"
          x="131.428"
          y="103.363"
          width="73.2769"
          height="74.939"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />

          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />

          <feOffset dy="5" />

          <feGaussianBlur stdDeviation="4" />

          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.1125 0 0 0 0 0.295916 0 0 0 0 1 0 0 0 0.22 0"
          />

          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_579_2335"
          />

          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_579_2335"
            result="shape"
          />
        </filter>

        <linearGradient
          id="paint0_linear_579_2335"
          x1="4.60124"
          y1="94.2536"
          x2="9.98806"
          y2="94.2536"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#83A6FF" />

          <stop offset="1" stopColor="#5A78FF" />
        </linearGradient>

        <linearGradient
          id="paint1_linear_579_2335"
          x1="196.139"
          y1="31.3058"
          x2="201.198"
          y2="31.3058"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF66A9" />

          <stop offset="1" stopColor="#F53689" />
        </linearGradient>

        <linearGradient
          id="paint2_linear_579_2335"
          x1="172.289"
          y1="140.918"
          x2="182.545"
          y2="150.889"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CED7E2" />

          <stop offset="1" stopColor="#CED7E2" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}

ContractIcon.propTypes = {
  width: PropTypes.number.isRequired,

  height: PropTypes.number.isRequired,
};

export default ContractIcon;
