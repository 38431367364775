import { Box, Divider, Grid, Skeleton, Stack, Typography } from "@mui/material";
import { blue, green, grey } from "@mui/material/colors";
import {
  CalendarPicker,
  LocalizationProvider,
  PickersDay,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  endOfMonth,
  format,
  formatDuration,
  intervalToDuration,
  startOfMonth,
} from "date-fns";
import sub from "date-fns/sub";
import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router";
import { DOCS } from "../../utils/constants";
import { get } from "../../utils/io";
import devicesData from "../DeviceList/data";
import MainContentContainer from "../Layouts/MainContentContainer";
import MeasureLineChart from "../Measures/MeasureLineChart";
import typeToDataMap from "./data";

function DemoDevice() {
  const { mac } = useParams();
  const location = useLocation();
  const deviceData = devicesData.find(
    (device) => device.mac.toUpperCase() === mac
  );
  const [imageUrl, setImageUrl] = useState(null);
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  useEffect(() => {
    const getDeviceModels = async () => {
      const res = await get("/device_models", { include_image_url: true });
      const device = res.data.device_models.find(
        (model) => model.display_name === deviceData.model
      );
      setImageUrl(device?.image?.url);
    };

    getDeviceModels();
  }, [deviceData.model]);

  const deviceTypeInfo =
    location.state.make === "Biofourmis"
      ? typeToDataMap.Biofourmis
      : typeToDataMap[location.state.type];

  const chartData = deviceTypeInfo.data
    .map((dataPoint) => ({ ...dataPoint, timestamp: dataPoint.created_time }))
    .reverse();

  const getTicks = (startDate, endDate) => {
    const ticks = [];
    let currDate = startDate;
    while (currDate < endDate) {
      ticks.push(currDate);
      const tempDate = new Date(currDate);
      currDate = startOfMonth(
        tempDate.setMonth(tempDate.getMonth() + 1)
      ).valueOf();
    }
    return ticks;
  };

  return (
    <MainContentContainer
      title={`${deviceData.make} ${
        deviceData.make === "Abbott" ? "CGM" : deviceData.type
      } (${mac})`}
      analyticsTitle="Device Detail"
      docsUrl={DOCS.DEVICE_METRICS.url}
    >
      <Stack
        direction={{ xs: "column", md: "row" }}
        spacing={3}
        sx={{ mb: "10px" }}
      >
        {imageUrl && (
          <>
            <img
              style={{
                width: "auto",
                height: "200px",
                display: isImageLoaded ? "block" : "none",
              }}
              src={imageUrl}
              alt={mac}
              onLoad={() => setIsImageLoaded(true)}
            />
            {!isImageLoaded && (
              <Skeleton variant="rectangular" width={300} height={200} />
            )}
          </>
        )}
        <DeviceDetails
          deviceData={deviceData}
          deviceTypeInfo={deviceTypeInfo}
        />
      </Stack>
      <Compliance />
      <Box>
        <Stack
          sx={{ mb: "10px" }}
          direction="row"
          justifyContent="space-between"
        >
          <Typography variant="h6">Measurements</Typography>
        </Stack>
        <Divider />
        {/* Using negative margin to center the box since the svg created by recharts
        has extra white space on the left */}
        <Box sx={{ mt: "20px", ml: "-30px" }}>
          <MeasureLineChart
            data={chartData}
            graphs={deviceTypeInfo.graphs}
            lineChartProps={{
              margin: { top: 5, right: 30, left: 20, bottom: 5 },
            }}
            labelProps={{ sx: { ml: "40px", mt: "10px" } }}
            XAxisProps={{
              ticks: getTicks(
                new Date(chartData[0].timestamp).valueOf(),
                endOfMonth(
                  new Date(chartData[chartData.length - 1].timestamp)
                ).valueOf()
              ),
              interval: 0,
              tickCount: 10,
            }}
            tickTimeFormat="MMM yy"
            toolTipLabelTimeFormat="M/d"
          />
        </Box>
      </Box>
    </MainContentContainer>
  );
}

/* eslint-disable react/prop-types */
function DeviceDetails(props) {
  const { deviceData, deviceTypeInfo } = props;
  // const { mac } = useParams();

  // Quick and dirty random time in past between 1 and 3
  const randomSecsInPast = Math.floor(
    (Math.random() * 2 + 1) * 365 * 24 * 60 * 60
  );
  const firstTime = sub(Date.now(), { seconds: randomSecsInPast });
  const lastTime = Date.now();

  const details = [
    // { label: "Type", value: deviceData.type },
    // { label: "Manufacturer", value: deviceData.make },
    { label: "Health System", value: "Yale Health" },
    { label: "Model", value: deviceData.model },
    // { label: "MAC Address", value: mac },
    { label: "First Used", value: format(firstTime, "MMM d, yyyy HH:mm") },
    { label: "Last Used", value: format(lastTime, "MMM d, yyyy HH:mm") },
    {
      label: "Time in Service",
      value: formatDuration(
        intervalToDuration({ start: firstTime, end: lastTime }),
        { format: ["years", "months", "days"] }
      ),
    },
    {
      label: "Vitals Taken",
      value: deviceTypeInfo.data.length,
    },
  ];

  return (
    <Box
      sx={{
        p: "10px",
        backgroundColor: "#ececf2",
        borderRadius: "5px",
      }}
    >
      <Grid container rowSpacing={3} columnSpacing={{ xs: 2, sm: 3, md: 6 }}>
        {details.map(({ label, value }) => (
          <DeviceDetailItem key={label} label={label} value={value} />
        ))}
      </Grid>
    </Box>
  );
}

// eslint-disable-next-line react/prop-types
function DeviceDetailItem({ label, value }) {
  return (
    <Grid item xs={7} sm={6} md={4}>
      <Typography sx={{ fontWeight: "bold", fontSize: "20px" }}>
        {label}
      </Typography>
      <Typography sx={{ fontSize: "16px" }}>{value}</Typography>
    </Grid>
  );
}

// function Longevity() {
//   return (
//     <>
//       <Typography variant="h6">Longevity</Typography>
//       <Divider />
//       <Stack
//         direction="row"
//         sx={{ mx: "50px", my: "50px", justifyContent: "center" }}
//       >
//         <Stack sx={{ alignItems: "center", mx: "50px" }}>
//           <Typography variant="h4">
//             {formatDuration(
//               intervalToDuration({ start: firstTime, end: lastTime }),
//               { format: ["years", "months", "days"] }
//             )}
//           </Typography>
//           <Typography variant="h5">in service</Typography>
//         </Stack>
//         <Stack sx={{ alignItems: "center", mx: "50px" }}>
//           <Typography variant="h4">
//             {Math.floor(random(0.5, 1) * differenceInDays(lastTime, firstTime))}
//           </Typography>
//           <Typography variant="h5">days used</Typography>
//         </Stack>
//       </Stack>
//     </>
//   );
// }

function Compliance() {
  return (
    <>
      <Typography variant="h6">Compliance</Typography>
      <Divider />
      <Stack
        direction={{ xs: "column", md: "row" }}
        justifyContent="flex-start"
        spacing={{ xs: 0, md: 3 }}
        sx={{ mb: "20px" }}
      >
        <MeasureCalendar />
        <ComplianceMonths />
      </Stack>
    </>
  );
}

function ComplianceMonths() {
  // const months = ["Jan", "Feb", "Mar"];

  return (
    <Stack>
      <ComplianceMonthsLegend />
      {/* Come back and make this dynamic based on current month */}
      <Stack sx={{ ml: "20px" }} direction="row" spacing={2}>
        <ComplianceMonth month="Sep" compliance="noncompliant" />
        <ComplianceMonth month="Oct" compliance="compliant" />
        <ComplianceMonth month="Nov" compliance="compliant" />
        <ComplianceMonth month="Dec" compliance="supercompliant" />
        <ComplianceMonth month="Jan" compliance="supercompliant" />
        <ComplianceMonth month="Feb" compliance="compliant" />
      </Stack>
    </Stack>
  );
}

function ComplianceMonthsLegend() {
  return (
    <Stack
      direction="row"
      sx={{
        margin: "20px",
      }}
    >
      <Box
        sx={{
          width: "20px",
          minWidth: "20px",
          height: "20px",
          minHeight: "20px",
          borderRadius: "50%",
          backgroundColor: grey[300],
          margin: "0 10px 10px 0",
          display: "flex",
          alignItems: "center",
        }}
      />
      <Typography sx={{ alignItems: "center" }}>Non-Compliant</Typography>
      <Box
        sx={{
          width: "20px",
          minWidth: "20px",
          height: "20px",
          minHeight: "20px",
          borderRadius: "50%",
          backgroundColor: blue[200],
          margin: "0 10px 10px 20px",
        }}
      />
      <Typography>Compliant</Typography>
      <Box
        sx={{
          width: "20px",
          minWidth: "20px",
          height: "20px",
          minHeight: "20px",
          borderRadius: "50%",
          backgroundColor: green[200],
          margin: "0 10px 10px 20px",
        }}
      />
      <Typography>Supercompliant</Typography>
    </Stack>
  );
}

// eslint-disable-next-line react/prop-types
function ComplianceMonth({ month, compliance }) {
  let color = grey[300];
  if (compliance === "compliant") {
    // eslint-disable-next-line prefer-destructuring
    color = blue[200];
  } else if (compliance === "supercompliant") {
    // eslint-disable-next-line prefer-destructuring
    color = green[200];
  }
  return (
    <Box
      sx={{
        width: "80px",
        minWidth: "80px",
        height: "80px",
        minHeight: "80px",
        borderRadius: "50%",
        backgroundColor: color,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography variant="h5">{month}</Typography>
    </Box>
  );
}

function MeasureCalendar() {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack
        sx={{
          mt: "20px",
          "& .MuiCalendarPicker-root": {
            m: 0,
          },
          "& .MuiPickersCalendarHeader-root": {
            p: 0,
            mt: 0,
          },
          "& .MuiPickersCalendarHeader-label": {
            fontSize: "15px",
          },
          "& .MuiDayPicker-header": {
            justifyContent: "space-between",
            width: "100%",
          },
          "& .MuiDayPicker-weekContainer": {
            justifyContent: "space-between",
          },
          "& .MuiPickersDay-hiddenDaySpacingFiller": {
            width: "22px",
            height: "22px",
          },
          "& .MuiDayPicker-weekDayLabel": {
            width: "22px",
            height: "22px",
          },
          "& .MuiDayPicker-slideTransition": {
            height: "auto",
            minHeight: "150px",
          },
        }}
      >
        <Typography>Daily Measurements</Typography>
        <CalendarPicker
          disableFuture
          readOnly
          disableHighlightToday
          onChange={() => {}}
          date={new Date()}
          renderDay={(day, _, pickersDayProps) => {
            const isOnDay = Math.random() >= 0.25;
            const isFutureDay = day > new Date();
            let color = grey[300];
            if (isFutureDay) {
              color = "#fff";
            } else if (isOnDay) {
              // eslint-disable-next-line prefer-destructuring
              color = blue[200];
            }
            return (
              <PickersDay
                key={pickersDayProps.key}
                {...pickersDayProps}
                today={false}
                sx={{
                  width: "22px",
                  height: "22px",
                  // !important because today=false isn't working
                  backgroundColor: `${color} !important`,
                }}
              />
            );
          }}
        />
      </Stack>
    </LocalizationProvider>
  );
}

export default DemoDevice;
