import { Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { format } from "date-fns";
import PropTypes from "prop-types";
import React from "react";
import {
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Label,
  Line,
  Legend,
  Tooltip,
  ReferenceLine,
  ResponsiveContainer,
} from "recharts";
import DownloadButton from "../../DownloadButton";

export default function MeasureLineChart(props) {
  const {
    data,
    isShowExportCSVButton,
    graphs,
    lineChartProps,
    labelProps,
    XAxisProps,
    tickTimeFormat,
    toolTipLabelTimeFormat,
  } = props;

  const getCSVData = () => {
    const keys = Object.keys(data[0]);

    const vitals = data
      .map((measure) => keys.map((key) => measure[key]).join(","))
      .join("\n");

    const result = `${keys.join(",")}\n${vitals}`;
    return result;
  };

  const renderLabel = ({ viewBox: { x, y }, value, position }) => (
    // Must use `text` tag because the chart is rendered as an svg
    // https://github.com/recharts/recharts/issues/3795
    <text x={x + 3} y={y + 18} textAnchor={position} fill={grey.A700}>
      {value}
    </text>
  );

  return (
    <>
      {isShowExportCSVButton && (
        <Stack flexDirection="row" justifyContent="flex-end">
          <DownloadButton
            getData={getCSVData}
            fileType="text/csv"
            fileName={`measures_${new Date().toISOString()}.csv`}
            size="small"
            disabled={!data.length}
          >
            Export to CSV
          </DownloadButton>
        </Stack>
      )}
      {graphs.map((graph) => {
        const formattedData = data.map((dataPoint) => ({
          ...dataPoint,
          timestamp: new Date(dataPoint.timestamp).valueOf(),
        }));

        return (
          <Stack key={graph.label}>
            <Typography fontWeight="bold" {...labelProps}>
              {graph.label}
            </Typography>
            <ResponsiveContainer width="100%" height={250}>
              <LineChart data={formattedData} {...lineChartProps}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="timestamp"
                  domain={["dataMin", "dataMax"]}
                  tickFormatter={(value) =>
                    format(new Date(value), tickTimeFormat)
                  }
                  type="number"
                  {...XAxisProps}
                />
                <Tooltip
                  labelFormatter={(label) =>
                    format(new Date(label), toolTipLabelTimeFormat)
                  }
                />
                <Legend verticalAlign="top" height={30} />
                {graph.value.map((key) => {
                  const keyData =
                    typeof key === "string" ? { key, color: "#3182BD" } : key;

                  return (
                    <React.Fragment key={keyData.key}>
                      <YAxis domain={key.yAxis || [0, "auto"]} />
                      <Line
                        key={keyData.key}
                        type="monotone"
                        dataKey={keyData.key}
                        connectNulls
                        isAnimationActive={false} // Fix for dots not showing up
                        stroke={keyData.color}
                        strokeWidth={2}
                      />
                      {keyData?.bounds &&
                        keyData.bounds.map((bound) => {
                          const { value, color } =
                            typeof bound === "number"
                              ? { value: bound, color: keyData.color }
                              : bound;
                          return (
                            <ReferenceLine
                              key={value}
                              y={value}
                              stroke={color}
                              strokeDasharray="6 6"
                              strokeWidth={2}
                              label={
                                <Label
                                  value={value}
                                  content={renderLabel}
                                  position="insideTopLeft"
                                />
                              }
                            />
                          );
                        })}
                    </React.Fragment>
                  );
                })}
              </LineChart>
            </ResponsiveContainer>
          </Stack>
        );
      })}
    </>
  );
}

MeasureLineChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.shape(),
    ])
  ).isRequired,
  isShowExportCSVButton: PropTypes.bool,
  graphs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string.isRequired,
          color: PropTypes.string,
          bounds: PropTypes.arrayOf(
            PropTypes.oneOfType([
              PropTypes.number,
              PropTypes.shape({
                color: PropTypes.string.isRequired,
                value: PropTypes.number.isRequired,
              }),
            ])
          ),
          yAxis: PropTypes.arrayOf(PropTypes.number),
        })
      ),
    })
  ).isRequired,
  lineChartProps: PropTypes.shape({
    margin: PropTypes.shape({
      top: PropTypes.number,
      right: PropTypes.number,
      bottom: PropTypes.number,
      left: PropTypes.number,
    }),
    syncId: PropTypes.string,
  }),
  labelProps: PropTypes.objectOf(
    PropTypes.shape({
      sx: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  XAxisProps: PropTypes.shape({
    interval: PropTypes.number,
    ticks: PropTypes.arrayOf(PropTypes.number),
  }),
  tickTimeFormat: PropTypes.string,
  toolTipLabelTimeFormat: PropTypes.string,
};

MeasureLineChart.defaultProps = {
  lineChartProps: {},
  labelProps: {},
  isShowExportCSVButton: true,
  XAxisProps: {},
  tickTimeFormat: "M/d",
  toolTipLabelTimeFormat: "M/d, h:mm aaa",
};
