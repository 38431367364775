import { Clear } from "@mui/icons-material";
import { Drawer } from "@mui/material";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useState } from "react";

import useStyles from "./styles";

export default function StelDrawer(props) {
  const { anchor, open, title, onClose, children, width } = props;
  const classes = useStyles({ width });

  const [boxShadow, setBoxShadow] = useState(null);

  const onScroll = (e) => {
    if (e.target.scrollTop > 10) {
      setBoxShadow(classes.boxShadow);
    } else {
      setBoxShadow(null);
    }
  };

  return (
    <Drawer
      anchor={anchor}
      open={open}
      onClose={onClose}
      className={classes.container}
      onScrollCapture={onScroll}
    >
      <div className={classNames(classes.header, boxShadow)}>
        {title && <div className={classes.mr0}>{title}</div>}
        <Clear
          className={classNames(classes.backIcon, classes.pointer)}
          onClick={onClose}
        />
      </div>
      <div className={classes.content}>{children}</div>
    </Drawer>
  );
}

StelDrawer.propTypes = {
  anchor: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  width: PropTypes.number,
};

StelDrawer.defaultProps = {
  width: 500,
};
