import InfoIcon from "@mui/icons-material/Info";
import { Box, Divider, Link } from "@mui/material";
import { pink } from "@mui/material/colors";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router";

import { ENTERPRISE_FEATURE_MESSAGE } from "../../../utils/constants";
import { isAdmin, isOrgEnterprise } from "../../../utils/io";
import theme from "../../../utils/theme";

function MainContentContainer(props) {
  const { title, docsUrl, children, isEnterpriseOnly, analyticsTitle } = props;
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      page_title:
        analyticsTitle ||
        (typeof title === "string" ? title : location.pathname),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isEnterpriseOnly && !isOrgEnterprise() && !isAdmin() && (
        <Box
          sx={{
            textAlign: "center",
            background: pink[200],
            padding: "7px 0",
            color: theme.colors.darkGrey,
            fontSize: theme.fontSize.medium,
            width: "100%",
            fontWeight: "bold",
          }}
        >
          {ENTERPRISE_FEATURE_MESSAGE}
        </Box>
      )}
      <Box sx={{ margin: "24px 40px" }}>
        <Box
          sx={{
            display: "flex",
            fontSize: 24,
            color: theme.colors.black,
            fontWeight: 700,
            alignItems: "center",
          }}
        >
          {title}{" "}
          {docsUrl && (
            <Link href={docsUrl} target="_blank" rel="noopener">
              <InfoIcon
                sx={{
                  ml: "5px",
                  pt: "5px",
                  fontSize: 20,
                  color: theme.colors.stelBlue,
                }}
              />
            </Link>
          )}
        </Box>
        <Box>
          <Divider sx={{ mb: "16px", mt: "10px" }} />
          {children}
        </Box>
      </Box>
    </>
  );
}
MainContentContainer.propTypes = {
  children: PropTypes.node.isRequired,
  docsUrl: PropTypes.string,
  isEnterpriseOnly: PropTypes.bool,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]).isRequired,
  analyticsTitle: PropTypes.string,
};
MainContentContainer.defaultProps = {
  isEnterpriseOnly: false,
  docsUrl: "",
  analyticsTitle: "",
};

export default MainContentContainer;
