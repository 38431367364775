import { yellow } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";
import theme from "../../utils/theme";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    height: "100vh",
  },
  mainLayout: {
    width: "100%",
    height: "100vh",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
  },
  sandboxBanner: {
    textAlign: "center",
    background: yellow[700],
    padding: "7px 0",
    color: theme.colors.darkGrey,
    fontSize: theme.fontSize.medium,
    width: "100%",
  },
  clickHereText: {
    textDecoration: "underline",
    cursor: "pointer",
  },
}));

export default useStyles;
