import { Link, Tooltip, Typography } from "@mui/material";
import PropTypes from "prop-types";

import React from "react";

function DocsTooltip({ description, docsUrl, color, children, ...rest }) {
  return (
    <Tooltip
      title={
        <Typography variant="body2" sx={{ lineHeight: 1.25 }}>
          {description}
          {docsUrl && (
            <Link
              href={docsUrl}
              sx={{ color, textDecorationColor: color }}
              underline="always"
              target="_blank"
              rel="noreferrer"
            >
              Learn more.
            </Link>
          )}
        </Typography>
      }
      arrow
      {...rest}
    >
      {children}
    </Tooltip>
  );
}
DocsTooltip.propTypes = {
  description: PropTypes.string.isRequired,
  docsUrl: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.node.isRequired,
};
DocsTooltip.defaultProps = {
  color: "#fff",
  docsUrl: "",
};
export default DocsTooltip;
