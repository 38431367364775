import { TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import useStyles from "../OrderModal/styles";

export default function InputAutoComplete(props) {
  const classes = useStyles();
  const {
    options,
    getOptionLabel,
    onInputChange,
    onChange,
    value,
    label,
    name,
    multiple,
    defaultValue,
    required,
    ...rest
  } = props;
  return (
    <>
      <div
        className={classNames(
          classes.w170px,
          classes.bold,
          classes.flex,
          classes.mrb10
        )}
      >
        {label}
      </div>
      <Autocomplete
        options={options}
        getOptionLabel={getOptionLabel}
        onInputChange={onInputChange}
        autoFocus={false}
        multiple={multiple}
        onChange={onChange}
        defaultValue={defaultValue}
        {...rest}
        renderInput={(params) => (
          <TextField
            {...params}
            name={name}
            value={value}
            variant="outlined"
            size="small"
            classes={{ root: classes.input400 }}
            fullWidth
            required={required}
          />
        )}
      />
    </>
  );
}

InputAutoComplete.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.number,
      PropTypes.objectOf(
        PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.bool,
          PropTypes.number,
        ])
      ),
    ])
  ).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number])
    ),
  ]),
  multiple: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number])
    ),
  ]),
  getOptionLabel: PropTypes.func.isRequired,
  onInputChange: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
};
InputAutoComplete.defaultProps = {
  multiple: false,
  onInputChange: () => {},
  value: null,
  defaultValue: null,
  required: false,
};
