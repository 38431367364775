import { Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { useFormContext } from "react-hook-form";
import RhfTextField from "../../form/RhfTextField";
import TroubleshootButtonGroup from "../TroubleshootButtonGroup";
import CheckboxGroup from "./components/CheckboxGroup";

const checkBoxList = ["hub_blinking_red", "hub_damage", "location_changed"];

function HubCellularForm(props) {
  const { handleFormSubmit, disableReturnButton } = props;
  const { control } = useFormContext();
  return (
    <Stack spacing={2}>
      <Typography sx={{ fontSize: "20px", fontWeight: 600 }}>
        Patient Troubleshooting
      </Typography>
      <CheckboxGroup list={checkBoxList} control={control} />
      <RhfTextField
        name="notes"
        control={control}
        label="Notes"
        size="small"
        rows={5}
        multiline
        variant="outlined"
      />
      <TroubleshootButtonGroup
        handleFormSubmit={(mainKey) => handleFormSubmit?.(mainKey)}
        disableReturnButton={disableReturnButton}
      />
    </Stack>
  );
}

HubCellularForm.propTypes = {
  handleFormSubmit: PropTypes.func,
  disableReturnButton: PropTypes.bool,
};

HubCellularForm.defaultProps = {
  handleFormSubmit: () => {},
  disableReturnButton: false,
};

export default HubCellularForm;
