import {
  Router,
  AutoFixHigh,
  Link as LinkIcon,
  DeviceHub,
  PersonAdd,
  Construction,
  DataObject,
  Science,
} from "@mui/icons-material";
import {
  Card,
  CardActions,
  CardContent,
  Grid,
  Link,
  Stack,
} from "@mui/material";
import React from "react";
import { DOCS } from "../../utils/constants";
import theme from "../../utils/theme";
import MainContentContainer from "../Layouts/MainContentContainer";

export default function GetStarted() {
  const cards = [
    {
      title: "You are currently in your organization's sandbox",
      docsLink: DOCS.SANDBOX_MODE.url,
      icon: Science,
    },
    {
      title: "Check out your virtual hubs for testing",
      docsLink: DOCS.HUBS.url,
      cmdLink: "/hubs",
      icon: Router,
    },
    {
      title: "Simulate measurements as if they were taken by a patient",
      docsLink: DOCS.SIMULATOR.url,
      cmdLink: "/simulate-measurements",
      icon: AutoFixHigh,
    },
    {
      title: "Read more about how Stel connects with your systems",
      docsLink: DOCS.INTEGRATION_GUIDE.url,
      icon: Construction,
    },
    {
      title: "Configure endpoints where you would like data to be sent",
      docsLink: DOCS.ENDPOINTS.url,
      cmdLink: "/endpoints",
      icon: LinkIcon,
    },
    {
      title: "Organize your hubs to suit your workflow",
      docsLink: DOCS.HUB_GROUP.url,
      cmdLink: "/hub-group",
      icon: DeviceHub,
    },
    {
      title: "See how the data delivered to your systems is formatted",
      docsLink: DOCS.PAYLOAD_SCHEMA.url,
      icon: DataObject,
    },
    {
      title: "Invite a colleague to collaborate in your organization",
      docsLink: DOCS.INVITE_USERS.url,
      cmdLink: "/users",
      icon: PersonAdd,
    },
  ];
  return (
    <MainContentContainer title="Get Started">
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12, lg: 16 }}
        sx={{ flexGrow: 1 }}
      >
        {cards.map((card) => (
          <Grid
            item
            key={card.title}
            xs={4}
            sm={4}
            md={4}
            sx={{ display: "flex" }}
          >
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                py: 2,
              }}
            >
              <Stack alignItems="center">
                <card.icon
                  sx={{ fontSize: 80, mb: 1, color: theme.colors.darkGrey }}
                />
              </Stack>
              <CardContent sx={{ my: 1, color: theme.colors.darkGrey }}>
                <strong>{card.title}</strong>
              </CardContent>
              <CardActions sx={{ justifyContent: "space-evenly" }}>
                <Link href={card.docsLink} target="_blank" rel="noopener">
                  Learn More
                </Link>
                {card?.cmdLink && <Link href={card.cmdLink}>Try It Out</Link>}
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </MainContentContainer>
  );
}
