import { Box, Button, TextField, Typography } from "@mui/material";
import { uniq } from "lodash";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import StelModal from "./StelModal";

function BulkHubsActionModal(props) {
  const { isVisible, onClose, width, onSubmit } = props;
  const [text, setText] = useState("");

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!isVisible) setText("");
  }, [isVisible]);

  const getHubIdsFromText = () =>
    uniq(text.split(/,|\r\n|\r|\n|\s+/).filter((hubId) => hubId.length > 0));

  const handleSubmit = async (e) => {
    e.preventDefault();

    const hubIds = getHubIdsFromText();
    const badHubIds = hubIds.filter((hubId) => hubId.length !== 10);
    if (badHubIds.length > 0) {
      enqueueSnackbar(
        `All hub IDs must be exactly 10 characters. Please fix the following hub IDs:\n
        ${badHubIds.join(", ")}`,
        { variant: "error" }
      );
    } else {
      await onSubmit(hubIds);
    }
  };

  return (
    <StelModal
      open={isVisible}
      onClose={onClose}
      title="Bulk Add Hubs"
      width={width}
    >
      <form onSubmit={handleSubmit}>
        <Typography sx={{ fontSize: "14px" }}>
          Paste a list of hub IDs separated by commas, whitespace, or newlines
          into the below field. Hub IDs must be exactly 10 characters.
        </Typography>
        <TextField
          label="Hub IDs"
          name="hubIds"
          multiline
          rows={8}
          fullWidth
          value={text}
          onChange={(e) => setText(e.target.value)}
          required
        />
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: "20px" }}>
          <Button color="primary" variant="contained" type="submit">
            Add
          </Button>
        </Box>
      </form>
    </StelModal>
  );
}

BulkHubsActionModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  width: PropTypes.number,
  onSubmit: PropTypes.func.isRequired,
};
BulkHubsActionModal.defaultProps = {
  width: 500,
};

export default BulkHubsActionModal;
