import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { validatePassword } from "../../../utils/io";

const StyledTextField = styled(TextField)({
  marginBottom: 10,
  width: "100%",
});

const PASSWORD_MATCH_ERROR = "Passwords do not match";
const PASSWORD_FIELD_NAME = "password";
const CONFIRM_PASSWORD_FIELD_NAME = "confirmPassword";

const propTypes = {
  password: PropTypes.string.isRequired,
  setPassword: PropTypes.func.isRequired,
  confirmPassword: PropTypes.string.isRequired,
  setConfirmPassword: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  passwordLabel: PropTypes.string,
  confirmPasswordLabel: PropTypes.string,
};

const defaultProps = {
  passwordLabel: "Password",
  confirmPasswordLabel: "Confirm Password",
};

function PasswordVerification({
  password,
  setPassword,
  confirmPassword,
  setConfirmPassword,
  passwordLabel,
  confirmPasswordLabel,
  setError,
}) {
  const [errorPassword, setErrorPassword] = useState("");
  const [errorConfirmPassword, setErrorConfirmPassword] = useState("");

  const onChange = (e) => {
    const { name, value } = e.target;

    if (name === CONFIRM_PASSWORD_FIELD_NAME) {
      const passwordsMatch = value === password;

      setErrorConfirmPassword(passwordsMatch ? "" : PASSWORD_MATCH_ERROR);
      setConfirmPassword(value);
    }
    if (name === PASSWORD_FIELD_NAME) {
      const isValidPassword = validatePassword(value);
      const isConfirmPassword = !!confirmPassword;
      const passwordsMatch = value === confirmPassword;

      setErrorConfirmPassword(
        isConfirmPassword && !passwordsMatch ? PASSWORD_MATCH_ERROR : ""
      );
      setErrorPassword(
        isValidPassword ? "" : "Password must be at least 8 characters"
      );
      setPassword(value);
    }
  };

  useEffect(
    () => setError(!!(errorPassword || errorConfirmPassword)),
    [errorPassword, errorConfirmPassword, setError]
  );

  return (
    <>
      <StyledTextField
        name={PASSWORD_FIELD_NAME}
        size="small"
        variant="outlined"
        placeholder={passwordLabel}
        label={passwordLabel}
        onChange={onChange}
        value={password}
        type="password"
        error={!!errorPassword}
        helperText={errorPassword}
      />
      <StyledTextField
        name={CONFIRM_PASSWORD_FIELD_NAME}
        size="small"
        variant="outlined"
        placeholder={confirmPasswordLabel}
        label={confirmPasswordLabel}
        onChange={onChange}
        value={confirmPassword}
        type="password"
        error={!!errorConfirmPassword}
        helperText={errorConfirmPassword}
      />
    </>
  );
}

PasswordVerification.propTypes = propTypes;
PasswordVerification.defaultProps = defaultProps;

export default PasswordVerification;
