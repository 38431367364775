import { Check, Clear } from "@mui/icons-material";
import { Box, Button, MenuItem } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { capitalize, debounce } from "lodash";
import { enqueueSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { DOCS, FEATURE_FLAGS } from "../utils/constants";

import { get, getFeatureFlag, isAdmin } from "../utils/io";
import { Protected } from "./Authz/Protected";
import RhfTextField from "./form/RhfTextField";
import MainContentContainer from "./Layouts/MainContentContainer";
import StelDataGrid from "./StelDataGrid";
import VirtualHubIndicator from "./VirtualHubIndicator";

const useStyles = makeStyles((theme) => ({
  capitalize: {
    textTransform: "capitalize",
  },
  checkIcon: {
    color: theme.palette.success.dark,
  },
  searchBar: {
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
  },
  searchButton: {
    margin: `${theme.spacing(0, 1)}`,
  },
  searchField: {
    minWidth: 150,
    marginRight: "15px",
  },
}));

function HubList() {
  const classes = useStyles();
  const history = useHistory();

  const defaultSearchInputs = {
    hubId: "",
    hubGroup: "",
    versionStatus: "",
    connected: "",
  };

  const [loading, setLoading] = useState(false);
  const [searchCriteria, setSearchCriteria] = useState(defaultSearchInputs);
  const [searchResults, setSearchResults] = useState([]);
  const [pagination, setPagination] = useState({
    pageSize: 20,
    page: 0,
    totalCount: 0,
  });

  const showVersionStatusFilter =
    isAdmin() || getFeatureFlag(FEATURE_FLAGS.VERSION_STATUS_FILTER);

  const DataGridColumns = [
    {
      field: "id",
      flex: 1,
      headerName: "Hub ID",
      valueGetter: (params) => params.value.substring(0, 10),
      renderCell: (params) => (
        <>
          {params.value}
          {params.row.isVirtual && (
            <Box sx={{ ml: "10px" }}>
              <VirtualHubIndicator />
            </Box>
          )}
        </>
      ),
    },
    {
      field: "connected",
      align: "center",
      flex: 0.7,
      headerAlign: "center",
      headerName: "Connected",
      renderCell: (params) => {
        if (params.value) {
          return <Check className={classes.checkIcon} />;
        }

        return <Clear color="error" />;
      },
      width: 150,
    },
    {
      field: "groupName",
      align: "center",
      flex: 1,
      headerAlign: "center",
      headerName: "Hub Group",
      valueGetter: (params) => params.row.hubGroup?.name,
    },
    ...(showVersionStatusFilter
      ? [
          {
            field: "versionStatus",
            align: "center",
            flex: 1,
            headerAlign: "center",
            headerName: "Version Status",
            valueGetter: (params) =>
              params.value
                ?.split(" ")
                .map((word) => capitalize(word))
                .join(" "),
          },
        ]
      : []),
  ];

  useEffect(() => {
    async function performUpdate(criteria, page, pageSize) {
      setLoading(true);

      const { status, data } = await get("/hubs", {
        hub_id: criteria.hubId,
        group_id: criteria.hubGroup,
        version_status: criteria.versionStatus,
        connected: criteria.connected,
        limit: pageSize,
        offset: pageSize * page,
      });

      if (status !== 200) {
        enqueueSnackbar("Error retrieving Hubs");
        setSearchResults([]);
        setPagination((pre) => ({
          ...pre,
          totalCount: 0,
        }));
        setLoading(false);
        return;
      }

      const newSearchResults = data.hubs.map(
        ({
          connected,
          hub_group: hubGroup = {},
          version_status: versionStatus,
          is_virtual: isVirtual,
          hub_id: hubId,
        }) => ({
          id: hubId,
          connected,
          hubGroup,
          versionStatus,
          isVirtual,
          hubId,
        })
      );

      setSearchResults(newSearchResults);
      setPagination((pre) => ({
        ...pre,
        totalCount: data.total_count,
      }));

      setLoading(false);
    }

    const debounced = debounce(performUpdate, 300);

    debounced(searchCriteria, pagination.page, pagination.pageSize);

    // Effect cleanup to cancel previous update calls
    return debounced.cancel;
  }, [searchCriteria, pagination.page, pagination.pageSize]);

  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty },
  } = useForm({
    defaultValues: defaultSearchInputs,
  });

  const resetForm = () => {
    reset();
    setSearchCriteria(defaultSearchInputs);
  };

  return (
    <MainContentContainer title="Hubs" docsUrl={DOCS.HUBS.url}>
      <form
        className={classes.searchBar}
        onSubmit={handleSubmit(setSearchCriteria)}
      >
        <RhfTextField
          name="hubId"
          control={control}
          className={classes.searchField}
          label="Hub ID"
          size="small"
          variant="standard"
        />
        <Protected permission="ViewHubGroups">
          <HubGroupSelect control={control} />
        </Protected>
        {showVersionStatusFilter && (
          <RhfTextField
            name="versionStatus"
            control={control}
            select
            className={classes.searchField}
            label="Version Status"
            size="small"
            variant="standard"
          >
            <MenuItem value="latest">Latest</MenuItem>
            <MenuItem value="updating">Updating</MenuItem>
            <MenuItem value="requires update">Requires Update</MenuItem>
          </RhfTextField>
        )}
        <RhfTextField
          name="connected"
          control={control}
          select
          className={classes.searchField}
          label="Connection"
          size="small"
          variant="standard"
        >
          <MenuItem value>Connected</MenuItem>
          <MenuItem value={false}>Disconnected</MenuItem>
        </RhfTextField>
        <Button
          className={classes.searchButton}
          color="primary"
          type="submit"
          variant="contained"
        >
          Search
        </Button>
        <Button
          className={classes.searchButton}
          color="error"
          variant="contained"
          onClick={resetForm}
          disabled={!isDirty}
        >
          Clear
        </Button>
      </form>

      <StelDataGrid
        columns={DataGridColumns}
        rows={searchResults}
        loading={loading}
        onRowClick={({ row }) => history.push(`/hubs/${row.hubId}`)}
        pageSize={pagination.pageSize}
        onPageSizeChange={(pageSize) =>
          setPagination((pre) => ({ ...pre, pageSize }))
        }
        rowsPerPageOptions={[20, 25, 50, 100]}
        rowCount={pagination.totalCount}
        onPageChange={(page) => setPagination((pre) => ({ ...pre, page }))}
        paginationMode="server"
      />
    </MainContentContainer>
  );
}

function HubGroupSelect({ control }) {
  const [hubGroups, setHubGroups] = useState([]);

  useEffect(() => {
    async function getHubGroups() {
      const { data } = await get("/hub_groups");
      setHubGroups(data.hub_groups);
    }
    getHubGroups();
  }, []);

  return (
    <RhfTextField
      sx={{
        minWidth: 150,
        marginRight: "15px",
      }}
      name="hubGroup"
      control={control}
      select
      label="Hub Group"
      size="small"
      variant="standard"
    >
      {hubGroups.map(({ id, name }) => (
        <MenuItem key={id} value={id}>
          {name}
        </MenuItem>
      ))}
    </RhfTextField>
  );
}

HubGroupSelect.propTypes = {
  control: PropTypes.shape().isRequired,
};

export default HubList;
