import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { post } from "../../utils/io";
import { FormField, SelectFormField } from "./UserFormUtils";

const getDefaultFormValues = (role) => ({
  ...(role?.id ? { ...role } : { id: "", name: "", permissions: [] }),
});

export default function RoleForm({ role, permissions, onUpdateRole }) {
  const [loading, setLoading] = useState(false);

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: getDefaultFormValues(role),
  });

  useEffect(() => {
    reset(getDefaultFormValues(role));
  }, [role, reset]);

  const updateRole = async (formData) => {
    try {
      setLoading(true);
      const res = await post(
        `/roles${formData?.id ? `/${formData?.id}` : "/"}`,
        {
          name: formData.name,
          permissions: formData.permissions,
        }
      );
      if (res.status === 200 || res.status === 201) {
        enqueueSnackbar(
          `${formData?.id ? "Saved" : "Created"} role ${formData.name}`,
          { variant: "success" }
        );
      } else {
        throw res;
      }
    } catch (error) {
      enqueueSnackbar(
        `Failed to ${formData?.id ? "edit" : "create"} role ${formData.name}`,
        { variant: "error" }
      );
    } finally {
      setLoading(false);
    }
    onUpdateRole();
  };

  return (
    <form onSubmit={handleSubmit(updateRole)}>
      <Box
        sx={{ "&": { display: "flex", flexDirection: "column", gap: "16px" } }}
      >
        <FormField name="name" label="Role" control={control} errors={errors} />

        <SelectFormField
          control={control}
          size="small"
          label="Permissions"
          name="permissions"
          options={permissions}
        />

        <LoadingButton
          sx={{ "&": { height: "40px" } }}
          loading={loading}
          variant="contained"
          color="primary"
          type="submit"
        >
          {role?.id ? "Save" : "Create role"}
        </LoadingButton>
      </Box>
    </form>
  );
}

RoleForm.propTypes = {
  role: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  }),
  permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  onUpdateRole: PropTypes.func.isRequired,
};

RoleForm.defaultProps = {
  role: null,
};
