import {
  Switch,
  FormControlLabel,
  Typography,
  Tooltip,
  Button,
  Stack,
} from "@mui/material";
import { GridToolbarContainer } from "@mui/x-data-grid";
import PropTypes from "prop-types";
import React from "react";
import DocsTooltip from "../../DocsTooltip";

function DevicesToolbar(props) {
  const {
    showExpiredDevices,
    setShowExpiredDevices,
    showExpiredDevicesDisabled,
    isDisabled,
    onMoveRegistrations,
  } = props;

  const onChangeShowDeviceExpired = (e) => {
    setShowExpiredDevices(e.target.checked);
  };

  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <GridToolbarContainer>
        <DocsTooltip
          description="Register the selected devices to another hub"
          docsUrl=""
        >
          <span>
            <Button
              variant="outlined"
              onClick={onMoveRegistrations}
              disabled={isDisabled}
              sx={{
                mr: "10px",
                padding: "5px 10px",
                textTransform: "capitalize",
              }}
            >
              Move Registrations
            </Button>
          </span>
        </DocsTooltip>
      </GridToolbarContainer>
      <GridToolbarContainer>
        <Tooltip
          title={
            <Typography variant="body2">Show completed devices</Typography>
          }
          arrow
        >
          <FormControlLabel
            control={<Switch checked={showExpiredDevices} />}
            onChange={onChangeShowDeviceExpired}
            label="Show Completed"
            labelPlacement="start"
            disabled={showExpiredDevicesDisabled}
          />
        </Tooltip>
      </GridToolbarContainer>
    </Stack>
  );
}

DevicesToolbar.propTypes = {
  showExpiredDevices: PropTypes.bool.isRequired,
  setShowExpiredDevices: PropTypes.func.isRequired,
  showExpiredDevicesDisabled: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onMoveRegistrations: PropTypes.func,
};

DevicesToolbar.defaultProps = {
  showExpiredDevicesDisabled: false,
  isDisabled: false,
  onMoveRegistrations: () => {},
};

export default DevicesToolbar;
