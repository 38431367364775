import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Box, Stack, Typography } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import Papa from "papaparse";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { get, getAuthHeader, post } from "../../utils/io";
import ManualInputIcon from "../icons/ManualInputIcon";
import StelModal from "../StelModal";
import ModalImportTable from "./ModalImportTable";
import CSVUploadAndDisplay from "./UploadCSV";

const dexcomModels = [
  {
    name: "G7",
    value: 115,
  },
  {
    name: "StelO",
    value: 145,
  },
];

const columns = [
  {
    field: "serial_number",
    headerName: "serial_number",
    flex: 1,
    editable: true,
  },
  {
    field: "bluetooth_code",
    headerName: "bluetooth_code",
    flex: 1,
    editable: true,
  },
  {
    field: "data_matrix",
    headerName: "data_matrix",
    flex: 1,
    editable: true,
  },
  {
    field: "box_data_matrix",
    headerName: "box_data_matrix",
    flex: 1,
    editable: true,
  },
];

export default function ImportDevice({ open, onClose }) {
  const [files, setFiles] = useState([]);
  const [rowImports, setRowImports] = useState([]);
  const [columnsImports, setColumnsImports] = useState(columns);
  const [showFileCSV, setShowFileCSV] = useState(false);
  const [openUploadDevices, setOpenUploadDevices] = useState(false);
  const [organizations, setOrganizations] = useState();
  const [organization, setOrganization] = useState("");
  const [dexcomModel, setDexcomModel] = useState();

  const handleSelectFile = (selectedFiles) => {
    setFiles([selectedFiles]);
  };

  const handleRemoveFile = () => {
    setFiles([]);
    setColumnsImports(columns);
    setRowImports([]);
  };

  const handUploadDevices = () => {
    setOpenUploadDevices((prev) => !prev);
    if (openUploadDevices) {
      handleRemoveFile();
      setOrganization("");
    }
  };

  const convertToCSV = (data) => {
    const filteredData = data.map(({ id, ...rest }) => rest);
    const csv = Papa.unparse(filteredData);
    return csv;
  };
  const getCSVBlob = () => {
    const filteredData = rowImports.map(({ id, ...rest }) => rest);
    const csv = convertToCSV(filteredData);
    const blob = new Blob([csv], { type: "text/csv" });
    return blob;
  };

  const handleImportDexcom = async () => {
    const csvBlob = getCSVBlob();
    const formData = new FormData();
    formData.append("file", csvBlob, "data.csv");
    formData.append("org_alias", organization);
    formData.append("rd", dexcomModel);

    const response = await post("admin/dexcom/import", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        ...getAuthHeader().headers,
      },
    });
    if (response?.status === 201) {
      enqueueSnackbar("Dexcom Devices Imported Success", {
        variant: "success",
      });
      setShowFileCSV(false);
      setOpenUploadDevices(false);
      handleRemoveFile();
    } else {
      enqueueSnackbar(response.data.error, {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    async function getOrganizations() {
      const res = await get("/admin/organizations");
      if (res.status === 200) {
        setOrganizations(res.data.organizations);
      }
    }
    getOrganizations();
  }, []);

  return (
    <>
      <StelModal
        open={open}
        onClose={onClose}
        title="Import Devices"
        width={500}
      >
        <Stack flexDirection="row" gap={3}>
          <Box
            sx={{
              border: "1px solid #1F79E2",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: "40px 20px",
              borderRadius: "4px",
              gap: 2,
              width: "50%",
              cursor: "pointer",
            }}
            onClick={handUploadDevices}
          >
            <CloudUploadIcon sx={{ color: "#1F79E2", width: 60, height: 60 }} />
            <Typography
              sx={{ fontSize: 20, fontWeight: 600, textAlign: "center" }}
            >
              Upload Devices List
            </Typography>
          </Box>
          <Box
            sx={{
              border: "1px solid #1F79E2",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: "40px 20px",
              borderRadius: "4px",
              gap: 2,
              width: "50%",
              cursor: "pointer",
            }}
            onClick={() => setShowFileCSV(true)}
          >
            <ManualInputIcon />
            <Typography
              sx={{ fontSize: 20, fontWeight: 600, textAlign: "center" }}
            >
              Enter Devices Manually
            </Typography>
          </Box>
        </Stack>
      </StelModal>
      <StelModal
        open={openUploadDevices}
        onClose={handUploadDevices}
        title="Upload Devices List"
        width={500}
      >
        <CSVUploadAndDisplay
          files={files}
          onSelectFile={handleSelectFile}
          onRemoveFile={handleRemoveFile}
          rowImports={rowImports}
          setRowImports={setRowImports}
          setColumnsImports={setColumnsImports}
          handleShowFileCSV={() => setShowFileCSV(true)}
          handleImportDexcom={handleImportDexcom}
          organizations={organizations}
          organization={organization}
          setOrganization={setOrganization}
          dexcomModels={dexcomModels}
          dexcomModel={dexcomModel}
          setDexcomModel={setDexcomModel}
        />
      </StelModal>
      {showFileCSV && (
        <ModalImportTable
          open={showFileCSV}
          columnsImports={columnsImports}
          rows={rowImports}
          onClose={() => setShowFileCSV(false)}
          setRowImports={setRowImports}
          handleImportDexcom={handleImportDexcom}
          organizations={organizations}
          organization={organization}
          setOrganization={setOrganization}
          dexcomModels={dexcomModels}
          dexcomModel={dexcomModel}
          setDexcomModel={setDexcomModel}
        />
      )}
    </>
  );
}

ImportDevice.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
