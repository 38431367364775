import { Typography } from "@mui/material";
import { differenceWith, uniqueId } from "lodash";
import { enqueueSnackbar } from "notistack";
import React from "react";

const getResultsByStatus = (results, status) =>
  results.filter((response) => response.status === status);

const showMultiResultsSnackbar = (
  results,
  successStatus,
  getSuccessText,
  getFailureText
) => {
  const successes = getResultsByStatus(results, successStatus);
  const failures = differenceWith(
    results,
    successes,
    (result, success) => result === success
  );
  if (failures.length) {
    enqueueSnackbar(getFailureText(failures.length), {
      variant: "collapse",
      persist: true,
      children: failures.map((response) => (
        <Typography key={uniqueId("failed_res_detail_")}>
          {response.data.detail}
        </Typography>
      )),
      type: "error",
    });
  }
  if (successes.length)
    enqueueSnackbar(getSuccessText(successes.length), {
      variant: "success",
    });
  return failures.length === 0;
};

export default showMultiResultsSnackbar;
