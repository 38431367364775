import React from "react";

export default function Weight() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="10 0 22 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.7478 7.06087C10.9178 5.61759 21.4118 5.61769 21.6304 7.24127C21.8005 8.50419 20.6823 10.4348 20.0998 10.4885C20.0998 10.4885 18.2036 9.78853 16.1631 9.78853C14.1226 9.78853 13.2981 10.669 12.2779 10.4885C11.2577 10.3081 10.5778 8.50414 10.7478 7.06087Z"
        fill="#545454"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.90057 8.37526C7.73538 8.37526 6.84464 9.41435 7.02272 10.5659L9.11649 24.1046C9.25974 25.0309 10.057 25.7144 10.9943 25.7144H21.672C22.6443 25.7144 23.4598 24.9804 23.5617 24.0135L24.9893 10.4747C25.1076 9.35265 24.2279 8.37526 23.0996 8.37526H22.1608C23.285 8.37526 24.1635 9.34605 24.0515 10.4647L22.7912 23.0534C22.6939 24.0247 21.8766 24.7643 20.9005 24.7643H11.6821C10.7393 24.7643 9.93909 24.073 9.80212 23.1402L7.95367 10.5515C7.78524 9.40446 8.67435 8.37526 9.83369 8.37526H8.90057ZM9.83369 8.61279H22.1608C23.1445 8.61279 23.9131 9.46223 23.8151 10.4411L22.5548 23.0297C22.4698 23.8796 21.7546 24.5268 20.9005 24.5268H11.6821C10.8572 24.5268 10.157 23.9219 10.0371 23.1057L8.18867 10.517C8.0413 9.51333 8.81927 8.61279 9.83369 8.61279Z"
        fill="#545454"
      />
      <path
        d="M12.4027 7.88587C12.5214 6.93576 19.8508 6.93583 20.0034 8.00463C20.1222 8.836 19.3413 10.1069 18.9344 10.1423C18.9344 10.1423 17.61 9.68148 16.1849 9.68148C14.7597 9.68148 14.1839 10.2611 13.4713 10.1423C12.7588 10.0235 12.284 8.83598 12.4027 7.88587Z"
        fill="white"
      />
      <path
        d="M15.2993 8.02287V9.60768H15V8.02287H15.2993ZM16.2507 7.93579L15.615 8.79568L15.2514 9.18427L15.1981 8.89364L15.4506 8.56601L15.8838 7.93579H16.2507ZM15.8474 9.5021L15.4245 8.86534L15.6389 8.6716L16.2034 9.5021H15.8474Z"
        fill="#545454"
      />
      <path
        d="M17.6145 8.72384V9.28005C17.5913 9.3098 17.5764 9.37245 17.5263 9.40729C17.477 9.44212 17.395 9.50282 17.3152 9.52822C17.2354 9.55289 17.1483 9.58923 17.0322 9.58923C16.9313 9.58923 16.8163 9.5616 16.7328 9.52822C16.6494 9.49412 16.5852 9.43135 16.5249 9.36604C16.4647 9.30073 16.4183 9.22091 16.3856 9.12658C16.353 9.03224 16.3366 8.92448 16.3366 8.8033V8.69881C16.3366 8.57762 16.3519 8.46987 16.3824 8.37553C16.4136 8.2812 16.4578 8.20138 16.5151 8.13607C16.5732 8.07076 16.6425 8.02105 16.723 7.98695C16.8043 7.95284 16.895 7.93579 16.9952 7.93579C17.1294 7.93579 17.2397 7.95792 17.3261 8.00219C17.4131 8.04645 17.4799 8.10741 17.5263 8.18505C17.5728 8.26197 17.6018 8.3505 17.6134 8.45064H17.3217C17.3137 8.39549 17.2978 8.34723 17.2738 8.30587C17.2499 8.26378 17.2158 8.23113 17.1715 8.20791C17.128 8.18396 17.0714 8.17199 17.0017 8.17199C16.9429 8.17199 16.891 8.1836 16.846 8.20682C16.8011 8.23004 16.7633 8.26378 16.7328 8.30805C16.7024 8.35231 16.6791 8.4071 16.6632 8.47241C16.6479 8.53771 16.6403 8.61246 16.6403 8.69663V8.8033C16.6403 8.8882 16.649 8.96367 16.6664 9.0297C16.6839 9.09501 16.7089 9.15016 16.7416 9.19515C16.7749 9.23941 16.8159 9.27316 16.8645 9.29638C16.9139 9.31887 16.9698 9.33012 17.0322 9.33012C17.0888 9.33012 17.1356 9.3254 17.1726 9.31597C17.2103 9.30581 17.2404 9.29384 17.2629 9.28005C17.2854 9.26626 17.3028 9.25284 17.3152 9.23978V8.94263H17.0006V8.72384H17.6145Z"
        fill="#545454"
      />
    </svg>
  );
}
