import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Button } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

function DownloadButton(props) {
  const { getData, fileType, fileName, children, ...rest } = props;

  const downloadFile = () => {
    const data = getData();
    const blob = new Blob([data], { type: fileType });

    const hiddenElement = document.createElement("a");
    hiddenElement.download = fileName;
    hiddenElement.href = URL.createObjectURL(blob);

    hiddenElement.click();
    hiddenElement.remove();
  };

  return (
    <Button
      variant="contained"
      startIcon={<FileDownloadIcon />}
      {...rest}
      onClick={downloadFile}
    >
      {children}
    </Button>
  );
}

DownloadButton.propTypes = {
  getData: PropTypes.func.isRequired,
  fileType: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default DownloadButton;
