import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Button } from "@mui/material";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import {
  ENTERPRISE_FEATURE_MESSAGE,
  SANDBOX_MODE_MESSAGE,
} from "../../../utils/constants";
import { post, get, isSandbox, isOrgEnterprise } from "../../../utils/io";
import RhfTextfield from "../../form/RhfTextField";
import MainContentContainer from "../../Layouts/MainContentContainer";
import StelModal from "../../StelModal";
import useStyles from "./styles";

function ErrorMessage(props) {
  const { message } = props;

  const classes = useStyles();

  return message ? (
    <div className={classes.errorContainer}>
      <WarningAmberIcon className={classes.errorIcon} fontSize="13px" />
      <p className={classes.errorMessage}>{message}</p>
    </div>
  ) : null;
}

ErrorMessage.propTypes = {
  message: PropTypes.string.isRequired,
};

function HubCountOrHubCost(props) {
  const { orgInfo } = props;

  const classes = useStyles();

  const remainingHubs = orgInfo.included_hub_stock - orgInfo.total_hubs;
  const hasRemainingHubs = remainingHubs > 0;

  return (
    <p className={classes.hubCountMessage}>
      <strong>{hasRemainingHubs ? remainingHubs : "$60"}</strong>
      {hasRemainingHubs
        ? ` hub${remainingHubs > 1 ? "s" : ""} left in included stock`
        : " per hub"}
    </p>
  );
}

HubCountOrHubCost.propTypes = {
  orgInfo: PropTypes.objectOf(
    PropTypes.oneOfType[(PropTypes.string, PropTypes.number, PropTypes.bool)]
  ).isRequired,
};

const defaultValues = {
  numberOfHubs: 0,
  shippingAddress: "",
  contactName: "",
  additionalInfo: "",
};

export default function OrderRequest() {
  const classes = useStyles();

  const [orgInfo, setOrgInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState({
    open: false,
    title: "",
    text: "",
  });

  const history = useHistory();

  const formFields = [
    {
      name: "numberOfHubs",
      label: "Number of Hubs*",
      type: "number",
      rules: {
        min: {
          value: 1,
          message: "Please enter a non-zero value",
        },
        required: {
          value: true,
          message: "This field is required",
        },
        pattern: {
          value: /^\d+$/,
          message: "Please enter a whole number",
        },
      },
    },
    {
      name: "shippingAddress",
      label: "Shipping Address*",
      rules: {
        required: {
          value: true,
          message: "Shipping address is required",
        },
      },
    },
    {
      name: "contactName",
      label: "Contact Name",
    },
    {
      name: "additionalInfo",
      label: "Additional Info",
      multiline: true,
      rows: 4,
    },
  ];

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({ defaultValues });

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const getAPIData = async () => {
      const res = await get("/organization");
      if (res.status === 200) {
        setOrgInfo(res.data);
      }
    };
    getAPIData();
  }, []);

  const submitOrder = async (inputs) => {
    if (!isOrgEnterprise()) {
      return setModal({
        open: true,
        title: "Enterprise Feature",
        text: ENTERPRISE_FEATURE_MESSAGE,
      });
    }
    if (isSandbox()) {
      return setModal({
        open: true,
        title: "Switch to Production Mode",
        text: SANDBOX_MODE_MESSAGE,
      });
    }

    setLoading(true);
    const { numberOfHubs, shippingAddress, contactName, additionalInfo } =
      inputs;
    const res = await post("/hub_orders", {
      number_of_hubs: numberOfHubs,
      shipping_address: shippingAddress,
      ...(contactName && { contact_name: contactName }),
      ...(additionalInfo && { additional_info: additionalInfo }),
    });
    if (res.status !== 201) {
      const message =
        res.data?.detail || "Request failed, please contact support@stel.life";
      setLoading(false);
      return enqueueSnackbar(message, { variant: "error" });
    }

    reset();
    setLoading(false);
    enqueueSnackbar("Your order request has been sent to Stel", {
      variant: "success",
    });
    return history.push(`/hub-orders/${res.data.id}`);
  };

  const closeModal = () => setModal((pre) => ({ ...pre, open: false }));

  return (
    <MainContentContainer title="Order Hubs" isEnterpriseOnly>
      <StelModal
        open={modal.open}
        title={modal.title}
        onClose={closeModal}
        width={500}
      >
        <p>{modal.text}</p>
      </StelModal>
      <HubCountOrHubCost orgInfo={orgInfo} />
      <div className={classes.container}>
        <form className={classes.form} onSubmit={handleSubmit(submitOrder)}>
          {formFields.map((field) => (
            <RhfTextfield
              {...field}
              key={field.name}
              className={classes.formField}
              control={control}
              disabled={loading}
              {...(field?.rules && {
                error: !!errors[field.name],
                helperText: (
                  <ErrorMessage message={errors[field.name]?.message} />
                ),
              })}
            />
          ))}
          <div className={classes.btnContainer}>
            <Button variant="contained" type="submit" disabled={loading}>
              Submit
            </Button>
          </div>
        </form>
        <div className={classes.hubImg}>
          <img src="/stel-hub.png" alt="" />
        </div>
      </div>
    </MainContentContainer>
  );
}
