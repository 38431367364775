import React from "react";
import ReactDOM from "react-dom";

import "./index.css";
import ReactGA from "react-ga4";
import AppContainer from "./AppContainer";
import config from "./config";
import webVitals from "./reportWebVitals";

ReactGA.initialize([
  {
    trackingId: config.googleAnalyticTrackingId,
  },
]);

ReactDOM.render(
  <React.StrictMode>
    <AppContainer />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
webVitals();
