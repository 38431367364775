import { HideImage, InfoOutlined, Star } from "@mui/icons-material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Checkbox,
  Grid,
  IconButton,
  Link,
  MenuItem,
  Skeleton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import classNames from "classnames";
import { format, isBefore } from "date-fns";
import { sortBy, uniq } from "lodash";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import {
  ENTERPRISE_FEATURE_MESSAGE,
  MEASURE_TYPES,
  SANDBOX_MODE_MESSAGE,
} from "../../utils/constants";
import { get, isOrgEnterprise, isSandbox, post } from "../../utils/io";
import theme from "../../utils/theme";
import { Protected, ProtectedButton } from "../Authz/Protected";
import MainContentContainer from "../Layouts/MainContentContainer";
import ChoicesModal from "./ChoicesModal";
import MessageModal from "./MessageModal";
import useStyles from "./styles";

export default function DeviceEcosystem() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [devices, setDevices] = useState([]);
  const [listDevices, setListDevices] = useState([]);
  const [typeFilter, setTypeFilter] = useState([]);
  const [makeFilter, setMakeFilter] = useState([]);
  const [quantities, setQuantities] = useState({});
  const [modalName, setModalName] = useState("");
  const [inputAdditionalInformation, setInputAdditionalInformation] =
    useState("");

  // Definining this as a plain old const created an effect-render loop.
  // Putting it in state fixes that, but it doesn't need to be set-able
  const [MEASURE_DEVICE_TYPES] = useState(
    Object.keys(MEASURE_TYPES).reduce(
      (result, measureType) => ({
        ...result,
        [measureType]:
          MEASURE_TYPES[measureType].deviceType ||
          MEASURE_TYPES[measureType].label,
      }),
      {}
    )
  );

  const isPriceAndExpirationValid = (price, expiration) =>
    price && expiration && isBefore(new Date(), new Date(expiration));

  useEffect(() => {
    const getDeviceModels = async () => {
      setLoading(true);
      const res = await get("/device_models", { include_image_url: true });
      if (res.status !== 200) {
        enqueueSnackbar({
          message: "Failed to retrieve device models",
          variant: "error",
        });
        setLoading(false);
        return;
      }
      const deviceModels = res.data.device_models.map((model) => ({
        ...model,
        deviceType: MEASURE_DEVICE_TYPES[model.type],
        // A special computer attribute to make sorting easier
        missingValidPrice: !isPriceAndExpirationValid(
          model.price,
          model.price_expiration_date
        ),
      }));

      const sortedDevices = sortBy(deviceModels, [
        "missingValidPrice",
        "deviceType",
        "make",
        "display_name",
      ]);
      setDevices(sortedDevices);
      setListDevices(sortedDevices);
      setLoading(false);
    };

    getDeviceModels();
  }, [MEASURE_DEVICE_TYPES]);

  useEffect(() => {
    setListDevices(
      devices.filter(
        ({ deviceType, make }) =>
          (typeFilter.length === 0 || typeFilter.includes(deviceType)) &&
          (makeFilter.length === 0 || makeFilter.includes(make))
      )
    );
  }, [typeFilter, makeFilter, devices]);

  const handleInputChange = (e) => {
    const { value, name } = e.target;
    setQuantities((pre) => ({ ...pre, [name]: Number.parseInt(value, 10) }));
  };

  const onPricingRequest = (id) => async () => {
    // Message for non-enterprise orgs, both in and out of sandbox
    if (!isOrgEnterprise()) {
      setModalName("enterprise_feature");
      return;
    }
    // Message for enterprise orgs when in the sandbox
    if (isSandbox()) {
      setModalName("enterprise_in_sandbox");
      return;
    }

    const res = await post("/device_pricing_request", { model_id: id });
    if (res.status === 200) {
      setModalName("device_pricing_request");
    }
  };

  const onClickDeviceOrder = () => {
    // Message for non-enterprise orgs, both in and out of sandbox
    if (!isOrgEnterprise()) {
      setModalName("enterprise_feature");
      return;
    }
    // Message for enterprise orgs when in the sandbox
    if (isSandbox()) {
      setModalName("enterprise_in_sandbox");
      return;
    }

    const badQuantities = Object.values(quantities).filter((q) => q < 1);
    if (badQuantities.length > 0) {
      enqueueSnackbar({
        message: "Order quantities must be at least 1",
        variant: "error",
      });
      return;
    }
    setModalName("device_order_requests");
  };

  const onRequestDeviceOrder = async () => {
    const payload = Object.keys(quantities).map((modelId) => ({
      model_id: modelId,
      quoted_price: devices.find(({ id }) => id.toString() === modelId).price,
      quantity: quantities[modelId],
    }));
    const res = await post("/device_order_request", {
      devices: payload,
      additional_information: inputAdditionalInformation,
    });
    if (res.status === 200) {
      setModalName("device_order_requests_success");
    } else {
      enqueueSnackbar({
        message: "Request failed, please contact support@stel.life",
        variant: "error",
      });
      setModalName("");
    }
  };

  const getDeviceTypes = () =>
    sortBy(uniq(devices.map((device) => device.deviceType)));

  return (
    <MainContentContainer title="Device Ecosystem" isEnterpriseOnly>
      <div>
        <div className={classes.header}>
          <div className={classes.filterContainer}>
            <TextField
              name="deviceType"
              label="Type"
              select
              SelectProps={{ multiple: true }}
              variant="outlined"
              size="small"
              className={classes.filterField}
              value={typeFilter}
              onChange={({ target: { value } }) => setTypeFilter(value)}
            >
              {getDeviceTypes().map((deviceType) => (
                <MenuItem value={deviceType} key={deviceType}>
                  {deviceType}
                </MenuItem>
              ))}
            </TextField>
            <Autocomplete
              limitTags={2}
              value={makeFilter}
              multiple
              disableCloseOnSelect
              size="small"
              className={classes.makeFilter}
              renderOption={(props, option, { selected }) => (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon />}
                    checkedIcon={<CheckBoxIcon />}
                    checked={selected}
                  />
                  {option}
                </li>
              )}
              options={sortBy(
                uniq(
                  devices.map(({ make }) => make),
                  (make) => make.toLowerCase()
                )
              )}
              renderInput={(params) => (
                <TextField {...params} name="make" label="Manufacturer" />
              )}
              onChange={(_, value) => setMakeFilter(value)}
            />
          </div>
          <div className={classes.buttonContainer}>
            <Button
              variant="contained"
              onClick={() => {
                setTypeFilter([]);
                setMakeFilter([]);
              }}
              color="error"
            >
              Clear
            </Button>
            <ProtectedButton
              permission="RequestDeviceOrders"
              variant="contained"
              className={classes.submitBtn}
              onClick={onClickDeviceOrder}
              disabled={!Object.keys(quantities).length}
            >
              Request Device Order
            </ProtectedButton>
          </div>
        </div>
        <Grid container spacing={2} className={classes.list}>
          {loading &&
            new Array(8).fill(0).map(() => (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <DeviceSkeleton />
              </Grid>
            ))}
          {listDevices.map((device) => (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              {/* Fixed height to account for some alignment issues with the bottom card content */}
              <Card sx={{ height: 341, position: "relative" }} key={device.id}>
                {/* Fixed height for now to account for longer make/models that wrap and make the cards misaligned */}
                <CardContent sx={{ height: 53 }}>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box>
                      {device.properties?.stel_detail_url ? (
                        <Link
                          href={device.properties.stel_detail_url}
                          className={classes.deviceName}
                          target="_blank"
                          rel="noopener"
                          underline="hover"
                          sx={{
                            color: theme.colors.black,
                            "&:hover": { color: theme.colors.stelBlue },
                          }}
                        >
                          {device.make} {device.display_name}
                        </Link>
                      ) : (
                        <p className={classes.deviceName}>
                          {device.make} {device.display_name}
                        </p>
                      )}
                      <p
                        className={classNames(
                          classes.m0,
                          classes.fs14,
                          classes.colorGrey
                        )}
                      >
                        {MEASURE_DEVICE_TYPES[device.type] || "N/A"}
                      </p>
                    </Box>
                    <Stack direction="row">
                      {device.properties?.preferred && (
                        <Box>
                          <Tooltip
                            title="Popular device in the Stel ecosystem"
                            arrow
                          >
                            <Star
                              sx={{
                                fontSize: 18,
                                color: theme.colors.stelBlue,
                              }}
                            />
                          </Tooltip>
                        </Box>
                      )}
                      {device.properties?.stel_detail_url && (
                        <Box>
                          <IconButton
                            href={device.properties.stel_detail_url}
                            target="_blank"
                            rel="noopener"
                            edge="end"
                            disableRipple
                            sx={{
                              color: theme.colors.black,
                              "&:hover": { color: theme.colors.stelBlue },
                              top: -8,
                            }}
                          >
                            <InfoOutlined
                              sx={{
                                fontSize: 18,
                              }}
                            />
                          </IconButton>
                        </Box>
                      )}
                    </Stack>
                  </Box>
                </CardContent>
                {device.image?.url && device.properties?.stel_detail_url && (
                  <Link
                    href={device.properties.stel_detail_url}
                    className={classes.deviceName}
                    target="_blank"
                    rel="noopener"
                  >
                    <CardMedia
                      component="img"
                      image={device.image.url}
                      className={classes.img}
                    />
                  </Link>
                )}
                {device.image?.url && !device.properties?.stel_detail_url && (
                  <CardMedia
                    component="img"
                    image={device.image.url}
                    className={classes.img}
                  />
                )}
                {!device.image?.url && (
                  <HideImage className={classes.imgIcon} />
                )}
                <CardContent
                  sx={{ position: "absolute", bottom: 0, right: 0, left: 0 }}
                >
                  {isPriceAndExpirationValid(
                    device.price,
                    device.price_expiration_date
                  ) ? (
                    <div className={classes.flex}>
                      <div>
                        <Typography
                          className={classNames(
                            classes.m0,
                            classes.bold,
                            classes.fs14
                          )}
                        >
                          Enterprise Price
                        </Typography>
                        <Typography className={classes.m0}>
                          ${device.price}
                        </Typography>
                        <Typography
                          className={classNames(
                            classes.m0,
                            classes.italic,
                            classes.fs12,
                            classes.colorGrey
                          )}
                        >
                          Valid Through{" "}
                          {format(
                            new Date(device.price_expiration_date),
                            "MM/dd/yy"
                          )}
                        </Typography>
                      </div>
                      <Protected permission="RequestDeviceOrders">
                        <div className={classes.mtAuto}>
                          <TextField
                            size="small"
                            variant="outlined"
                            className={classes.quantityInput}
                            label="Quantity"
                            name={device.id}
                            value={quantities[device.id] || ""}
                            onChange={handleInputChange}
                            type="number"
                          />
                        </div>
                      </Protected>
                    </div>
                  ) : (
                    <div className={classes.right}>
                      <Button
                        className={classes.requestInfoBtn}
                        onClick={onPricingRequest(device.id)}
                        variant="contained"
                      >
                        Request Info
                      </Button>
                    </div>
                  )}
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>
      {modalName === "device_pricing_request" && (
        <MessageModal
          title="Pricing Request Received"
          isDisplayed
          onClose={() => setModalName("")}
          message="Thank you for submitting your request. A representative from Stel will contact you with more information."
        />
      )}
      {modalName === "device_order_requests_success" && (
        <MessageModal
          title="Device Order Request Received"
          isDisplayed
          onClose={() => {
            setModalName("");
            setQuantities({});
          }}
          message="Thank you for submitting your request. A representative from Stel will contact you with more information."
        />
      )}
      {modalName === "device_order_requests" && (
        <ChoicesModal
          isDisplayed
          title="Device Order Request"
          onConfirm={onRequestDeviceOrder}
          onClose={() => setModalName("")}
          quantities={quantities}
          devices={devices}
          inputAdditionalInformation={inputAdditionalInformation}
          setInputAdditionalInformation={setInputAdditionalInformation}
        />
      )}
      {modalName === "enterprise_feature" && (
        <MessageModal
          title="Enterprise Feature"
          isDisplayed
          onClose={() => {
            setModalName("");
            setQuantities({});
          }}
          message={ENTERPRISE_FEATURE_MESSAGE}
        />
      )}
      {modalName === "enterprise_in_sandbox" && (
        <MessageModal
          title="Switch to Production Mode"
          isDisplayed
          onClose={() => {
            setModalName("");
            setQuantities({});
          }}
          message={SANDBOX_MODE_MESSAGE}
        />
      )}
    </MainContentContainer>
  );
}

function DeviceSkeleton() {
  const classes = useStyles();
  return (
    <Card>
      <div className={classes.pd16}>
        <Skeleton variant="text" width={170} height={28} />
        <Skeleton variant="text" width={100} height={20} />
      </div>
      <Skeleton
        variant="rectangular"
        height={200}
        className={classes.imgSkeleton}
      />
      <CardContent>
        <Skeleton variant="text" width={140} height={20} />
        <Skeleton variant="text" width={60} height={20} />
        <Skeleton variant="text" width={200} height={20} />
      </CardContent>
    </Card>
  );
}
