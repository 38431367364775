import { makeStyles } from "@mui/styles";
import { ORDER_STATUS } from "../../utils/constants";

export const orderStatusStyles = {
  [ORDER_STATUS.PROCESSED]: {
    border: "1px solid #1876d1",
    color: "#1876d1",
  },
  [ORDER_STATUS.CANCELLED]: {
    border: "1px solid #eb0013",
    color: "#eb0013",
  },
  [ORDER_STATUS.COMPLETE]: {
    border: "1px solid #66bb69",
    color: "#66bb69",
  },
  [ORDER_STATUS.CONFIRMED]: {
    border: "1px solid #2979ff",
    color: "#2979ff",
  },
  [ORDER_STATUS.SHIPPED]: {
    border: "1px solid #ff5722",
    color: "#ff5722",
  },
  [ORDER_STATUS.PENDING_CONFIRM.replace(" ", "_")]: {
    border: "1px solid #ff9800",
    color: "#ff9800",
  },
  [ORDER_STATUS.AWAITING_PO.replace(" ", "_")]: {
    border: "1px solid #a323bc",
    color: "#a323bc",
  },
};

const useStyles = makeStyles(() => ({
  ...orderStatusStyles,
  flexWrap: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  flex: {
    display: "flex",
  },
  spaceBetween: {
    justifyContent: "space-between",
  },
  mrb15: {
    marginBottom: 15,
  },
  mrr15: {
    marginRight: 15,
  },
  mrt15: {
    marginTop: 15,
  },
  mrl15: {
    marginLeft: 15,
  },
  chip: {
    padding: "5px 7px",
    borderRadius: 4,
    textTransform: "uppercase",
  },
  created: {
    border: "1px solid grey",
    color: "grey",
  },
  btn: {
    height: "fit-content",
  },
  pointer: {
    cursor: "pointer",
  },
  flexEnd: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

export default useStyles;
