import { ErrorMessage } from "@hookform/error-message";
import { TextField, Typography } from "@mui/material";
import { red } from "@mui/material/colors";
import PropTypes from "prop-types";
import React from "react";
import { Controller } from "react-hook-form";

function RhfTextField(props) {
  const { name, errors, control, rules, controllerProps, ...rest } = props;
  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => <TextField {...field} {...rest} />}
        {...controllerProps}
      />
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => (
          <Typography
            sx={{ color: red[500], fontSize: "12px", ml: "10px", mt: "10px" }}
          >
            {message}
          </Typography>
        )}
      />
    </>
  );
}

RhfTextField.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.shape().isRequired,
  rules: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.number,
      PropTypes.object,
      PropTypes.func,
    ])
  ),
  errors: PropTypes.shape({}),
  controllerProps: PropTypes.shape(),
};

RhfTextField.defaultProps = {
  rules: {},
  controllerProps: {},
  errors: {},
};

export default RhfTextField;
