import { Button } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import OrgAutocomplete from "../OrgAutocomplete";
import StelModal from "../StelModal";

function OrgSelectionModal(props) {
  const { open, handleClose, onCreate } = props;
  const { handleSubmit, control, watch } = useForm({});

  const watchOrg = watch("org");

  const onSubmit = async ({ org }) => {
    handleClose();
    await onCreate(org.id);
  };

  const onClose = () => {
    handleClose();
  };

  return (
    <StelModal
      title="Choose Organization"
      width={400}
      onClose={onClose}
      open={open}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="org"
          control={control}
          defaultValue={{ id: "", alias: "", name: "" }}
          shouldUnregister
          render={({ field: { onChange } }) => (
            <OrgAutocomplete onChange={onChange} />
          )}
        />
        <Button
          type="submit"
          disabled={!watchOrg?.id.length}
          variant="contained"
          sx={{
            float: "right",
            mt: 1.25,
          }}
        >
          Create
        </Button>
      </form>
    </StelModal>
  );
}

OrgSelectionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
};

export default OrgSelectionModal;
