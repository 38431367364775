import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

import { hasPermission } from "../../utils/io";

/**
 * Permitted and NotPermitted effectively exist for their names and nothing
 * more. They are (optionally) used as children to Protected to identify which
 * component trees should be rendered if permission is granted or not.
 */
export function Permitted(props) {
  const { children } = props;
  return children;
}
export function NotPermitted(props) {
  const { children } = props;
  return children;
}

/**
 * This component is the central component in this module. It accepts a
 * `permission` prop which can be supplied as a string (single required
 * permission) or an array of strings (multiple required permissions) and checks
 * if the current logged in user has the required permissions to render.
 *
 * The component can be used in one of two ways. Any child component tree
 * can be supplied and that tree will be rendered or not rendered based on the
 * permission logic specified above. Additionnally, two sibling subtrees can be
 * supplied wrapped in the Permitted and NonPermitted components. If the user
 * has permission, the Permitted tree will be rendered, otherwise the
 * NotPermitted tree will be rendered.
 */
export function Protected(props) {
  const { permission, children } = props;

  let permittedChildren = null;
  let notPermittedChildren = null;

  if (
    Array.isArray(children) &&
    children.length === 2 &&
    children.filter((child) => child.type === Permitted).length === 1 &&
    children.filter((child) => child.type === NotPermitted).length === 1
  ) {
    permittedChildren = children.find((child) => child.type === Permitted);
    notPermittedChildren = children.find(
      (child) => child.type === NotPermitted
    );
  }

  if (typeof permission === "string" && hasPermission(permission)) {
    return permittedChildren || children;
  }

  if (Array.isArray(permission)) {
    const allowed = permission.reduce(
      (allow, p) => allow && hasPermission(p),
      true
    );

    if (allowed) {
      return permittedChildren || children;
    }
  }

  return notPermittedChildren;
}

export function ProtectedComponent(props) {
  const { permission, WrappedComponent, ...rest } = props;

  return (
    <Protected permission={permission}>
      <WrappedComponent {...rest} />
    </Protected>
  );
}

ProtectedComponent.propTypes = {
  permission: PropTypes.string.isRequired,
  WrappedComponent: PropTypes.elementType.isRequired,
  childProps: PropTypes.shape({}),
};

ProtectedComponent.defaultProps = {
  childProps: {},
};

const PERMISSION_PROP_TYPE = {
  permission: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
};

export function ProtectedButton(props) {
  const { permission, ...buttonProps } = props;
  return (
    <ProtectedComponent
      permission={permission}
      WrappedComponent={Button}
      {...buttonProps}
    />
  );
}

ProtectedButton.propTypes = PERMISSION_PROP_TYPE;

export function ProtectedLoadingButton(props) {
  const { permission, ...buttonProps } = props;
  return (
    <ProtectedComponent
      permission={permission}
      WrappedComponent={LoadingButton}
      {...buttonProps}
    />
  );
}

ProtectedLoadingButton.propTypes = PERMISSION_PROP_TYPE;
