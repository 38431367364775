import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { setUser } from "../utils/io";

function AuthProvider(props) {
  const { children } = props;
  const { search } = useLocation();

  // Used for Xealth iFrame authentication where the URL includes a short lived
  // authentication token
  const querySuppliedToken = new URLSearchParams(search).get("authToken");
  if (querySuppliedToken) {
    setUser({ accessToken: querySuppliedToken });
  }

  return children;
}

export default AuthProvider;
