import React from "react";
import { DOCS } from "../utils/constants";
import TooltipChip from "./TooltipChip";

function DefaultHubGroupIndicator() {
  return (
    <TooltipChip
      description={DOCS.HUB_GROUP.description}
      docsUrl={DOCS.HUB_GROUP.url}
      color="success"
      label="Default"
    />
  );
}

export default DefaultHubGroupIndicator;
