import { Button, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import StelModal from "..";

export default function ConfirmationModal(props) {
  const { onClose, onConfirm, message, open, title, width } = props;

  return (
    <StelModal open={open} onClose={onClose} title={title} width={width}>
      <Stack>
        <Typography sx={{ lineHeight: "normal" }} variant="body1">
          {message}
        </Typography>
        <Stack sx={{ mt: "20px" }} direction="row" justifyContent="flex-end">
          <Button
            color="error"
            variant="outlined"
            onClick={() => onClose()}
            sx={{ mr: "10px" }}
          >
            No
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => onConfirm()}
          >
            Yes
          </Button>
        </Stack>
      </Stack>
    </StelModal>
  );
}

ConfirmationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  message: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  width: PropTypes.number,
};

ConfirmationModal.defaultProps = {
  width: 500,
};
