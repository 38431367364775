import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BarChart, ResponsiveContainer, XAxis, YAxis, Bar } from "recharts";
import { SNACKBAR_STATUS } from "../../utils/constants";
import { get, isSandbox } from "../../utils/io";
import theme from "../../utils/theme";
import MainContentContainer from "../Layouts/MainContentContainer";
import ShowSnackBar from "../Snackbar";
import useStyles from "./styles";

function Organization() {
  const classes = useStyles();

  const [org, setOrg] = useState({});
  const [activeHubs, setActiveHubs] = useState([]);
  const [snackbar, setSnackbar] = useState({
    message: "",
    open: false,
    status: "",
  });

  useEffect(() => {
    const getOrganizationData = async () => {
      const orgRes = await get("/organization");
      if (orgRes.status !== 200) {
        return setSnackbar({
          open: true,
          message: "Unable to retrieve organization information",
          status: SNACKBAR_STATUS.ERROR,
        });
      }
      setOrg(orgRes.data);

      const activeHubsRes = await get("/organization/active_hubs_by_month");
      if (activeHubsRes.status !== 200) {
        return setSnackbar({
          open: true,
          message: "Unable to retrieve active hub information",
          status: SNACKBAR_STATUS.ERROR,
        });
      }
      return setActiveHubs(
        activeHubsRes.data.map((dataPoint) => ({
          month: new Intl.DateTimeFormat([], {
            year: "2-digit",
            month: "short",
            timeZone: "UTC",
          }).format(new Date(dataPoint.month)),
          count: dataPoint.count,
        }))
      );
    };
    getOrganizationData();
  }, []);

  return (
    <MainContentContainer
      title={org?.name || ""}
      analyticsTitle="Organization Detail"
    >
      <ShowSnackBar
        {...snackbar}
        onClose={() =>
          setSnackbar({
            message: "",
            open: false,
            status: "",
          })
        }
      />
      <p className={classes.mrt0}>
        <strong>Alias:</strong> {org?.alias}
      </p>
      <p>
        {isSandbox() ? (
          <>
            <strong>Subscription:</strong> {" Level 1 "}
            <Tooltip
              placement="right"
              title="Enterprise customers license a subscription level that reflects the number of active hubs per month"
              arrow="left"
            >
              <InfoOutlinedIcon
                sx={{
                  color: theme.colors.stelBlue,
                  fontSize: 16,
                  position: "relative",
                  top: 2,
                }}
              />
            </Tooltip>
          </>
        ) : (
          <>
            <strong>Subscription:</strong> Level{" "}
            {/* A temporary hack here to make things look legit to real customers and in our Stellar demo org */}
            {org.alias === "stellar" ? 4 : 1} - {org.licensed_hubs} Hubs
          </>
        )}
      </p>
      {activeHubs.length > 0 && (
        <div>
          <h3 className={classes.mrt30}>Active Hubs per Month</h3>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={activeHubs}>
              <XAxis
                dataKey="month"
                label={{
                  value: "Month",
                  position: "insideBottom",
                  offset: -5,
                }}
              />
              <YAxis />
              <Tooltip />
              <Bar
                dataKey="count"
                fill={theme.colors.stelBlue}
                label={{ position: "insideTop", fill: "#fff" }}
                maxBarSize={125}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}
    </MainContentContainer>
  );
}

export default Organization;
