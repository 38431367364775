import React from "react";

export default function Temperature() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="10 0 22 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.9178 10.0453H21.2017M18.9178 11.1872H19.9075M18.9178 12.4053H21.2017M18.9178 13.5473H19.9075M18.9178 14.6893H21.2017M18.9178 15.8313H19.9075M18.9178 16.9732H21.2017M12.5226 9.20781C12.5226 10.4259 12.5226 18.0391 12.5226 18.0391C11.3173 19.2848 10.9999 20.3991 11 21.5411C11.0001 22.6831 12.142 25.5 14.9588 25.5C17.7757 25.5 19.1461 22.9115 19.1461 21.5411C19.1461 20.1708 18.7702 19.5299 17.6234 18.0391C17.6234 14.5903 17.6234 10.1214 17.6234 9.20781C17.6234 8.29424 16.4053 7 15.1111 7C13.8169 7 12.5226 7.98971 12.5226 9.20781Z"
        stroke="#545454"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="15.1113" cy="21.3888" r="2.58847" fill="#545454" />
      <rect
        x="14.35"
        y="11.644"
        width="1.52263"
        height="8.06994"
        rx="0.761315"
        fill="#545454"
      />
    </svg>
  );
}
