import { Check, Clear, Sync } from "@mui/icons-material";
import BlockIcon from "@mui/icons-material/Block";
import CloudOffIcon from "@mui/icons-material/CloudOff";
import { Box, Tooltip, Typography } from "@mui/material";
import { grey, blue, yellow } from "@mui/material/colors";
import { format } from "date-fns";
import { intersectionWith, uniqBy } from "lodash";
import { enqueueSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { MEASURE_TYPES } from "../utils/constants";
import { get, isAutoRefresh, post } from "../utils/io";
import PendingAction from "./icons/PendingAction";
import RejectAction from "./icons/RejectAction";
import MeasureDetail from "./Measures/MeasureDetail";
import MeasureListToolbar from "./Measures/MeasureToolbar";
import MeasureValueTooltip from "./MeasureValueTooltip";
import StelDataGrid from "./StelDataGrid";

const tooltipValidateStatus = (
  validationStatus,
  validatedBy,
  validatedTime
) => (
  <Typography sx={{ fontSize: 12 }}>
    {validationStatus === "Clinical Rejected"
      ? "Rejected By "
      : "Validated By "}
    {validatedBy?.first_name && validatedBy?.last_name
      ? `${validatedBy.first_name} ${validatedBy.last_name}`
      : `${validatedBy?.username || "-"}`}{" "}
    on {format(new Date(validatedTime), "MM/dd/yyyy")}
  </Typography>
);

const statusTextWithValidationSubtext = (
  statusText,
  clinicalValidationStatus
) => (
  <Box>
    <Typography sx={{ fontSize: 12 }}>{statusText}</Typography>
    {clinicalValidationStatus &&
      tooltipValidateStatus(
        clinicalValidationStatus.status,
        clinicalValidationStatus.validated_by,
        clinicalValidationStatus.validated_time
      )}
  </Box>
);

const statusConfig = {
  "in progress": {
    icon: <Sync sx={{ color: blue[500], fontSize: 20, margin: "auto" }} />,
    getText: (clinicalValidationStatus) =>
      statusTextWithValidationSubtext(
        "Measurement is being sent to endpoint(s)",
        clinicalValidationStatus
      ),
  },
  failed: {
    icon: <Clear sx={{ color: "red", fontSize: 20, margin: "auto" }} />,
    getText: (clinicalValidationStatus) =>
      statusTextWithValidationSubtext(
        "Measurement failed to send to at least one endpoint",
        clinicalValidationStatus
      ),
  },
  succeeded: {
    icon: <Check sx={{ color: "green", fontSize: 20, margin: "auto" }} />,
    getText: (clinicalValidationStatus) =>
      statusTextWithValidationSubtext(
        "Measurement successfully sent to all endpoints",
        clinicalValidationStatus
      ),
  },
  "no endpoints": {
    icon: (
      <CloudOffIcon sx={{ color: grey[600], fontSize: 20, margin: "auto" }} />
    ),
    getText: () => "Hub group does not have an endpoint configured",
  },
  "Pending Clinical Validation": {
    icon: (
      <Box sx={{ margin: "auto" }}>
        <PendingAction />
      </Box>
    ),
    getText: () => "Pending validation",
  },
  "Clinical Rejected": {
    icon: (
      <Box sx={{ margin: "auto" }}>
        <RejectAction />
      </Box>
    ),
    getText: (clinicalValidationStatus) =>
      tooltipValidateStatus(
        clinicalValidationStatus.status,
        clinicalValidationStatus.validated_by,
        clinicalValidationStatus.validated_time
      ),
  },
  default: {
    icon: (
      <BlockIcon sx={{ color: yellow[800], fontSize: 20, margin: "auto" }} />
    ),
    getText: () => "Measurement is being sent to endpoint(s)",
  },
};

function renderStatusCell(
  deliveryStatus,
  clinicalValidationStatus,
  suppressedReason
) {
  const getStatusConfig = () => {
    if (
      suppressedReason &&
      ["Clinical Rejected", "Pending Clinical Validation"].includes(
        suppressedReason
      )
    ) {
      return {
        text: statusConfig[suppressedReason].getText(clinicalValidationStatus),
        icon: statusConfig[suppressedReason].icon,
      };
    }
    if (suppressedReason) {
      return {
        text: suppressedReason,
        icon: statusConfig.default.icon,
      };
    }
    const config = statusConfig[deliveryStatus] || statusConfig.default;
    return {
      text: config.getText(clinicalValidationStatus),
      icon: config.icon,
    };
  };

  const { text, icon } = getStatusConfig();

  return (
    <Tooltip title={text} placement="bottom" arrow>
      {icon}
    </Tooltip>
  );
}

const MeasureListColumns = [
  {
    field: "delivery_status",
    headerName: "Status",
    width: 60,
    renderCell: (params) =>
      renderStatusCell(
        params.row.delivery_status,
        params.row.clinical_validation_status,
        params.row.delivery_suppressed_reason
      ),
  },
  {
    field: "timestamp",
    headerName: "Time",
    width: 175,
    valueGetter: (params) => new Date(params.row.timestamp).toLocaleString(),
  },
  {
    field: "type",
    headerName: "Type",
    width: 145,
    valueGetter: (params) =>
      MEASURE_TYPES[params?.value]?.label || params.row.type,
  },
  {
    field: "device_mac",
    headerName: "MAC Address",
    width: 150,
    renderCell: (params) => {
      const { device } = params.row;
      const make = device?.make;
      const model = device?.model_display_name;
      const title = make && model ? `${make} ${model}` : "N/A";
      return (
        <Tooltip
          title={<Box sx={{ fontSize: 13 }}>{title}</Box>}
          placement="right"
          arrow
        >
          <span>{params?.value}</span>
        </Tooltip>
      );
    },
    valueGetter: (params) => params.row.device?.mac_address,
  },
  {
    field: "hub_id",
    headerName: "Hub ID",
    width: 130,
    valueGetter: (params) => params.row.hub?.hub_id,
  },
  {
    field: "transmitting_hub_id",
    headerName: "Transmitting Hub ID",
    width: 150,
    valueGetter: (params) => params.row.transmitting_hub?.hub_id,
  },
  {
    field: "value",
    headerName: "Measure Value",
    width: 250,
    renderCell: (params) => (
      <MeasureValueTooltip params={params} value={params.row.value} />
    ),
    valueGetter: (params) => JSON.stringify(params.row.value),
  },
  {
    field: "device_make",
    headerName: "Device Make",
    width: 150,
    valueGetter: (params) => params.row.device?.make,
  },
  {
    field: "device_model",
    headerName: "Device Model",
    width: 150,
    valueGetter: (params) => params.row.device?.model_display_name,
  },
];

function MeasureList(props) {
  const {
    checkbox,
    loading,
    onSelectionChanged,
    rows,
    pageSize,
    hideColumns,
    onPageChange,
    rowCount,
    rowsPerPageOptions,
    onPageSizeChange,
    selectedMeasures,
    searchResults,
    refreshMeasureList,
    onChangeBindings,
    paginationMode,
    showToolbar,
    columnProps,
    showDeliveries,
    columnOrder,
    toolbarProps,
    setSortModel,
    sortingMode,
    autoRefreshMeasure,
    setAutoRefreshMeasure,
  } = props;
  const [isResend, setIsResend] = useState(false);
  const [rowSelected, setRowSelected] = useState({});

  useEffect(() => {
    setAutoRefreshMeasure(isAutoRefresh);
  }, [setAutoRefreshMeasure]);

  const selectedMeasureObjects = intersectionWith(
    rows,
    selectedMeasures,
    (row, selectedMeasure) => row.id === selectedMeasure
  );

  const selectedDevices = uniqBy(
    selectedMeasureObjects,
    (measure) => measure.device.id
  ).map((measure) => measure.device);

  const selectedDeviceBindings = uniqBy(
    selectedMeasureObjects,
    (measure) => measure.device.mac_address
  ).map((measure) => ({
    hubId: measure.transmitting_hub?.hub_id || "",
    macAddress: measure.device.mac_address,
  }));

  const getColumns = () => {
    const filteredColums = MeasureListColumns.map((col) => {
      // We must create copies here to avoid changing the underlying default
      // MeasureListColumns
      const copiedCol = {
        ...col,
        sortable: ["hub_id", "transmitting_hub_id"].includes(col.field),
      };
      const fieldName = copiedCol.field;
      if (hideColumns.includes(fieldName)) {
        copiedCol.hide = true;
      }
      const fieldProps = columnProps[fieldName] || {};
      return { ...copiedCol, ...fieldProps };
    });
    if (columnOrder.length > 0) {
      return columnOrder.map((col) =>
        filteredColums.find((c) => c.field === col)
      );
    }
    return filteredColums;
  };

  const columns = getColumns();

  const handleResend = async () => {
    if (selectedMeasures.length === 0) return;
    setIsResend(true);
    const measures = searchResults.filter(
      (measure) =>
        selectedMeasures.includes(measure.id) && measure.deliveries.length > 0
    );
    const deliveryIds = measures.reduce(
      (arr, measure) => [
        ...arr,
        ...measure.deliveries.map((delivery) => delivery.id),
      ],
      []
    );

    const res = await post("measure_delivery/resend", {
      ids: deliveryIds,
    });
    if (res.status === 204) {
      enqueueSnackbar("Measurement(s) queued for resend", {
        variant: "success",
      });
      // Force refresh of data and maintain same page & pageSize
      refreshMeasureList();
    } else {
      enqueueSnackbar(
        "Failed to queue measurement(s) for resend, please contact support@stel.life",
        { variant: "error" }
      );
    }
    setIsResend(false);
  };

  const handleMeasureAction = async (status) => {
    const res = await post("measures/clinical_validation", {
      ids: selectedMeasures,
      status,
    });

    if (res.status === 204) {
      enqueueSnackbar(`Measurement(s) ${status}`, { variant: "success" });
      refreshMeasureList();
    } else {
      enqueueSnackbar(
        `Failed measurement(s) ${status}, please contact support@stel.life`,
        { variant: "error" }
      );
    }
  };

  const handleApprove = () => handleMeasureAction("Clinical Approved");
  const handleReject = () => handleMeasureAction("Clinical Rejected");

  const handleRowClick = async ({ row }) => {
    let copiedRow = row;
    if (showDeliveries) {
      const deliveryIds = row.deliveries.map((delivery) => delivery.id);
      const res = await Promise.all(
        deliveryIds.map((id) => get(`/measures/deliveries/${id}`))
      );
      const resSuccess = res.reduce((arr, item) => {
        if (item.status === 200) arr.push(item.data);
        return arr;
      }, []);
      copiedRow = { ...row, delivery: resSuccess };
    }
    setRowSelected(copiedRow);
  };

  const handleCloseDrawer = () => {
    setRowSelected({});
  };

  return (
    <>
      <StelDataGrid
        checkboxSelection={checkbox}
        columns={columns}
        loading={loading}
        onSelectionModelChange={onSelectionChanged}
        rows={rows}
        selectionModel={selectedMeasures}
        pageSize={pageSize}
        paginationMode={paginationMode}
        components={{
          ...(showToolbar && { Toolbar: MeasureListToolbar }),
        }}
        onSortModelChange={(model) => setSortModel(model)}
        componentsProps={{
          toolbar: {
            handleResend,
            isResend,
            autoRefreshMeasure,
            setAutoRefreshMeasure,
            isDisabled: checkbox
              ? selectedMeasures.length === 0
              : rowCount === 0,
            bindDevicesButtonProps: {
              bindingsToCreate: selectedDeviceBindings,
              handleSuccessfulBind: onChangeBindings,
            },
            blockDevicesButtonProps: {
              devices: selectedDevices || [],
              handleSuccessfulBlock: onChangeBindings,
            },
            unbindDevicesButtonProps: {
              devices: selectedDevices || [],
              handleSuccessfulUnbind: onChangeBindings,
            },
            handleApprove,
            handleReject,
            ...toolbarProps,
          },
        }}
        onPageChange={onPageChange}
        rowCount={rowCount}
        rowsPerPageOptions={rowsPerPageOptions}
        onPageSizeChange={onPageSizeChange}
        onRowClick={handleRowClick}
        className={{ width: 200 }}
        disableSelectionOnClick
        disableColumnFilter
        sortingMode={sortingMode}
      />
      {rowSelected.id && (
        <MeasureDetail rowSelected={rowSelected} onClose={handleCloseDrawer} />
      )}
    </>
  );
}

const propTypes = {
  checkbox: PropTypes.bool,
  loading: PropTypes.bool,
  showToolbar: PropTypes.bool,
  onSelectionChanged: PropTypes.func,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      deliveries: PropTypes.arrayOf(PropTypes.shape()).isRequired,
      delivery_status: PropTypes.string,
      device: PropTypes.shape(),
      hub: PropTypes.shape().isRequired,
      id: PropTypes.string.isRequired,
      timestamp: PropTypes.string.isRequired,
      transmitting_hub: PropTypes.shape(),
      type: PropTypes.string.isRequired,
      value: PropTypes.shape().isRequired,
    }).isRequired
  ).isRequired,
  pageSize: PropTypes.number,
  hideColumns: PropTypes.arrayOf(PropTypes.string),
  onPageChange: PropTypes.func,
  rowCount: PropTypes.number,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  onPageSizeChange: PropTypes.func,
  selectedMeasures: PropTypes.arrayOf(PropTypes.string),
  searchResults: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.object,
        PropTypes.array,
        PropTypes.bool,
      ])
    )
  ),
  refreshMeasureList: PropTypes.func,
  autoRefreshMeasure: PropTypes.bool,
  setAutoRefreshMeasure: PropTypes.func,
  onChangeBindings: PropTypes.func,
  paginationMode: PropTypes.string,
  columnProps: PropTypes.objectOf(
    PropTypes.objectOf(PropTypes.oneOfType([PropTypes.func]))
  ),
  showDeliveries: PropTypes.bool,
  columnOrder: PropTypes.arrayOf(PropTypes.string),
  toolbarProps: PropTypes.shape({
    csvExportProps: PropTypes.shape({
      exportDataGetter: PropTypes.func,
      fileName: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    }),
    visibleToolbarItems: PropTypes.shape({
      resend: PropTypes.bool,
      bindDevices: PropTypes.bool,
      unbindDevices: PropTypes.bool,
      blockDevices: PropTypes.bool,
      export: PropTypes.bool,
      autoRefresh: PropTypes.bool,
    }),
    customComponents: PropTypes.arrayOf(
      PropTypes.shape({ name: PropTypes.string, value: PropTypes.node })
    ),
  }),
  searchInputs: PropTypes.shape({
    macAddress: PropTypes.string,
    hubId: PropTypes.string,
    status: PropTypes.string,
    takenAfterDate: PropTypes.instanceOf(Date),
    takenBeforeDate: PropTypes.instanceOf(Date),
  }),
  setSortModel: PropTypes.func,
  sortingMode: PropTypes.string,
};

const defaultProps = {
  checkbox: false,
  loading: false,
  showToolbar: true,
  onSelectionChanged: () => {},
  pageSize: 5,
  hideColumns: [],
  onPageChange: () => {},
  rowCount: 0,
  rowsPerPageOptions: [25, 50, 100],
  onPageSizeChange: () => {},
  selectedMeasures: [],
  searchResults: [],
  refreshMeasureList: () => {},
  autoRefreshMeasure: false,
  setAutoRefreshMeasure: () => {},
  onChangeBindings: () => {},
  paginationMode: "server",
  columnProps: {},
  showDeliveries: true,
  columnOrder: [],
  toolbarProps: {
    visibleToolbarItems: {
      resend: true,
      bindDevices: true,
      unbindDevices: true,
      blockDevices: true,
      export: true,
      autoRefresh: true,
    },
    customComponents: [],
  },
  searchInputs: {},
  setSortModel: () => {},
  sortingMode: "client",
};

MeasureList.propTypes = propTypes;
MeasureList.defaultProps = defaultProps;

export default MeasureList;
