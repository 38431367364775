import { groupBy, sortBy, uniqWith } from "lodash";
import { useEffect, useState } from "react";
import { MEASURE_TYPES } from "../../../utils/constants";
import { get } from "../../../utils/io";

function useDeviceModels(props) {
  const [makes, setMakes] = useState([]);
  const [models, setModels] = useState([]);
  const [deviceType, setDeviceType] = useState([]);

  const { deviceTypeValue, makeIdFormValue } = props;

  const deviceTypeOptions = sortBy(Object.values(deviceType), "label");
  const makeOptions = sortBy(
    makes.filter(
      (make) => !deviceTypeValue || make.types.includes(deviceTypeValue)
    ),
    ["label"]
  );
  const modelOptions = sortBy(
    models.filter(
      (model) =>
        !makeIdFormValue ||
        (model.makeId === makeIdFormValue && model.type === deviceTypeValue)
    ),
    ["label"]
  );

  useEffect(() => {
    async function getDeviceModel() {
      const res = await get("/device_models", { include_image_url: true });
      if (res.status === 200) {
        const resModels = res.data.device_models;
        const resDeviceTypes = uniqWith(
          resModels
            .filter((model) => model.type !== "demo")
            .map((model) => ({
              value: model.type,
              label: MEASURE_TYPES[model.type].label,
            })),
          (a, b) => a.value === b.value
        );
        const modelsByMakeId = groupBy(resModels, (model) => model.make_id);
        const resMakes = Object.keys(modelsByMakeId).map((makeId) => {
          const modelsForMake = modelsByMakeId[makeId];
          const modelTypes = modelsForMake.map((model) => model.type);
          const { make } = modelsForMake[0];
          return { value: Number(makeId), label: make, types: modelTypes };
        });
        const resNewModels = resModels.map((model) => ({
          value: model.id,
          label: model.display_name,
          makeId: model.make_id,
          image: model.image,
          type: model.type,
        }));
        setMakes(resMakes);
        setModels(resNewModels);
        setDeviceType(resDeviceTypes);
      }
    }
    getDeviceModel();
  }, []);
  return { deviceTypeOptions, makeOptions, modelOptions };
}

export default useDeviceModels;
