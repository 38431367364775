import { grey } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";
import themeStyled from "../../utils/theme";

const useStyles = makeStyles(() => ({
  submitBtn: {
    marginLeft: "auto",
  },
  filterField: {
    width: 200,
    marginRight: 16,
  },
  makeFilter: {
    width: 350,
    marginRight: 16,
  },
  header: {
    display: "flex",
    flexWrap: "wrap",
    marginBottom: 16,
    position: "sticky",
    top: 0,
    backgroundColor: "white",
    zIndex: 10,
    paddingTop: 10,
    paddingBottom: 10,
  },
  filterContainer: {
    display: "flex",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexGrow: 1,
    alignItems: "flex-start",
  },
  quantityInput: {
    width: 92,
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: themeStyled.darkBlue,
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  requestInfoBtn: {
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#1F79E2",
      color: "#fff",
    },
  },
  list: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
  },
  imgIcon: {
    width: "100%",
    height: 150,
    color: grey[400],
  },
  flex: {
    display: "flex",
    justifyContent: "space-between",
  },
  mtAuto: { marginTop: "auto" },
  m0: { margin: 0 },
  fs12: { fontSize: 12 },
  fs14: { fontSize: 14 },
  fs16: { fontSize: 16 },
  bold: { fontWeight: "bold" },
  pd16: { padding: 16 },
  mr16: { marginRight: 16 },
  italic: { fontStyle: "italic" },
  colorGrey: { color: "grey" },
  right: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 20,
  },
  img: {
    width: "inherit",
    padding: "0 16px",
    height: 150,
    margin: "auto",
  },
  imgSkeleton: {
    margin: "0 16px",
    borderRadius: 4,
  },
  deviceName: {
    margin: 0,
    fontSize: 16,
    fontWeight: "bold",
  },
}));

export default useStyles;
