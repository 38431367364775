import React from "react";

export default function UploadCSVFile() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 17.0001C2 17.5524 2.44767 18.0001 3 18.0001H4.33333V15.6667H2.66667C2.66667 15.6667 2 15.6667 2 17.0001Z"
        fill="#5AA064"
      />
      <path
        d="M18 3.99992V18.3333C18 19.0696 17.403 19.6666 16.6667 19.6666H5.33333C4.597 19.6666 4 19.0696 4 18.3333V1.66659C4 0.930252 4.597 0.333252 5.33333 0.333252H14.3333L18 3.99992Z"
        fill="#E5E9EC"
      />
      <path
        d="M18.0007 4.00016H14.6673C14.484 4.00016 14.334 3.85016 14.334 3.66683V0.333496L18.0007 4.00016Z"
        fill="#C7D0DD"
      />
      <path
        d="M3 15.9999H15C15.5523 15.9999 16 15.5523 16 14.9999V10.3333C16 9.78092 15.5523 9.33325 15 9.33325H3C2.44767 9.33325 2 9.78092 2 10.3333V16.9999C2 16.4476 2.44767 15.9999 3 15.9999Z"
        fill="#61C161"
      />
      <path
        d="M13.429 10.6803C13.2517 10.6283 13.0667 10.7276 13.014 10.904L12.3334 13.173L11.6527 10.904C11.5997 10.7276 11.4147 10.6283 11.2377 10.6803C11.0614 10.7333 10.9614 10.9193 11.014 11.0953L12.014 14.4286C12.0564 14.5696 12.1864 14.6663 12.3334 14.6663C12.4804 14.6663 12.6104 14.5696 12.6527 14.4286L13.6527 11.0953C13.7054 10.9193 13.6054 10.7333 13.429 10.6803Z"
        fill="white"
      />
      <path
        d="M5.66732 11.3332C6.03498 11.3332 6.33398 11.6322 6.33398 11.9998C6.33398 12.1842 6.48298 12.3332 6.66732 12.3332C6.85165 12.3332 7.00065 12.1842 7.00065 11.9998C7.00065 11.2645 6.40265 10.6665 5.66732 10.6665C4.93198 10.6665 4.33398 11.2645 4.33398 11.9998V13.3332C4.33398 14.0685 4.93198 14.6665 5.66732 14.6665C6.40265 14.6665 7.00065 14.0685 7.00065 13.3332C7.00065 13.1488 6.85165 12.9998 6.66732 12.9998C6.48298 12.9998 6.33398 13.1488 6.33398 13.3332C6.33398 13.7008 6.03498 13.9998 5.66732 13.9998C5.29965 13.9998 5.00065 13.7008 5.00065 13.3332V11.9998C5.00065 11.6322 5.29965 11.3332 5.66732 11.3332Z"
        fill="white"
      />
      <path
        d="M9.08197 12.3433C8.50297 12.1983 8.33464 12.114 8.33464 11.8333C8.33464 11.5623 8.63997 11.3333 9.0013 11.3333C9.47297 11.3333 9.69797 11.7017 9.7113 11.7247C9.80164 11.8837 10.0026 11.9407 10.163 11.8517C10.3236 11.7623 10.382 11.5597 10.2926 11.3987C10.276 11.3687 9.87664 10.667 9.0013 10.667C8.26597 10.667 7.66797 11.1903 7.66797 11.8337C7.66797 12.6773 8.42164 12.8657 8.92064 12.9903C9.49964 13.1353 9.66797 13.2197 9.66797 13.5003C9.66797 13.7713 9.36264 14.0003 9.0013 14.0003C8.5253 14.0003 8.3003 13.6247 8.29264 13.6117C8.2033 13.451 8.0013 13.3923 7.83964 13.482C7.67897 13.5713 7.62064 13.774 7.70997 13.935C7.72664 13.965 8.12597 14.6667 9.0013 14.6667C9.73664 14.6667 10.3346 14.1433 10.3346 13.5C10.3346 12.6563 9.58097 12.468 9.08197 12.3433Z"
        fill="white"
      />
    </svg>
  );
}
