import { Button } from "@mui/material";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import useStyles from "../OrderModal/styles";

export default function ConfirmCloseViewOrder(props) {
  const classes = useStyles();

  const { onCloseModal, onExitAndSaveOrder, onExitWithoutSave } = props;
  const onCancelClose = () => onCloseModal();
  const onSave = () => onExitAndSaveOrder();
  const onExit = () => onExitWithoutSave();

  return (
    <div>
      <p className={classes.textCenter}>
        There are unsaved changes that will be lost. Are you sure you want to
        exit this order?
      </p>
      <div className={classes.textCenter}>
        <Button
          color="error"
          variant="outlined"
          className={classNames(classes.mrr16, classes.mrb16)}
          onClick={onCancelClose}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          className={classes.mrb16}
          variant="outlined"
          onClick={onExit}
        >
          Exit without Saving
        </Button>
        <Button color="primary" variant="contained" onClick={onSave}>
          Save and Exit
        </Button>
      </div>
    </div>
  );
}

ConfirmCloseViewOrder.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  onExitAndSaveOrder: PropTypes.func.isRequired,
  onExitWithoutSave: PropTypes.func.isRequired,
};
