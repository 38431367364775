import { red } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  bold: {
    fontWeight: "bold",
  },
  border: {
    border: "1px solid #c4c4c4",
  },
  input: {
    borderBottom: "none",
    "& > .MuiIput-root:hover:not(.Mui-disabled)": {
      borderBottom: "none !important",
    },
    "& > .MuiInput-root::before": {
      borderBottom: "none !important",
    },
    "& > .MuiInput-root::after": {
      borderBottom: "none !important",
    },
  },
  inputPadding: {
    padding: "0 12px",
  },
  borderBottom: {
    borderBottom: "1px solid #c4c4c4",
  },
  pd12: {
    padding: 12,
  },
  chip: {
    margin: "0 6px 10px 0",
    fontFamily: "monospace",
    fontSize: 14,
    userSelect: "auto",
  },
  scroll: {
    height: "auto",
    maxHeight: 1000,
    overflowY: "auto",
  },
  relative: {
    position: "relative",
  },
  absolute: {
    position: "absolute",
  },
  pdt30: {
    paddingTop: 30,
  },
  warning: {
    margin: 0,
    top: 0,
    fontSize: 14,
    width: "100%",
    left: 0,
    background: red[300],
    color: "#fff",
  },
  text: {
    margin: "6px 10px",
  },
  fullWidth: {
    width: "100%",
  },
  radius4: {
    borderRadius: 4,
  },
  mb16: {
    marginBottom: 16,
  },
  alignCenter: {
    alignItems: "center",
  },
  justifyCenter: {
    justifyContent: "center",
  },
  flex: {
    display: "flex",
  },
}));

export default useStyles;
