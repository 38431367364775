import { ErrorMessage } from "@hookform/error-message";
import {
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { red, grey } from "@mui/material/colors";
import PropTypes from "prop-types";
import React from "react";
import { Controller } from "react-hook-form";

import RhfTextField from "../form/RhfTextField";

export function UserFormLabel(props) {
  const { value, description } = props;

  return (
    <Stack height="100%" width="100%" direction="row" alignItems="flex-start">
      <Stack>
        <Typography sx={{ fontWeight: "bold" }}>{value}</Typography>
        {description && (
          <Typography sx={{ fontSize: "12px", color: grey[700] }}>
            {typeof description === "function" ? description() : description}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
}

UserFormLabel.propTypes = {
  value: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

UserFormLabel.defaultProps = {
  description: "",
};

export function FormField(props) {
  const { name, label, description, control, rules, errors } = props;

  return (
    <>
      <Grid item xs={6}>
        <UserFormLabel value={label} description={description} />
      </Grid>
      <Grid item xs={6}>
        <RhfTextField
          name={name}
          label={label}
          control={control}
          controllerProps={{ shouldUnregister: true }}
          size="small"
          fullWidth
          rules={{
            required: {
              value: true,
              message: "This field is required.",
            },
            ...rules,
          }}
        />
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => <FormErrorText value={message} />}
        />
      </Grid>
    </>
  );
}

FormField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  control: PropTypes.shape().isRequired,
  rules: PropTypes.shape(),
  errors: PropTypes.shape().isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

FormField.defaultProps = {
  rules: {},
  description: "",
};

function FormErrorText(props) {
  const { value } = props;

  return (
    <Typography sx={{ color: red[700], fontSize: "12px" }}>{value}</Typography>
  );
}

FormErrorText.propTypes = {
  value: PropTypes.string,
};

FormErrorText.defaultProps = {
  value: "",
};

export function SelectFormField(props) {
  const { control, label, name, options } = props;
  const isStringOption = options.every((option) => typeof option === "string");

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <FormControl sx={{ width: "100%" }}>
          <InputLabel size="small">{label}</InputLabel>
          <Select
            {...field}
            size="small"
            multiple
            input={<OutlinedInput label={label} />}
            renderValue={(selected) => {
              if (isStringOption) return selected.join(", ");

              const result = options
                .filter((option) => selected.includes(option.id))
                .map((option) => option.name);
              return result.join(", ");
            }}
            sx={{
              "&.MuiOutlinedInput-root": {
                width: "100%",
              },
            }}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            }}
          >
            {options.map((option) => (
              <MenuItem
                key={isStringOption ? option : option.id}
                value={isStringOption ? option : option.id}
                sx={{
                  "&": {
                    height: 42,
                    padding: "12px",
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  },
                  "&.Mui-selected": {
                    color: "#1F79E2",
                  },
                }}
              >
                <Checkbox
                  checked={field.value?.includes(
                    isStringOption ? option : option.id
                  )}
                  sx={{
                    "&": {
                      padding: 0,
                    },
                  }}
                />
                <ListItemText primary={isStringOption ? option : option.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  );
}

SelectFormField.propTypes = {
  control: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.oneOf([PropTypes.string, PropTypes.object])
  ).isRequired,
};
