import { Box, Divider, Stack } from "@mui/material";
import React, { useState } from "react";
import theme from "../../../utils/theme";
import NavFooter from "./NavFooter";
import NavHeader from "./NavHeader";
import NavSectionOne from "./NavSectionOne";
import NavSectionThree from "./NavSectionThree";
import NavSectionTwo from "./NavSectionTwo";

function Navbar() {
  const [isOpenNav, setIsOpenNav] = useState(true);

  return (
    <Box
      sx={{
        width: isOpenNav ? "100%" : "60px",
        maxWidth: 204,
        transition: "width 0.3s",
        color: theme.colors.darkGrey,
        background: "#ececf2",
        borderRight: "1px solid #CDD6DC",
        "&:hover": {
          "& .arrowIcon": {
            display: "block",
          },
        },
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      {/* Unclear why isOpenNav needs to have the value passed (as opposed to
        working like other booleans) for the `hiddenTransition` to work */}
      <Stack>
        <NavHeader isOpenNav={isOpenNav} setIsOpenNav={setIsOpenNav} />
        <NavSectionOne />
        <Divider variant="middle" />
        <NavSectionTwo />

        <Divider variant="middle" />
        <NavSectionThree />
      </Stack>
      <NavFooter />
    </Box>
  );
}

export default Navbar;
