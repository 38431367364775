import { useMemo, useState } from "react";
import { useLocation } from "react-router";

// A custom hook that builds on useLocation to parse the query string for you.

// Probably will add more functions in the future so disabling default
// export check until then. Remove these comments once another util
// function is added in this file.
/* eslint-disable import/prefer-default-export */
function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

function useSelectionItem(defaultValue) {
  const [valueSelected, setValue] = useState(defaultValue);

  const handleChangeSelection = (event) => {
    setValue(event.target.value);
  };
  return { valueSelected, handleChangeSelection };
}

function usePagination(defaultPageSize = 20) {
  const [pagination, setPagination] = useState({
    pageSize: defaultPageSize,
    page: 0,
  });
  const [rowCount, setRowCount] = useState(0);

  const handlePageChange = (page) =>
    setPagination((pre) => ({
      ...pre,
      page,
    }));

  const handleRowPerPageChange = (pageSize) =>
    setPagination((pre) => ({
      ...pre,
      pageSize,
    }));

  return {
    pagination,
    handlePageChange,
    handleRowPerPageChange,
    rowCount,
    setRowCount,
  };
}

function useLoading() {
  const [loading, setLoading] = useState(false);
  const handleSetLoading = (status) => setLoading(status);

  return { handleSetLoading, loading, setLoading };
}

function useModal() {
  const [isModal, setIsModal] = useState(false);

  const openModal = () => setIsModal(true);
  const closeModal = () => setIsModal(false);
  return { isModal, openModal, closeModal };
}

export { useQuery, useSelectionItem, usePagination, useLoading, useModal };
