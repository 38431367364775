import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Button,
  Box,
  Chip,
  Stack,
  Switch,
  FormControlLabel,
  Typography,
  Tooltip,
} from "@mui/material";
import { difference } from "lodash";
import { enqueueSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { del, get, getFeatureFlag, getUserId } from "../../utils/io";
import StelDataGrid from "../StelDataGrid";
import StelModal from "../StelModal";
import ConfirmationModal from "../StelModal/ConfirmationModal";
import UserForm from "./UserForm";

export default function UserTable({ permissions, rolesStatic }) {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isViewingUser, setIsViewingUser] = useState(false);
  const [isConfirmingDeactivate, setIsConfirmingDeactivate] = useState(false);
  const [includeInactive, setIncludeInactive] = useState(false);

  const deactivateUser = async (user) => {
    setIsConfirmingDeactivate(false);
    const res = await del(`/users/${user.id}`);
    if (res.status !== 204) {
      enqueueSnackbar(res.data.detail, { variant: "error" });
    } else {
      enqueueSnackbar("User deactivated", { variant: "success" });
      setUsers((pre) => difference(pre, [user]));
    }
  };

  const getUsers = async (argIncludeInactive) => {
    setLoading(true);
    const res = await get("/users", { include_inactive: argIncludeInactive });
    if (res.status === 200) {
      const dataUsers = res.data.users.map((user) => ({
        ...user,
        email: user?.email || user?.username || "-",
        roles: user?.roles || [],
      }));
      setUsers(dataUsers);
      setLoading(false);
      return;
    }
    setUsers([]);
    setLoading(false);
  };

  useEffect(() => {
    getUsers(includeInactive);
  }, [includeInactive]);

  const columns = [
    ...[
      {
        field: "email",
        headerName: "Email/Username",
        flex: 1.75,
        renderCell: (params) => (
          <>
            {params.value}
            {params.row.is_org_owner ? (
              <Chip sx={{ ml: "10px" }} label="Owner" size="small" />
            ) : null}
          </>
        ),
      },
      { field: "first_name", headerName: "First Name", flex: 1 },
      { field: "last_name", headerName: "Last Name", flex: 1 },
    ],
    ...(getFeatureFlag("api_permissions")
      ? [
          {
            field: "roles",
            headerName: "Roles",
            flex: 1.25,
            renderCell: (params) => {
              const usersRoles = rolesStatic.filter((role) =>
                params.row.roles.includes(role.id)
              );

              return (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Tooltip
                    title={usersRoles.map(({ name }) => (
                      <Box key={name}>{name}</Box>
                    ))}
                    arrow
                  >
                    <Stack direction="row" alignItems="center">
                      <Typography
                        sx={{
                          fontSize: "14px",
                          marginRight: "8px",
                          maxWidth: 280,
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                        }}
                      >
                        {/* {usersRoles.map(({ name }) => name).join(", ")} */}
                        {usersRoles.length > 0 ? usersRoles[0].name : ""}
                      </Typography>
                      {usersRoles.length - 1 > 0 && (
                        <Chip
                          label={`+${usersRoles.length - 1}`}
                          color="primary"
                          size="small"
                        />
                      )}
                    </Stack>
                  </Tooltip>
                </Box>
              );
            },
          },
        ]
      : []),
    ...[
      {
        field: "is_active",
        headerName: "Active",
        flex: 0.5,
        renderCell: (params) =>
          params.value ? (
            <CheckIcon style={{ color: "green" }} />
          ) : (
            <ClearIcon style={{ color: "red" }} />
          ),
      },
      {
        field: "actions",
        headerName: "",
        renderCell: (params) =>
          params.row.id !== getUserId() && params.row.is_active ? (
            <Button
              sx={{ fontSize: "10px" }}
              variant="contained"
              color="error"
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                setSelectedUser(params.row);
                setIsConfirmingDeactivate(true);
              }}
            >
              Deactivate
            </Button>
          ) : null,
      },
    ],
  ];

  return (
    <Box>
      {isConfirmingDeactivate && (
        <ConfirmationModal
          open
          title="Deactivate User"
          onClose={() => setIsConfirmingDeactivate(false)}
          onConfirm={() => deactivateUser(selectedUser, undefined)}
          message={
            <>
              <strong>
                {selectedUser.first_name} {selectedUser.last_name}
              </strong>{" "}
              will no longer be able to log in. This cannot be undone. Are you
              sure you want to deactivate this user?
            </>
          }
        />
      )}

      {isViewingUser && (
        <StelModal
          open
          onClose={() => {
            setIsViewingUser(false);
          }}
          title="Edit User"
          width={840}
        >
          <UserForm
            user={selectedUser}
            permissions={permissions}
            rolesStatic={rolesStatic}
            onUpdateUser={() => {
              getUsers(includeInactive);
              setIsViewingUser(false);
            }}
          />
        </StelModal>
      )}

      <Stack direction="row" justifyContent="flex-end">
        <FormControlLabel
          sx={{ mb: "10px", mr: 0 }}
          control={<Switch size="small" />}
          label="Show Inactive"
          checked={includeInactive}
          onChange={(e) => setIncludeInactive(e.target.checked)}
        />
      </Stack>
      <StelDataGrid
        rowClickPermission="AssignUserRoles"
        columns={columns}
        rows={users}
        columnPermissions={{ actions: "DeactivateUsers", roles: "ViewRoles" }}
        loading={loading}
        hideFooterSelectedRowCount
        rowHeight={50}
        onRowClick={(params) => {
          setSelectedUser(params.row);
          setIsViewingUser(true);
        }}
      />
    </Box>
  );
}

UserTable.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  rolesStatic: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
    })
  ).isRequired,
};
