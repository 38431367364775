const bpData = [
  { created_time: "2024-02-27", systolic: 142.0, diastolic: 80.0, bpm: 61.0 },
  { created_time: "2024-02-26", systolic: 155.0, diastolic: 58.0, bpm: 49.0 },
  { created_time: "2024-02-25", systolic: 165.0, diastolic: 110.0, bpm: 75.0 },
  { created_time: "2024-02-24", systolic: 167.0, diastolic: 68.0, bpm: 63.0 },
  { created_time: "2024-02-23", systolic: 104.0, diastolic: 62.0, bpm: 80.0 },
  { created_time: "2024-02-22", systolic: 134.0, diastolic: 74.0, bpm: 78.0 },
  { created_time: "2024-02-21", systolic: 158.0, diastolic: 70.0, bpm: 74.0 },
  { created_time: "2024-02-20", systolic: 121.0, diastolic: 60.0, bpm: 89.0 },
  { created_time: "2024-02-19", systolic: 130.0, diastolic: 86.0, bpm: 79.0 },
  { created_time: "2024-02-18", systolic: 164.0, diastolic: 76.0, bpm: 97.0 },
  { created_time: "2024-02-17", systolic: 164.0, diastolic: 98.0, bpm: 109.0 },
  { created_time: "2024-02-16", systolic: 120.0, diastolic: 77.0, bpm: 76.0 },
  { created_time: "2024-02-15", systolic: 131.0, diastolic: 63.0, bpm: 66.0 },
  { created_time: "2024-02-14", systolic: 102.0, diastolic: 77.0, bpm: 90.0 },
  { created_time: "2024-02-13", systolic: 121.0, diastolic: 65.0, bpm: 67.0 },
  { created_time: "2024-02-12", systolic: 132.0, diastolic: 71.0, bpm: 81.0 },
  { created_time: "2024-02-11", systolic: 138.0, diastolic: 86.0, bpm: 96.0 },
  { created_time: "2024-02-10", systolic: 136.0, diastolic: 59.0, bpm: 51.0 },
  { created_time: "2024-02-09", systolic: 120.0, diastolic: 71.0, bpm: 50.0 },
  { created_time: "2024-02-08", systolic: 100.0, diastolic: 69.0, bpm: 66.0 },
  { created_time: "2024-02-07", systolic: 120.0, diastolic: 77.0, bpm: 63.0 },
  { created_time: "2024-02-06", systolic: 152.0, diastolic: 65.0, bpm: 60.0 },
  { created_time: "2024-02-05", systolic: 144.0, diastolic: 102.0, bpm: 83.0 },
  { created_time: "2024-02-04", systolic: 103.0, diastolic: 56.0, bpm: 76.0 },
  { created_time: "2024-02-03", systolic: 171.0, diastolic: 71.0, bpm: 58.0 },
  { created_time: "2024-02-02", systolic: 130.0, diastolic: 80.0, bpm: 80.0 },
  { created_time: "2024-02-01", systolic: 132.0, diastolic: 66.0, bpm: 63.0 },
  { created_time: "2024-01-31", systolic: 123.0, diastolic: 63.0, bpm: 61.0 },
  { created_time: "2024-01-30", systolic: 100.0, diastolic: 66.0, bpm: 60.0 },
  { created_time: "2024-01-29", systolic: 126.0, diastolic: 41.0, bpm: 61.0 },
  { created_time: "2024-01-28", systolic: 121.0, diastolic: 81.0, bpm: 75.0 },
  { created_time: "2024-01-27", systolic: 116.0, diastolic: 64.0, bpm: 70.0 },
  { created_time: "2024-01-26", systolic: 120.0, diastolic: 78.0, bpm: 109.0 },
  { created_time: "2024-01-25", systolic: 118.0, diastolic: 58.0, bpm: 64.0 },
  { created_time: "2024-01-24", systolic: 147.0, diastolic: 71.0, bpm: 61.0 },
  { created_time: "2024-01-23", systolic: 114.0, diastolic: 63.0, bpm: 83.0 },
  { created_time: "2024-01-22", systolic: 116.0, diastolic: 51.0, bpm: 78.0 },
  { created_time: "2024-01-21", systolic: 136.0, diastolic: 74.0, bpm: 81.0 },
  { created_time: "2024-01-20", systolic: 144.0, diastolic: 58.0, bpm: 74.0 },
  { created_time: "2024-01-19", systolic: 112.0, diastolic: 92.0, bpm: 74.0 },
  { created_time: "2024-01-18", systolic: 115.0, diastolic: 52.0, bpm: 73.0 },
  { created_time: "2024-01-17", systolic: 124.0, diastolic: 69.0, bpm: 67.0 },
  { created_time: "2024-01-16", systolic: 132.0, diastolic: 102.0, bpm: 78.0 },
  { created_time: "2024-01-15", systolic: 90.0, diastolic: 72.0, bpm: 85.0 },
  { created_time: "2024-01-14", systolic: 134.0, diastolic: 59.0, bpm: 68.0 },
  { created_time: "2024-01-13", systolic: 123.0, diastolic: 62.0, bpm: 69.0 },
  { created_time: "2024-01-12", systolic: 156.0, diastolic: 90.0, bpm: 82.0 },
  { created_time: "2024-01-11", systolic: 133.0, diastolic: 80.0, bpm: 95.0 },
  { created_time: "2024-01-10", systolic: 138.0, diastolic: 72.0, bpm: 64.0 },
  { created_time: "2024-01-09", systolic: 94.0, diastolic: 62.0, bpm: 46.0 },
  { created_time: "2024-01-08", systolic: 114.0, diastolic: 47.0, bpm: 68.0 },
  { created_time: "2024-01-07", systolic: 117.0, diastolic: 84.0, bpm: 89.0 },
  { created_time: "2024-01-06", systolic: 112.0, diastolic: 57.0, bpm: 68.0 },
  { created_time: "2024-01-05", systolic: 112.0, diastolic: 74.0, bpm: 81.0 },
  { created_time: "2024-01-04", systolic: 108.0, diastolic: 63.0, bpm: 82.0 },
  { created_time: "2024-01-03", systolic: 128.0, diastolic: 80.0, bpm: 73.0 },
  { created_time: "2024-01-02", systolic: 101.0, diastolic: 63.0, bpm: 87.0 },
  { created_time: "2024-01-01", systolic: 119.0, diastolic: 56.0, bpm: 58.0 },
  { created_time: "2023-12-31", systolic: 132.0, diastolic: 62.0, bpm: 81.0 },
  { created_time: "2023-12-30", systolic: 153.0, diastolic: 109.0, bpm: 94.0 },
  { created_time: "2023-12-29", systolic: 141.0, diastolic: 92.0, bpm: 88.0 },
  { created_time: "2023-12-28", systolic: 130.0, diastolic: 79.0, bpm: 108.0 },
  { created_time: "2023-12-27", systolic: 148.0, diastolic: 67.0, bpm: 87.0 },
  { created_time: "2023-12-26", systolic: 131.0, diastolic: 69.0, bpm: 75.0 },
  { created_time: "2023-12-25", systolic: 113.0, diastolic: 89.0, bpm: 64.0 },
  { created_time: "2023-12-24", systolic: 126.0, diastolic: 70.0, bpm: 74.0 },
  { created_time: "2023-12-23", systolic: 140.0, diastolic: 72.0, bpm: 76.0 },
  { created_time: "2023-12-22", systolic: 154.0, diastolic: 108.0, bpm: 82.0 },
  { created_time: "2023-12-21", systolic: 113.0, diastolic: 74.0, bpm: 84.0 },
  { created_time: "2023-12-20", systolic: 115.0, diastolic: 74.0, bpm: 76.0 },
  { created_time: "2023-12-19", systolic: 108.0, diastolic: 73.0, bpm: 91.0 },
  { created_time: "2023-12-18", systolic: 136.0, diastolic: 67.0, bpm: 75.0 },
  { created_time: "2023-12-17", systolic: 100.0, diastolic: 62.0, bpm: 104.0 },
  { created_time: "2023-12-16", systolic: 126.0, diastolic: 71.0, bpm: 72.0 },
  { created_time: "2023-12-15", systolic: 82.0, diastolic: 41.0, bpm: 70.0 },
  { created_time: "2023-12-14", systolic: 123.0, diastolic: 83.0, bpm: 99.0 },
  { created_time: "2023-12-13", systolic: 136.0, diastolic: 96.0, bpm: 70.0 },
  { created_time: "2023-12-12", systolic: 130.0, diastolic: 49.0, bpm: 44.0 },
  { created_time: "2023-12-11", systolic: 147.0, diastolic: 86.0, bpm: 64.0 },
  { created_time: "2023-12-10", systolic: 117.0, diastolic: 53.0, bpm: 64.0 },
  { created_time: "2023-12-09", systolic: 160.0, diastolic: 71.0, bpm: 90.0 },
  { created_time: "2023-12-08", systolic: 144.0, diastolic: 84.0, bpm: 101.0 },
  { created_time: "2023-12-07", systolic: 100.0, diastolic: 100.0, bpm: 66.0 },
  { created_time: "2023-12-06", systolic: 137.0, diastolic: 68.0, bpm: 61.0 },
  { created_time: "2023-12-05", systolic: 151.0, diastolic: 73.0, bpm: 74.0 },
  { created_time: "2023-12-04", systolic: 127.0, diastolic: 68.0, bpm: 61.0 },
  { created_time: "2023-12-03", systolic: 183.0, diastolic: 75.0, bpm: 75.0 },
  { created_time: "2023-12-02", systolic: 116.0, diastolic: 65.0, bpm: 95.0 },
  { created_time: "2023-12-01", systolic: 110.0, diastolic: 55.0, bpm: 94.0 },
  { created_time: "2023-11-30", systolic: 99.0, diastolic: 53.0, bpm: 81.0 },
  { created_time: "2023-11-29", systolic: 146.0, diastolic: 79.0, bpm: 68.0 },
  { created_time: "2023-11-28", systolic: 144.0, diastolic: 60.0, bpm: 74.0 },
  { created_time: "2023-11-27", systolic: 116.0, diastolic: 83.0, bpm: 83.0 },
  { created_time: "2023-11-26", systolic: 103.0, diastolic: 61.0, bpm: 91.0 },
  { created_time: "2023-11-25", systolic: 168.0, diastolic: 96.0, bpm: 83.0 },
  { created_time: "2023-11-24", systolic: 112.0, diastolic: 55.0, bpm: 65.0 },
  { created_time: "2023-11-23", systolic: 131.0, diastolic: 90.0, bpm: 111.0 },
  { created_time: "2023-11-22", systolic: 118.0, diastolic: 45.0, bpm: 48.0 },
  { created_time: "2023-11-21", systolic: 127.0, diastolic: 87.0, bpm: 104.0 },
  { created_time: "2023-11-20", systolic: 104.0, diastolic: 54.0, bpm: 77.0 },
  { created_time: "2023-11-19", systolic: 106.0, diastolic: 69.0, bpm: 78.0 },
  { created_time: "2023-11-18", systolic: 147.0, diastolic: 82.0, bpm: 81.0 },
  { created_time: "2023-11-17", systolic: 120.0, diastolic: 56.0, bpm: 59.0 },
  { created_time: "2023-11-16", systolic: 144.0, diastolic: 76.0, bpm: 47.0 },
  { created_time: "2023-11-15", systolic: 139.0, diastolic: 57.0, bpm: 61.0 },
  { created_time: "2023-11-14", systolic: 142.0, diastolic: 78.0, bpm: 86.0 },
  { created_time: "2023-11-13", systolic: 118.0, diastolic: 81.0, bpm: 87.0 },
  { created_time: "2023-11-12", systolic: 131.0, diastolic: 99.0, bpm: 74.0 },
  { created_time: "2023-11-11", systolic: 152.0, diastolic: 72.0, bpm: 59.0 },
  { created_time: "2023-11-10", systolic: 129.0, diastolic: 74.0, bpm: 57.0 },
  { created_time: "2023-11-10", systolic: 115.0, diastolic: 57.0, bpm: 76.0 },
  { created_time: "2023-11-09", systolic: 141.0, diastolic: 72.0, bpm: 70.0 },
  { created_time: "2023-11-08", systolic: 145.0, diastolic: 73.0, bpm: 65.0 },
  { created_time: "2023-11-07", systolic: 149.0, diastolic: 99.0, bpm: 79.0 },
  { created_time: "2023-11-06", systolic: 155.0, diastolic: 99.0, bpm: 73.0 },
  { created_time: "2023-11-05", systolic: 120.0, diastolic: 59.0, bpm: 63.0 },
  { created_time: "2023-11-04", systolic: 117.0, diastolic: 74.0, bpm: 111.0 },
  { created_time: "2023-11-03", systolic: 116.0, diastolic: 91.0, bpm: 110.0 },
  { created_time: "2023-11-02", systolic: 147.0, diastolic: 83.0, bpm: 76.0 },
  { created_time: "2023-11-01", systolic: 109.0, diastolic: 63.0, bpm: 71.0 },
  { created_time: "2023-10-31", systolic: 139.0, diastolic: 91.0, bpm: 86.0 },
  { created_time: "2023-10-30", systolic: 100.0, diastolic: 75.0, bpm: 68.0 },
  { created_time: "2023-10-29", systolic: 124.0, diastolic: 73.0, bpm: 72.0 },
  { created_time: "2023-10-28", systolic: 142.0, diastolic: 68.0, bpm: 57.0 },
  { created_time: "2023-10-27", systolic: 108.0, diastolic: 67.0, bpm: 88.0 },
  { created_time: "2023-10-26", systolic: 125.0, diastolic: 76.0, bpm: 72.0 },
  { created_time: "2023-10-25", systolic: 147.0, diastolic: 72.0, bpm: 55.0 },
  { created_time: "2023-10-24", systolic: 114.0, diastolic: 84.0, bpm: 72.0 },
  { created_time: "2023-10-23", systolic: 141.0, diastolic: 84.0, bpm: 63.0 },
  { created_time: "2023-10-22", systolic: 149.0, diastolic: 68.0, bpm: 61.0 },
  { created_time: "2023-10-21", systolic: 132.0, diastolic: 70.0, bpm: 54.0 },
  { created_time: "2023-10-20", systolic: 131.0, diastolic: 84.0, bpm: 54.0 },
  { created_time: "2023-10-19", systolic: 124.0, diastolic: 78.0, bpm: 62.0 },
  { created_time: "2023-10-18", systolic: 141.0, diastolic: 89.0, bpm: 65.0 },
  { created_time: "2023-10-17", systolic: 127.0, diastolic: 85.0, bpm: 75.0 },
  { created_time: "2023-10-16", systolic: 118.0, diastolic: 74.0, bpm: 69.0 },
  { created_time: "2023-10-15", systolic: 175.0, diastolic: 87.0, bpm: 83.0 },
  { created_time: "2023-10-14", systolic: 141.0, diastolic: 84.0, bpm: 97.0 },
  { created_time: "2023-10-13", systolic: 100.0, diastolic: 69.0, bpm: 81.0 },
  { created_time: "2023-10-12", systolic: 97.0, diastolic: 42.0, bpm: 84.0 },
  { created_time: "2023-10-11", systolic: 107.0, diastolic: 61.0, bpm: 88.0 },
  { created_time: "2023-10-10", systolic: 143.0, diastolic: 86.0, bpm: 99.0 },
  { created_time: "2023-10-09", systolic: 163.0, diastolic: 115.0, bpm: 88.0 },
  { created_time: "2023-10-08", systolic: 112.0, diastolic: 56.0, bpm: 79.0 },
  { created_time: "2023-10-07", systolic: 140.0, diastolic: 74.0, bpm: 66.0 },
  { created_time: "2023-10-06", systolic: 132.0, diastolic: 84.0, bpm: 66.0 },
  { created_time: "2023-10-05", systolic: 110.0, diastolic: 76.0, bpm: 64.0 },
  { created_time: "2023-10-04", systolic: 125.0, diastolic: 61.0, bpm: 55.0 },
  { created_time: "2023-10-03", systolic: 132.0, diastolic: 73.0, bpm: 64.0 },
  { created_time: "2023-10-02", systolic: 107.0, diastolic: 44.0, bpm: 66.0 },
  { created_time: "2023-10-01", systolic: 142.0, diastolic: 64.0, bpm: 64.0 },
  { created_time: "2023-09-30", systolic: 144.0, diastolic: 86.0, bpm: 66.0 },
  { created_time: "2023-09-29", systolic: 122.0, diastolic: 64.0, bpm: 67.0 },
  { created_time: "2023-09-28", systolic: 157.0, diastolic: 80.0, bpm: 56.0 },
  { created_time: "2023-09-27", systolic: 132.0, diastolic: 83.0, bpm: 56.0 },
  { created_time: "2023-09-26", systolic: 148.0, diastolic: 93.0, bpm: 89.0 },
  { created_time: "2023-09-25", systolic: 122.0, diastolic: 62.0, bpm: 77.0 },
  { created_time: "2023-09-24", systolic: 86.0, diastolic: 63.0, bpm: 103.0 },
  { created_time: "2023-09-23", systolic: 134.0, diastolic: 80.0, bpm: 81.0 },
  { created_time: "2023-09-22", systolic: 114.0, diastolic: 52.0, bpm: 54.0 },
  { created_time: "2023-09-21", systolic: 120.0, diastolic: 80.0, bpm: 76.0 },
  { created_time: "2023-09-20", systolic: 148.0, diastolic: 75.0, bpm: 69.0 },
  { created_time: "2023-09-19", systolic: 119.0, diastolic: 74.0, bpm: 100.0 },
  { created_time: "2023-09-18", systolic: 146.0, diastolic: 76.0, bpm: 87.0 },
  { created_time: "2023-09-16", systolic: 123.0, diastolic: 62.0, bpm: 60.0 },
  { created_time: "2023-09-15", systolic: 148.0, diastolic: 78.0, bpm: 61.0 },
  { created_time: "2023-09-14", systolic: 133.0, diastolic: 66.0, bpm: 81.0 },
  { created_time: "2023-09-13", systolic: 135.0, diastolic: 99.0, bpm: 77.0 },
  { created_time: "2023-09-12", systolic: 124.0, diastolic: 72.0, bpm: 58.0 },
  { created_time: "2023-09-11", systolic: 136.0, diastolic: 85.0, bpm: 76.0 },
  { created_time: "2023-09-10", systolic: 114.0, diastolic: 62.0, bpm: 87.0 },
  { created_time: "2023-09-09", systolic: 112.0, diastolic: 77.0, bpm: 51.0 },
  { created_time: "2023-09-08", systolic: 117.0, diastolic: 66.0, bpm: 60.0 },
  { created_time: "2023-09-07", systolic: 128.0, diastolic: 78.0, bpm: 75.0 },
  { created_time: "2023-09-06", systolic: 124.0, diastolic: 82.0, bpm: 104.0 },
  { created_time: "2023-09-05", systolic: 188.0, diastolic: 90.0, bpm: 78.0 },
  { created_time: "2023-09-04", systolic: 136.0, diastolic: 71.0, bpm: 82.0 },
  { created_time: "2023-09-03", systolic: 107.0, diastolic: 62.0, bpm: 95.0 },
  { created_time: "2023-09-02", systolic: 147.0, diastolic: 59.0, bpm: 75.0 },
  { created_time: "2023-09-01", systolic: 111.0, diastolic: 50.0, bpm: 65.0 },
  { created_time: "2023-08-31", systolic: 121.0, diastolic: 77.0, bpm: 61.0 },
  { created_time: "2023-08-30", systolic: 130.0, diastolic: 67.0, bpm: 59.0 },
  { created_time: "2023-08-29", systolic: 126.0, diastolic: 55.0, bpm: 67.0 },
  { created_time: "2023-08-28", systolic: 152.0, diastolic: 92.0, bpm: 67.0 },
  { created_time: "2023-08-27", systolic: 108.0, diastolic: 55.0, bpm: 63.0 },
  { created_time: "2023-08-26", systolic: 117.0, diastolic: 72.0, bpm: 61.0 },
  { created_time: "2023-08-25", systolic: 147.0, diastolic: 97.0, bpm: 72.0 },
  { created_time: "2023-08-24", systolic: 163.0, diastolic: 84.0, bpm: 79.0 },
  { created_time: "2023-08-23", systolic: 140.0, diastolic: 92.0, bpm: 82.0 },
  { created_time: "2023-08-22", systolic: 128.0, diastolic: 70.0, bpm: 77.0 },
  { created_time: "2023-08-21", systolic: 115.0, diastolic: 70.0, bpm: 107.0 },
  { created_time: "2023-08-20", systolic: 169.0, diastolic: 75.0, bpm: 67.0 },
  { created_time: "2023-08-19", systolic: 127.0, diastolic: 65.0, bpm: 68.0 },
  { created_time: "2023-08-18", systolic: 99.0, diastolic: 57.0, bpm: 64.0 },
  { created_time: "2023-08-17", systolic: 128.0, diastolic: 73.0, bpm: 72.0 },
  { created_time: "2023-08-16", systolic: 144.0, diastolic: 98.0, bpm: 78.0 },
  { created_time: "2023-08-15", systolic: 128.0, diastolic: 85.0, bpm: 79.0 },
  { created_time: "2023-08-14", systolic: 114.0, diastolic: 63.0, bpm: 56.0 },
  { created_time: "2023-08-13", systolic: 148.0, diastolic: 63.0, bpm: 59.0 },
  { created_time: "2023-08-12", systolic: 125.0, diastolic: 56.0, bpm: 76.0 },
  { created_time: "2023-08-11", systolic: 90.0, diastolic: 52.0, bpm: 80.0 },
  { created_time: "2023-08-10", systolic: 100.0, diastolic: 63.0, bpm: 63.0 },
  { created_time: "2023-08-09", systolic: 175.0, diastolic: 84.0, bpm: 79.0 },
  { created_time: "2023-08-08", systolic: 147.0, diastolic: 79.0, bpm: 102.0 },
  { created_time: "2023-08-07", systolic: 186.0, diastolic: 170.0, bpm: 69.0 },
  { created_time: "2023-08-06", systolic: 173.0, diastolic: 115.0, bpm: 67.0 },
  { created_time: "2023-08-05", systolic: 128.0, diastolic: 82.0, bpm: 74.0 },
  { created_time: "2023-08-04", systolic: 173.0, diastolic: 63.0, bpm: 62.0 },
  { created_time: "2023-08-03", systolic: 103.0, diastolic: 66.0, bpm: 81.0 },
  { created_time: "2023-08-02", systolic: 92.0, diastolic: 64.0, bpm: 76.0 },
  // { created_time: "2023-08-01", systolic: 116.0, diastolic: 76.0, bpm: 84.0 },
  // { created_time: "2023-07-31", systolic: 138.0, diastolic: 68.0, bpm: 67.0 },
  // { created_time: "2023-07-30", systolic: 132.0, diastolic: 71.0, bpm: 72.0 },
  // { created_time: "2023-07-29", systolic: 130.0, diastolic: 58.0, bpm: 64.0 },
  // { created_time: "2023-07-28", systolic: 116.0, diastolic: 64.0, bpm: 82.0 },
  // { created_time: "2023-07-27", systolic: 123.0, diastolic: 79.0, bpm: 73.0 },
  // { created_time: "2023-07-26", systolic: 124.0, diastolic: 86.0, bpm: 71.0 },
  // { created_time: "2023-07-25", systolic: 126.0, diastolic: 84.0, bpm: 91.0 },
  // { created_time: "2023-07-24", systolic: 159.0, diastolic: 78.0, bpm: 63.0 },
  // { created_time: "2023-07-23", systolic: 114.0, diastolic: 70.0, bpm: 74.0 },
  // { created_time: "2023-07-22", systolic: 110.0, diastolic: 82.0, bpm: 65.0 },
  // { created_time: "2023-07-21", systolic: 132.0, diastolic: 102.0, bpm: 65.0 },
  // { created_time: "2023-07-20", systolic: 96.0, diastolic: 65.0, bpm: 94.0 },
  // { created_time: "2023-07-19", systolic: 86.0, diastolic: 46.0, bpm: 77.0 },
  // { created_time: "2023-07-18", systolic: 133.0, diastolic: 89.0, bpm: 77.0 },
  // { created_time: "2023-07-17", systolic: 133.0, diastolic: 92.0, bpm: 75.0 },
  // { created_time: "2023-07-16", systolic: 147.0, diastolic: 81.0, bpm: 53.0 },
  // { created_time: "2023-07-15", systolic: 115.0, diastolic: 57.0, bpm: 57.0 },
  // { created_time: "2023-07-14", systolic: 160.0, diastolic: 72.0, bpm: 63.0 },
  // { created_time: "2023-07-13", systolic: 111.0, diastolic: 44.0, bpm: 75.0 },
  // { created_time: "2023-07-12", systolic: 130.0, diastolic: 78.0, bpm: 81.0 },
  // { created_time: "2023-07-11", systolic: 138.0, diastolic: 64.0, bpm: 71.0 },
  // { created_time: "2023-07-10", systolic: 113.0, diastolic: 74.0, bpm: 65.0 },
  // { created_time: "2023-07-09", systolic: 140.0, diastolic: 64.0, bpm: 68.0 },
  // { created_time: "2023-07-08", systolic: 111.0, diastolic: 59.0, bpm: 49.0 },
  // { created_time: "2023-07-07", systolic: 157.0, diastolic: 75.0, bpm: 84.0 },
  // { created_time: "2023-07-06", systolic: 112.0, diastolic: 57.0, bpm: 80.0 },
  // { created_time: "2023-07-05", systolic: 149.0, diastolic: 97.0, bpm: 70.0 },
  // { created_time: "2023-07-04", systolic: 91.0, diastolic: 54.0, bpm: 60.0 },
  // { created_time: "2023-07-03", systolic: 152.0, diastolic: 87.0, bpm: 74.0 },
  // { created_time: "2023-07-02", systolic: 199.0, diastolic: 101.0, bpm: 78.0 },
  // { created_time: "2023-07-01", systolic: 131.0, diastolic: 74.0, bpm: 52.0 },
  // { created_time: "2023-06-30", systolic: 124.0, diastolic: 66.0, bpm: 68.0 },
  // { created_time: "2023-06-29", systolic: 163.0, diastolic: 66.0, bpm: 56.0 },
  // { created_time: "2023-06-28", systolic: 100.0, diastolic: 72.0, bpm: 91.0 },
  // { created_time: "2023-06-27", systolic: 123.0, diastolic: 72.0, bpm: 76.0 },
  // { created_time: "2023-06-26", systolic: 121.0, diastolic: 79.0, bpm: 70.0 },
  // { created_time: "2023-06-25", systolic: 131.0, diastolic: 80.0, bpm: 60.0 },
  // { created_time: "2023-06-24", systolic: 81.0, diastolic: 57.0, bpm: 82.0 },
  // { created_time: "2023-06-23", systolic: 148.0, diastolic: 75.0, bpm: 78.0 },
  // { created_time: "2023-06-22", systolic: 123.0, diastolic: 88.0, bpm: 81.0 },
  // { created_time: "2023-06-21", systolic: 123.0, diastolic: 64.0, bpm: 56.0 },
  // { created_time: "2023-06-20", systolic: 123.0, diastolic: 76.0, bpm: 64.0 },
  // { created_time: "2023-06-19", systolic: 142.0, diastolic: 76.0, bpm: 74.0 },
  // { created_time: "2023-06-18", systolic: 143.0, diastolic: 90.0, bpm: 84.0 },
  // { created_time: "2023-06-17", systolic: 116.0, diastolic: 68.0, bpm: 67.0 },
  // { created_time: "2023-06-16", systolic: 98.0, diastolic: 50.0, bpm: 71.0 },
  // { created_time: "2023-06-15", systolic: 110.0, diastolic: 72.0, bpm: 52.0 },
  // { created_time: "2023-06-14", systolic: 131.0, diastolic: 85.0, bpm: 70.0 },
  // { created_time: "2023-06-13", systolic: 138.0, diastolic: 84.0, bpm: 110.0 },
  // { created_time: "2023-06-12", systolic: 112.0, diastolic: 54.0, bpm: 75.0 },
  // { created_time: "2023-06-11", systolic: 162.0, diastolic: 94.0, bpm: 83.0 },
  // { created_time: "2023-06-10", systolic: 122.0, diastolic: 73.0, bpm: 77.0 },
  // { created_time: "2023-06-09", systolic: 134.0, diastolic: 80.0, bpm: 55.0 },
  // { created_time: "2023-06-08", systolic: 135.0, diastolic: 93.0, bpm: 72.0 },
  // { created_time: "2023-06-07", systolic: 132.0, diastolic: 72.0, bpm: 77.0 },
  // { created_time: "2023-06-06", systolic: 136.0, diastolic: 60.0, bpm: 59.0 },
  // { created_time: "2023-06-05", systolic: 148.0, diastolic: 75.0, bpm: 86.0 },
  // { created_time: "2023-06-04", systolic: 142.0, diastolic: 81.0, bpm: 88.0 },
  // { created_time: "2023-06-03", systolic: 106.0, diastolic: 76.0, bpm: 72.0 },
  // { created_time: "2023-06-02", systolic: 102.0, diastolic: 54.0, bpm: 76.0 },
  // { created_time: "2023-06-01", systolic: 140.0, diastolic: 100.0, bpm: 67.0 },
  // { created_time: "2023-05-31", systolic: 140.0, diastolic: 93.0, bpm: 80.0 },
  // { created_time: "2023-05-30", systolic: 136.0, diastolic: 83.0, bpm: 65.0 },
  // { created_time: "2023-05-29", systolic: 144.0, diastolic: 96.0, bpm: 99.0 },
  // { created_time: "2023-05-28", systolic: 142.0, diastolic: 88.0, bpm: 63.0 },
  // { created_time: "2023-05-27", systolic: 112.0, diastolic: 74.0, bpm: 77.0 },
  // { created_time: "2023-05-26", systolic: 118.0, diastolic: 65.0, bpm: 82.0 },
  // { created_time: "2023-05-25", systolic: 130.0, diastolic: 72.0, bpm: 69.0 },
  // { created_time: "2023-05-24", systolic: 130.0, diastolic: 72.0, bpm: 69.0 },
  // { created_time: "2023-05-23", systolic: 130.0, diastolic: 72.0, bpm: 69.0 },
  // { created_time: "2023-05-22", systolic: 103.0, diastolic: 51.0, bpm: 68.0 },
  // { created_time: "2023-05-21", systolic: 90.0, diastolic: 67.0, bpm: 72.0 },
  // { created_time: "2023-05-20", systolic: 128.0, diastolic: 80.0, bpm: 69.0 },
  // { created_time: "2023-05-19", systolic: 136.0, diastolic: 65.0, bpm: 73.0 },
  // { created_time: "2023-05-18", systolic: 83.0, diastolic: 65.0, bpm: 85.0 },
  // { created_time: "2023-05-17", systolic: 144.0, diastolic: 83.0, bpm: 91.0 },
  // { created_time: "2023-05-16", systolic: 140.0, diastolic: 83.0, bpm: 88.0 },
  // { created_time: "2023-05-15", systolic: 139.0, diastolic: 90.0, bpm: 82.0 },
  // { created_time: "2023-05-14", systolic: 164.0, diastolic: 77.0, bpm: 83.0 },
  // { created_time: "2023-05-13", systolic: 110.0, diastolic: 64.0, bpm: 60.0 },
  // { created_time: "2023-05-12", systolic: 100.0, diastolic: 50.0, bpm: 84.0 },
  // { created_time: "2023-05-11", systolic: 104.0, diastolic: 89.0, bpm: 90.0 },
  // { created_time: "2023-05-10", systolic: 122.0, diastolic: 80.0, bpm: 76.0 },
  // { created_time: "2023-05-09", systolic: 142.0, diastolic: 70.0, bpm: 70.0 },
  // { created_time: "2023-05-08", systolic: 129.0, diastolic: 79.0, bpm: 52.0 },
  // { created_time: "2023-05-07", systolic: 108.0, diastolic: 58.0, bpm: 63.0 },
  // { created_time: "2023-05-06", systolic: 124.0, diastolic: 82.0, bpm: 111.0 },
  // { created_time: "2023-05-05", systolic: 103.0, diastolic: 63.0, bpm: 62.0 },
  // { created_time: "2023-05-04", systolic: 156.0, diastolic: 66.0, bpm: 61.0 },
  // { created_time: "2023-05-03", systolic: 149.0, diastolic: 72.0, bpm: 70.0 },
  // { created_time: "2023-05-02", systolic: 131.0, diastolic: 61.0, bpm: 55.0 },
  // { created_time: "2023-05-01", systolic: 124.0, diastolic: 78.0, bpm: 75.0 },
  // {"created_time":"2023-04-30","systolic":128.000,"diastolic":77.000,"bpm":67.000},
  // {"created_time":"2023-04-29","systolic":127.000,"diastolic":68.000,"bpm":82.000},
  // {"created_time":"2023-04-28","systolic":152.000,"diastolic":74.000,"bpm":61.000},
  // {"created_time":"2023-04-27","systolic":142.000,"diastolic":73.000,"bpm":90.000},
  // {"created_time":"2023-04-26","systolic":101.000,"diastolic":68.000,"bpm":88.000},
  // {"created_time":"2023-04-25","systolic":128.000,"diastolic":83.000,"bpm":59.000},
  // {"created_time":"2023-04-24","systolic":128.000,"diastolic":65.000,"bpm":58.000},
  // {"created_time":"2023-04-23","systolic":121.000,"diastolic":75.000,"bpm":85.000},
  // {"created_time":"2023-04-22","systolic":116.000,"diastolic":59.000,"bpm":76.000},
  // {"created_time":"2023-04-21","systolic":110.000,"diastolic":78.000,"bpm":77.000},
  // {"created_time":"2023-04-20","systolic":108.000,"diastolic":74.000,"bpm":81.000},
  // {"created_time":"2023-04-19","systolic":176.000,"diastolic":75.000,"bpm":70.000},
  // {"created_time":"2023-04-18","systolic":200.000,"diastolic":110.000,"bpm":89.000},
  // {"created_time":"2023-04-17","systolic":140.000,"diastolic":78.000,"bpm":68.000},
  // {"created_time":"2023-04-16","systolic":120.000,"diastolic":72.000,"bpm":71.000},
  // {"created_time":"2023-04-15","systolic":141.000,"diastolic":97.000,"bpm":86.000},
  // {"created_time":"2023-04-14","systolic":65.000,"diastolic":45.000,"bpm":67.000},
  // {"created_time":"2023-04-13","systolic":88.000,"diastolic":63.000,"bpm":106.000},
  // {"created_time":"2023-04-12","systolic":130.000,"diastolic":76.000,"bpm":90.000},
  // {"created_time":"2023-04-11","systolic":143.000,"diastolic":75.000,"bpm":58.000},
  // {"created_time":"2023-04-10","systolic":96.000,"diastolic":73.000,"bpm":103.000},
  // {"created_time":"2023-04-09","systolic":88.000,"diastolic":62.000,"bpm":118.000},
  // {"created_time":"2023-04-08","systolic":115.000,"diastolic":95.000,"bpm":78.000},
  // {"created_time":"2023-04-07","systolic":109.000,"diastolic":63.000,"bpm":62.000},
  // {"created_time":"2023-04-06","systolic":121.000,"diastolic":76.000,"bpm":63.000},
  // {"created_time":"2023-04-05","systolic":157.000,"diastolic":86.000,"bpm":69.000},
  // {"created_time":"2023-04-04","systolic":156.000,"diastolic":85.000,"bpm":69.000},
  // {"created_time":"2023-04-03","systolic":148.000,"diastolic":100.000,"bpm":86.000},
  // {"created_time":"2023-04-02","systolic":137.000,"diastolic":81.000,"bpm":64.000},
  // {"created_time":"2023-04-01","systolic":126.000,"diastolic":84.000,"bpm":94.000},
  // {"created_time":"2023-03-31","systolic":90.000,"diastolic":60.000,"bpm":80.000},
  // {"created_time":"2023-03-30","systolic":150.000,"diastolic":109.000,"bpm":73.000},
  // {"created_time":"2023-03-29","systolic":112.000,"diastolic":59.000,"bpm":72.000},
  // {"created_time":"2023-03-28","systolic":116.000,"diastolic":61.000,"bpm":69.000},
  // {"created_time":"2023-03-27","systolic":100.000,"diastolic":64.000,"bpm":83.000},
  // {"created_time":"2023-03-26","systolic":114.000,"diastolic":64.000,"bpm":79.000},
  // {"created_time":"2023-03-25","systolic":122.000,"diastolic":64.000,"bpm":96.000},
  // {"created_time":"2023-03-24","systolic":110.000,"diastolic":52.000,"bpm":67.000},
  // {"created_time":"2023-03-23","systolic":181.000,"diastolic":93.000,"bpm":93.000},
  // {"created_time":"2023-03-22","systolic":136.000,"diastolic":86.000,"bpm":50.000},
  // {"created_time":"2023-03-21","systolic":172.000,"diastolic":78.000,"bpm":68.000},
  // {"created_time":"2023-03-20","systolic":96.000,"diastolic":45.000,"bpm":51.000},
  // {"created_time":"2023-03-19","systolic":156.000,"diastolic":85.000,"bpm":79.000},
  // {"created_time":"2023-03-18","systolic":128.000,"diastolic":56.000,"bpm":66.000},
  // {"created_time":"2023-03-17","systolic":110.000,"diastolic":63.000,"bpm":82.000},
  // {"created_time":"2023-03-16","systolic":128.000,"diastolic":84.000,"bpm":107.000},
  // {"created_time":"2023-03-15","systolic":131.000,"diastolic":59.000,"bpm":54.000},
  // {"created_time":"2023-03-14","systolic":100.000,"diastolic":59.000,"bpm":64.000},
  // {"created_time":"2023-03-13","systolic":119.000,"diastolic":76.000,"bpm":68.000},
  // {"created_time":"2023-03-12","systolic":124.000,"diastolic":68.000,"bpm":61.000},
  // {"created_time":"2023-03-11","systolic":174.000,"diastolic":113.000,"bpm":77.000},
  // {"created_time":"2023-03-10","systolic":113.000,"diastolic":60.000,"bpm":62.000},
  // {"created_time":"2023-03-09","systolic":166.000,"diastolic":69.000,"bpm":72.000},
  // {"created_time":"2023-03-08","systolic":84.000,"diastolic":63.000,"bpm":170.000},
  // {"created_time":"2023-03-07","systolic":146.000,"diastolic":71.000,"bpm":74.000},
  // {"created_time":"2023-03-06","systolic":140.000,"diastolic":81.000,"bpm":71.000},
  // {"created_time":"2023-03-05","systolic":141.000,"diastolic":87.000,"bpm":75.000},
  // {"created_time":"2023-03-04","systolic":112.000,"diastolic":77.000,"bpm":84.000},
  // {"created_time":"2023-03-03","systolic":160.000,"diastolic":71.000,"bpm":63.000},
  // {"created_time":"2023-03-02","systolic":144.000,"diastolic":78.000,"bpm":78.000},
  // {"created_time":"2023-03-01","systolic":109.000,"diastolic":72.000,"bpm":99.000},
  // {"created_time":"2023-02-28","systolic":143.000,"diastolic":66.000,"bpm":80.000},
];

const pulseoxData = [
  { created_time: "2024-02-28", spo2: 99.0, bpm: 135.0 },
  { created_time: "2024-02-27", spo2: 94.0, bpm: 93.0 },
  { created_time: "2024-02-26", spo2: 99.0, bpm: 95.0 },
  { created_time: "2024-02-25", spo2: 99.0, bpm: 52.0 },
  { created_time: "2024-02-24", spo2: 100.0, bpm: 93.0 },
  { created_time: "2024-02-23", spo2: 97.0, bpm: 81.0 },
  { created_time: "2024-02-22", spo2: 90.0, bpm: 115.0 },
  { created_time: "2024-02-21", spo2: 87.0, bpm: 99.0 },
  { created_time: "2024-02-20", spo2: 96.0, bpm: 73.0 },
  { created_time: "2024-02-19", spo2: 88.0, bpm: 118.0 },
  { created_time: "2024-02-18", spo2: 94.0, bpm: 79.0 },
  { created_time: "2024-02-17", spo2: 99.0, bpm: 97.0 },
  { created_time: "2024-02-16", spo2: 97.0, bpm: 70.0 },
  { created_time: "2024-02-15", spo2: 93.0, bpm: 63.0 },
  { created_time: "2024-02-14", spo2: 98.0, bpm: 74.0 },
  { created_time: "2024-02-13", spo2: 93.0, bpm: 77.0 },
  { created_time: "2024-02-12", spo2: 96.0, bpm: 72.0 },
  { created_time: "2024-02-11", spo2: 94.0, bpm: 103.0 },
  { created_time: "2024-02-10", spo2: 96.0, bpm: 40.0 },
  { created_time: "2024-02-09", spo2: 96.0, bpm: 93.0 },
  { created_time: "2024-02-08", spo2: 99.0, bpm: 91.0 },
  { created_time: "2024-02-07", spo2: 98.0, bpm: 103.0 },
  { created_time: "2024-02-06", spo2: 97.0, bpm: 84.0 },
  { created_time: "2024-02-05", spo2: 99.0, bpm: 68.0 },
  { created_time: "2024-02-04", spo2: 96.0, bpm: 66.0 },
  { created_time: "2024-02-03", spo2: 96.0, bpm: 68.0 },
  { created_time: "2024-02-02", spo2: 96.0, bpm: 90.0 },
  { created_time: "2024-02-01", spo2: 96.0, bpm: 90.0 },
  { created_time: "2024-01-31", spo2: 91.0, bpm: 81.0 },
  { created_time: "2024-01-30", spo2: 98.0, bpm: 106.0 },
  { created_time: "2024-01-29", spo2: 97.0, bpm: 65.0 },
  { created_time: "2024-01-28", spo2: 99.0, bpm: 77.0 },
  { created_time: "2024-01-27", spo2: 93.0, bpm: 77.0 },
  { created_time: "2024-01-26", spo2: 95.0, bpm: 64.0 },
  { created_time: "2024-01-25", spo2: 95.0, bpm: 80.0 },
  { created_time: "2024-01-24", spo2: 93.0, bpm: 113.0 },
  { created_time: "2024-01-23", spo2: 97.0, bpm: 46.0 },
  { created_time: "2024-01-22", spo2: 99.0, bpm: 64.0 },
  { created_time: "2024-01-21", spo2: 95.0, bpm: 73.0 },
  { created_time: "2024-01-20", spo2: 96.0, bpm: 60.0 },
  { created_time: "2024-01-19", spo2: 99.0, bpm: 77.0 },
  { created_time: "2024-01-18", spo2: 99.0, bpm: 103.0 },
  { created_time: "2024-01-17", spo2: 94.0, bpm: 75.0 },
  { created_time: "2024-01-16", spo2: 97.0, bpm: 83.0 },
  { created_time: "2024-01-15", spo2: 95.0, bpm: 90.0 },
  { created_time: "2024-01-14", spo2: 96.0, bpm: 76.0 },
  { created_time: "2024-01-13", spo2: 97.0, bpm: 71.0 },
  { created_time: "2024-01-12", spo2: 97.0, bpm: 69.0 },
  { created_time: "2024-01-11", spo2: 99.0, bpm: 105.0 },
  { created_time: "2024-01-10", spo2: 99.0, bpm: 49.0 },
  { created_time: "2024-01-09", spo2: 98.0, bpm: 81.0 },
  { created_time: "2024-01-08", spo2: 98.0, bpm: 75.0 },
  { created_time: "2024-01-07", spo2: 91.0, bpm: 100.0 },
  { created_time: "2024-01-06", spo2: 95.0, bpm: 101.0 },
  { created_time: "2024-01-05", spo2: 99.0, bpm: 90.0 },
  { created_time: "2024-01-04", spo2: 90.0, bpm: 85.0 },
  { created_time: "2024-01-03", spo2: 97.0, bpm: 86.0 },
  { created_time: "2024-01-02", spo2: 98.0, bpm: 60.0 },
  { created_time: "2024-01-01", spo2: 98.0, bpm: 70.0 },
  { created_time: "2023-12-31", spo2: 98.0, bpm: 56.0 },
  { created_time: "2023-12-30", spo2: 98.0, bpm: 92.0 },
  { created_time: "2023-12-29", spo2: 100.0, bpm: 90.0 },
  { created_time: "2023-12-28", spo2: 100.0, bpm: 34.0 },
  { created_time: "2023-12-27", spo2: 99.0, bpm: 73.0 },
  { created_time: "2023-12-26", spo2: 97.0, bpm: 88.0 },
  { created_time: "2023-12-25", spo2: 95.0, bpm: 79.0 },
  { created_time: "2023-12-24", spo2: 94.0, bpm: 77.0 },
  { created_time: "2023-12-23", spo2: 97.0, bpm: 68.0 },
  { created_time: "2023-12-22", spo2: 95.0, bpm: 95.0 },
  { created_time: "2023-12-21", spo2: 99.0, bpm: 60.0 },
  { created_time: "2023-12-20", spo2: 97.0, bpm: 73.0 },
  { created_time: "2023-12-19", spo2: 97.0, bpm: 66.0 },
  { created_time: "2023-12-18", spo2: 97.0, bpm: 106.0 },
  { created_time: "2023-12-17", spo2: 100.0, bpm: 87.0 },
  { created_time: "2023-12-16", spo2: 99.0, bpm: 92.0 },
  { created_time: "2023-12-15", spo2: 97.0, bpm: 62.0 },
  { created_time: "2023-12-14", spo2: 94.0, bpm: 62.0 },
  { created_time: "2023-12-13", spo2: 97.0, bpm: 63.0 },
  { created_time: "2023-12-12", spo2: 93.0, bpm: 93.0 },
  { created_time: "2023-12-11", spo2: 97.0, bpm: 100.0 },
  { created_time: "2023-12-10", spo2: 96.0, bpm: 65.0 },
  { created_time: "2023-12-09", spo2: 94.0, bpm: 77.0 },
  { created_time: "2023-12-08", spo2: 93.0, bpm: 69.0 },
  { created_time: "2023-12-07", spo2: 98.0, bpm: 81.0 },
  { created_time: "2023-12-06", spo2: 97.0, bpm: 75.0 },
  { created_time: "2023-12-05", spo2: 95.0, bpm: 77.0 },
  { created_time: "2023-12-04", spo2: 95.0, bpm: 126.0 },
  { created_time: "2023-12-03", spo2: 98.0, bpm: 61.0 },
  { created_time: "2023-12-02", spo2: 97.0, bpm: 45.0 },
  { created_time: "2023-12-01", spo2: 98.0, bpm: 138.0 },
  { created_time: "2023-11-30", spo2: 98.0, bpm: 79.0 },
  { created_time: "2023-11-29", spo2: 97.0, bpm: 78.0 },
  { created_time: "2023-11-28", spo2: 96.0, bpm: 88.0 },
  { created_time: "2023-11-27", spo2: 99.0, bpm: 59.0 },
  { created_time: "2023-11-26", spo2: 98.0, bpm: 80.0 },
  { created_time: "2023-11-25", spo2: 98.0, bpm: 94.0 },
  { created_time: "2023-11-24", spo2: 98.0, bpm: 94.0 },
  { created_time: "2023-11-23", spo2: 98.0, bpm: 65.0 },
  { created_time: "2023-11-22", spo2: 97.0, bpm: 94.0 },
  { created_time: "2023-11-21", spo2: 98.0, bpm: 88.0 },
  { created_time: "2023-11-20", spo2: 91.0, bpm: 88.0 },
  { created_time: "2023-11-19", spo2: 99.0, bpm: 106.0 },
  { created_time: "2023-11-18", spo2: 98.0, bpm: 69.0 },
  { created_time: "2023-11-17", spo2: 99.0, bpm: 72.0 },
  { created_time: "2023-11-16", spo2: 98.0, bpm: 78.0 },
  { created_time: "2023-11-15", spo2: 99.0, bpm: 69.0 },
  { created_time: "2023-11-14", spo2: 95.0, bpm: 85.0 },
  { created_time: "2023-11-13", spo2: 89.0, bpm: 91.0 },
  { created_time: "2023-11-12", spo2: 93.0, bpm: 89.0 },
  { created_time: "2023-11-11", spo2: 95.0, bpm: 89.0 },
  // {created_time: "2021-11-10", spo2: 100.000, bpm: 88.000},
  { created_time: "2023-11-10", spo2: 98.0, bpm: 51.0 },
  { created_time: "2023-11-09", spo2: 99.0, bpm: 77.0 },
  { created_time: "2023-11-08", spo2: 99.0, bpm: 113.0 },
  { created_time: "2023-11-07", spo2: 91.0, bpm: 76.0 },
  { created_time: "2023-11-06", spo2: 95.0, bpm: 59.0 },
  { created_time: "2023-11-05", spo2: 96.0, bpm: 69.0 },
  { created_time: "2023-11-04", spo2: 97.0, bpm: 83.0 },
  { created_time: "2023-11-03", spo2: 93.0, bpm: 74.0 },
  { created_time: "2023-11-02", spo2: 93.0, bpm: 89.0 },
  { created_time: "2023-11-01", spo2: 92.0, bpm: 80.0 },
  { created_time: "2023-10-31", spo2: 93.0, bpm: 93.0 },
  { created_time: "2023-10-30", spo2: 97.0, bpm: 86.0 },
  { created_time: "2023-10-29", spo2: 93.0, bpm: 89.0 },
  { created_time: "2023-10-28", spo2: 99.0, bpm: 92.0 },
  { created_time: "2023-10-27", spo2: 98.0, bpm: 92.0 },
  { created_time: "2023-10-26", spo2: 98.0, bpm: 85.0 },
  { created_time: "2023-10-25", spo2: 94.0, bpm: 47.0 },
  { created_time: "2023-10-24", spo2: 96.0, bpm: 68.0 },
  { created_time: "2023-10-23", spo2: 100.0, bpm: 70.0 },
  { created_time: "2023-10-22", spo2: 97.0, bpm: 82.0 },
  { created_time: "2023-10-21", spo2: 95.0, bpm: 41.0 },
  { created_time: "2023-10-20", spo2: 96.0, bpm: 59.0 },
  { created_time: "2023-10-19", spo2: 94.0, bpm: 80.0 },
  { created_time: "2023-10-18", spo2: 97.0, bpm: 75.0 },
  { created_time: "2023-10-17", spo2: 99.0, bpm: 60.0 },
  { created_time: "2023-10-16", spo2: 99.0, bpm: 72.0 },
  { created_time: "2023-10-15", spo2: 93.0, bpm: 65.0 },
  { created_time: "2023-10-14", spo2: 99.0, bpm: 40.0 },
  { created_time: "2023-10-13", spo2: 96.0, bpm: 63.0 },
  { created_time: "2023-10-12", spo2: 98.0, bpm: 83.0 },
  { created_time: "2023-10-11", spo2: 96.0, bpm: 63.0 },
  { created_time: "2023-10-10", spo2: 96.0, bpm: 69.0 },
  { created_time: "2023-10-09", spo2: 98.0, bpm: 81.0 },
  { created_time: "2023-10-08", spo2: 96.0, bpm: 92.0 },
  { created_time: "2023-10-07", spo2: 98.0, bpm: 60.0 },
  { created_time: "2023-10-06", spo2: 100.0, bpm: 77.0 },
  { created_time: "2023-10-05", spo2: 99.0, bpm: 101.0 },
  { created_time: "2023-10-04", spo2: 99.0, bpm: 94.0 },
  { created_time: "2023-10-03", spo2: 96.0, bpm: 75.0 },
  { created_time: "2023-10-02", spo2: 95.0, bpm: 91.0 },
  { created_time: "2023-10-01", spo2: 96.0, bpm: 89.0 },
  { created_time: "2023-09-30", spo2: 98.0, bpm: 60.0 },
  { created_time: "2023-09-29", spo2: 98.0, bpm: 105.0 },
  { created_time: "2023-09-28", spo2: 95.0, bpm: 68.0 },
  { created_time: "2023-09-27", spo2: 98.0, bpm: 53.0 },
  { created_time: "2023-09-26", spo2: 94.0, bpm: 74.0 },
  { created_time: "2023-09-25", spo2: 100.0, bpm: 85.0 },
  { created_time: "2023-09-24", spo2: 90.0, bpm: 71.0 },
  { created_time: "2023-09-23", spo2: 96.0, bpm: 79.0 },
  { created_time: "2023-09-22", spo2: 97.0, bpm: 70.0 },
  { created_time: "2023-09-21", spo2: 96.0, bpm: 85.0 },
  { created_time: "2023-09-20", spo2: 97.0, bpm: 67.0 },
  { created_time: "2023-09-19", spo2: 99.0, bpm: 103.0 },
  { created_time: "2023-09-18", spo2: 99.0, bpm: 76.0 },
  { created_time: "2023-09-17", spo2: 94.0, bpm: 53.0 },
  { created_time: "2023-09-16", spo2: 98.0, bpm: 70.0 },
  { created_time: "2023-09-15", spo2: 95.0, bpm: 68.0 },
  { created_time: "2023-09-14", spo2: 99.0, bpm: 68.0 },
  { created_time: "2023-09-13", spo2: 98.0, bpm: 72.0 },
  { created_time: "2023-09-12", spo2: 94.0, bpm: 58.0 },
  { created_time: "2023-09-11", spo2: 96.0, bpm: 82.0 },
  { created_time: "2023-09-10", spo2: 97.0, bpm: 86.0 },
  { created_time: "2023-09-09", spo2: 94.0, bpm: 70.0 },
  { created_time: "2023-09-08", spo2: 99.0, bpm: 66.0 },
  { created_time: "2023-09-07", spo2: 97.0, bpm: 86.0 },
  { created_time: "2023-09-06", spo2: 98.0, bpm: 56.0 },
  { created_time: "2023-09-05", spo2: 95.0, bpm: 82.0 },
  { created_time: "2023-09-04", spo2: 95.0, bpm: 73.0 },
  { created_time: "2023-09-03", spo2: 90.0, bpm: 88.0 },
  { created_time: "2023-09-02", spo2: 98.0, bpm: 86.0 },
  { created_time: "2023-09-01", spo2: 96.0, bpm: 105.0 },
  { created_time: "2023-08-31", spo2: 95.0, bpm: 68.0 },
  { created_time: "2023-08-30", spo2: 96.0, bpm: 65.0 },
  { created_time: "2023-08-29", spo2: 98.0, bpm: 64.0 },
  { created_time: "2023-08-28", spo2: 97.0, bpm: 77.0 },
  { created_time: "2023-08-27", spo2: 99.0, bpm: 73.0 },
  { created_time: "2023-08-26", spo2: 96.0, bpm: 84.0 },
  { created_time: "2023-08-25", spo2: 95.0, bpm: 72.0 },
  { created_time: "2023-08-24", spo2: 99.0, bpm: 85.0 },
  { created_time: "2023-08-23", spo2: 98.0, bpm: 47.0 },
  { created_time: "2023-08-22", spo2: 98.0, bpm: 76.0 },
  { created_time: "2023-08-21", spo2: 100.0, bpm: 82.0 },
  { created_time: "2023-08-20", spo2: 96.0, bpm: 73.0 },
  { created_time: "2023-08-19", spo2: 92.0, bpm: 85.0 },
  { created_time: "2023-08-18", spo2: 98.0, bpm: 60.0 },
  { created_time: "2023-08-17", spo2: 96.0, bpm: 76.0 },
  { created_time: "2023-08-16", spo2: 99.0, bpm: 50.0 },
  { created_time: "2023-08-15", spo2: 92.0, bpm: 83.0 },
  { created_time: "2023-08-14", spo2: 94.0, bpm: 59.0 },
  { created_time: "2023-08-13", spo2: 95.0, bpm: 58.0 },
  { created_time: "2023-08-12", spo2: 94.0, bpm: 73.0 },
  { created_time: "2023-08-11", spo2: 97.0, bpm: 78.0 },
  { created_time: "2023-08-10", spo2: 95.0, bpm: 67.0 },
  { created_time: "2023-08-09", spo2: 99.0, bpm: 64.0 },
  { created_time: "2023-08-08", spo2: 98.0, bpm: 80.0 },
  { created_time: "2023-08-07", spo2: 98.0, bpm: 90.0 },
  { created_time: "2023-08-06", spo2: 96.0, bpm: 77.0 },
  { created_time: "2023-08-05", spo2: 97.0, bpm: 71.0 },
  { created_time: "2023-08-04", spo2: 99.0, bpm: 71.0 },
  { created_time: "2023-08-03", spo2: 99.0, bpm: 89.0 },
  { created_time: "2023-08-02", spo2: 96.0, bpm: 61.0 },
  // { created_time: "2023-08-01", spo2: 98.0, bpm: 67.0 },
  // { created_time: "2023-07-31", spo2: 97.0, bpm: 91.0 },
  // { created_time: "2023-07-30", spo2: 96.0, bpm: 94.0 },
  // { created_time: "2023-07-29", spo2: 97.0, bpm: 71.0 },
  // { created_time: "2023-07-28", spo2: 95.0, bpm: 70.0 },
  // { created_time: "2023-07-27", spo2: 97.0, bpm: 100.0 },
  // { created_time: "2023-07-26", spo2: 91.0, bpm: 77.0 },
  // { created_time: "2023-07-25", spo2: 94.0, bpm: 86.0 },
  // { created_time: "2023-07-24", spo2: 94.0, bpm: 69.0 },
  // { created_time: "2023-07-23", spo2: 99.0, bpm: 64.0 },
  // { created_time: "2023-07-22", spo2: 96.0, bpm: 120.0 },
  // { created_time: "2023-07-21", spo2: 99.0, bpm: 86.0 },
  // { created_time: "2023-07-20", spo2: 96.0, bpm: 77.0 },
  // { created_time: "2023-07-19", spo2: 100.0, bpm: 59.0 },
  // { created_time: "2023-07-18", spo2: 96.0, bpm: 77.0 },
  // { created_time: "2023-07-17", spo2: 96.0, bpm: 60.0 },
  // { created_time: "2023-07-16", spo2: 96.0, bpm: 86.0 },
  // // {"created_time":"2023-07-15","spo2":41.000,"bpm":76.000},
  // { created_time: "2023-07-14", spo2: 96.0, bpm: 89.0 },
  // { created_time: "2023-07-13", spo2: 99.0, bpm: 78.0 },
  // { created_time: "2023-07-12", spo2: 94.0, bpm: 66.0 },
  // { created_time: "2023-07-11", spo2: 97.0, bpm: 80.0 },
  // { created_time: "2023-07-10", spo2: 98.0, bpm: 67.0 },
  // { created_time: "2023-07-09", spo2: 95.0, bpm: 64.0 },
  // { created_time: "2023-07-08", spo2: 99.0, bpm: 79.0 },
  // { created_time: "2023-07-07", spo2: 99.0, bpm: 73.0 },
  // { created_time: "2023-07-06", spo2: 95.0, bpm: 92.0 },
  // { created_time: "2023-07-05", spo2: 87.0, bpm: 82.0 },
  // { created_time: "2023-07-04", spo2: 97.0, bpm: 73.0 },
  // { created_time: "2023-07-03", spo2: 98.0, bpm: 38.0 },
  // { created_time: "2023-07-02", spo2: 96.0, bpm: 51.0 },
  // { created_time: "2023-07-01", spo2: 97.0, bpm: 86.0 },
  // { created_time: "2023-06-30", spo2: 97.0, bpm: 59.0 },
  // { created_time: "2023-06-29", spo2: 92.0, bpm: 64.0 },
  // { created_time: "2023-06-28", spo2: 98.0, bpm: 80.0 },
  // { created_time: "2023-06-27", spo2: 94.0, bpm: 72.0 },
  // { created_time: "2023-06-26", spo2: 98.0, bpm: 75.0 },
  // { created_time: "2023-06-25", spo2: 97.0, bpm: 58.0 },
  // { created_time: "2023-06-24", spo2: 97.0, bpm: 82.0 },
  // { created_time: "2023-06-23", spo2: 98.0, bpm: 62.0 },
  // { created_time: "2023-06-22", spo2: 96.0, bpm: 68.0 },
  // { created_time: "2023-06-21", spo2: 93.0, bpm: 91.0 },
  // { created_time: "2023-06-20", spo2: 99.0, bpm: 86.0 },
  // { created_time: "2023-06-19", spo2: 93.0, bpm: 59.0 },
  // { created_time: "2023-06-18", spo2: 100.0, bpm: 90.0 },
  // { created_time: "2023-06-17", spo2: 99.0, bpm: 82.0 },
  // { created_time: "2023-06-16", spo2: 98.0, bpm: 93.0 },
  // { created_time: "2023-06-15", spo2: 95.0, bpm: 69.0 },
  // { created_time: "2023-06-14", spo2: 82.0, bpm: 81.0 },
  // { created_time: "2023-06-13", spo2: 95.0, bpm: 103.0 },
  // { created_time: "2023-06-12", spo2: 98.0, bpm: 60.0 },
  // { created_time: "2023-06-11", spo2: 94.0, bpm: 59.0 },
  // { created_time: "2023-06-10", spo2: 90.0, bpm: 57.0 },
  // { created_time: "2023-06-09", spo2: 97.0, bpm: 77.0 },
  // { created_time: "2023-06-08", spo2: 98.0, bpm: 53.0 },
  // { created_time: "2023-06-07", spo2: 97.0, bpm: 95.0 },
  // { created_time: "2023-06-06", spo2: 97.0, bpm: 80.0 },
  // { created_time: "2023-06-05", spo2: 96.0, bpm: 85.0 },
  // { created_time: "2023-06-04", spo2: 97.0, bpm: 69.0 },
  // { created_time: "2023-06-03", spo2: 97.0, bpm: 50.0 },
  // { created_time: "2023-06-02", spo2: 96.0, bpm: 61.0 },
  // { created_time: "2023-06-01", spo2: 98.0, bpm: 97.0 },
  // { created_time: "2023-05-31", spo2: 99.0, bpm: 95.0 },
  // { created_time: "2023-05-30", spo2: 97.0, bpm: 75.0 },
  // { created_time: "2023-05-29", spo2: 97.0, bpm: 68.0 },
  // { created_time: "2023-05-28", spo2: 99.0, bpm: 107.0 },
  // { created_time: "2023-05-27", spo2: 98.0, bpm: 75.0 },
  // { created_time: "2023-05-26", spo2: 97.0, bpm: 78.0 },
  // { created_time: "2023-05-25", spo2: 99.0, bpm: 54.0 },
  // { created_time: "2023-05-24", spo2: 91.0, bpm: 97.0 },
  // { created_time: "2023-05-23", spo2: 93.0, bpm: 96.0 },
  // { created_time: "2023-05-22", spo2: 97.0, bpm: 84.0 },
  // { created_time: "2023-05-21", spo2: 98.0, bpm: 64.0 },
  // { created_time: "2023-05-20", spo2: 99.0, bpm: 85.0 },
  // { created_time: "2023-05-19", spo2: 98.0, bpm: 97.0 },
  // { created_time: "2023-05-18", spo2: 97.0, bpm: 82.0 },
  // { created_time: "2023-05-17", spo2: 99.0, bpm: 85.0 },
  // { created_time: "2023-05-16", spo2: 94.0, bpm: 92.0 },
  // { created_time: "2023-05-15", spo2: 99.0, bpm: 100.0 },
  // { created_time: "2023-05-14", spo2: 98.0, bpm: 76.0 },
  // { created_time: "2023-05-13", spo2: 98.0, bpm: 56.0 },
  // { created_time: "2023-05-12", spo2: 91.0, bpm: 86.0 },
  // { created_time: "2023-05-11", spo2: 96.0, bpm: 86.0 },
  // { created_time: "2023-05-10", spo2: 93.0, bpm: 82.0 },
  // { created_time: "2023-05-09", spo2: 97.0, bpm: 75.0 },
  // { created_time: "2023-05-08", spo2: 96.0, bpm: 71.0 },
  // { created_time: "2023-05-07", spo2: 95.0, bpm: 79.0 },
  // { created_time: "2023-05-06", spo2: 98.0, bpm: 88.0 },
  // { created_time: "2023-05-05", spo2: 98.0, bpm: 72.0 },
  // { created_time: "2023-05-04", spo2: 95.0, bpm: 82.0 },
  // { created_time: "2023-05-03", spo2: 98.0, bpm: 73.0 },
  // { created_time: "2023-05-02", spo2: 96.0, bpm: 85.0 },
  // { created_time: "2023-05-01", spo2: 94.0, bpm: 66.0 },
  // {"created_time":"2023-04-30","spo2":98.000,"bpm":84.000},
  // {"created_time":"2023-04-29","spo2":89.000,"bpm":75.000},
  // {"created_time":"2023-04-28","spo2":98.000,"bpm":71.000},
  // {"created_time":"2023-04-27","spo2":90.000,"bpm":98.000},
  // {"created_time":"2023-04-26","spo2":99.000,"bpm":88.000},
  // {"created_time":"2023-04-25","spo2":99.000,"bpm":107.000},
  // {"created_time":"2023-04-24","spo2":97.000,"bpm":39.000},
  // {"created_time":"2023-04-23","spo2":97.000,"bpm":55.000},
  // {"created_time":"2023-04-22","spo2":98.000,"bpm":93.000},
  // {"created_time":"2023-04-21","spo2":87.000,"bpm":62.000},
  // {"created_time":"2023-04-20","spo2":93.000,"bpm":71.000},
  // {"created_time":"2023-04-19","spo2":98.000,"bpm":70.000},
  // {"created_time":"2023-04-18","spo2":93.000,"bpm":85.000},
  // {"created_time":"2023-04-17","spo2":97.000,"bpm":79.000},
  // {"created_time":"2023-04-16","spo2":98.000,"bpm":88.000},
  // {"created_time":"2023-04-15","spo2":92.000,"bpm":69.000},
  // {"created_time":"2023-04-14","spo2":97.000,"bpm":99.000},
  // {"created_time":"2023-04-13","spo2":100.000,"bpm":70.000},
  // {"created_time":"2023-04-12","spo2":98.000,"bpm":66.000},
  // {"created_time":"2023-04-11","spo2":96.000,"bpm":68.000},
  // {"created_time":"2023-04-10","spo2":96.000,"bpm":48.000},
  // {"created_time":"2023-04-09","spo2":99.000,"bpm":52.000},
  // {"created_time":"2023-04-08","spo2":97.000,"bpm":76.000},
  // {"created_time":"2023-04-07","spo2":98.000,"bpm":67.000},
  // {"created_time":"2023-04-06","spo2":98.000,"bpm":68.000},
  // {"created_time":"2023-04-05","spo2":99.000,"bpm":88.000},
  // {"created_time":"2023-04-04","spo2":93.000,"bpm":108.000},
  // {"created_time":"2023-04-03","spo2":87.000,"bpm":96.000},
  // {"created_time":"2023-04-02","spo2":98.000,"bpm":89.000},
  // {"created_time":"2023-04-01","spo2":95.000,"bpm":70.000},
  // {"created_time":"2023-03-31","spo2":99.000,"bpm":89.000},
  // {"created_time":"2023-03-30","spo2":96.000,"bpm":84.000},
  // {"created_time":"2023-03-29","spo2":99.000,"bpm":59.000},
  // {"created_time":"2023-03-28","spo2":96.000,"bpm":70.000},
  // {"created_time":"2023-03-27","spo2":100.000,"bpm":70.000},
  // {"created_time":"2023-03-26","spo2":87.000,"bpm":80.000},
  // {"created_time":"2023-03-25","spo2":95.000,"bpm":84.000},
  // {"created_time":"2023-03-24","spo2":99.000,"bpm":78.000},
  // {"created_time":"2023-03-23","spo2":98.000,"bpm":88.000},
  // {"created_time":"2023-03-22","spo2":95.000,"bpm":83.000},
  // {"created_time":"2023-03-21","spo2":99.000,"bpm":74.000},
  // {"created_time":"2023-03-20","spo2":90.000,"bpm":100.000},
  // {"created_time":"2023-03-19","spo2":100.000,"bpm":92.000},
  // {"created_time":"2023-03-18","spo2":97.000,"bpm":70.000},
  // {"created_time":"2023-03-17","spo2":96.000,"bpm":88.000},
  // {"created_time":"2023-03-16","spo2":97.000,"bpm":68.000},
  // {"created_time":"2023-03-15","spo2":94.000,"bpm":97.000},
  // {"created_time":"2023-03-14","spo2":96.000,"bpm":70.000},
  // {"created_time":"2023-03-13","spo2":86.000,"bpm":70.000},
  // {"created_time":"2023-03-12","spo2":97.000,"bpm":76.000},
  // {"created_time":"2023-03-11","spo2":94.000,"bpm":74.000},
  // {"created_time":"2023-03-10","spo2":98.000,"bpm":118.000},
  // {"created_time":"2023-03-09","spo2":90.000,"bpm":86.000},
  // {"created_time":"2023-03-08","spo2":86.000,"bpm":115.000},
  // {"created_time":"2023-03-07","spo2":98.000,"bpm":84.000},
  // {"created_time":"2023-03-06","spo2":99.000,"bpm":88.000},
  // {"created_time":"2023-03-05","spo2":98.000,"bpm":73.000},
  // {"created_time":"2023-03-04","spo2":96.000,"bpm":91.000},
  // {"created_time":"2023-03-03","spo2":96.000,"bpm":73.000},
  // {"created_time":"2023-03-02","spo2":98.000,"bpm":61.000},
  // {"created_time":"2023-03-01","spo2":97.000,"bpm":90.000},
];

const glucData = [
  { created_time: "2024-02-28", mg_dl: 97.0 },
  { created_time: "2024-02-27", mg_dl: 93.0 },
  { created_time: "2024-02-26", mg_dl: 174.0 },
  { created_time: "2024-02-25", mg_dl: 139.0 },
  { created_time: "2024-02-24", mg_dl: 159.0 },
  { created_time: "2024-02-23", mg_dl: 131.0 },
  { created_time: "2024-02-22", mg_dl: 122.0 },
  { created_time: "2024-02-21", mg_dl: 179.0 },
  { created_time: "2024-02-20", mg_dl: 176.0 },
  { created_time: "2024-02-19", mg_dl: 195.0 },
  { created_time: "2024-02-18", mg_dl: 69.0 },
  { created_time: "2024-02-17", mg_dl: 120.0 },
  { created_time: "2024-02-16", mg_dl: 180.0 },
  { created_time: "2024-02-15", mg_dl: 138.0 },
  { created_time: "2024-02-14", mg_dl: 94.0 },
  { created_time: "2024-02-13", mg_dl: 160.0 },
  { created_time: "2024-02-12", mg_dl: 134.0 },
  { created_time: "2024-02-11", mg_dl: 134.0 },
  { created_time: "2024-02-10", mg_dl: 139.0 },
  { created_time: "2024-02-09", mg_dl: 198.0 },
  { created_time: "2024-02-08", mg_dl: 146.0 },
  { created_time: "2024-02-07", mg_dl: 119.0 },
  { created_time: "2024-02-06", mg_dl: 127.0 },
  { created_time: "2024-02-05", mg_dl: 153.0 },
  { created_time: "2024-02-04", mg_dl: 147.0 },
  { created_time: "2024-02-03", mg_dl: 132.0 },
  { created_time: "2024-02-02", mg_dl: 146.0 },
  { created_time: "2024-02-01", mg_dl: 179.0 },
  { created_time: "2024-01-31", mg_dl: 148.0 },
  { created_time: "2024-01-30", mg_dl: 207.0 },
  { created_time: "2024-01-29", mg_dl: 165.0 },
  { created_time: "2024-01-28", mg_dl: 288.0 },
  { created_time: "2024-01-27", mg_dl: 183.0 },
  { created_time: "2024-01-26", mg_dl: 143.0 },
  { created_time: "2024-01-25", mg_dl: 123.0 },
  { created_time: "2024-01-24", mg_dl: 220.0 },
  { created_time: "2024-01-23", mg_dl: 152.0 },
  { created_time: "2024-01-22", mg_dl: 153.0 },
  { created_time: "2024-01-21", mg_dl: 75.0 },
  { created_time: "2024-01-20", mg_dl: 155.0 },
  { created_time: "2024-01-19", mg_dl: 99.0 },
  { created_time: "2024-01-18", mg_dl: 113.0 },
  { created_time: "2024-01-17", mg_dl: 137.0 },
  { created_time: "2024-01-16", mg_dl: 157.0 },
  { created_time: "2024-01-15", mg_dl: 210.0 },
  { created_time: "2024-01-14", mg_dl: 155.0 },
  { created_time: "2024-01-13", mg_dl: 179.0 },
  { created_time: "2024-01-12", mg_dl: 208.0 },
  { created_time: "2024-01-11", mg_dl: 185.0 },
  { created_time: "2024-01-10", mg_dl: 134.0 },
  { created_time: "2024-01-09", mg_dl: 143.0 },
  { created_time: "2024-01-08", mg_dl: 107.0 },
  { created_time: "2024-01-07", mg_dl: 159.0 },
  { created_time: "2024-01-06", mg_dl: 172.0 },
  { created_time: "2024-01-05", mg_dl: 160.0 },
  { created_time: "2024-01-04", mg_dl: 151.0 },
  { created_time: "2024-01-03", mg_dl: 152.0 },
  { created_time: "2024-01-02", mg_dl: 89.0 },
  { created_time: "2024-01-01", mg_dl: 125.0 },
  { created_time: "2023-12-31", mg_dl: 253.0 },
  { created_time: "2023-12-30", mg_dl: 223.0 },
  { created_time: "2023-12-29", mg_dl: 265.0 },
  { created_time: "2023-12-28", mg_dl: 185.0 },
  { created_time: "2023-12-27", mg_dl: 157.0 },
  { created_time: "2023-12-26", mg_dl: 291.0 },
  { created_time: "2023-12-25", mg_dl: 97.0 },
  { created_time: "2023-12-24", mg_dl: 186.0 },
  { created_time: "2023-12-23", mg_dl: 107.0 },
  { created_time: "2023-12-22", mg_dl: 91.0 },
  { created_time: "2023-12-21", mg_dl: 81.0 },
  { created_time: "2023-12-20", mg_dl: 168.0 },
  { created_time: "2023-12-19", mg_dl: 175.0 },
  { created_time: "2023-12-18", mg_dl: 163.0 },
  { created_time: "2023-12-17", mg_dl: 147.0 },
  { created_time: "2023-12-16", mg_dl: 179.0 },
  { created_time: "2023-12-15", mg_dl: 103.0 },
  { created_time: "2023-12-14", mg_dl: 134.0 },
  { created_time: "2023-12-13", mg_dl: 166.0 },
  { created_time: "2023-12-12", mg_dl: 94.0 },
  { created_time: "2023-12-11", mg_dl: 149.0 },
  { created_time: "2023-12-10", mg_dl: 241.0 },
  { created_time: "2023-12-09", mg_dl: 217.0 },
  { created_time: "2023-12-08", mg_dl: 304.0 },
  { created_time: "2023-12-07", mg_dl: 124.0 },
  { created_time: "2023-12-06", mg_dl: 204.0 },
  { created_time: "2023-12-05", mg_dl: 107.0 },
  { created_time: "2023-12-04", mg_dl: 179.0 },
  { created_time: "2023-12-03", mg_dl: 86.0 },
  { created_time: "2023-12-02", mg_dl: 84.0 },
  { created_time: "2023-12-01", mg_dl: 102.0 },
  { created_time: "2023-11-30", mg_dl: 121.0 },
  { created_time: "2023-11-29", mg_dl: 144.0 },
  { created_time: "2023-11-28", mg_dl: 336.0 },
  { created_time: "2023-11-27", mg_dl: 101.0 },
  { created_time: "2023-11-26", mg_dl: 92.0 },
  { created_time: "2023-11-25", mg_dl: 125.0 },
  { created_time: "2023-11-24", mg_dl: 118.0 },
  { created_time: "2023-11-23", mg_dl: 228.0 },
  { created_time: "2023-11-22", mg_dl: 155.0 },
  { created_time: "2023-11-21", mg_dl: 141.0 },
  { created_time: "2023-11-20", mg_dl: 272.0 },
  { created_time: "2023-11-19", mg_dl: 137.0 },
  { created_time: "2023-11-18", mg_dl: 215.0 },
  { created_time: "2023-11-17", mg_dl: 165.0 },
  { created_time: "2023-11-16", mg_dl: 226.0 },
  { created_time: "2023-11-15", mg_dl: 158.0 },
  { created_time: "2023-11-14", mg_dl: 201.0 },
  { created_time: "2023-11-13", mg_dl: 189.0 },
  { created_time: "2023-11-12", mg_dl: 163.0 },
  { created_time: "2023-11-11", mg_dl: 149.0 },
  { created_time: "2023-11-10", mg_dl: 81.0 },
  { created_time: "2023-11-10", mg_dl: 142.0 },
  { created_time: "2023-11-09", mg_dl: 106.0 },
  { created_time: "2023-11-08", mg_dl: 118.0 },
  { created_time: "2023-11-07", mg_dl: 136.0 },
  { created_time: "2023-11-06", mg_dl: 142.0 },
  { created_time: "2023-11-05", mg_dl: 151.0 },
  { created_time: "2023-11-04", mg_dl: 155.0 },
  { created_time: "2023-11-03", mg_dl: 75.0 },
  { created_time: "2023-11-02", mg_dl: 139.0 },
  { created_time: "2023-11-01", mg_dl: 88.0 },
  { created_time: "2023-10-31", mg_dl: 108.0 },
  { created_time: "2023-10-30", mg_dl: 150.0 },
  { created_time: "2023-10-29", mg_dl: 146.0 },
  { created_time: "2023-10-28", mg_dl: 298.0 },
  { created_time: "2023-10-27", mg_dl: 122.0 },
  { created_time: "2023-10-26", mg_dl: 357.0 },
  { created_time: "2023-10-25", mg_dl: 185.0 },
  { created_time: "2023-10-24", mg_dl: 151.0 },
  { created_time: "2023-10-23", mg_dl: 182.0 },
  { created_time: "2023-10-22", mg_dl: 166.0 },
  { created_time: "2023-10-21", mg_dl: 76.0 },
  { created_time: "2023-10-20", mg_dl: 160.0 },
  { created_time: "2023-10-19", mg_dl: 134.0 },
  { created_time: "2023-10-18", mg_dl: 89.0 },
  { created_time: "2023-10-17", mg_dl: 158.0 },
  { created_time: "2023-10-16", mg_dl: 212.0 },
  { created_time: "2023-10-15", mg_dl: 113.0 },
  { created_time: "2023-10-14", mg_dl: 147.0 },
  { created_time: "2023-10-13", mg_dl: 376.0 },
  { created_time: "2023-10-12", mg_dl: 205.0 },
  { created_time: "2023-10-11", mg_dl: 200.0 },
  { created_time: "2023-10-10", mg_dl: 307.0 },
  { created_time: "2023-10-09", mg_dl: 289.0 },
  { created_time: "2023-10-08", mg_dl: 99.0 },
  { created_time: "2023-10-07", mg_dl: 74.0 },
  { created_time: "2023-10-06", mg_dl: 198.0 },
  { created_time: "2023-10-05", mg_dl: 155.0 },
  { created_time: "2023-10-04", mg_dl: 205.0 },
  { created_time: "2023-10-03", mg_dl: 229.0 },
  { created_time: "2023-10-02", mg_dl: 113.0 },
  { created_time: "2023-10-01", mg_dl: 137.0 },
  { created_time: "2023-09-30", mg_dl: 156.0 },
  { created_time: "2023-09-29", mg_dl: 110.0 },
  { created_time: "2023-09-28", mg_dl: 233.0 },
  { created_time: "2023-09-27", mg_dl: 228.0 },
  { created_time: "2023-09-26", mg_dl: 94.0 },
  { created_time: "2023-09-25", mg_dl: 255.0 },
  { created_time: "2023-09-24", mg_dl: 109.0 },
  { created_time: "2023-09-23", mg_dl: 94.0 },
  { created_time: "2023-09-22", mg_dl: 137.0 },
  { created_time: "2023-09-21", mg_dl: 139.0 },
  { created_time: "2023-09-20", mg_dl: 42.0 },
  { created_time: "2023-09-19", mg_dl: 134.0 },
  { created_time: "2023-09-18", mg_dl: 137.0 },
  { created_time: "2023-09-17", mg_dl: 199.0 },
  { created_time: "2023-09-16", mg_dl: 84.0 },
  { created_time: "2023-09-15", mg_dl: 175.0 },
  { created_time: "2023-09-14", mg_dl: 93.0 },
  { created_time: "2023-09-13", mg_dl: 195.0 },
  { created_time: "2023-09-12", mg_dl: 130.0 },
  { created_time: "2023-09-11", mg_dl: 208.0 },
  { created_time: "2023-09-10", mg_dl: 151.0 },
  { created_time: "2023-09-09", mg_dl: 102.0 },
  { created_time: "2023-09-08", mg_dl: 143.0 },
  { created_time: "2023-09-07", mg_dl: 149.0 },
  { created_time: "2023-09-06", mg_dl: 324.0 },
  { created_time: "2023-09-05", mg_dl: 241.0 },
  { created_time: "2023-09-04", mg_dl: 169.0 },
  { created_time: "2023-09-03", mg_dl: 107.0 },
  { created_time: "2023-09-02", mg_dl: 111.0 },
  { created_time: "2023-09-01", mg_dl: 196.0 },
  { created_time: "2023-08-31", mg_dl: 245.0 },
  { created_time: "2023-08-30", mg_dl: 161.0 },
  { created_time: "2023-08-29", mg_dl: 189.0 },
  { created_time: "2023-08-28", mg_dl: 169.0 },
  { created_time: "2023-08-27", mg_dl: 71.0 },
  { created_time: "2023-08-26", mg_dl: 207.0 },
  { created_time: "2023-08-25", mg_dl: 150.0 },
  { created_time: "2023-08-24", mg_dl: 170.0 },
  { created_time: "2023-08-23", mg_dl: 205.0 },
  { created_time: "2023-08-22", mg_dl: 172.0 },
  { created_time: "2023-08-21", mg_dl: 75.0 },
  { created_time: "2023-08-20", mg_dl: 93.0 },
  { created_time: "2023-08-19", mg_dl: 339.0 },
  { created_time: "2023-08-18", mg_dl: 179.0 },
  { created_time: "2023-08-17", mg_dl: 106.0 },
  { created_time: "2023-08-16", mg_dl: 239.0 },
  { created_time: "2023-08-15", mg_dl: 196.0 },
  { created_time: "2023-08-14", mg_dl: 129.0 },
  { created_time: "2023-08-13", mg_dl: 137.0 },
  { created_time: "2023-08-12", mg_dl: 143.0 },
  { created_time: "2023-08-11", mg_dl: 177.0 },
  { created_time: "2023-08-10", mg_dl: 102.0 },
  { created_time: "2023-08-09", mg_dl: 175.0 },
  { created_time: "2023-08-08", mg_dl: 94.0 },
  { created_time: "2023-08-07", mg_dl: 251.0 },
  { created_time: "2023-08-06", mg_dl: 69.0 },
  { created_time: "2023-08-05", mg_dl: 89.0 },
  { created_time: "2023-08-04", mg_dl: 229.0 },
  { created_time: "2023-08-03", mg_dl: 151.0 },
  { created_time: "2023-08-02", mg_dl: 67.0 },
  // { created_time: "2023-08-01", mg_dl: 311.0 },
  // { created_time: "2023-07-31", mg_dl: 233.0 },
  // { created_time: "2023-07-30", mg_dl: 139.0 },
  // { created_time: "2023-07-29", mg_dl: 158.0 },
  // { created_time: "2023-07-28", mg_dl: 152.0 },
  // { created_time: "2023-07-27", mg_dl: 148.0 },
  // { created_time: "2023-07-26", mg_dl: 171.0 },
  // { created_time: "2023-07-25", mg_dl: 138.0 },
  // { created_time: "2023-07-24", mg_dl: 112.0 },
  // { created_time: "2023-07-23", mg_dl: 139.0 },
  // { created_time: "2023-07-22", mg_dl: 133.0 },
  // { created_time: "2023-07-21", mg_dl: 208.0 },
  // { created_time: "2023-07-20", mg_dl: 159.0 },
  // { created_time: "2023-07-19", mg_dl: 135.0 },
  // { created_time: "2023-07-18", mg_dl: 136.0 },
  // { created_time: "2023-07-17", mg_dl: 250.0 },
  // { created_time: "2023-07-16", mg_dl: 110.0 },
  // { created_time: "2023-07-15", mg_dl: 97.0 },
  // { created_time: "2023-07-14", mg_dl: 105.0 },
  // { created_time: "2023-07-13", mg_dl: 160.0 },
  // { created_time: "2023-07-12", mg_dl: 58.0 },
  // { created_time: "2023-07-11", mg_dl: 131.0 },
  // { created_time: "2023-07-10", mg_dl: 226.0 },
  // { created_time: "2023-07-09", mg_dl: 108.0 },
  // { created_time: "2023-07-08", mg_dl: 211.0 },
  // { created_time: "2023-07-07", mg_dl: 107.0 },
  // { created_time: "2023-07-06", mg_dl: 97.0 },
  // { created_time: "2023-07-05", mg_dl: 140.0 },
  // { created_time: "2023-07-04", mg_dl: 98.0 },
  // { created_time: "2023-07-03", mg_dl: 94.0 },
  // { created_time: "2023-07-02", mg_dl: 154.0 },
  // { created_time: "2023-07-01", mg_dl: 159.0 },
  // { created_time: "2023-06-30", mg_dl: 182.0 },
  // { created_time: "2023-06-29", mg_dl: 105.0 },
  // { created_time: "2023-06-28", mg_dl: 154.0 },
  // { created_time: "2023-06-27", mg_dl: 254.0 },
  // { created_time: "2023-06-26", mg_dl: 59.0 },
  // { created_time: "2023-06-25", mg_dl: 52.0 },
  // { created_time: "2023-06-24", mg_dl: 150.0 },
  // { created_time: "2023-06-23", mg_dl: 156.0 },
  // { created_time: "2023-06-22", mg_dl: 175.0 },
  // { created_time: "2023-06-21", mg_dl: 98.0 },
  // { created_time: "2023-06-20", mg_dl: 116.0 },
  // { created_time: "2023-06-19", mg_dl: 55.0 },
  // { created_time: "2023-06-18", mg_dl: 140.0 },
  // { created_time: "2023-06-17", mg_dl: 36.0 },
  // { created_time: "2023-06-16", mg_dl: 143.0 },
  // { created_time: "2023-06-15", mg_dl: 236.0 },
  // { created_time: "2023-06-14", mg_dl: 118.0 },
  // { created_time: "2023-06-13", mg_dl: 92.0 },
  // { created_time: "2023-06-12", mg_dl: 101.0 },
  // { created_time: "2023-06-11", mg_dl: 125.0 },
  // { created_time: "2023-06-10", mg_dl: 166.0 },
  // { created_time: "2023-06-09", mg_dl: 31.0 },
  // { created_time: "2023-06-08", mg_dl: 81.0 },
  // { created_time: "2023-06-07", mg_dl: 121.0 },
  // { created_time: "2023-06-06", mg_dl: 92.0 },
  // { created_time: "2023-06-05", mg_dl: 159.0 },
  // { created_time: "2023-06-04", mg_dl: 52.0 },
  // { created_time: "2023-06-03", mg_dl: 78.0 },
  // { created_time: "2023-06-02", mg_dl: 125.0 },
  // { created_time: "2023-06-01", mg_dl: 127.0 },
  // { created_time: "2023-05-31", mg_dl: 115.0 },
  // { created_time: "2023-05-30", mg_dl: 144.0 },
  // { created_time: "2023-05-29", mg_dl: 101.0 },
  // { created_time: "2023-05-28", mg_dl: 115.0 },
  // { created_time: "2023-05-27", mg_dl: 131.0 },
  // { created_time: "2023-05-26", mg_dl: 140.0 },
  // { created_time: "2023-05-25", mg_dl: 56.0 },
  // { created_time: "2023-05-24", mg_dl: 60.0 },
  // { created_time: "2023-05-23", mg_dl: 125.0 },
  // { created_time: "2023-05-22", mg_dl: 43.0 },
  // { created_time: "2023-05-21", mg_dl: 98.0 },
  // { created_time: "2023-05-20", mg_dl: 139.0 },
  // { created_time: "2023-05-19", mg_dl: 139.0 },
  // { created_time: "2023-05-18", mg_dl: 119.0 },
  // { created_time: "2023-05-17", mg_dl: 89.0 },
  // { created_time: "2023-05-16", mg_dl: 101.0 },
  // { created_time: "2023-05-15", mg_dl: 73.0 },
  // { created_time: "2023-05-14", mg_dl: 67.0 },
  // { created_time: "2023-05-13", mg_dl: 145.0 },
  // { created_time: "2023-05-12", mg_dl: 115.0 },
  // { created_time: "2023-05-11", mg_dl: 56.0 },
  // { created_time: "2023-05-10", mg_dl: 75.0 },
  // { created_time: "2023-05-09", mg_dl: 122.0 },
  // { created_time: "2023-05-08", mg_dl: 142.0 },
  // { created_time: "2023-05-07", mg_dl: 65.0 },
  // { created_time: "2023-05-06", mg_dl: 247.0 },
  // { created_time: "2023-05-05", mg_dl: 137.0 },
  // { created_time: "2023-05-04", mg_dl: 216.0 },
  // { created_time: "2023-05-03", mg_dl: 59.0 },
  // { created_time: "2023-05-02", mg_dl: 153.0 },
  // { created_time: "2023-05-01", mg_dl: 87.0 },
  // {"created_time":"2023-04-30","mg_dl":196.000},
  // {"created_time":"2023-04-29","mg_dl":221.000},
  // {"created_time":"2023-04-28","mg_dl":142.000},
  // {"created_time":"2023-04-27","mg_dl":207.000},
  // {"created_time":"2023-04-26","mg_dl":138.000},
  // {"created_time":"2023-04-25","mg_dl":91.000},
  // {"created_time":"2023-04-24","mg_dl":154.000},
  // {"created_time":"2023-04-23","mg_dl":105.000},
  // {"created_time":"2023-04-22","mg_dl":102.000},
  // {"created_time":"2023-04-21","mg_dl":186.000},
  // {"created_time":"2023-04-20","mg_dl":121.000},
  // {"created_time":"2023-04-19","mg_dl":208.000},
  // {"created_time":"2023-04-18","mg_dl":34.000},
  // {"created_time":"2023-04-17","mg_dl":92.000},
  // {"created_time":"2023-04-16","mg_dl":121.000},
  // {"created_time":"2023-04-15","mg_dl":109.000},
  // {"created_time":"2023-04-14","mg_dl":142.000},
  // {"created_time":"2023-04-13","mg_dl":133.000},
  // {"created_time":"2023-04-12","mg_dl":146.000},
  // {"created_time":"2023-04-11","mg_dl":115.000},
  // {"created_time":"2023-04-10","mg_dl":178.000},
  // {"created_time":"2023-04-09","mg_dl":119.000},
  // {"created_time":"2023-04-08","mg_dl":307.000},
  // {"created_time":"2023-04-07","mg_dl":129.000},
  // {"created_time":"2023-04-06","mg_dl":152.000},
  // {"created_time":"2023-04-05","mg_dl":179.000},
  // {"created_time":"2023-04-04","mg_dl":107.000},
  // {"created_time":"2023-04-03","mg_dl":202.000},
  // {"created_time":"2023-04-02","mg_dl":157.000},
  // {"created_time":"2023-04-01","mg_dl":131.000},
  // {"created_time":"2023-03-31","mg_dl":116.000},
  // {"created_time":"2023-03-30","mg_dl":144.000},
  // {"created_time":"2023-03-29","mg_dl":140.000},
  // {"created_time":"2023-03-28","mg_dl":107.000},
  // {"created_time":"2023-03-27","mg_dl":171.000},
  // {"created_time":"2023-03-26","mg_dl":125.000},
  // {"created_time":"2023-03-25","mg_dl":136.000},
  // {"created_time":"2023-03-24","mg_dl":166.000},
  // {"created_time":"2023-03-23","mg_dl":101.000},
  // {"created_time":"2023-03-22","mg_dl":99.000},
  // {"created_time":"2023-03-21","mg_dl":132.000},
  // {"created_time":"2023-03-20","mg_dl":163.000},
  // {"created_time":"2023-03-19","mg_dl":192.000},
  // {"created_time":"2023-03-18","mg_dl":79.000},
  // {"created_time":"2023-03-17","mg_dl":56.000},
  // {"created_time":"2023-03-16","mg_dl":46.000},
  // {"created_time":"2023-03-15","mg_dl":92.000},
  // {"created_time":"2023-03-14","mg_dl":89.000},
  // {"created_time":"2023-03-13","mg_dl":89.000},
  // {"created_time":"2023-03-12","mg_dl":40.000},
  // {"created_time":"2023-03-11","mg_dl":119.000},
  // {"created_time":"2023-03-10","mg_dl":143.000},
  // {"created_time":"2023-03-09","mg_dl":53.000},
  // {"created_time":"2023-03-08","mg_dl":111.000},
  // {"created_time":"2023-03-07","mg_dl":183.000},
  // {"created_time":"2023-03-06","mg_dl":154.000},
  // {"created_time":"2023-03-05","mg_dl":77.000},
  // {"created_time":"2023-03-04","mg_dl":68.000},
  // {"created_time":"2023-03-03","mg_dl":139.000},
  // {"created_time":"2023-03-02","mg_dl":98.000},
  // {"created_time":"2023-03-01","mg_dl":140.000},
];

const spiroData = [
  {
    created_time: "2024-02-28",
    fvc: null,
    fev1: 2.16,
    pef: 186.0,
    fef2575: 1.66,
    fev1fev6: 0.69,
    fev6: 3.12,
  },
  {
    created_time: "2024-02-25",
    fvc: null,
    fev1: 4.29,
    pef: 567.0,
    fef2575: null,
    fev1fev6: null,
    fev6: null,
  },
  {
    created_time: "2024-02-21",
    fvc: 0.9,
    fev1: 2.43,
    pef: 173.4,
    fef2575: null,
    fev1fev6: null,
    fev6: null,
  },
  {
    created_time: "2024-02-19",
    fvc: 0.0,
    fev1: 0.19,
    pef: 28.2,
    fef2575: null,
    fev1fev6: null,
    fev6: null,
  },
  {
    created_time: "2024-02-16",
    fvc: 0.87,
    fev1: 0.35,
    pef: 22.2,
    fef2575: null,
    fev1fev6: null,
    fev6: null,
  },
  {
    created_time: "2024-02-15",
    fvc: 4.57,
    fev1: 4.57,
    pef: 0.0,
    fef2575: null,
    fev1fev6: null,
    fev6: 4.57,
  },
  {
    created_time: "2024-02-11",
    fvc: 3.64,
    fev1: 3.02,
    pef: 401.4,
    fef2575: null,
    fev1fev6: null,
    fev6: null,
  },
  {
    created_time: "2024-02-10",
    fvc: 3.33,
    fev1: 3.13,
    pef: 483.0,
    fef2575: null,
    fev1fev6: null,
    fev6: null,
  },
  {
    created_time: "2024-02-07",
    fvc: 0.53,
    fev1: 2.11,
    pef: 241.8,
    fef2575: null,
    fev1fev6: null,
    fev6: null,
  },
  {
    created_time: "2024-02-03",
    fvc: 2.02,
    fev1: 2.87,
    pef: 213.6,
    fef2575: null,
    fev1fev6: null,
    fev6: null,
  },
  {
    created_time: "2024-02-02",
    fvc: 1.82,
    fev1: 2.68,
    pef: 219.0,
    fef2575: null,
    fev1fev6: null,
    fev6: null,
  },
  {
    created_time: "2024-01-26",
    fvc: 0.78,
    fev1: 2.25,
    pef: 161.4,
    fef2575: null,
    fev1fev6: null,
    fev6: null,
  },
  {
    created_time: "2024-01-25",
    fvc: null,
    fev1: 1.23,
    pef: 148.2,
    fef2575: null,
    fev1fev6: null,
    fev6: null,
  },
  {
    created_time: "2024-01-22",
    fvc: 0.76,
    fev1: 2.18,
    pef: 223.8,
    fef2575: null,
    fev1fev6: null,
    fev6: null,
  },
  {
    created_time: "2024-01-20",
    fvc: 2.95,
    fev1: 2.88,
    pef: 408.6,
    fef2575: null,
    fev1fev6: null,
    fev6: null,
  },
  {
    created_time: "2024-01-19",
    fvc: 2.52,
    fev1: 3.28,
    pef: 228.0,
    fef2575: null,
    fev1fev6: null,
    fev6: null,
  },
  {
    created_time: "2024-01-18",
    fvc: 1.45,
    fev1: 2.23,
    pef: 162.0,
    fef2575: null,
    fev1fev6: null,
    fev6: null,
  },
  {
    created_time: "2024-01-17",
    fvc: 0.46,
    fev1: 2.41,
    pef: 258.6,
    fef2575: null,
    fev1fev6: null,
    fev6: null,
  },
  {
    created_time: "2024-01-15",
    fvc: 0.08,
    fev1: 2.41,
    pef: 255.0,
    fef2575: null,
    fev1fev6: null,
    fev6: null,
  },
  {
    created_time: "2024-01-13",
    fvc: null,
    fev1: 1.39,
    pef: 122.0,
    fef2575: 1.01,
    fev1fev6: 0.24,
    fev6: 5.69,
  },
  {
    created_time: "2024-01-12",
    fvc: 0.87,
    fev1: 1.7,
    pef: null,
    fef2575: null,
    fev1fev6: null,
    fev6: null,
  },
  {
    created_time: "2024-01-10",
    fvc: null,
    fev1: 2.36,
    pef: 220.0,
    fef2575: 2.18,
    fev1fev6: 0.83,
    fev6: 2.85,
  },
  {
    created_time: "2024-01-07",
    fvc: null,
    fev1: 0.76,
    pef: 45.0,
    fef2575: 0.65,
    fev1fev6: null,
    fev6: null,
  },
  {
    created_time: "2024-01-06",
    fvc: null,
    fev1: 2.26,
    pef: 175.0,
    fef2575: 1.89,
    fev1fev6: null,
    fev6: null,
  },
  {
    created_time: "2024-01-05",
    fvc: 4.36,
    fev1: 4.36,
    pef: 0.0,
    fef2575: null,
    fev1fev6: null,
    fev6: null,
  },
  {
    created_time: "2024-01-04",
    fvc: 2.26,
    fev1: 2.26,
    pef: 0.0,
    fef2575: null,
    fev1fev6: null,
    fev6: null,
  },
  {
    created_time: "2023-12-29",
    fvc: 0.16,
    fev1: 0.13,
    pef: 7.8,
    fef2575: null,
    fev1fev6: null,
    fev6: null,
  },
  {
    created_time: "2023-12-22",
    fvc: null,
    fev1: 3.12,
    pef: 285.0,
    fef2575: 3.31,
    fev1fev6: null,
    fev6: null,
  },
  {
    created_time: "2023-12-21",
    fvc: null,
    fev1: 2.64,
    pef: 342.0,
    fef2575: 3.11,
    fev1fev6: null,
    fev6: null,
  },
  {
    created_time: "2023-12-16",
    fvc: null,
    fev1: 2.07,
    pef: 268.0,
    fef2575: 2.18,
    fev1fev6: null,
    fev6: null,
  },
  {
    created_time: "2023-12-15",
    fvc: 0.19,
    fev1: 0.07,
    pef: null,
    fef2575: null,
    fev1fev6: null,
    fev6: null,
  },
  {
    created_time: "2023-12-14",
    fvc: 0.0,
    fev1: 0.0,
    pef: null,
    fef2575: null,
    fev1fev6: null,
    fev6: null,
  },
  {
    created_time: "2023-12-09",
    fvc: 0.0,
    fev1: 0.0,
    pef: null,
    fef2575: null,
    fev1fev6: null,
    fev6: null,
  },
  {
    created_time: "2023-12-08",
    fvc: null,
    fev1: 3.38,
    pef: 577.2,
    fef2575: null,
    fev1fev6: null,
    fev6: null,
  },
  {
    created_time: "2023-12-07",
    fvc: 4.85,
    fev1: 4.79,
    pef: 547.2,
    fef2575: null,
    fev1fev6: null,
    fev6: null,
  },
  {
    created_time: "2023-12-06",
    fvc: 1.64,
    fev1: 3.01,
    pef: null,
    fef2575: null,
    fev1fev6: null,
    fev6: null,
  },
  {
    created_time: "2023-12-01",
    fvc: 1.06,
    fev1: 2.34,
    pef: null,
    fef2575: null,
    fev1fev6: null,
    fev6: null,
  },
  {
    created_time: "2023-11-30",
    fvc: 0.55,
    fev1: 3.13,
    pef: null,
    fef2575: null,
    fev1fev6: null,
    fev6: null,
  },
  {
    created_time: "2023-11-29",
    fvc: null,
    fev1: 2.4,
    pef: 250.0,
    fef2575: null,
    fev1fev6: null,
    fev6: null,
  },
  {
    created_time: "2023-11-24",
    fvc: null,
    fev1: 2.58,
    pef: 256.8,
    fef2575: null,
    fev1fev6: null,
    fev6: null,
  },
  {
    created_time: "2023-11-22",
    fvc: null,
    fev1: 3.38,
    pef: 576.6,
    fef2575: null,
    fev1fev6: null,
    fev6: null,
  },
  {
    created_time: "2023-11-18",
    fvc: null,
    fev1: 2.91,
    pef: 361.0,
    fef2575: null,
    fev1fev6: null,
    fev6: null,
  },
  {
    created_time: "2023-11-17",
    fvc: 3.73,
    fev1: 3.73,
    pef: 642.6,
    fef2575: null,
    fev1fev6: null,
    fev6: null,
  },
  {
    created_time: "2023-10-19",
    fvc: null,
    fev1: 6.42,
    pef: 922.0,
    fef2575: 1.2,
    fev1fev6: 0.04,
    fev6: 6.41,
  },
  {
    created_time: "2023-09-28",
    fvc: 3.851,
    fev1: 3.741,
    pef: 402.0,
    fef2575: 6.351,
    fev1fev6: 0.97,
    fev6: 3.851,
  },
  {
    created_time: "2023-09-20",
    fvc: 4.581,
    fev1: 4.331,
    pef: 391.0,
    fef2575: 5.821,
    fev1fev6: 0.94,
    fev6: 4.581,
  },
  {
    created_time: "2023-09-18",
    fvc: null,
    fev1: 3.44,
    pef: 86.0,
    fef2575: 4.22,
    fev1fev6: 0.89,
    fev6: 3.88,
  },
  {
    created_time: "2023-09-08",
    fvc: 0.83,
    fev1: 3.02,
    pef: 280.2,
    fef2575: null,
    fev1fev6: null,
    fev6: null,
  },
  {
    created_time: "2023-09-02",
    fvc: 2.01,
    fev1: 2.92,
    pef: 214.2,
    fef2575: null,
    fev1fev6: null,
    fev6: null,
  },
  {
    created_time: "2023-09-01",
    fvc: 3.06,
    fev1: 3.04,
    pef: 218.4,
    fef2575: null,
    fev1fev6: null,
    fev6: null,
  },
  {
    created_time: "2023-08-27",
    fvc: 0.89,
    fev1: 0.81,
    pef: null,
    fef2575: null,
    fev1fev6: null,
    fev6: null,
  },
  {
    created_time: "2023-08-25",
    fvc: 4.35,
    fev1: 2.42,
    pef: 361.0,
    fef2575: 0.56,
    fev1fev6: 0.55,
    fev6: 4.35,
  },
  {
    created_time: "2023-08-24",
    fvc: 0.0,
    fev1: 1.82,
    pef: 168.0,
    fef2575: null,
    fev1fev6: null,
    fev6: null,
  },
  {
    created_time: "2023-08-21",
    fvc: 1.04,
    fev1: 2.06,
    pef: null,
    fef2575: null,
    fev1fev6: null,
    fev6: null,
  },
  {
    created_time: "2023-08-18",
    fvc: 1.41,
    fev1: 3.16,
    pef: 255.0,
    fef2575: null,
    fev1fev6: null,
    fev6: null,
  },
  {
    created_time: "2023-08-11",
    fvc: null,
    fev1: 3.131,
    pef: 329.0,
    fef2575: null,
    fev1fev6: null,
    fev6: null,
  },
  {
    created_time: "2023-08-08",
    fvc: 3.91,
    fev1: 3.64,
    pef: 234.0,
    fef2575: 0.55,
    fev1fev6: 0.92,
    fev6: 3.91,
  },
  {
    created_time: "2023-08-05",
    fvc: null,
    fev1: 3.55,
    pef: 403.0,
    fef2575: null,
    fev1fev6: null,
    fev6: null,
  },
  {
    created_time: "2023-08-04",
    fvc: null,
    fev1: 3.971,
    pef: 616.0,
    fef2575: null,
    fev1fev6: null,
    fev6: null,
  },
  {
    created_time: "2023-08-03",
    fvc: 3.741,
    fev1: 3.741,
    pef: 576.0,
    fef2575: 0.001,
    fev1fev6: 0.0,
    fev6: 0.001,
  },
  {
    created_time: "2023-08-02",
    fvc: null,
    fev1: 4.67,
    pef: 0.0,
    fef2575: null,
    fev1fev6: null,
    fev6: null,
  },
  // {
  //   created_time: "2023-07-27",
  //   fvc: 0.96,
  //   fev1: 2.68,
  //   pef: 184.8,
  //   fef2575: null,
  //   fev1fev6: null,
  //   fev6: null,
  // },
  // {
  //   created_time: "2023-07-26",
  //   fvc: null,
  //   fev1: 2.89,
  //   pef: 457.0,
  //   fef2575: 3.31,
  //   fev1fev6: 0.87,
  //   fev6: 3.33,
  // },
  // {
  //   created_time: "2023-07-19",
  //   fvc: 0.86,
  //   fev1: 2.53,
  //   pef: 222.6,
  //   fef2575: null,
  //   fev1fev6: null,
  //   fev6: null,
  // },
  // {
  //   created_time: "2023-07-18",
  //   fvc: 2.25,
  //   fev1: 3.31,
  //   pef: 253.8,
  //   fef2575: null,
  //   fev1fev6: null,
  //   fev6: null,
  // },
  // {
  //   created_time: "2023-07-15",
  //   fvc: 1.4,
  //   fev1: 2.4,
  //   pef: 148.2,
  //   fef2575: null,
  //   fev1fev6: null,
  //   fev6: null,
  // },
  // {
  //   created_time: "2023-07-12",
  //   fvc: 0.8,
  //   fev1: 1.91,
  //   pef: 148.2,
  //   fef2575: null,
  //   fev1fev6: null,
  //   fev6: null,
  // },
  // {
  //   created_time: "2023-07-11",
  //   fvc: 1.63,
  //   fev1: 2.03,
  //   pef: 168.6,
  //   fef2575: null,
  //   fev1fev6: null,
  //   fev6: null,
  // },
  // {
  //   created_time: "2023-07-08",
  //   fvc: null,
  //   fev1: 3.791,
  //   pef: 532.0,
  //   fef2575: null,
  //   fev1fev6: null,
  //   fev6: null,
  // },
  // {
  //   created_time: "2023-07-07",
  //   fvc: null,
  //   fev1: 2.871,
  //   pef: 226.0,
  //   fef2575: 3.251,
  //   fev1fev6: 0.96,
  //   fev6: 2.981,
  // },
  // {
  //   created_time: "2023-06-30",
  //   fvc: null,
  //   fev1: 2.771,
  //   pef: 277.0,
  //   fef2575: 3.281,
  //   fev1fev6: 0.92,
  //   fev6: 3.001,
  // },
  // {
  //   created_time: "2023-06-29",
  //   fvc: null,
  //   fev1: 0.001,
  //   pef: 260.0,
  //   fef2575: 3.731,
  //   fev1fev6: 0.0,
  //   fev6: 2.391,
  // },
  // {
  //   created_time: "2023-06-28",
  //   fvc: null,
  //   fev1: 3.021,
  //   pef: 226.0,
  //   fef2575: 3.321,
  //   fev1fev6: 0.94,
  //   fev6: 3.201,
  // },
  // {
  //   created_time: "2023-06-25",
  //   fvc: 4.79,
  //   fev1: 4.54,
  //   pef: 455.0,
  //   fef2575: 6.62,
  //   fev1fev6: 0.94,
  //   fev6: 4.79,
  // },
  // {
  //   created_time: "2023-06-23",
  //   fvc: null,
  //   fev1: 2.5,
  //   pef: 297.0,
  //   fef2575: 2.66,
  //   fev1fev6: 0.91,
  //   fev6: 2.75,
  // },
  // {
  //   created_time: "2023-06-22",
  //   fvc: null,
  //   fev1: 3.15,
  //   pef: 296.0,
  //   fef2575: 3.73,
  //   fev1fev6: 0.96,
  //   fev6: 3.28,
  // },
  // {
  //   created_time: "2023-06-21",
  //   fvc: null,
  //   fev1: 3.13,
  //   pef: 216.0,
  //   fef2575: 3.32,
  //   fev1fev6: 0.95,
  //   fev6: 3.29,
  // },
  // {
  //   created_time: "2023-06-20",
  //   fvc: null,
  //   fev1: 5.0,
  //   pef: 468.0,
  //   fef2575: null,
  //   fev1fev6: null,
  //   fev6: null,
  // },
  // {
  //   created_time: "2023-06-17",
  //   fvc: null,
  //   fev1: 3.21,
  //   pef: 210.0,
  //   fef2575: 3.31,
  //   fev1fev6: 0.94,
  //   fev6: 3.41,
  // },
  // {
  //   created_time: "2023-06-13",
  //   fvc: null,
  //   fev1: 4.75,
  //   pef: 529.0,
  //   fef2575: null,
  //   fev1fev6: null,
  //   fev6: null,
  // },
  // {
  //   created_time: "2023-06-09",
  //   fvc: null,
  //   fev1: 2.8,
  //   pef: 205.0,
  //   fef2575: 2.83,
  //   fev1fev6: 0.87,
  //   fev6: 3.22,
  // },
  // {
  //   created_time: "2023-06-08",
  //   fvc: null,
  //   fev1: 2.65,
  //   pef: 224.0,
  //   fef2575: 3.4,
  //   fev1fev6: 0.99,
  //   fev6: 2.67,
  // },
  // {
  //   created_time: "2023-06-06",
  //   fvc: null,
  //   fev1: 2.63,
  //   pef: 246.0,
  //   fef2575: 3.33,
  //   fev1fev6: 0.99,
  //   fev6: 2.67,
  // },
  // {
  //   created_time: "2023-06-04",
  //   fvc: null,
  //   fev1: 3.08,
  //   pef: 258.0,
  //   fef2575: 3.81,
  //   fev1fev6: 0.99,
  //   fev6: 3.1,
  // },
  // {
  //   created_time: "2023-06-03",
  //   fvc: null,
  //   fev1: 3.13,
  //   pef: 440.0,
  //   fef2575: 5.05,
  //   fev1fev6: 1.0,
  //   fev6: 3.14,
  // },
  // {
  //   created_time: "2023-06-02",
  //   fvc: null,
  //   fev1: 0.8,
  //   pef: 330.0,
  //   fef2575: 3.28,
  //   fev1fev6: 0.89,
  //   fev6: 0.88,
  // },
  // {
  //   created_time: "2023-06-01",
  //   fvc: null,
  //   fev1: 2.54,
  //   pef: 227.0,
  //   fef2575: 3.02,
  //   fev1fev6: 0.93,
  //   fev6: 2.72,
  // },
  // {
  //   created_time: "2023-05-31",
  //   fvc: null,
  //   fev1: 0.34,
  //   pef: 23.0,
  //   fef2575: 0.54,
  //   fev1fev6: 0.17,
  //   fev6: 2.04,
  // },
  // {
  //   created_time: "2023-05-28",
  //   fvc: 5.55,
  //   fev1: 5.44,
  //   pef: 552.0,
  //   fef2575: 7.92,
  //   fev1fev6: 0.98,
  //   fev6: 5.55,
  // },
  // {
  //   created_time: "2023-05-27",
  //   fvc: null,
  //   fev1: 0.93,
  //   pef: 178.0,
  //   fef2575: 1.76,
  //   fev1fev6: 1.0,
  //   fev6: 0.93,
  // },
  // {
  //   created_time: "2023-05-26",
  //   fvc: null,
  //   fev1: 2.51,
  //   pef: 446.0,
  //   fef2575: 4.45,
  //   fev1fev6: 1.0,
  //   fev6: 2.51,
  // },
  // {
  //   created_time: "2023-05-24",
  //   fvc: 2.12,
  //   fev1: 3.11,
  //   pef: 578.4,
  //   fef2575: null,
  //   fev1fev6: null,
  //   fev6: null,
  // },
  // {
  //   created_time: "2023-05-23",
  //   fvc: null,
  //   fev1: 1.0,
  //   pef: 199.0,
  //   fef2575: 2.8,
  //   fev1fev6: 1.0,
  //   fev6: 1.0,
  // },
  // {
  //   created_time: "2023-05-19",
  //   fvc: null,
  //   fev1: 3.05,
  //   pef: 218.0,
  //   fef2575: 2.92,
  //   fev1fev6: 0.79,
  //   fev6: 3.86,
  // },
  // {
  //   created_time: "2023-05-17",
  //   fvc: 1.62,
  //   fev1: 3.03,
  //   pef: 539.4,
  //   fef2575: null,
  //   fev1fev6: null,
  //   fev6: null,
  // },
  // {
  //   created_time: "2023-05-13",
  //   fvc: 2.14,
  //   fev1: 3.06,
  //   pef: 585.0,
  //   fef2575: null,
  //   fev1fev6: null,
  //   fev6: null,
  // },
  // {
  //   created_time: "2023-05-12",
  //   fvc: 2.23,
  //   fev1: 2.47,
  //   pef: 149.4,
  //   fef2575: null,
  //   fev1fev6: null,
  //   fev6: null,
  // },
  // {
  //   created_time: "2023-05-11",
  //   fvc: null,
  //   fev1: 4.63,
  //   pef: 454.0,
  //   fef2575: null,
  //   fev1fev6: null,
  //   fev6: null,
  // },
  // {
  //   created_time: "2023-05-09",
  //   fvc: null,
  //   fev1: 5.28,
  //   pef: 457.0,
  //   fef2575: null,
  //   fev1fev6: null,
  //   fev6: null,
  // },
  // {
  //   created_time: "2023-05-04",
  //   fvc: null,
  //   fev1: 4.67,
  //   pef: 462.0,
  //   fef2575: null,
  //   fev1fev6: null,
  //   fev6: null,
  // },
  // {
  //   created_time: "2023-05-03",
  //   fvc: 0.2,
  //   fev1: 0.53,
  //   pef: 157.2,
  //   fef2575: null,
  //   fev1fev6: null,
  //   fev6: null,
  // },
  // {
  //   created_time: "2023-05-02",
  //   fvc: null,
  //   fev1: 2.22,
  //   pef: 180.0,
  //   fef2575: 2.12,
  //   fev1fev6: 0.88,
  //   fev6: 2.53,
  // },
  // {
  //   created_time: "2023-04-29",
  //   fvc: null,
  //   fev1: 1.12,
  //   pef: 0.0,
  //   fef2575: 0.92,
  //   fev1fev6: 0.54,
  //   fev6: 2.08,
  // },
  // {
  //   created_time: "2023-04-28",
  //   fvc: 4.5,
  //   fev1: 4.42,
  //   pef: 545.0,
  //   fef2575: 8.01,
  //   fev1fev6: 0.98,
  //   fev6: 4.5,
  // },
  // {
  //   created_time: "2023-04-26",
  //   fvc: null,
  //   fev1: 2.29,
  //   pef: 279.0,
  //   fef2575: 3.57,
  //   fev1fev6: 0.99,
  //   fev6: 2.31,
  // },
  // {
  //   created_time: "2023-04-25",
  //   fvc: null,
  //   fev1: 3.19,
  //   pef: 347.0,
  //   fef2575: 3.95,
  //   fev1fev6: 0.96,
  //   fev6: 3.32,
  // },
  // {
  //   created_time: "2023-04-21",
  //   fvc: 4.94,
  //   fev1: 4.94,
  //   pef: 535.0,
  //   fef2575: 8.22,
  //   fev1fev6: 1.0,
  //   fev6: 4.94,
  // },
  // {
  //   created_time: "2023-04-20",
  //   fvc: 2.04,
  //   fev1: 2.64,
  //   pef: 164.4,
  //   fef2575: null,
  //   fev1fev6: null,
  //   fev6: null,
  // },
  // {
  //   created_time: "2023-04-12",
  //   fvc: null,
  //   fev1: 2.91,
  //   pef: 235.0,
  //   fef2575: 2.98,
  //   fev1fev6: 0.97,
  //   fev6: 3.47,
  // },
  // {
  //   created_time: "2023-04-11",
  //   fvc: null,
  //   fev1: 4.66,
  //   pef: 471.0,
  //   fef2575: null,
  //   fev1fev6: null,
  //   fev6: null,
  // },
  // {
  //   created_time: "2023-04-09",
  //   fvc: null,
  //   fev1: 2.66,
  //   pef: 332.0,
  //   fef2575: 4.83,
  //   fev1fev6: 1.0,
  //   fev6: 2.67,
  // },
  // {
  //   created_time: "2023-04-08",
  //   fvc: null,
  //   fev1: 4.08,
  //   pef: 498.6,
  //   fef2575: null,
  //   fev1fev6: null,
  //   fev6: null,
  // },
  // {
  //   created_time: "2023-04-07",
  //   fvc: null,
  //   fev1: 3.07,
  //   pef: 605.0,
  //   fef2575: 9.45,
  //   fev1fev6: null,
  //   fev6: null,
  // },
  // {"created_time":"2023-03-31","fvc":0.000,fev1:0.000,pef:18.000,fef2575:null,fev1fev6:null,fev6:null},
  // {"created_time":"2023-03-29","fvc":0.870,fev1:1.700,pef:null,fef2575:null,fev1fev6:null,fev6:null},
  // {"created_time":"2023-03-28","fvc":0.080,fev1:1.420,pef:284.400,fef2575:null,fev1fev6:null,fev6:null},
  // {"created_time":"2023-03-25","fvc":4.330,fev1:4.330,pef:0.000,fef2575:null,fev1fev6:null,fev6:4.330},
  // {"created_time":"2023-03-21","fvc":0.860,fev1:2.240,pef:180.600,fef2575:null,fev1fev6:null,fev6:null},
  // {"created_time":"2023-03-17","fvc":0.870,fev1:1.700,pef:null,fef2575:null,fev1fev6:null,fev6:null},
  // {"created_time":"2023-03-16","fvc":0.960,fev1:2.710,pef:408.000,fef2575:null,fev1fev6:null,fev6:null},
];

const wearableData = [
  // {created_time: "2024-03-24", steps: 919, bpm: null},
  // {created_time: "2024-03-23", steps: null, bpm: 61.00},
  // {created_time: "2024-03-22", steps: 68, bpm: null},
  // {created_time: "2024-03-21", steps: null, bpm: 83.00},
  { created_time: "2024-01-28", steps: 1077, bpm: 58.0 },
  { created_time: "2024-01-14", steps: null, bpm: 74.0 },
  { created_time: "2024-01-03", steps: 866, bpm: null },
  { created_time: "2023-12-10", steps: 3045, bpm: 69.0 },
  // { created_time: "2023-11-09", steps: null, bpm: 69.0 },
  // { created_time: "2023-11-08", steps: 654, bpm: null },
  // { created_time: "2023-11-07", steps: 1121, bpm: null },
  // { created_time: "2023-11-06", steps: null, bpm: 52.0 },
  // { created_time: "2023-11-05", steps: null, bpm: 62.0 },
  // { created_time: "2023-11-04", steps: null, bpm: 74.0 },
  // { created_time: "2023-11-03", steps: null, bpm: 64.0 },
  // { created_time: "2023-11-02", steps: null, bpm: 72.0 },
  { created_time: "2023-11-01", steps: null, bpm: 63.0 },
  { created_time: "2023-10-31", steps: null, bpm: 70.0 },
  { created_time: "2023-10-30", steps: null, bpm: 67.0 },
  { created_time: "2023-10-29", steps: 5294, bpm: null },
  { created_time: "2023-10-28", steps: null, bpm: 71.0 },
  { created_time: "2023-10-27", steps: null, bpm: 59.0 },
  { created_time: "2023-10-07", steps: 21, bpm: null },
  { created_time: "2023-10-06", steps: 2175, bpm: null },
  { created_time: "2023-10-05", steps: null, bpm: 76.0 },
  { created_time: "2023-10-03", steps: null, bpm: 108.0 },
  { created_time: "2023-09-18", steps: null, bpm: 73.0 },
  { created_time: "2023-08-11", steps: null, bpm: 69.0 },
  { created_time: "2023-08-05", steps: 2884, bpm: null },
  { created_time: "2023-08-04", steps: 131, bpm: 70.0 },
  { created_time: "2023-08-03", steps: 2402, bpm: 74.0 },
  { created_time: "2023-07-28", steps: 2080, bpm: 68.0 },
  { created_time: "2023-07-27", steps: 319, bpm: null },
  { created_time: "2023-07-11", steps: 5411, bpm: 71.0 },
  { created_time: "2023-07-05", steps: 82, bpm: 72.0 },
  { created_time: "2023-07-04", steps: 5147, bpm: null },
  // { created_time: "2023-07-01", steps: 3585, bpm: 69.0 },
  // { created_time: "2023-06-30", steps: 9306, bpm: 75.0 },
  // { created_time: "2023-06-29", steps: 5258, bpm: null },
  // { created_time: "2023-06-28", steps: 3538, bpm: 78.0 },
  // { created_time: "2023-06-21", steps: 263, bpm: null },
  // { created_time: "2023-06-20", steps: 829, bpm: 78.0 },
  // { created_time: "2023-06-19", steps: 1617, bpm: null },
  // { created_time: "2023-06-18", steps: 734, bpm: 82.0 },
  // { created_time: "2023-06-17", steps: null, bpm: 60.0 },
  // { created_time: "2023-06-16", steps: null, bpm: 65.0 },
  // { created_time: "2023-06-15", steps: 657, bpm: null },
  // { created_time: "2023-06-14", steps: null, bpm: 72.0 },
  // { created_time: "2023-06-03", steps: null, bpm: 76.0 },
  // { created_time: "2023-05-02", steps: 31, bpm: null },
  // { created_time: "2023-05-01", steps: 6086, bpm: null },
  // {"created_time":"2023-04-29","steps":13,"bpm":null},
  // {"created_time":"2023-04-26","steps":null,"bpm":62.00},
  // {"created_time":"2023-04-25","steps":null,"bpm":58.00},
  // {"created_time":"2023-04-08","steps":null,"bpm":117.00},
  // {"created_time":"2023-04-07","steps":null,"bpm":85.00},
  // {"created_time":"2023-04-04","steps":953,"bpm":null},
  // {"created_time":"2023-04-03","steps":null,"bpm":77.00},
  // {"created_time":"2023-04-02","steps":1189,"bpm":null},
  // {"created_time":"2023-04-01","steps":865,"bpm":null},
  // {"created_time":"2023-03-31","steps":null,"bpm":72.00},
  // {"created_time":"2023-03-30","steps":1702,"bpm":null},
  // {"created_time":"2023-03-29","steps":3880,"bpm":null},
  // {"created_time":"2023-03-28","steps":null,"bpm":78.00},
  // {"created_time":"2023-03-27","steps":11750,"bpm":null},
  // {"created_time":"2023-03-26","steps":null,"bpm":70.00},
  // {"created_time":"2023-03-25","steps":null,"bpm":65.00},
];

const inrData = [
  { created_time: "2024-02-25", inr: 0.4, pt: 4.7 },
  { created_time: "2024-02-14", inr: 0.4, pt: 5.7 },
  { created_time: "2024-02-01", inr: 0.3, pt: 4.3 },
  { created_time: "2024-01-25", inr: 0.8, pt: 10.2 },
  { created_time: "2024-01-17", inr: 0.3, pt: 4.6 },
  // { created_time: "2024-01-25", inr: 0.300, pt: 4.200},
  { created_time: "2024-01-05", inr: 0.2, pt: 3.0 },
  // { created_time: "2024-01-25", inr: 2.000, pt: 22.700},
  { created_time: "2023-12-27", inr: 0.8, pt: 9.3 },
  { created_time: "2023-12-12", inr: 1.2, pt: 14.6 },
  { created_time: "2023-12-03", inr: 1.1, pt: 12.7 },
  { created_time: "2023-11-27", inr: 0.8, pt: 9.3 },
  { created_time: "2023-11-12", inr: 1.2, pt: 14.6 },
  { created_time: "2023-11-03", inr: 1.1, pt: 12.7 },
  { created_time: "2023-10-19", inr: 1.6, pt: 19.0 },
  // {"created_time":"2023-10-19","inr":1.300,"pt":15.600},
  { created_time: "2023-10-17", inr: 3.7, pt: 40.2 },
  // {"created_time":"2023-10-17","inr":11.000,"pt":109.000},
  { created_time: "2023-10-14", inr: 1.4, pt: 16.0 },
  // {"created_time":"2023-10-09","inr":0.200,"pt":3.000},
  { created_time: "2023-10-09", inr: 1.1, pt: 13.2 },
  // {"created_time":"2023-10-09","inr":2.200,"pt":25.400},
  // {"created_time":"2023-10-09","inr":0.600,"pt":7.500},
  // {"created_time":"2023-10-09","inr":0.900,"pt":11.500},
  // {"created_time":"2023-10-09","inr":0.400,"pt":5.700},
  // {"created_time":"2023-10-09","inr":0.400,"pt":4.700},
  // {"created_time":"2023-10-09","inr":1.400,"pt":16.300},
  // {"created_time":"2023-10-09","inr":4.700,"pt":50.000},
  // {"created_time":"2023-10-09","inr":0.400,"pt":4.700},
  // {"created_time":"2023-10-09","inr":0.300,"pt":3.500},
  // {"created_time":"2023-10-09","inr":2.400,"pt":26.800},
  // {"created_time":"2023-10-09","inr":1.600,"pt":18.800},
  // {"created_time":"2023-10-09","inr":4.600,"pt":49.200},
  // {"created_time":"2023-10-09","inr":0.700,"pt":9.200},
  // {"created_time":"2023-10-09","inr":0.400,"pt":5.400},
  // {"created_time":"2023-10-09","inr":1.300,"pt":15.700},
  // {"created_time":"2023-10-08","inr":1.400,"pt":16.400},
  { created_time: "2023-10-08", inr: 0.4, pt: 5.6 },
  // {"created_time":"2023-10-08","inr":1.100,"pt":13.500},
  { created_time: "2023-10-06", inr: 0.3, pt: 3.8 },
  { created_time: "2023-09-30", inr: 1.3, pt: 15.8 },
  // {"created_time":"2023-09-28","inr":3.600,"pt":39.200},
  // {"created_time":"2023-09-28","inr":1.300,"pt":15.600},
  // {"created_time":"2023-09-28","inr":0.400,"pt":5.100},
  // {"created_time":"2023-09-28","inr":0.400,"pt":5.000},
  // {"created_time":"2023-09-28","inr":0.700,"pt":9.200},
  { created_time: "2023-09-28", inr: 0.3, pt: 4.0 },
  // {"created_time":"2023-09-28","inr":3.800,"pt":41.600},
  // {"created_time":"2023-09-28","inr":3.000,"pt":33.400},
  // {"created_time":"2023-09-28","inr":1.301,"pt":15.601},
  // {"created_time":"2023-09-28","inr":0.401,"pt":5.101},
  { created_time: "2023-09-27", inr: 0.401, pt: 5.001 },
  // {"created_time":"2023-09-27","inr":0.701,"pt":9.201},
  // {"created_time":"2023-09-27","inr":0.301,"pt":4.001},
  // {"created_time":"2023-09-27","inr":3.801,"pt":41.601},
  { created_time: "2023-09-15", inr: 0.5, pt: 6.7 },
  { created_time: "2023-08-31", inr: 0.5, pt: 6.7 },
  // {"created_time":"2023-08-31","inr":2.200,"pt":24.600},
  { created_time: "2023-08-30", inr: 2.7, pt: 29.7 },
  // {"created_time":"2023-08-30","inr":0.400,"pt":5.700},
  // {"created_time":"2023-08-30","inr":2.700,"pt":30.300},
  // {"created_time":"2023-08-30","inr":1.900,"pt":22.200},
  // {"created_time":"2023-08-30","inr":0.700,"pt":8.500},
  // {"created_time":"2023-08-30","inr":0.500,"pt":6.900},
  // {"created_time":"2023-08-30","inr":1.200,"pt":14.300},
  // {"created_time":"2023-08-30","inr":1.400,"pt":16.600},
  // {"created_time":"2023-08-30","inr":1.800,"pt":20.900},
  // {"created_time":"2023-08-30","inr":1.000,"pt":12.400},
  { created_time: "2023-08-16", inr: 0.401, pt: 5.801 },
  // {"created_time":"2023-08-16","inr":0.501,"pt":6.401},
  // {"created_time":"2023-08-16","inr":0.801,"pt":9.601},
  // {"created_time":"2023-08-16","inr":2.401,"pt":26.901},
  // {"created_time":"2023-08-16","inr":1.001,"pt":11.801},
  // {"created_time":"2023-08-15","inr":2.700,"pt":30.300},
  // {"created_time":"2023-08-15","inr":0.400,"pt":5.000},
  { created_time: "2023-08-15", inr: 0.9, pt: 10.8 },
  // {"created_time":"2023-08-15","inr":1.200,"pt":14.200},
  // {"created_time":"2023-08-15","inr":0.600,"pt":7.500},
  { created_time: "2023-08-13", inr: 0.4, pt: 4.7 },
  // {"created_time":"2023-08-13","inr":1.100,"pt":13.700},
  // {"created_time":"2023-08-13","inr":0.700,"pt":8.200},
  // {"created_time":"2023-08-13","inr":0.300,"pt":4.200},
  // {"created_time":"2023-08-13","inr":0.700,"pt":9.200},
  // {"created_time":"2023-08-13","inr":0.200,"pt":3.100},
  // {"created_time":"2023-08-13","inr":1.400,"pt":16.100},
  // {"created_time":"2023-08-13","inr":0.400,"pt":5.000},
  // {"created_time":"2023-08-13","inr":1.700,"pt":19.500},
  // {"created_time":"2023-08-13","inr":3.100,"pt":33.700},
  // {"created_time":"2023-08-13","inr":1.700,"pt":19.500},
  // {"created_time":"2023-08-13","inr":0.500,"pt":6.300},
  { created_time: "2023-08-12", inr: 1.2, pt: 14.8 },
  // {"created_time":"2023-08-12","inr":0.600,"pt":7.700},
  // {"created_time":"2023-08-12","inr":0.700,"pt":8.400},
  // {"created_time":"2023-08-12","inr":0.400,"pt":4.900},
  { created_time: "2023-08-11", inr: 0.801, pt: 10.101 },
  // {"created_time":"2023-08-11","inr":0.201,"pt":3.001},
  // {"created_time":"2023-08-11","inr":11.601,"pt":115.001},
  // {"created_time":"2023-08-11","inr":0.401,"pt":5.701},
  // {"created_time":"2023-08-11","inr":0.701,"pt":8.701},
  { created_time: "2023-08-08", inr: 2.1, pt: 24.1 },
  // {"created_time":"2023-08-08","inr":1.300,"pt":15.000},
  // {"created_time":"2023-08-06","inr":0.300,"pt":3.400},
  // {"created_time":"2023-08-06","inr":0.500,"pt":5.900},
  // {"created_time":"2023-08-06","inr":0.500,"pt":6.100},
  // {"created_time":"2023-08-06","inr":0.300,"pt":3.600},
  // {"created_time":"2023-08-06","inr":1.100,"pt":13.200},
  // {"created_time":"2023-08-06","inr":0.600,"pt":7.900},
  // {"created_time":"2023-08-06","inr":1.100,"pt":13.300},
  // {"created_time":"2023-08-06","inr":0.500,"pt":6.500},
  { created_time: "2023-08-05", inr: 0.5, pt: 6.3 },
  // {"created_time":"2023-08-05","inr":1.900,"pt":21.600},
  { created_time: "2023-08-04", inr: 0.3, pt: 4.6 },
  // {"created_time":"2023-08-04","inr":0.301,"pt":4.601},
  { created_time: "2023-08-03", inr: 0.201, pt: 3.001 },
  // {"created_time":"2023-08-03","inr":0.601,"pt":7.801},
  // {"created_time":"2023-08-03","inr":0.501,"pt":6.801},
  { created_time: "2023-08-02", inr: 1.401, pt: 16.801 },
  // {"created_time":"2023-08-02","inr":0.301,"pt":4.601},
  // {"created_time":"2023-08-02","inr":0.501,"pt":6.401},
  // {"created_time":"2023-08-02","inr":1.701,"pt":19.401},
  // {"created_time":"2023-08-02","inr":3.701,"pt":40.001},
  // {"created_time":"2023-08-02","inr":0.501,"pt":5.901},
  // {"created_time":"2023-08-02","inr":0.301,"pt":3.601},
  { created_time: "2023-07-27", inr: 0.4, pt: 5.2 },
  // {"created_time":"2023-07-27","inr":0.300,"pt":3.700},
  // {"created_time":"2023-07-27","inr":0.200,"pt":3.100},
  // {"created_time":"2023-07-27","inr":1.400,"pt":16.100},
  // {"created_time":"2023-07-27","inr":0.300,"pt":3.700},
  // {"created_time":"2023-07-27","inr":1.500,"pt":17.600},
  // {"created_time":"2023-07-27","inr":0.400,"pt":4.700},
  // {"created_time":"2023-07-27","inr":0.200,"pt":3.300},
  // {"created_time":"2023-07-27","inr":1.100,"pt":13.400},
  // {"created_time":"2023-07-27","inr":0.500,"pt":6.900},
  // {"created_time":"2023-07-27","inr":0.600,"pt":7.200},
  // {"created_time":"2023-07-27","inr":0.300,"pt":3.500},
  // {"created_time":"2023-07-27","inr":0.600,"pt":7.100},
  // {"created_time":"2023-07-27","inr":0.600,"pt":7.300},
  // {"created_time":"2023-07-27","inr":0.800,"pt":9.500},
  // {"created_time":"2023-07-27","inr":0.300,"pt":4.200},
  // {"created_time":"2023-07-27","inr":5.000,"pt":53.200},
  // {"created_time":"2023-07-27","inr":1.600,"pt":18.800},
  // {"created_time":"2023-07-27","inr":0.300,"pt":3.900},
  // {"created_time":"2023-07-27","inr":5.300,"pt":55.800},
  // {"created_time":"2023-07-27","inr":6.100,"pt":63.700},
  // {"created_time":"2023-07-27","inr":0.700,"pt":9.000},
  // {"created_time":"2023-07-27","inr":0.600,"pt":7.300},
  // {"created_time":"2023-07-27","inr":2.500,"pt":28.400},
  { created_time: "2023-07-27", inr: 0.6, pt: 8.0 },
  // {"created_time":"2023-07-21","inr":0.501,"pt":6.701},
  // {"created_time":"2023-07-21","inr":1.401,"pt":16.001},
  // {"created_time":"2023-07-21","inr":0.400,"pt":5.400},
  // {"created_time":"2023-07-21","inr":0.300,"pt":3.500},
  // {"created_time":"2023-07-21","inr":0.800,"pt":9.700},
  // {"created_time":"2023-07-21","inr":0.800,"pt":9.400},
  // {"created_time":"2023-07-21","inr":0.200,"pt":3.000},
  // {"created_time":"2023-07-21","inr":0.900,"pt":10.500},
  { created_time: "2023-07-20", inr: 0.5, pt: 6.2 },
  // {"created_time":"2023-07-20","inr":9.300,"pt":94.000},
  // {"created_time":"2023-07-20","inr":1.100,"pt":13.500},
  // {"created_time":"2023-07-20","inr":1.200,"pt":14.000},
  // {"created_time":"2023-07-20","inr":0.200,"pt":3.100},
  // {"created_time":"2023-07-20","inr":0.300,"pt":4.400},
  // {"created_time":"2023-07-20","inr":0.700,"pt":8.600},
  // {"created_time":"2023-07-20","inr":2.800,"pt":31.300},
  // {"created_time":"2023-07-20","inr":0.200,"pt":3.000},
  // {"created_time":"2023-07-20","inr":0.800,"pt":10.300},
  // {"created_time":"2023-07-20","inr":4.000,"pt":43.300},
  // {"created_time":"2023-07-20","inr":1.101,"pt":13.501},
  // {"created_time":"2023-07-20","inr":1.201,"pt":14.001},
  // {"created_time":"2023-07-20","inr":0.201,"pt":3.101},
  { created_time: "2023-07-18", inr: 1.0, pt: 12.1 },
  { created_time: "2023-07-07", inr: 2.801, pt: 31.301 },
  // {"created_time":"2023-07-07","inr":0.201,"pt":3.001},
  // {"created_time":"2023-07-07","inr":0.801,"pt":10.301},
  { created_time: "2023-06-29", inr: 0.501, pt: 5.901 },
  // {"created_time":"2023-06-29","inr":0.401,"pt":4.901},
  { created_time: "2023-06-28", inr: 3.6, pt: 39.2 },
  { created_time: "2023-06-27", inr: 0.3, pt: 3.9 },
  // {"created_time":"2023-06-27","inr":1.201,"pt":14.101},
  // {"created_time":"2023-06-27","inr":0.901,"pt":11.501},
  // {"created_time":"2023-06-27","inr":1.501,"pt":17.801},
  // {"created_time":"2023-06-27","inr":0.300,"pt":4.600},
  // {"created_time":"2023-06-27","inr":0.400,"pt":4.700},
  // {"created_time":"2023-06-27","inr":0.701,"pt":8.801},
  { created_time: "2023-06-26", inr: 0.4, pt: 5.3 },
  { created_time: "2023-06-25", inr: 0.3, pt: 4.5 },
  { created_time: "2023-06-24", inr: 0.301, pt: 4.201 },
  { created_time: "2023-06-22", inr: 0.7, pt: 8.5 },
  { created_time: "2023-06-20", inr: 4.3, pt: 46.0 },
  { created_time: "2023-06-13", inr: 0.2, pt: 3.1 },
  // {"created_time":"2023-06-13","inr":0.300,"pt":4.300},
  { created_time: "2023-06-09", inr: 0.6, pt: 7.5 },
  // {"created_time":"2023-06-09","inr":0.300,"pt":3.600},
  // {"created_time":"2023-06-09","inr":5.400,"pt":57.100},
  // {"created_time":"2023-06-09","inr":0.300,"pt":3.600},
  { created_time: "2023-06-06", inr: 0.6, pt: 7.9 },
  // {"created_time":"2023-06-06","inr":0.600,"pt":8.000},
  // {"created_time":"2023-06-06","inr":0.600,"pt":7.500},
  // {"created_time":"2023-06-06","inr":2.000,"pt":22.890},
  // {"created_time":"2023-06-06","inr":0.200,"pt":3.200},
  // {"created_time":"2023-06-06","inr":0.500,"pt":6.400},
  // {"created_time":"2023-06-06","inr":9.900,"pt":98.900},
  { created_time: "2023-06-04", inr: 0.31, pt: 3.91 },
  // {"created_time":"2023-06-04","inr":0.310,"pt":4.010},
  // {"created_time":"2023-06-04","inr":10.890,"pt":108.690},
  // {"created_time":"2023-06-04","inr":2.090,"pt":23.890},
  // {"created_time":"2023-06-03","inr":3.590,"pt":39.200},
  // {"created_time":"2023-06-03","inr":1.300,"pt":15.800},
  // {"created_time":"2023-06-03","inr":4.700,"pt":49.700},
  { created_time: "2023-06-03", inr: 1.0, pt: 11.69 },
  // {"created_time":"2023-05-31","inr":0.500,"pt":5.900},
  // {"created_time":"2023-05-31","inr":0.300,"pt":4.000},
  // {"created_time":"2023-05-31","inr":0.890,"pt":11.000},
  // {"created_time":"2023-05-31","inr":0.500,"pt":6.500},
  // {"created_time":"2023-05-31","inr":6.590,"pt":68.090},
  // {"created_time":"2023-05-31","inr":10.390,"pt":103.400},
  // {"created_time":"2023-05-31","inr":0.300,"pt":3.700},
  // {"created_time":"2023-05-31","inr":0.600,"pt":8.100},
  // {"created_time":"2023-05-31","inr":0.500,"pt":6.300},
  // {"created_time":"2023-05-31","inr":1.290,"pt":15.690},
  // {"created_time":"2023-05-31","inr":3.900,"pt":42.400},
  // {"created_time":"2023-05-31","inr":2.090,"pt":23.600},
  // {"created_time":"2023-05-31","inr":0.300,"pt":4.300},
  // {"created_time":"2023-05-31","inr":0.600,"pt":7.900},
  // {"created_time":"2023-05-31","inr":2.290,"pt":26.100},
  // {"created_time":"2023-05-31","inr":0.300,"pt":4.590},
  // {"created_time":"2023-05-31","inr":1.290,"pt":15.390},
  // {"created_time":"2023-05-31","inr":0.500,"pt":6.900},
  // {"created_time":"2023-05-31","inr":0.300,"pt":4.300},
  // {"created_time":"2023-04-26","inr":0.400,"pt":5.400},
  // {"created_time":"2023-04-26","inr":1.000,"pt":11.700},
  // {"created_time":"2023-04-26","inr":0.500,"pt":6.100},
  // {"created_time":"2023-04-26","inr":0.300,"pt":3.400},
  // {"created_time":"2023-04-26","inr":1.000,"pt":11.900},
  // {"created_time":"2023-04-26","inr":0.600,"pt":7.500},
  // {"created_time":"2023-04-25","inr":11.200,"pt":110.700},
  // {"created_time":"2023-04-25","inr":0.400,"pt":4.800},
  // {"created_time":"2023-04-12","inr":0.900,"pt":11.100},
  // {"created_time":"2023-04-12","inr":0.500,"pt":6.600},
  // {"created_time":"2023-04-11","inr":0.700,"pt":9.100},
  // {"created_time":"2023-04-11","inr":0.400,"pt":5.700},
  // {"created_time":"2023-04-11","inr":0.300,"pt":4.500},
  // {"created_time":"2023-04-11","inr":0.900,"pt":11.500},
  // {"created_time":"2023-04-11","inr":3.000,"pt":32.900},
  // {"created_time":"2023-04-11","inr":0.400,"pt":4.800},
  // {"created_time":"2023-04-11","inr":0.600,"pt":8.100},
  // {"created_time":"2023-04-11","inr":0.600,"pt":7.500},
  // {"created_time":"2023-04-11","inr":0.300,"pt":4.400},
  // {"created_time":"2023-04-11","inr":0.400,"pt":4.800},
  // {"created_time":"2023-04-11","inr":0.700,"pt":8.400},
  // {"created_time":"2023-04-11","inr":0.000,"pt":0.000},
  // {"created_time":"2023-04-09","inr":0.500,"pt":6.400},
  // {"created_time":"2023-04-09","inr":9.900,"pt":99.300},
  // {"created_time":"2023-04-09","inr":0.300,"pt":3.800},
  // {"created_time":"2023-04-09","inr":9.300,"pt":93.800},
  // {"created_time":"2023-04-09","inr":0.600,"pt":7.500},
  // {"created_time":"2023-04-09","inr":0.300,"pt":3.700},
  // {"created_time":"2023-04-09","inr":1.300,"pt":15.800},
  // {"created_time":"2023-04-09","inr":0.300,"pt":3.900},
  // {"created_time":"2023-04-08","inr":8.800,"pt":88.700},
  // {"created_time":"2023-04-05","inr":0.700,"pt":9.100},
  // {"created_time":"2023-03-25","inr":1.790,"pt":20.290},
  // {"created_time":"2023-03-17","inr":0.600,"pt":7.100},
  // {"created_time":"2023-03-14","inr":0.400,"pt":5.700},
  // {"created_time":"2023-03-11","inr":0.400,"pt":5.100},
  // {"created_time":"2023-03-11","inr":0.600,"pt":8.000},
  // {"created_time":"2023-03-11","inr":4.100,"pt":43.800},
  // {"created_time":"2023-03-11","inr":1.400,"pt":16.100},
  // {"created_time":"2023-03-11","inr":0.400,"pt":5.100},
  // {"created_time":"2023-03-11","inr":0.800,"pt":9.700},
  // {"created_time":"2023-03-11","inr":0.600,"pt":7.000},
  // {"created_time":"2023-03-11","inr":0.300,"pt":3.400},
  // {"created_time":"2023-03-11","inr":0.300,"pt":4.100},
  // {"created_time":"2023-03-11","inr":0.600,"pt":7.300},
  // {"created_time":"2023-03-11","inr":0.300,"pt":3.600},
  // {"created_time":"2023-03-11","inr":0.600,"pt":7.000},
  // {"created_time":"2023-03-11","inr":0.400,"pt":5.700},
  // {"created_time":"2023-03-11","inr":0.300,"pt":3.500},
  // {"created_time":"2023-03-11","inr":0.500,"pt":6.900},
  // {"created_time":"2023-03-11","inr":0.300,"pt":3.600},
  // {"created_time":"2023-03-11","inr":1.600,"pt":18.900},
  // {"created_time":"2023-03-11","inr":0.300,"pt":3.500},
  // {"created_time":"2023-03-11","inr":0.500,"pt":6.100},
  // {"created_time":"2023-03-11","inr":0.400,"pt":5.500},
  // {"created_time":"2023-03-11","inr":0.400,"pt":5.400},
  // {"created_time":"2023-03-11","inr":0.300,"pt":4.100},
  // {"created_time":"2023-03-09","inr":0.300,"pt":3.500},
  // {"created_time":"2023-03-09","inr":0.200,"pt":3.200},
  // {"created_time":"2023-03-09","inr":0.300,"pt":3.700},
  // {"created_time":"2023-03-09","inr":0.300,"pt":4.400},
  // {"created_time":"2023-03-09","inr":0.300,"pt":3.400},
  // {"created_time":"2023-03-09","inr":0.500,"pt":6.900},
  // {"created_time":"2023-03-09","inr":2.100,"pt":24.300},
  // {"created_time":"2023-03-09","inr":3.100,"pt":34.200},
  // {"created_time":"2023-03-09","inr":0.000,"pt":406.600},
  // {"created_time":"2023-03-09","inr":3.800,"pt":41.200},
  // {"created_time":"2023-03-09","inr":0.300,"pt":4.300},
  // {"created_time":"2023-03-09","inr":0.000,"pt":406.600},
  // {"created_time":"2023-03-09","inr":0.300,"pt":4.500},
  // {"created_time":"2023-03-09","inr":1.100,"pt":13.000},
  // {"created_time":"2023-03-09","inr":1.200,"pt":14.100},
  // {"created_time":"2023-03-09","inr":0.800,"pt":10.000},
  // {"created_time":"2023-03-09","inr":0.300,"pt":4.300},
  // {"created_time":"2023-03-09","inr":0.000,"pt":404.600},
  // {"created_time":"2023-03-09","inr":0.300,"pt":3.900},
  // {"created_time":"2023-03-09","inr":4.500,"pt":48.300},
  // {"created_time":"2023-03-09","inr":1.000,"pt":12.100},
  // {"created_time":"2023-03-09","inr":0.000,"pt":406.600},
];

const biofourmisData = [
  {
    created_time: "2024-02-28",
    steps: 2402,
    bpm: 74.0,
    spo2: 97.0,
    rr: 28,
    hrv: 88,
    temp: 99.32,
  },
  {
    created_time: "2024-02-03",
    steps: 2080,
    bpm: 68.0,
    spo2: 99.0,
    rr: 20,
    hrv: 61,
    temp: 98.6,
  },
  {
    created_time: "2024-01-27",
    steps: 319,
    bpm: null,
    spo2: 99.0,
    rr: 32,
    hrv: 73,
    temp: 98.41999999999999,
  },
  {
    created_time: "2024-01-11",
    steps: 5411,
    bpm: 71.0,
    spo2: 93.0,
    rr: 12,
    hrv: 70,
    temp: 99.122,
  },
  {
    created_time: "2024-01-05",
    steps: 82,
    bpm: 72.0,
    spo2: 99.0,
    rr: 25,
    hrv: 33,
    temp: 97.7,
  },
  {
    created_time: "2023-12-25",
    steps: 5147,
    bpm: null,
    spo2: 96.0,
    rr: 32,
    hrv: 53,
    temp: 98.6,
  },
  {
    created_time: "2023-12-13",
    steps: 3585,
    bpm: 69.0,
    spo2: 98.0,
    rr: 6,
    hrv: 99,
    temp: 98.76200000000001,
  },
  {
    created_time: "2023-12-08",
    steps: 9306,
    bpm: 75.0,
    spo2: 81.0,
    rr: 28,
    hrv: 46,
    temp: 97.322,
  },
  {
    created_time: "2023-11-29",
    steps: 5258,
    bpm: null,
    spo2: 96.0,
    rr: 24,
    hrv: 63,
    temp: 97.16,
  },
  {
    created_time: "2023-11-10",
    steps: null,
    bpm: 69.0,
    spo2: 98.0,
    rr: 31,
    hrv: 49,
    temp: 98.96000000000001,
  },
  {
    created_time: "2023-11-09",
    steps: null,
    bpm: 69.0,
    spo2: 99.0,
    rr: 9,
    hrv: 64,
    temp: 98.76200000000001,
  },
  {
    created_time: "2023-11-08",
    steps: 654,
    bpm: null,
    spo2: 99.0,
    rr: 18,
    hrv: 61,
    temp: 98.6,
  },
  {
    created_time: "2023-11-07",
    steps: 1121,
    bpm: null,
    spo2: 91.0,
    rr: 21,
    hrv: 53,
    temp: 98.41999999999999,
  },
  {
    created_time: "2023-11-06",
    steps: null,
    bpm: 52.0,
    spo2: 95.0,
    rr: 16,
    hrv: 36,
    temp: 98.06,
  },
  {
    created_time: "2023-11-05",
    steps: null,
    bpm: 62.0,
    spo2: 96.0,
    rr: 7,
    hrv: 81,
    temp: 99.32,
  },
  {
    created_time: "2023-11-04",
    steps: null,
    bpm: 74.0,
    spo2: 97.0,
    rr: 23,
    hrv: 94,
    temp: 98.76200000000001,
  },
  {
    created_time: "2023-11-03",
    steps: null,
    bpm: 64.0,
    spo2: 93.0,
    rr: 26,
    hrv: 89,
    temp: 97.7,
  },
  {
    created_time: "2023-11-02",
    steps: null,
    bpm: 72.0,
    spo2: 93.0,
    rr: 35,
    hrv: 79,
    temp: 98.06,
  },
  {
    created_time: "2023-11-01",
    steps: null,
    bpm: 63.0,
    spo2: 92.0,
    rr: 11,
    hrv: 85,
    temp: 98.41999999999999,
  },
  {
    created_time: "2023-10-31",
    steps: null,
    bpm: 70.0,
    spo2: 93.0,
    rr: 8,
    hrv: 38,
    temp: 99.662,
  },
  {
    created_time: "2023-10-30",
    steps: null,
    bpm: 67.0,
    spo2: 97.0,
    rr: 28,
    hrv: 48,
    temp: 98.76200000000001,
  },
  {
    created_time: "2023-10-29",
    steps: 5294,
    bpm: null,
    spo2: 93.0,
    rr: 17,
    hrv: 80,
    temp: 98.41999999999999,
  },
  {
    created_time: "2023-10-28",
    steps: null,
    bpm: 71.0,
    spo2: 99.0,
    rr: 17,
    hrv: 87,
    temp: 99.122,
  },
  {
    created_time: "2023-10-27",
    steps: null,
    bpm: 59.0,
    spo2: 98.0,
    rr: 16,
    hrv: 65,
    temp: 98.6,
  },
  {
    created_time: "2023-10-07",
    steps: 21,
    bpm: null,
    spo2: 98.0,
    rr: 28,
    hrv: 43,
    temp: 98.6,
  },
  {
    created_time: "2023-10-06",
    steps: 2175,
    bpm: null,
    spo2: 94.0,
    rr: 12,
    hrv: 49,
    temp: 98.41999999999999,
  },
  {
    created_time: "2023-10-05",
    steps: null,
    bpm: 76.0,
    spo2: 96.0,
    rr: 36,
    hrv: 71,
    temp: 98.41999999999999,
  },
  {
    created_time: "2023-10-03",
    steps: null,
    bpm: 108.0,
    spo2: 100.0,
    rr: 30,
    hrv: 45,
    temp: 98.06,
  },
  {
    created_time: "2023-09-18",
    steps: null,
    bpm: 73.0,
    spo2: 97.0,
    rr: 26,
    hrv: 49,
    temp: 97.86200000000001,
  },
  {
    created_time: "2023-08-11",
    steps: null,
    bpm: 69.0,
    spo2: 95.0,
    rr: 27,
    hrv: 45,
    temp: 98.06,
  },
  {
    created_time: "2023-08-05",
    steps: 2884,
    bpm: null,
    spo2: 96.0,
    rr: 27,
    hrv: 91,
    temp: 98.06,
  },
  {
    created_time: "2023-08-04",
    steps: 131,
    bpm: 70.0,
    spo2: 94.0,
    rr: 28,
    hrv: 67,
    temp: 97.7,
  },
  // {
  //   created_time: "2023-08-03",
  //   steps: 2402,
  //   bpm: 74.0,
  //   spo2: 97.0,
  //   rr: 28,
  //   hrv: 88,
  //   temp: 99.32,
  // },
  // {
  //   created_time: "2023-07-28",
  //   steps: 2080,
  //   bpm: 68.0,
  //   spo2: 99.0,
  //   rr: 20,
  //   hrv: 61,
  //   temp: 98.6,
  // },
  // {
  //   created_time: "2023-07-27",
  //   steps: 319,
  //   bpm: null,
  //   spo2: 99.0,
  //   rr: 32,
  //   hrv: 73,
  //   temp: 98.41999999999999,
  // },
  // {
  //   created_time: "2023-07-11",
  //   steps: 5411,
  //   bpm: 71.0,
  //   spo2: 93.0,
  //   rr: 12,
  //   hrv: 70,
  //   temp: 99.122,
  // },
  // {
  //   created_time: "2023-07-05",
  //   steps: 82,
  //   bpm: 72.0,
  //   spo2: 99.0,
  //   rr: 25,
  //   hrv: 33,
  //   temp: 97.7,
  // },
  // {
  //   created_time: "2023-07-04",
  //   steps: 5147,
  //   bpm: null,
  //   spo2: 96.0,
  //   rr: 32,
  //   hrv: 53,
  //   temp: 98.6,
  // },
  // {
  //   created_time: "2023-07-01",
  //   steps: 3585,
  //   bpm: 69.0,
  //   spo2: 98.0,
  //   rr: 6,
  //   hrv: 99,
  //   temp: 98.76200000000001,
  // },
  // {
  //   created_time: "2023-06-30",
  //   steps: 9306,
  //   bpm: 75.0,
  //   spo2: 81.0,
  //   rr: 28,
  //   hrv: 46,
  //   temp: 97.322,
  // },
  // {
  //   created_time: "2023-06-29",
  //   steps: 5258,
  //   bpm: null,
  //   spo2: 96.0,
  //   rr: 24,
  //   hrv: 63,
  //   temp: 97.16,
  // },
  // {
  //   created_time: "2023-06-28",
  //   steps: 3538,
  //   bpm: 78.0,
  //   spo2: 98.0,
  //   rr: 40,
  //   hrv: 68,
  //   temp: 98.41999999999999,
  // },
  // {
  //   created_time: "2023-06-21",
  //   steps: 263,
  //   bpm: null,
  //   spo2: 96.0,
  //   rr: 31,
  //   hrv: 88,
  //   temp: 97.7,
  // },
  // {
  //   created_time: "2023-06-20",
  //   steps: 829,
  //   bpm: 78.0,
  //   spo2: 98.0,
  //   rr: 34,
  //   hrv: 50,
  //   temp: 98.06,
  // },
  // {
  //   created_time: "2023-06-19",
  //   steps: 1617,
  //   bpm: null,
  //   spo2: 100.0,
  //   rr: 21,
  //   hrv: 67,
  //   temp: 98.41999999999999,
  // },
  // {
  //   created_time: "2023-06-18",
  //   steps: 734,
  //   bpm: 82.0,
  //   spo2: 99.0,
  //   rr: 27,
  //   hrv: 77,
  //   temp: 98.6,
  // },
  // {
  //   created_time: "2023-06-17",
  //   steps: null,
  //   bpm: 60.0,
  //   spo2: 99.0,
  //   rr: 6,
  //   hrv: 99,
  //   temp: 98.41999999999999,
  // },
  // {
  //   created_time: "2023-06-16",
  //   steps: null,
  //   bpm: 65.0,
  //   spo2: 96.0,
  //   rr: 29,
  //   hrv: 35,
  //   temp: 98.41999999999999,
  // },
  // {
  //   created_time: "2023-06-15",
  //   steps: 657,
  //   bpm: null,
  //   spo2: 95.0,
  //   rr: 13,
  //   hrv: 79,
  //   temp: 98.41999999999999,
  // },
  // {
  //   created_time: "2023-06-14",
  //   steps: null,
  //   bpm: 72.0,
  //   spo2: 96.0,
  //   rr: 8,
  //   hrv: 51,
  //   temp: 98.6,
  // },
  // {
  //   created_time: "2023-06-03",
  //   steps: null,
  //   bpm: 76.0,
  //   spo2: 98.0,
  //   rr: 28,
  //   hrv: 63,
  //   temp: 98.6,
  // },
  // {
  //   created_time: "2023-05-02",
  //   steps: 31,
  //   bpm: null,
  //   spo2: 98.0,
  //   rr: 29,
  //   hrv: 82,
  //   temp: 99.5,
  // },
  // {
  //   created_time: "2023-05-01",
  //   steps: 6086,
  //   bpm: null,
  //   spo2: 95.0,
  //   rr: 15,
  //   hrv: 40,
  //   temp: 100.22,
  // },
];

const typeToDataMap = {
  "Blood Pressure": {
    data: bpData,
    graphs: [
      {
        label: "Blood Pressure",
        value: [
          {
            key: "systolic",
            color: "#8884d8",
            bounds: [120],
          },
          { key: "diastolic", color: "#82ca9d", bounds: [80] },
        ],
      },
      {
        label: "Heart Rate",
        value: [{ key: "bpm", color: "#3182BD", bounds: [60, 100] }],
      },
    ],
  },
  Glucometer: {
    data: glucData,
    graphs: [
      {
        label: "Glucose",
        value: [{ key: "mg_dl", color: "#3182BD", bounds: [70, 240] }],
      },
    ],
  },
  "Pulse Ox": {
    data: pulseoxData,
    graphs: [
      {
        label: "Oxygen Saturation",
        value: [
          { key: "spo2", color: "#3182BD", bounds: [95], yAxis: [75, 100] },
        ],
      },
      {
        label: "Heart Rate",
        value: [{ key: "bpm", color: "#3182BD", bounds: [60, 100] }],
      },
    ],
  },
  Spirometer: {
    data: spiroData,
    graphs: [
      {
        label: "Spirometry 1",
        value: [
          { key: "fev1", color: "#8884d8" },
          { key: "fev6", color: "#82ca9d" },
          "fev1fev6",
        ],
      },
      {
        label: "Spirometry 2",
        value: [{ key: "fvc", color: "#82ca9d" }, "fef2575"],
      },
      { label: "Spirometry 3", value: ["pef"] },
    ],
  },
  Wearable: {
    data: wearableData,
    graphs: [
      { label: "Steps", value: ["steps"] },
      {
        label: "Heart Rate",
        value: [{ key: "bpm", color: "#3182BD", bounds: [60, 100] }],
      },
    ],
  },
  INR: {
    data: inrData,
    graphs: [
      { label: "INR", value: ["inr"] },
      { label: "PT", value: ["pt"] },
    ],
  },
  Biofourmis: {
    data: biofourmisData,
    graphs: [
      { label: "Steps", value: ["steps"] },
      {
        label: "Heart Rate",
        value: [{ key: "bpm", color: "#3182BD", bounds: [60, 100] }],
      },
      {
        label: "Oxygen Saturation",
        value: [
          { key: "spo2", color: "#3182BD", bounds: [95], yAxis: [75, 100] },
        ],
      },
      { label: "HRV", value: ["hrv"] },
      { label: "Respiration Rate", value: ["rr"] },
      {
        label: "Temperature",
        value: [
          { key: "temp", color: "#3182BD", bounds: [97, 99], yAxis: [96] },
        ],
      },
    ],
  },
};

export default typeToDataMap;
