import React from "react";

export default function SPO2() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="10 0 22 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.1458 15.5702C11.5384 15.8467 11.1678 16.0592 11.0281 16.8276C10.8884 17.596 11.2717 21.8421 12.1458 24.0926M12.1458 24.0926C12.2855 25.1404 12.984 25.6294 13.473 25.6294C13.962 25.6294 18.293 25.6294 19.2012 25.6294C20.1093 25.6294 21.227 24.372 21.227 23.394C21.227 22.416 21.227 15.9195 21.227 15.5702C21.227 15.2209 20.8777 14.9415 20.8078 14.9415C20.738 14.9415 19.4806 14.9415 19.4806 14.9415C19.282 14.9675 19.1764 15.1505 18.9916 15.5702V18.7835C18.9916 18.7835 18.9916 15.1511 18.9916 14.7319C18.9916 14.3128 18.6423 14.0334 18.5026 14.0334C18.3629 14.0334 17.3849 14.0334 17.1753 14.0334C16.9658 14.0334 16.8153 14.2795 16.6864 14.7319M12.1458 24.0926V11.7281M16.6864 14.7319V18.7835M16.6864 14.7319C16.5262 14.2759 16.3371 14.0334 16.1275 14.0334C15.9179 14.0334 15.1495 14.0334 15.0098 14.0334C14.8701 14.0334 14.5911 14.3053 14.451 14.7319V18.7835V11.7281M14.451 11.7281C13.7524 12.0774 12.984 12.1473 12.1458 11.7281M14.451 11.7281C14.9286 11.3114 15.0719 11.0541 15.0797 10.5406C15.0797 10.5406 15.0797 6.69855 15.0797 6.41913C15.0797 6.13971 14.8701 6 14.5907 6C14.3113 6 12.1458 6 11.9362 6C11.7266 6 11.5171 6.13971 11.5171 6.41913C11.5171 6.69855 11.5171 10.5406 11.5171 10.5406C11.6272 11.0879 11.7311 11.3688 12.1458 11.7281M13.5429 6.97798H13.8921H14.2414M13.4032 7.53682H14.2414M12.006 6.6287V8.93393H14.5907V6.6287H12.006Z"
        stroke="#545454"
        strokeWidth="0.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6765 7.01152C12.5611 6.86979 12.3526 6.8778 12.288 7.01152C12.2233 7.14524 12.2231 7.47908 12.6765 7.74647M12.6765 7.01152C12.5611 6.86979 12.3526 6.8778 12.288 7.01152C12.2233 7.14524 12.2231 7.47908 12.6765 7.74647"
        stroke="#545454"
        strokeWidth="0.139711"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6764 7.01152C12.7918 6.86979 13.0003 6.8778 13.0649 7.01152C13.1296 7.14524 13.1298 7.47908 12.6764 7.74647M12.6764 7.01152C12.7918 6.86979 13.0003 6.8778 13.0649 7.01152C13.1296 7.14524 13.1298 7.47908 12.6764 7.74647"
        stroke="#545454"
        strokeWidth="0.139711"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
