import { makeStyles } from "@mui/styles";
import theme from "../../utils/theme";

const useStyles = makeStyles(() => ({
  form: {
    "& .MuiFormControl-root": {
      display: "block",
      marginBottom: 16,
    },
  },
  mt0: {
    marginTop: 0,
  },
  mb16: {
    marginBottom: 16,
  },
  mt16: {
    marginTop: 16,
  },
  mr16: {
    marginRight: 16,
  },
  mr10: {
    marginRight: 10,
  },
  mb32: {
    marginBottom: 32,
  },
  flex: {
    display: "flex",
  },
  alignCenter: {
    alignItems: "center",
  },
  justifyCenter: {
    justifyContent: "center",
  },
  flex1: {
    flex: 1,
  },
  pl68: {
    paddingLeft: 68,
  },
  fs14: {
    fontSize: 14,
  },
  linkIcon: {
    padding: 10,
    color: theme.colors.blue,
    cursor: "pointer",
  },
  groupBtn: {
    float: "right",
    marginTop: "10px",
  },
  listChip: {
    flexWrap: "wrap",
  },
  chipItem: {
    marginBottom: 10,
    marginRight: 10,
  },
  textCenter: {
    textAlign: "center",
  },
  hubInfoDisplay: {
    padding: 0,
    "& .hubId": {
      fontWeight: "bold",
      marginTop: "10px",
      fontSize: "14px",
    },
    "& .reason": {
      color: "gray",
      fontSize: "13px",
    },
    "& .reasonDetail": {
      marginBottom: "10px",
      fontSize: "12px",
    },
  },
  shippingAddress: {
    marginBottom: "5px",
    fontWeight: "bold",
  },
  bold: {
    fontWeight: "bold",
  },
  btnContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "20px",
  },
  instructions: {
    fontSize: "14px",
  },
  wrap: {
    flexWrap: "wrap",
  },
  hubReturnTitle: {
    marginBottom: 10,
    fontWeight: "bold",
    fontSize: 16,
  },
  shippingText: {
    color: "grey",
    marginTop: 5,
  },
  mt10: {
    marginTop: 10,
  },
}));

export default useStyles;
