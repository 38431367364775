import { FormControlLabel, Checkbox } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { Controller } from "react-hook-form";

function RhfCheckBox({ name, control, rules, label, ...rest }) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <FormControlLabel
          label={label}
          control={<Checkbox {...field} checked={field.value || false} />}
          {...rest}
        />
      )}
    />
  );
}

RhfCheckBox.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.shape.isRequired,
  label: PropTypes.string,
  rules: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.number,
      PropTypes.object,
    ])
  ),
};

RhfCheckBox.defaultProps = {
  rules: {},
  label: "",
};

export default RhfCheckBox;
