import { Tab, Tabs } from "@mui/material";
import { endOfDay, sub } from "date-fns";
import { debounce } from "lodash";
import { enqueueSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import { DOCS, FEATURE_FLAGS } from "../../utils/constants";

import { get, getFeatureFlag } from "../../utils/io";
import MainContentContainer from "../Layouts/MainContentContainer";
import MeasureList from "../MeasureList";
import TabPanel from "../Users/TabPanel";
import MeasureSearchForm from "./MeasureSearchForm";

const getOneMonthAgo = () => sub(new Date(), { months: 1 });

const getEndOfDay = () => endOfDay(new Date());
const emptySearchInputs = {
  macAddress: "",
  hubId: "",
  status: "",
  takenAfterDate: getOneMonthAgo(),
  takenBeforeDate: getEndOfDay(),
};

function Measures() {
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [selectedMeasures, setSelectedMeasures] = useState([]);
  const [pagination, setPagination] = useState({
    pageSize: 20,
    page: 0,
  });
  const [totalCount, setTotalCount] = useState(0);
  const [searchInputs, setSearchInputs] = useState({ ...emptySearchInputs });
  const [autoRefreshMeasure, setAutoRefreshMeasure] = useState(false);
  const [sortModel, setSortModel] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);

  const tabs = [
    { index: 0, label: "All" },
    { index: 1, label: "Pending Validation" },
  ];

  const getMeasureList = useCallback(
    async (searchInputs, pagination, sortField) => {
      const res = await get("/measures", {
        esn: searchInputs.hubId,
        device_mac: searchInputs.macAddress,
        status: tabIndex ? "pending clinical validation" : searchInputs.status,
        taken_after: searchInputs.takenAfterDate.toISOString(),
        taken_before: searchInputs.takenBeforeDate.toISOString(),
        limit: pagination.pageSize,
        offset: pagination.page * pagination.pageSize,
        ordering: sortField,
      });
      if (res?.status === 200) {
        setTotalCount(res.data.total_count);
        setSearchResults(res.data.measures);
      } else {
        return enqueueSnackbar("Unable to retrieve measurements", {
          variant: "error",
        });
      }
      return null;
    },
    [tabIndex]
  );

  const onSelectionChanged = (selectionModel) => {
    setSelectedMeasures(selectionModel);
  };

  const onPageChange = async (page) => {
    setPagination((pre) => ({
      ...pre,
      page,
    }));
  };

  const onChangeRowPerPage = (pageSize) => {
    setPagination((pre) => ({ ...pre, pageSize }));
  };

  const refreshMeasureList = () => setPagination({ ...pagination });

  const handleTabChange = (_, newValue) => setTabIndex(newValue);

  const clinicalValidationToolbarProps = {
    visibleToolbarItems: {
      resend: false,
      bindDevices: false,
      unbindDevices: false,
      blockDevices: false,
      export: false,
      autoRefresh: true,
      approveMeasures: true,
      rejectMeasures: true,
    },
  };

  const updateMeasureGrid = useCallback(() => {
    const debouncedUpdate = debounce(async () => {
      setLoading(true);
      const sortField = sortModel[0]?.field;
      const sortDirection = sortModel[0]?.sort === "desc" ? "-" : "";
      const sortParam = sortField ? `${sortDirection}${sortField}` : undefined;

      await getMeasureList(searchInputs, pagination, sortParam);
      setLoading(false);
    }, 300);

    debouncedUpdate();
    return debouncedUpdate.cancel;
  }, [getMeasureList, pagination, searchInputs, sortModel, setLoading]);

  useEffect(() => {
    const cleanUp = updateMeasureGrid();
    return cleanUp;
  }, [updateMeasureGrid]);

  useEffect(() => {
    if (autoRefreshMeasure) {
      const interval = setInterval(updateMeasureGrid, 10000);
      return () => clearInterval(interval);
    }
    return null;
  }, [autoRefreshMeasure, updateMeasureGrid]);

  const commonProps = {
    checkbox: true,
    loading,
    rows: searchResults,
    onSelectionChanged,
    pageSize: pagination.pageSize,
    page: pagination.page,
    onPageSizeChange: onChangeRowPerPage,
    rowsPerPageOptions: [20, 25, 50, 100],
    rowCount: totalCount,
    onPageChange,
    refreshMeasureList,
    selectedMeasures,
    setSortModel,
    sortingMode: "server",
    setAutoRefreshMeasure,
    autoRefreshMeasure,
  };

  return (
    <MainContentContainer title="Measures" docsUrl={DOCS.MEASURES.url}>
      {getFeatureFlag(FEATURE_FLAGS.CLINICAL_VALIDATION) && (
        <Tabs value={tabIndex} onChange={handleTabChange}>
          {tabs.map((tab) => (
            <Tab key={tab.index} label={tab.label} />
          ))}
        </Tabs>
      )}
      <MeasureSearchForm
        onSubmit={setSearchInputs}
        isSearchDisabled={loading}
        statusPendingValidation={
          tabIndex ? "pending clinical validation" : null
        }
      />
      <TabPanel value={tabIndex} index={0}>
        <MeasureList
          {...commonProps}
          searchResults={searchResults}
          searchInputs={searchInputs}
        />
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <MeasureList
          {...commonProps}
          toolbarProps={clinicalValidationToolbarProps}
        />
      </TabPanel>
    </MainContentContainer>
  );
}

export default Measures;
