import { Stack } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import HubCellularHelpfulTip from "../TroubleshootCategoryTabs/HubCellularHelpfulTip";
import HubDeviceConnectivityLatestReadings from "../TroubleshootCategoryTabs/HubDeviceConnectivityLatestReadings";
import ActiveReturnForm from "./ActiveReturnForm";
import HealthDeviceIssueForm from "./HealthDeviceIssueForm";
import HubCellularForm from "./HubCellularForm";
import HubDeviceConnectivityForm from "./HubDeviceConnectivityForm";
import HubPowerForm from "./HubPowerForm";

export const TROUBLESHOOT_FORM_DATA = Object.freeze({
  "hub-power": {
    form: HubPowerForm,
    defaultValues: {
      outlet_power: false,
      hub_damage: false,
      notes: "",
    },
  },
  "hub-cellular": {
    form: HubCellularForm,
    defaultValues: {
      hub_blinking_red: false,
      hub_damage: false,
      location_changed: false,
      notes: "",
    },
    rightSection: <HubCellularHelpfulTip />,
  },
  "device-hub-connectivity": {
    form: HubDeviceConnectivityForm,
    defaultValues: {
      outlet_power: false,
      repair_attempted: false,
      measure_attempted: false,
      hub_solid_red: false,
      hub_rebooted: false,
      hub_damage: false,
      devices_verified: false,
      notes: "",
      connectivity_issues: "",
      device_model_id: null,
    },
    rightSection: <HubDeviceConnectivityLatestReadings />,
  },
  "device-issue": {
    form: HealthDeviceIssueForm,
    defaultValues: {
      issue_type: "connectivity",
      notes: "",
      device_model_id: null,
      device_make_id: null,
      device_type: "",
    },
  },
  "active-return": {
    form: ActiveReturnForm,
    defaultValues: {
      confirmed_lights_blinking: false,
      repair_attempted: false,
      notes: "",
      replacement_hub_id: "",
    },
  },
});

function TroubleshootForm(props) {
  const {
    type,
    ticketReturn,
    handleFormSubmit,
    completeLoading,
    saveLoading,
    requiredReplacementHub,
    setRequiredReplacementHub,
    disableReturnButton,
  } = props;

  const troubleshootDataSelected = TROUBLESHOOT_FORM_DATA[type];

  return (
    <Stack spacing={2}>
      <troubleshootDataSelected.form
        ticketReturn={ticketReturn}
        handleFormSubmit={handleFormSubmit}
        completeLoading={completeLoading}
        requiredReplacementHub={requiredReplacementHub}
        setRequiredReplacementHub={setRequiredReplacementHub}
        saveLoading={saveLoading}
        disableReturnButton={disableReturnButton}
      />
    </Stack>
  );
}

TroubleshootForm.propTypes = {
  type: PropTypes.string,
  ticketReturn: PropTypes.shape({}),
  handleFormSubmit: PropTypes.func,
  completeLoading: PropTypes.bool.isRequired,
  saveLoading: PropTypes.bool.isRequired,
  requiredReplacementHub: PropTypes.string,
  setRequiredReplacementHub: PropTypes.func,
  disableReturnButton: PropTypes.bool,
};

TroubleshootForm.defaultProps = {
  type: "",
  ticketReturn: {},
  handleFormSubmit: () => {},
  requiredReplacementHub: null,
  setRequiredReplacementHub: () => {},
  disableReturnButton: false,
};

export default TroubleshootForm;
