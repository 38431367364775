export default {
  colors: {
    blue: "#1F79E2",
    darkBlue: "#0153cc",
    lightBlue: "#cadefb",
    lighterBlue: "#e6effc",
    black: "#001737",
    grey: "#545454",
    darkGrey: "#42526E",
    stelBlue: "#1b6ede",
  },
  fontSize: {
    small: "13px",
    medium: "14px",
    large: "15px",
    xlarge: "16px",
    xxlarge: "17px",
  },
};
