import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  mrt0: {
    marginTop: 0,
  },
  mrt30: {
    marginTop: 30,
  },
}));

export default useStyles;
