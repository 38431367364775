import { Box } from "@mui/material";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React from "react";

import TroubleshootCategoryCard from "./TroubleshootCategoryCard";

function TroubleshootNavTiles(props) {
  const { options, ticketReturn } = props;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "16px",
      }}
    >
      <Box sx={{ display: "flex", gap: "24px" }}>
        {options.map((option) => (
          <TroubleshootCategoryCard
            key={option.link}
            {...option}
            fontSizeLabel={{ fontSize: "18px" }}
            disabled={option.label === "Active Return" && isEmpty(ticketReturn)}
          />
        ))}
      </Box>
    </Box>
  );
}

TroubleshootNavTiles.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    })
  ).isRequired,
  ticketReturn: PropTypes.shape({
    id: PropTypes.string,
    details: PropTypes.shape({
      triage: PropTypes.shape({ status: PropTypes.string }),
    }),
  }),
};
TroubleshootNavTiles.defaultProps = {
  ticketReturn: {},
};

export default TroubleshootNavTiles;
