import { Stack } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import RhfCheckBox from "../../../form/RhfCheckBox";
import { CHECKBOX_TITLES } from "../../constants";

function CheckboxGroup(props) {
  const { list, control } = props;
  return (
    <Stack>
      {list.map((key) => (
        <RhfCheckBox
          key={key}
          label={CHECKBOX_TITLES[key]?.title || ""}
          name={key || ""}
          control={control}
        />
      ))}
    </Stack>
  );
}

CheckboxGroup.propTypes = {
  list: PropTypes.arrayOf(PropTypes.string),
  control: PropTypes.shape().isRequired,
};

CheckboxGroup.defaultProps = {
  list: [],
};

export default CheckboxGroup;
