import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import { Button, Divider, Link, Stack, Typography } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { DOCS } from "../../utils/constants";
import { get, isAdmin } from "../../utils/io";
import { TROUBLESHOOT_MANAGEMENT, TROUBLESHOOT_CATEGORIES } from "./constants";
import TroubleshootCategoryTabs from "./TroubleshootCategoryTabs";
import TroubleshootLayout from "./TroubleshootLayout";
import TroubleshootNavTiles from "./TroubleshootNavTiles";

function Troubleshoot() {
  const history = useHistory();
  const { hubId } = useParams();
  const [ticketReturn, setTicketReturn] = useState({});
  const [tickets, setTickets] = useState([]);

  const currentTab = history.location.hash.substring(1);

  useEffect(() => {
    const getOpenTicket = async () => {
      if (!hubId) {
        enqueueSnackbar(`Hub ${hubId} was not found.`, {
          variant: "error",
        });
        return;
      }
      try {
        const ticketRes = await get("/troubleshoot/tickets", {
          hub_id: hubId,
          status: "open",
        });
        if (ticketRes.data.items.length) {
          setTicketReturn(ticketRes.data.items[0]);
        }
      } catch (error) {
        enqueueSnackbar(error, {
          variant: "error",
        });
      }
    };
    getOpenTicket();
  }, [hubId]);

  useEffect(() => {
    if (tickets.length > 0) {
      setTicketReturn(tickets.find((item) => item.details?.status === "open"));
    }
  }, [tickets]);

  const renderContent = useMemo(() => {
    if (!currentTab) {
      return (
        <Stack>
          <Stack
            alignItems="center"
            justifyContent="center"
            width="100%"
            spacing={2}
            paddingY="40px"
            sx={{
              background: "#1F79E2",
            }}
          >
            <Typography variant="h5" fontWeight={600} color="#fff">
              TROUBLESHOOT
            </Typography>
            <Typography variant="inherit" color="#fff">
              Choose the issue below that matches your problem
            </Typography>
          </Stack>
          <Stack padding="40px">
            {!isAdmin() && (
              <>
                <TroubleshootNavTiles options={TROUBLESHOOT_CATEGORIES} />

                <Divider
                  sx={{
                    margin: "32px 10%",
                    background: "#E0E0E0",
                  }}
                />
              </>
            )}
            <TroubleshootNavTiles
              options={TROUBLESHOOT_MANAGEMENT}
              ticketReturn={ticketReturn}
            />
          </Stack>
        </Stack>
      );
    }
    return (
      <TroubleshootCategoryTabs
        currentCategoryTab={currentTab}
        ticketReturn={ticketReturn}
        setTicketReturn={setTicketReturn}
        setTickets={setTickets}
      />
    );
  }, [currentTab, ticketReturn]);

  return (
    <TroubleshootLayout
      title={
        <Stack flexDirection="row" gap="5px">
          Troubleshooting Hub {hubId.substring(0, 10)}
          <Link href={DOCS.TROUBLESHOOT.url} target="_blank" rel="nooper">
            <InfoIcon sx={{ mt: "5px" }} />
          </Link>
        </Stack>
      }
      actions={
        currentTab && (
          <Button
            sx={{
              minWidth: "max-content",
              "& .MuiButton-startIcon": {
                margin: 0,
              },
            }}
            onClick={() => history.replace(`/hubs/${hubId}`)}
            startIcon={<CloseIcon style={{ color: "#001737" }} />}
          />
        )
      }
    >
      {renderContent}
    </TroubleshootLayout>
  );
}

export default Troubleshoot;
