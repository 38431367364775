import { TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import PropTypes from "prop-types";

import React from "react";

export default function StelDatePicker(props) {
  const { value, onChange, label, variant, ...rest } = props;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label={label}
        value={value}
        onChange={onChange}
        renderInput={(params) => <TextField {...params} variant={variant} />}
        {...rest}
      />
    </LocalizationProvider>
  );
}

StelDatePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  variant: PropTypes.string,
};
StelDatePicker.defaultProps = {
  variant: "outlined",
};
