import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  flex: {
    display: "flex",
  },
  alignCenter: {
    alignItems: "center",
  },
  spaceBetween: {
    justifyContent: "space-between",
  },
  fontSize: {
    fontSize: 16,
  },
  pointer: {
    cursor: "pointer",
  },
  mrr10: {
    marginRight: 10,
  },
  drop: {
    "& .MuiTypography-root MuiTypography-body1": {
      color: "red",
    },
  },
  imgContainer: {
    width: "125px",
    height: "100px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #e0e0e0",
    color: "#aeaeae",
    backgroundColor: "#f1f1f1",
  },
  inputUploadImage: {
    display: "flex",
    alignItems: "center",
    justifyVontent: "center",
    marginTop: 15,
    color: "#999999",
    fontWeight: "normal",
    border: "1px solid #999999",
    padding: "2px 10px 2px 10px",
    borderRadius: 3,
    backgroundColor: "#f5f5f5",
  },
  widthBtnImage: {
    width: "120px",
    whiteSpace: "nowrap",
  },
  textAdd: {
    fontSize: 13,
  },
  iconCamera: {
    fontSize: 18,
    marginRight: 5,
  },
  loading: {
    position: "absolute",
    color: "#fff",
    top: 22,
    left: 22,
  },
  uploadImage: {
    marginBottom: 25,
    height: 174,
    borderRadius: 4,
    border: "dashed 2px #cbcbcb",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    cursor: "pointer",
    "&:hover": {
      opacity: 0.5,
    },
  },
  removeIcon: {
    position: "absolute",
    cursor: "pointer",
    right: -10,
    top: -20,
    "&:hover": {
      color: "red",
    },
  },
  file: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    marginRight: 15,
    maxWidth: 150,
  },
  fileName: {
    width: 150,
    wordBreak: "break-word",
    fontSize: 12,
    textAlign: "center",
  },
  cloudIcon: {
    width: 56,
    height: 56,
  },
  cloudIconSmall: {
    width: 26,
    height: 26,
    marginRight: 10,
  },
  fullWidth: {
    width: "100%",
    height: 56,
  },
  flexCol: {
    flexDirection: "column",
  },
  flexRow: {
    flexDirection: "row-reverse",
  },
  img: {
    width: "100%",
    height: "100%",
    margin: "auto",
    objectFit: "contain",
    backgroundSize: "cover",
  },
  wrap: {
    flexWrap: "wrap",
  },
  imagesContainer: {
    width: "100%",
    overflowX: "auto",
    paddingTop: "20px",
  },
  disabled: {
    opacity: 0.5,
  },
}));

export default useStyles;
