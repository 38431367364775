import { Box, Button } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import PropTypes from "prop-types";
import React from "react";
import { useForm } from "react-hook-form";

import { post, regexValidateEmail } from "../../utils/io";
import { Protected } from "../Authz/Protected";
import { FormField, SelectFormField } from "./UserFormUtils";

function InviteForm({ isLoading, rolesStatic, onClose }) {
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      email: "",
      roles: [],
    },
  });

  const inviteUser = async (invite) => {
    const res = await post("/users/invites", invite);
    if (res.status === 201) {
      enqueueSnackbar("Invite sent", { variant: "success" });
    } else {
      enqueueSnackbar(
        "Could not send invite. If the problem persists, please contact support@stel.life.",
        { variant: "error" }
      );
    }
  };

  const sendInvite = async (formData) => {
    await inviteUser(formData);
    onClose();
  };

  return (
    <form onSubmit={handleSubmit(sendInvite)}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        <FormField
          name="email"
          label="Email"
          control={control}
          errors={errors}
          rules={{
            pattern: {
              value: regexValidateEmail,
              message: "Email is not valid",
            },
          }}
        />
        {rolesStatic.length > 0 && (
          <Protected permission="AssignUserRoles">
            <SelectFormField
              control={control}
              label="Roles"
              name="roles"
              options={rolesStatic}
            />
          </Protected>
        )}
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={isLoading}
        >
          Send Invite
        </Button>
      </Box>
    </form>
  );
}

InviteForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  rolesStatic: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
    })
  ).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default InviteForm;
