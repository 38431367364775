import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import { blue, green, red } from "@mui/material/colors";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useSnackbar, SnackbarContent } from "notistack";
import PropTypes from "prop-types";
import React, { useState } from "react";

const CollapseSnackbar = React.forwardRef((props, ref) => {
  const { id, message, children, type } = props;
  const { closeSnackbar } = useSnackbar();
  const [expanded, setExpanded] = useState(false);

  const handleExpand = () => setExpanded((oldExpanded) => !oldExpanded);
  const handleDismiss = () => closeSnackbar(id);

  return (
    <SnackbarContent
      ref={ref}
      sx={{
        "@media (min-width:600px)": {
          minWidth: "344px !important",
        },
      }}
    >
      <Card
        sx={[
          { width: "100%", color: "#fff", background: blue[500] },
          type === "success" && { background: green[600] },
          type === "error" && { background: red[700] },
        ]}
      >
        <CardActions
          classes={{
            root: {
              padding: "8px 8px 8px 16px",
              justifyContent: "space-between",
            },
          }}
        >
          <Typography variant="body2">{message}</Typography>
          <Box sx={{ marginLeft: "auto" }}>
            <IconButton
              size="small"
              sx={[
                {
                  padding: "8px 8px",
                  transform: "rotate(0deg)",
                  color: "#fff",
                  transition: "all .2s",
                },
                expanded && { transform: "rotate(180deg)" },
              ]}
              onClick={handleExpand}
            >
              <ExpandMoreIcon />
            </IconButton>
            <IconButton
              size="small"
              sx={{
                padding: "8px 8px",
                transform: "rotate(0deg)",
                color: "#fff",
                transition: "all .2s",
              }}
              onClick={handleDismiss}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Paper
            sx={{
              backgroundColor: "#fff",
              padding: "16px",
              borderRadius: 0,
              fontSize: "14px",
            }}
          >
            {children}
          </Paper>
        </Collapse>
      </Card>
    </SnackbarContent>
  );
});

CollapseSnackbar.propTypes = {
  id: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  message: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};
export default CollapseSnackbar;
