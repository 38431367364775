import { Chip } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import DocsTooltip from "../DocsTooltip";

function TooltipChip(props) {
  const { description, docsUrl, color, label } = props;

  return (
    <DocsTooltip description={description} docsUrl={docsUrl} placement="bottom">
      <Chip color={color} label={label} size="small" />
    </DocsTooltip>
  );
}

TooltipChip.propTypes = {
  description: PropTypes.string.isRequired,
  docsUrl: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

export default TooltipChip;
