import { LoadingButton } from "@mui/lab";
import {
  Box,
  Chip,
  FormControl,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  Link,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import classNames from "classnames";
import { format } from "date-fns";
import { isEqual, omit } from "lodash";
import { enqueueSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { CARRIERS, ORDER_STATUS } from "../../../utils/constants";
import { del, get, getAuthHeader, post } from "../../../utils/io";
import DownloadButton from "../../DownloadButton";
import StelDatePicker from "../../StelDatePicker";
import StelModal from "../../StelModal";
import TrackingNumberFormField from "../../TrackingNumberFormField";
import UploadFile, { ImageView } from "../../UploadImages";
import ChoicesModal from "../ChoicesModal";
import ConfirmCloseViewOrder from "../ConfirmCloseViewOrder";
import InputAutoComplete from "../InputAutoComplete";
import InputText from "../InputText";

import useStyles from "./styles";

const getStateFromRow = (row) => ({
  organization: row.org || "",
  hubIds: row.hub_ids || [],
  ...(!row.id && { hubId: "", trackingNumber: "" }),
  trackingNumberIds: row.tracking_numbers || [],
  carrier: row.carrier || {},
  internalInfo: row.internal_info || "",
  images: row.images || [],
  estimatedShipDate:
    new Date(row.estimated_ship_date).toLocaleString(navigator.language, {
      timeZone: "utc",
    }) || null,
  shippingAddress: row.shipping_address || "",
  minHubVersion: row.minimum_fw_version || "",
  numberOfHubs: row.number_of_hubs || null,
  contactName: row.contact_name || "",
  custAdditionalInfo: row.cust_additional_info || "",
});

export default function OrderModal(props) {
  const classes = useStyles();

  const { isModal, onCloseOrderModal, row } = props;
  const [input, setInput] = useState(getStateFromRow(row));

  const [searchResult, setSearchResult] = useState({
    hubId: "",
    hubs: [],
    organizations: [],
  });
  const [isConfirmingCloseCreate, setIsConfirmingCloseCreate] = useState(false);
  const [isConfirmingCloseView, setIsConfirmingCloseView] = useState(false);
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);
  const [isConfirmingProcess, setIsConfirmingProcess] = useState(false);
  const [isConfirmingCancel, setIsConfirmingCancel] = useState(false);
  const [files, setFiles] = useState([]);
  const [isShowDupeHubWarning, setIsShowDupeHubWarning] = useState(false);
  const [isShowDupeTrackingNum, setIsShowDupeTrackingNum] = useState(false);
  const [imagesToDelete, setImagesToDelete] = useState([]);
  const [loading, setLoading] = useState(false);

  const isViewOrder = !!row.id;
  const isDisableFields = [
    ORDER_STATUS.CANCELLED,
    ORDER_STATUS.PROCESSED,
    ORDER_STATUS.COMPLETE,
    ORDER_STATUS.SHIPPED,
  ].includes(row.status);
  const isTrackingDisabled = [
    ORDER_STATUS.CANCELLED,
    ORDER_STATUS.COMPLETE,
    ORDER_STATUS.SHIPPED,
  ].includes(row.status);
  const isImagesDisabled = [
    ORDER_STATUS.CANCELLED,
    ORDER_STATUS.COMPLETE,
  ].includes(row.status);

  useEffect(() => {
    window.onclick = () => setIsShowDupeHubWarning(false);
  }, [setIsShowDupeHubWarning]);

  useEffect(() => {
    window.onclick = () => setIsShowDupeTrackingNum(false);
  }, [setIsShowDupeTrackingNum]);

  useEffect(() => {
    async function getOrganizations() {
      const res = await get("/admin/organizations");
      if (res.status === 200) {
        return setSearchResult((pre) => ({
          ...pre,
          organizations: res.data.organizations,
        }));
      }
      return enqueueSnackbar("Unable to retrieve organizations", {
        variant: "error",
      });
    }
    getOrganizations();
  }, []);

  const onChangeField = (e) => {
    const { value, name } = e.target;
    setInput((pre) => ({ ...pre, [name]: value }));
  };

  const onChangeDate = (value) => {
    setInput((pre) => ({ ...pre, estimatedShipDate: value }));
  };

  const isDirty = () =>
    !isEqual(
      omit(input, ["hubId", "trackingNumber"]),
      getStateFromRow(row || {})
    ) || files.length > 0;

  const selectCarrier = (e, value) => {
    setInput((pre) => ({ ...pre, carrier: value }));
  };

  const selectOrg = (e, value) => {
    setInput((pre) => ({ ...pre, organization: value }));
  };

  const onRemoveHubId = (hubId) => () => {
    setInput((pre) => ({
      ...pre,
      hubIds: pre.hubIds.filter((id) => id !== hubId),
    }));
  };

  const onRemoveTrackingNumberId = (trackingId) => () => {
    setInput((pre) => ({
      ...pre,
      trackingNumberIds: pre.trackingNumberIds.filter(
        (id) => id !== trackingId
      ),
    }));
  };

  const handleOnKeyUp = (e, fieldName, fieldIds, value) => {
    setIsShowDupeHubWarning(false);
    setIsShowDupeTrackingNum(false);
    // Only handle <Enter/Return> key
    if (e.keyCode !== 13) {
      return null;
    }

    if (value.length === 0) return null;
    if (fieldName === "hubId") {
      if (value.length < 10) return null;
      if (input.hubIds.filter((hub) => value.includes(hub)).length > 0)
        return setIsShowDupeHubWarning(true);
      setSearchResult((pre) => ({
        ...pre,
        hubs: [],
        hubId: "",
      }));
    }
    if (fieldName === "trackingNumber") {
      if (
        input.trackingNumberIds.filter(
          (trackingNumber) => value === trackingNumber
        ).length > 0
      )
        return setIsShowDupeTrackingNum(true);
    }
    return setInput((pre) => ({
      ...pre,
      [fieldIds]: pre[fieldIds].concat(value),
      [fieldName]: "",
    }));
  };

  const onChangeHubId = (e) => {
    const { name, value } = e.target;
    if (value.length > 31) return null;
    setInput((pre) => ({
      ...pre,
      [name]: value,
    }));
    setSearchResult((pre) => ({
      ...pre,
      hubs: input.hubIds.filter((hubId) => hubId.includes(e.target.value)),
      hubId: e.target.value,
    }));
    return handleOnKeyUp(e, name, "hubIds", value);
  };

  const onChangeTrackingNumber = (e) => {
    const { name, value } = e.target;
    setInput((pre) => ({
      ...pre,
      [name]: value,
    }));
    return handleOnKeyUp(e, name, "trackingNumberIds", value);
  };

  const onClose = () => {
    const {
      organization,
      hubIds,
      trackingNumber,
      trackingNumberIds,
      carrier,
      internalInfo,
      hubId,
    } = input;
    if (isDisableFields) return onCloseOrderModal();
    if (
      hubId ||
      internalInfo ||
      carrier ||
      trackingNumberIds.length ||
      trackingNumber ||
      hubIds.length ||
      organization?.id
    ) {
      if (isViewOrder) {
        return isDirty() ? setIsConfirmingCloseView(true) : onCloseOrderModal();
      }
      return setIsConfirmingCloseCreate(true);
    }
    return onCloseOrderModal();
  };

  const closeCreateModal = () => {
    setIsConfirmingCloseCreate(false);
    onCloseOrderModal();
  };

  const updateOrCreateOrder = async () => {
    const {
      organization,
      hubIds,
      trackingNumberIds,
      carrier,
      internalInfo,
      estimatedShipDate,
      contactName,
      numberOfHubs,
      minHubVersion,
      shippingAddress,
    } = input;

    return post(`admin/orders${isViewOrder ? `/${row.id}` : ""}`, {
      org_id: organization.id,
      internal_info: internalInfo,
      hub_ids: hubIds,
      tracking_numbers: carrier ? trackingNumberIds : [],
      carrier: carrier ? carrier?.value : null,
      estimated_ship_date: format(new Date(estimatedShipDate), "yyyy-MM-dd"),
      min_fw_version: minHubVersion || null,
      contact_name: contactName || null,
      ...(numberOfHubs && { number_of_hubs: numberOfHubs }),
      shipping_address: shippingAddress,
    });
  };

  const onSubmit = async () => {
    if (isImagesDisabled)
      return enqueueSnackbar("This order can no longer be updated", {
        variant: "error",
      });

    if (!input.organization?.id) {
      setLoading(false);
      return enqueueSnackbar("Organization is required", {
        variant: "error",
      });
    }
    if (!input.numberOfHubs) {
      setLoading(false);
      return enqueueSnackbar("Number of hubs is required", {
        variant: "error",
      });
    }
    if (!input.shippingAddress) {
      setLoading(false);
      return enqueueSnackbar("Shipping address is required", {
        variant: "error",
      });
    }
    if (!input.estimatedShipDate) {
      setLoading(false);
      return enqueueSnackbar("Estimated ship date is required", {
        variant: "error",
      });
    }
    if (
      !(
        Number.isInteger(input.numberOfHubs) ||
        Number.parseInt(input.numberOfHubs, 10) > 0
      )
    ) {
      setLoading(false);
      return enqueueSnackbar("Number of hubs must be a positive integer", {
        variant: "error",
      });
    }
    setLoading(true);

    let orderId = row?.id;
    if (!isTrackingDisabled) {
      const res = await updateOrCreateOrder();
      if (res.status === 400) {
        enqueueSnackbar(res.data.detail, { variant: "error" });
        return onCloseOrderModal();
      }
      orderId = res.data.id;
    }

    const url = `admin/orders/${orderId}/images`;
    if (files.length > 0) {
      const imagesFormData = new FormData();
      files.forEach((image) => imagesFormData.append("images", image));
      const uploadImagesRes = await post(url, imagesFormData, {
        headers: {
          ...getAuthHeader().headers,
          "Content-Type": "multipart/form-data",
        },
      });
      if (uploadImagesRes.status !== 204) {
        enqueueSnackbar("Error uploading images", { variant: "error" });
        return onCloseOrderModal();
      }
    }
    if (imagesToDelete.length > 0) {
      const deleteImagesRes = await del(
        url,
        {},
        { image_keys: imagesToDelete.map((image) => image.key) }
      );
      if (deleteImagesRes.status !== 204) {
        enqueueSnackbar("Error deleting images", { variant: "error" });
        return onCloseOrderModal();
      }
    }

    if (!isViewOrder) {
      setSearchResult({
        hubId: "",
        hubs: [],
        organizations: [],
      });
    }
    return onCloseOrderModal();
  };

  const deleteOrder = async () => {
    setLoading(true);
    const res = await del(`admin/orders/${row.id}`);
    if (res.status === 400) {
      setSearchResult({
        hubId: "",
        hubs: [],
        organizations: [],
      });
      return onCloseOrderModal();
    }
    setIsConfirmingDelete(false);
    return onCloseOrderModal();
  };

  const processOrder = async () => {
    setLoading(true);
    setIsConfirmingProcess(false);
    if (isDirty()) {
      if (!input.organization?.id) {
        setLoading(false);
        return enqueueSnackbar("An organization is required", {
          variant: "error",
        });
      }
      const updateRes = await updateOrCreateOrder();

      if (updateRes.status >= 400) {
        setLoading(false);
        return enqueueSnackbar(updateRes.data.detail, { variant: "error" });
      }
    }

    const processRes = await post(`admin/orders/${row.id}/process`);
    if (processRes.status >= 400) {
      setLoading(false);
      return enqueueSnackbar(processRes.data.detail, { variant: "error" });
    }
    return onCloseOrderModal();
  };

  const onCloseViewOrder = () => setIsConfirmingCloseView(false);

  const onExitAndSaveOrder = () => {
    setIsConfirmingCloseView(false);
    onSubmit();
  };

  const onExitWithoutSave = () => {
    setIsConfirmingCloseView(false);
    onCloseOrderModal();
  };

  const cancelProcessedOrder = async () => {
    setLoading(true);
    const res = await post(`admin/orders/${row.id}/cancel`);
    setLoading(false);
    if (res.status === 400) {
      enqueueSnackbar(res.data.detail, { variant: "error" });
      return setIsConfirmingCancel(false);
    }
    return onCloseOrderModal();
  };

  const handleSelectFile = (selectedFiles) => {
    setFiles((pre) => [...pre, ...selectedFiles]);
  };

  const handelRemoveFile = (index) => {
    setFiles((pre) => pre.filter((_, i) => i !== index));
  };

  const removeImage = (image, index) => {
    setInput((pre) => ({
      ...pre,
      images: pre.images.filter((_, i) => i !== index),
    }));
    setImagesToDelete((pre) => [...pre, image]);
  };

  const onConfirmOrder = async () => {
    if (isDirty()) {
      setLoading(true);
      const updateRes = await updateOrCreateOrder();

      if (updateRes.status === 400) {
        setLoading(false);
        return enqueueSnackbar(updateRes.data.detail, { variant: "error" });
      }
    }

    const res = await post(`admin/hub_orders/${row.id}/confirm`);
    if (res.status === 400) {
      setLoading(false);
      return enqueueSnackbar(res.data.detail, { variant: "error" });
    }
    return onCloseOrderModal();
  };

  const onCompleteOrder = async () => {
    setLoading(true);
    const res = await post(`admin/orders/${row.id}/complete`);
    if (res.status === 400) {
      setLoading(false);
      return enqueueSnackbar(res.data.detail, { variant: "error" });
    }
    return onCloseOrderModal();
  };

  const onShipOrder = async () => {
    setLoading(true);
    const updateRes = await updateOrCreateOrder();
    if (updateRes.status >= 400) {
      setLoading(false);
      return enqueueSnackbar(updateRes.data.detail, { variant: "error" });
    }

    const res = await post(`admin/orders/${row.id}/ship`);
    if (res.status !== 204) {
      setLoading(false);
      return enqueueSnackbar(res.data.detail, { variant: "error" });
    }
    return onCloseOrderModal();
  };

  const renderHubChips = () => {
    if (searchResult.hubId && searchResult.hubs.length) {
      return searchResult.hubs.map((hubId) => (
        <Chip
          key={hubId}
          label={hubId}
          color="primary"
          variant="outlined"
          onDelete={onRemoveHubId(hubId)}
          className={classes.chip}
          disabled={isDisableFields}
        />
      ));
    }
    if (searchResult.hubId && !searchResult.hubs.length) {
      return (
        <p
          className={classNames(
            classes.flex,
            classes.alignCenter,
            classes.justifyCenter
          )}
        >
          No Hub IDs found
        </p>
      );
    }
    if (input.hubIds.length) {
      return input.hubIds.map((hubId) => (
        <Chip
          key={hubId}
          label={hubId}
          color="primary"
          variant="outlined"
          onDelete={onRemoveHubId(hubId)}
          className={classes.chip}
          disabled={isDisableFields}
        />
      ));
    }
    return (
      <p
        className={classNames(
          classes.flex,
          classes.alignCenter,
          classes.justifyCenter
        )}
      >
        No Hub IDs added
      </p>
    );
  };

  const buttonListByStatus = {
    [`${ORDER_STATUS.PENDING_CONFIRM}`]: [
      {
        label: "Delete",
        onClick: () => setIsConfirmingDelete(true),
        color: "error",
      },
      { label: "Save", onClick: onSubmit, color: "primary" },
      { label: "Confirm", onClick: onConfirmOrder, color: "primary" },
    ],
    [`${ORDER_STATUS.CONFIRMED}`]: [
      {
        label: "Delete",
        onClick: () => setIsConfirmingDelete(true),
        color: "error",
      },
      { label: "Save", onClick: onSubmit, color: "primary" },
      {
        label: "Process",
        onClick: () => setIsConfirmingProcess(true),
        color: "primary",
      },
    ],
    [`${ORDER_STATUS.PROCESSED}`]: [
      {
        label: "Cancel",
        onClick: () => setIsConfirmingCancel(true),
        color: "error",
      },
      { label: "Save", onClick: onSubmit, color: "primary" },
      { label: "Ship", onClick: onShipOrder, color: "primary" },
    ],
    [`${ORDER_STATUS.SHIPPED}`]: [
      { label: "Save", onClick: onSubmit, color: "primary" },
      { label: "Complete", onClick: onCompleteOrder, color: "success" },
    ],
    createOrder: [
      { label: "Close", onClick: onClose, color: "error" },
      { label: "Save", onClick: onSubmit, color: "primary" },
    ],
  };

  const promptModalOptions = [
    {
      isDisplayed: isConfirmingProcess,
      onClose: () => setIsConfirmingProcess(false),
      title: "Process Order",
      onConfirm: processOrder,
      message:
        "All current changes will be saved before processing. Processed orders can no longer be updated. Are you ready to process this order?",
    },
    {
      isDisplayed: isConfirmingCancel,
      onClose: () => setIsConfirmingCancel(false),
      title: "Cancel Order",
      onConfirm: cancelProcessedOrder,
      message:
        "Cancelling an order cannot be undone. The order will need to be recreated. Are you sure you want to cancel this order?",
    },
    {
      isDisplayed: isConfirmingDelete,
      onClose: () => setIsConfirmingDelete(false),
      title: "Delete Order",
      onConfirm: deleteOrder,
      message:
        "All the information related to this order will be deleted. Are you sure you want to delete this order?",
    },
    {
      isDisplayed: isConfirmingCloseCreate,
      onClose: () => setIsConfirmingCloseCreate(false),
      title: "Close Order",
      onConfirm: closeCreateModal,
      message:
        "This order has not been saved yet. Are you sure you want to cancel?",
    },
  ];

  const getCarrierObject = (carrier) =>
    CARRIERS.filter((c) => c.label === carrier)[0] || null;

  const getTitleOfModal = () => {
    if (row.id)
      return (
        <div className={classNames(classes.flex, classes.alignCenter)}>
          {row.id}{" "}
          <Chip
            label={row.status.toUpperCase()}
            size="small"
            className={classNames(
              classes.mrl15,
              classes[row.status.replace(" ", "_")],
              classes.whiteBg
            )}
          />
        </div>
      );
    return "New Order";
  };

  const getOrgLabel = (org) => (org ? `${org.name} (${org.alias})` : "");

  return (
    <StelModal
      open={isModal}
      onClose={onClose}
      title={getTitleOfModal()}
      width={1000}
    >
      <Grid className={classes.container}>
        {isViewOrder && (
          <Box sx={{ mb: 1.25 }}>
            {[
              {
                label: "Created time",
                value: new Date(row.created_time).toLocaleString(),
              },
              {
                label: "Created by",
                value: `${row.created_by.first_name} ${row.created_by.last_name} (${row.created_by.username})`,
              },
              ...(row.hub_return_id
                ? [
                    {
                      label: "Generated from return",
                      value: (
                        <Link
                          href={`/hub-returns/${row.hub_return_id}/evaluate`}
                        >
                          {row.hub_return_id}
                        </Link>
                      ),
                    },
                  ]
                : []),
            ].map(({ label, value }) => (
              <Typography key={label} sx={{ color: grey[500], fontSize: 15 }}>
                {label}: {value}
              </Typography>
            ))}
          </Box>
        )}
        <div className={classes.mrb15}>
          <InputAutoComplete
            options={searchResult.organizations}
            getOptionLabel={getOrgLabel}
            name="organization"
            label={
              <span>
                Organization
                <span className={classes.errorText}>*</span>
              </span>
            }
            onChange={selectOrg}
            value={input.organization || null}
            defaultValue={row?.org || null}
            disabled={isDisableFields}
            isOptionEqualToValue={(option, value) =>
              option.alias === value?.alias
            }
          />
        </div>
        <div className={classes.mrb15}>
          <InputText
            name="numberOfHubs"
            label={
              <span>
                Number of Hubs
                <span className={classes.errorText}>*</span>
              </span>
            }
            onChangeField={onChangeField}
            value={input.numberOfHubs}
            disabled={isDisableFields}
            type="number"
          />
        </div>
        <div className={classes.mrb15}>
          <InputText
            name="contactName"
            label="Contact Name"
            onChangeField={onChangeField}
            value={input.contactName}
            disabled={isDisableFields}
          />
        </div>
        <div className={classes.mrb15}>
          <InputText
            name="shippingAddress"
            onChangeField={onChangeField}
            value={input.shippingAddress}
            disabled={isDisableFields}
            label={
              <span>
                Shipping Address
                <span className={classes.errorText}>*</span>
              </span>
            }
          />
        </div>
        <div className={classes.mrb15}>
          <div
            className={classNames(
              classes.w170px,
              classes.bold,
              classes.flex,
              classes.alignCenter,
              classes.mrb10
            )}
          >
            Estimated Ship Date
            <span className={classes.errorText}>*</span>
          </div>
          <StelDatePicker
            onChange={onChangeDate}
            value={input.estimatedShipDate}
            minDate={new Date()}
            disabled={isDisableFields}
          />
        </div>

        <div className={classes.mrb15}>
          <InputText
            name="internalInfo"
            label="Internal Info"
            multiline
            rows={4}
            onChangeField={onChangeField}
            value={input.internalInfo}
            disabled={isDisableFields}
          />
        </div>
        <div className={classes.mrb15}>
          <InputText
            name="custAdditionalInfo"
            label="Customer Additional Info"
            multiline
            rows={4}
            value={input.custAdditionalInfo}
            disabled
          />
        </div>
        <div className={classes.mrb15}>
          <InputText
            name="minHubVersion"
            onChangeField={onChangeField}
            value={input.minHubVersion}
            disabled={isDisableFields}
            label="Minimum Hub Version"
          />
        </div>
        <div
          className={classNames(
            classes.bold,
            classes.flex,
            classes.alignCenter
          )}
        >
          Hubs
        </div>
        <p className={classNames(classes.mrt0, classes.mrb0, classes.grey)}>
          At least 10 characters are required
        </p>
        <div
          className={classNames(
            classes.mrb15,
            classes.input400,
            classes.border
          )}
        >
          <div
            className={classNames(
              classes.flex,
              classes.alignCenter,
              classes.inputPadding,
              classes.borderBottom
            )}
          >
            <FormControl
              fullWidth
              variant="standard"
              className={classes.input}
              size="small"
              disabled={isDisableFields}
            >
              <InputLabel htmlFor="standard-adornment-amount">
                Search or Add Hub ID
              </InputLabel>
              <Input
                id="standard-adornment-amount"
                onKeyUp={onChangeHubId}
                onChange={onChangeHubId}
                value={input.hubId}
                name="hubId"
                endAdornment={
                  <InputAdornment position="end">
                    {input.hubIds.length}
                  </InputAdornment>
                }
              />
            </FormControl>
          </div>
          <div
            className={classNames(
              classes.pd12,
              classes.scroll,
              classes.relative,
              classes.pdt30
            )}
          >
            {isShowDupeHubWarning && (
              <div
                className={classNames(
                  classes.absolute,
                  classes.warning,
                  classes.flex,
                  classes.alignCenter,
                  classes.spaceBetween
                )}
              >
                <p className={classes.text}>Hub ID has already been added</p>
              </div>
            )}
            {renderHubChips()}
          </div>
        </div>
        {input.hubIds.length > 0 && (
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <DownloadButton
              getData={() => `Hub ID\n${input.hubIds.join("\n")}`}
              fileType="text/csv"
              fileName={`hub_order_${row.id}_${new Date().toISOString()}.csv`}
            >
              Export Hub ID List
            </DownloadButton>
          </Box>
        )}
        <div className={classNames(classes.mrb15)}>
          <InputAutoComplete
            options={CARRIERS}
            getOptionLabel={(option) => option?.label || option}
            name="carrier"
            label="Carrier"
            onChange={selectCarrier}
            value={getCarrierObject(input.carrier)}
            defaultValue={getCarrierObject(row?.carrier)}
            disabled={isTrackingDisabled}
            isOptionEqualToValue={(option, value) =>
              option.value === (value?.value || value)
            }
          />
        </div>
        {input.carrier && (
          <TrackingNumberFormField
            value={input.trackingNumber}
            onChangeTrackingNumber={onChangeTrackingNumber}
            isShowDupeTrackingNum={isShowDupeTrackingNum}
            onRemoveTrackingNumberId={onRemoveTrackingNumberId}
            trackingNumberIds={input.trackingNumberIds}
            disabled={isTrackingDisabled}
          />
        )}
        {isViewOrder && input.images.length > 0 && (
          <>
            <h4>{`Images (${input.images.length})`}</h4>
            <div className={classNames(classes.flex, classes.imagesContainer)}>
              {input.images.map((image, index) => (
                <ImageView
                  key={image.key}
                  image={image.presigned_url}
                  onRemove={() => removeImage(image, index)}
                  disabled={isImagesDisabled}
                />
              ))}
            </div>
          </>
        )}
        {!isImagesDisabled && (
          <>
            <h4>Add Images</h4>
            <UploadFile
              files={files}
              onSelectFile={handleSelectFile}
              isVisibleFile
              disabled={isImagesDisabled}
              onRemoveFile={handelRemoveFile}
            />
          </>
        )}
        <div className={classes.btnCreate}>
          {(buttonListByStatus[row.status || "createOrder"] || []).map(
            (button) => (
              <LoadingButton
                loading={loading}
                color={button.color}
                variant="contained"
                className={classes.mrr16}
                onClick={button.onClick}
                disabled={loading}
              >
                {button.label}
              </LoadingButton>
            )
          )}
        </div>
        {promptModalOptions.map((option) => (
          <ChoicesModal
            key={option.title}
            isDisplayed={option.isDisplayed}
            onClose={option.onClose}
            title={option.title}
            onConfirm={option.onConfirm}
            message={option.message}
            width={option?.width}
          />
        ))}
        {isConfirmingCloseView && (
          <StelModal
            open
            onClose={onCloseViewOrder}
            title="Close View Order"
            width={450}
          >
            <ConfirmCloseViewOrder
              onCloseModal={onCloseViewOrder}
              onExitAndSaveOrder={onExitAndSaveOrder}
              onExitWithoutSave={onExitWithoutSave}
            />
          </StelModal>
        )}
      </Grid>
    </StelModal>
  );
}

OrderModal.propTypes = {
  onCloseOrderModal: PropTypes.func.isRequired,
  isModal: PropTypes.bool.isRequired,
  row: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.arrayOf(
        PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.objectOf(PropTypes.string),
        ])
      ),
      PropTypes.objectOf(
        PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.bool,
          PropTypes.number,
        ])
      ),
    ])
  ),
};
OrderModal.defaultProps = {
  row: {},
};
