import { Tooltip, Box, Typography, Stack } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { MEASURE_TYPES } from "../utils/constants";

function MeasureValueTooltip(props) {
  const { value, params } = props;
  const {
    row: { type },
    colDef: { valueGetter },
  } = params;
  return (
    <Tooltip
      sx={{ width: 200 }}
      arrow
      title={
        <Box>
          {Object.keys(value).map((field) => {
            const measureTypeField =
              MEASURE_TYPES[type]?.fields[field.toLowerCase()];
            const name = measureTypeField?.label || field;
            const unit = measureTypeField?.unit || "";
            const displayValue = `${value[field]} ${unit}`;
            return (
              <Stack alignItems="center" direction="row" margin={0} key={field}>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    margin: "5px 0",
                    width: 90,
                    fontSize: "11px",
                  }}
                >
                  {name}:
                </Typography>
                <span>{displayValue}</span>
              </Stack>
            );
          })}
        </Box>
      }
    >
      <span
        style={{ width: 250, textOverflow: "ellipsis", overflow: "hidden" }}
      >
        {valueGetter(params)}
      </span>
    </Tooltip>
  );
}

export default MeasureValueTooltip;

MeasureValueTooltip.propTypes = {
  params: PropTypes.shape().isRequired,
  value: PropTypes.shape().isRequired,
};
