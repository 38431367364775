import { grey } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  form: {
    border: `1px solid ${grey[300]}`,
    padding: 20,
    borderRadius: 4,
    maxWidth: 400,
  },
  mrb20: {
    marginBottom: "20px !important",
  },
  w100: {
    width: "100%",
  },
  progress: {
    marginRight: 10,
  },
  link: {
    textAlign: "center",
    marginTop: 15,
  },
}));

export default useStyles;
