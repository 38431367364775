import { Box, Button, Menu, MenuItem } from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";

function DropdownButton(props) {
  const {
    components,
    id,
    title,
    options,
    onClick,
    customMenuItem: CustomMenuItem,
    ...rest
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (value) => () => {
    if (onClick) {
      onClick(value);
    }
    setAnchorEl(null);
  };

  return (
    <Box>
      <Button
        onClick={handleClick}
        aria-haspopup="true"
        aria-controls={id}
        {...components.button}
        {...rest}
      >
        {title}
      </Button>
      <Menu
        id={id}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        {...components.menu}
      >
        {options.map((option) => (
          <MenuItem key={option.value} onClick={handleMenuClick(option)}>
            {CustomMenuItem ? <CustomMenuItem {...option} /> : option.label}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}

DropdownButton.propTypes = {
  components: PropTypes.shape({
    button: PropTypes.shape(),
    menu: PropTypes.shape(),
  }),
  menuComponent: PropTypes.shape({}),
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })
  ).isRequired,
  onClick: PropTypes.func,
  customMenuItem: PropTypes.func,
};
DropdownButton.defaultProps = {
  components: {
    button: {},
    menu: {},
  },
  menuComponent: {},
  onClick: undefined,
  customMenuItem: undefined,
};

export default DropdownButton;
