import CloseIcon from "@mui/icons-material/Close";
import { Box, Grid, IconButton } from "@mui/material";

import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider as StyledEngine,
} from "@mui/material/styles";
import { SnackbarProvider, closeSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";

import ForgotPassword from "./components/Auth/ForgotPassword";
import VerifyPassword from "./components/Auth/VerifyPassword";
import AuthProvider from "./components/AuthProvider";
import { ProtectedComponent } from "./components/Authz/Protected";
import ChangePassword from "./components/ChangePassword";
import CollapseSnackbar from "./components/CollapseSnackbar";
import DemoDevice from "./components/DemoDevice";
import Device from "./components/Device";
import DeviceEcosystem from "./components/DeviceEcosystem";
import DeviceList from "./components/DeviceList";
import Devices from "./components/Devices";
import Endpoint from "./components/Endpoint";
import GetStarted from "./components/GetStarted";
import Header from "./components/Header";
import HubDetail from "./components/HubDetail";
import HubGroup from "./components/HubGroup";
import HubGroupList from "./components/HubGroupList";
import HubList from "./components/HubList";
import HubReturn from "./components/HubReturn";
import ReturnEvaluation from "./components/HubReturn/ReturnEvaluation";
import HubReturns from "./components/HubReturns";
import MainLayout, { CONTENT_ROOT_ID } from "./components/Layouts/MainLayout";
import Login from "./components/Login";
import Measures from "./components/Measures";
import NewOrganization from "./components/NewOrganization";
import NewOrgConfirmation from "./components/NewOrgConfirmation";
import OauthTokenCallback from "./components/OauthTokenCallback";
import Orders from "./components/Orders";
import HubOrder from "./components/Orders/HubOrder";
import OrderRequest from "./components/Orders/OrderRequest";
import Organization from "./components/Organization";
import SimulateMeasures from "./components/SimulateMeasures";
import Troubleshoot from "./components/Troubleshoot";
import TroubleshootTickets from "./components/TroubleshootTickets";
import Users from "./components/Users";
import VerifyUser from "./components/Users/VerifyUser";
import XealthApp from "./components/Xealth/XealthApp";

import {
  isOrgEnterprise,
  isOrgUser,
  isSandbox,
  userIsLoggedIn,
  isAdmin,
  isEnableDeviceRegistration,
} from "./utils/io";

const theme = createTheme();

function RenderLoggedIn() {
  return (
    <MainLayout>
      <Switch>
        <ProtectedRoute permission="ViewHubs" exact path="/hubs/:hubId">
          <HubDetail />
        </ProtectedRoute>
        <ProtectedRoute permission="ViewHubs" exact path="/hubs">
          <HubList />
        </ProtectedRoute>
        <ProtectedRoute permission="ViewMeasures" exact path="/measures">
          <Measures />
        </ProtectedRoute>
        <ProtectedRoute
          permission="CreateHubOrders"
          exact
          path="/hub-orders/request"
        >
          <OrderRequest />
        </ProtectedRoute>
        {(isOrgEnterprise() || isAdmin()) && !isSandbox() && (
          <ProtectedRoute
            permission="ViewHubOrders"
            exact
            path="/hub-orders/:orderId"
          >
            <HubOrder />
          </ProtectedRoute>
        )}
        <Route exact path="/device-ecosystem">
          <DeviceEcosystem />
        </Route>
        <Route exact path="/demo-devices">
          <DeviceList />
        </Route>
        <Route exact path="/demo-devices/:mac">
          <DemoDevice />
        </Route>
        {isOrgUser() && (
          <ProtectedRoute permission="ViewUsers" exact path="/users">
            <Users />
          </ProtectedRoute>
        )}
        <ProtectedRoute permission="ViewHubGroups" exact path="/hub-group">
          <HubGroupList />
        </ProtectedRoute>
        <ProtectedRoute
          permission="UpdateHubGroups"
          exact
          path="/hub-group/:id"
        >
          <HubGroup />
        </ProtectedRoute>
        {isOrgUser() && (
          <ProtectedRoute permission="ViewEndpoints" exact path="/endpoints">
            <Endpoint />
          </ProtectedRoute>
        )}
        {isSandbox() && (
          <Route exact path="/simulate-measurements">
            <SimulateMeasures />
          </Route>
        )}
        {isSandbox() && (
          <Route exact path="/get-started">
            <GetStarted />
          </Route>
        )}
        {!isSandbox() && isAdmin() && (
          <ProtectedRoute
            permission="ViewTroubleshootTickets"
            exact
            path="/troubleshoot-tickets"
          >
            <TroubleshootTickets />
          </ProtectedRoute>
        )}
        {!isSandbox() && (
          <ProtectedRoute permission="ViewHubReturns" exact path="/hub-returns">
            <HubReturns />
          </ProtectedRoute>
        )}
        {!isSandbox() && (isOrgEnterprise() || isAdmin()) && (
          <ProtectedRoute
            permission="ManageHubReturns"
            exact
            path="/hub-returns/:returnId"
          >
            <HubReturn />
          </ProtectedRoute>
        )}
        {isAdmin() && (
          <Route exact path="/hub-returns/:id/evaluate">
            <ReturnEvaluation />
          </Route>
        )}
        <ProtectedRoute permission="ViewHubOrders" exact path="/hub-orders">
          <Orders />
        </ProtectedRoute>
        {isOrgUser() && !isAdmin() && (
          <Route exact path="/organization">
            <Organization />
          </Route>
        )}
        {(isEnableDeviceRegistration() || isAdmin()) && (
          <Route exact path="/devices">
            <Devices />
          </Route>
        )}
        {(isEnableDeviceRegistration() || isAdmin()) && (
          <Route exact path="/devices/:deviceId">
            <Device />
          </Route>
        )}
        <Route exact path="/change-password">
          <ChangePassword />
        </Route>
        <Route path="*">
          {isSandbox() ? (
            <Redirect to="/get-started" />
          ) : (
            <Redirect to="/hubs" />
          )}
        </Route>
      </Switch>
    </MainLayout>
  );
}

function RenderLoggedOut() {
  const path = window.location.pathname;

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Header />
      <Box
        sx={{
          height: "calc(100vh - 64px)",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Switch>
          <Route exact path="/login">
            <Login />
          </Route>
          <Route exact path="/auth/oauth-token">
            <OauthTokenCallback />
          </Route>
          <Route exact path="/users/invites/verify">
            <VerifyUser />
          </Route>
          <Route exact path="/new-organization">
            <NewOrganization />
          </Route>
          <Route exact path="/new-organization/confirm">
            <NewOrgConfirmation />
          </Route>
          <Route exact path="/password-reset">
            <ForgotPassword />
          </Route>
          <Route exact path="/password-reset/confirm">
            <VerifyPassword />
          </Route>
          <Route path="*">
            <Redirect to={`/login?redirect=${path}`} />
          </Route>
        </Switch>
      </Box>
    </Grid>
  );
}

function ScrollToTop() {
  // Allows scroll restoration when navigating to different routes
  const { pathname } = useLocation();

  useEffect(
    // Specifically selecting the node that is larger than the screen because it has
    // overflow
    () => document.getElementById(CONTENT_ROOT_ID)?.scrollTo(0, 0),
    [pathname]
  );

  return null;
}

function App() {
  // FIXME: Need a proper auth implementation
  // See the io util for additional notes.

  const location = useLocation();

  // GA use effect must be within component that is wrapped in BrowserRouter, that is
  // why App is a separate component from AppContainer
  useEffect(() => {
    if (!userIsLoggedIn())
      ReactGA.send({
        hitType: "pageview",
        page: location.pathname,
        page_title: location.pathname,
      });
  }, [location.pathname]);

  return (
    <Switch>
      <Route exact path="/xealth/:xpid">
        <XealthApp />
      </Route>
      {userIsLoggedIn() && (
        <Route exact path="/hubs/:hubId/troubleshoot">
          <Troubleshoot />
        </Route>
      )}
      <Route path="*">
        {userIsLoggedIn() ? <RenderLoggedIn /> : <RenderLoggedOut />}
      </Route>
    </Switch>
  );
}

const closeSnackbarAction = (snackbarId) => (
  <IconButton
    sx={{ color: "white" }}
    onClick={() => closeSnackbar(snackbarId)}
    size="small"
  >
    <CloseIcon fontSize="small" />
  </IconButton>
);

function AppContainer() {
  return (
    <StyledEngine injectFirst>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          maxSnack={3}
          Components={{
            collapse: CollapseSnackbar,
          }}
          action={(snackbarId) => closeSnackbarAction(snackbarId)}
        >
          <Router>
            <AuthProvider>
              <ScrollToTop />
              <App />
            </AuthProvider>
          </Router>
        </SnackbarProvider>
      </ThemeProvider>
    </StyledEngine>
  );
}

RenderLoggedIn.propTypes = {};

RenderLoggedOut.propTypes = {};

function ProtectedRoute(props) {
  const { permission, ...routeProps } = props;
  return (
    <ProtectedComponent
      permission={permission}
      WrappedComponent={Route}
      {...routeProps}
    />
  );
}

ProtectedRoute.propTypes = {
  permission: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
};

export default AppContainer;
