import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

function HubCellularHelpfulTipCard({ title, description, image }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "12px",
        justifyContent: "space-between",
      }}
    >
      {title && description && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            fontSize={16}
            fontWeight={600}
            color="#000000de"
            textAlign="center"
          >
            {title}
          </Typography>
          <Typography
            fontSize={16}
            fontWeight={600}
            color="#FF2D2D"
            textAlign="center"
          >
            {description}
          </Typography>
        </Box>
      )}
      <img style={{ width: "100%" }} src={image} alt={image} />
    </Box>
  );
}

HubCellularHelpfulTipCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string.isRequired,
};
HubCellularHelpfulTipCard.defaultProps = {
  title: "",
  description: "",
};

export default HubCellularHelpfulTipCard;
