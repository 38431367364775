import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Snackbar } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { SNACKBAR_STATUS } from "../../utils/constants";

import useStyles from "./styles";

function ShowSnackBar(props) {
  const classes = useStyles();

  const { onClose, message, open, status, duration = 3000 } = props;

  const onCloseSnack = () => onClose();

  const getStatus = () => {
    if (status === SNACKBAR_STATUS.SUCCESS) return classes.snackBarSuccess;
    if (status === SNACKBAR_STATUS.WARNING) return classes.snackBarWarning;
    return classes.snackBarFail;
  };

  return (
    <Snackbar
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={onCloseSnack}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      }
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      autoHideDuration={duration}
      className={getStatus()}
      message={message}
      open={open}
      onClose={onCloseSnack}
    />
  );
}

ShowSnackBar.propTypes = {
  onClose: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
  duration: PropTypes.number,
};
ShowSnackBar.defaultProps = {
  duration: 3000,
};

export default ShowSnackBar;
