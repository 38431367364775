import { Box, Tab, Tabs } from "@mui/material";
import { grey } from "@mui/material/colors";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { DOCS } from "../../utils/constants";
import { get, hasPermission, isSsoUser, getFeatureFlag } from "../../utils/io";
import MainContentContainer from "../Layouts/MainContentContainer";
import InviteTable from "./InviteTable";
import RoleTable from "./RoleTable";
import TabPanel from "./TabPanel";
import UserTable from "./UserTable";

export default function Users() {
  const [tab, setTab] = useState(0);
  const [tabs, setTabs] = useState([]);
  const [tabPanels, setTabPanels] = useState([]);
  const [rolesStatic, setRolesStatic] = useState([]);
  const [permissions, setPermissions] = useState([]);

  const getPermissions = async () => {
    try {
      // TODO requestv2
      const res = await get("/permissions");
      if (res.status === 200) {
        setPermissions(res.data.permissions);
      } else {
        throw res;
      }
    } catch (error) {
      enqueueSnackbar("Failed to get permissions", { variant: "error" });
    }
  };

  const getRoles = async () => {
    if (!hasPermission("ViewRoles")) {
      return;
    }
    // TODO requestv2
    const resp = await get("/roles");
    if (resp.status === 200) {
      setRolesStatic(resp.data.roles);
    } else {
      enqueueSnackbar("Failed to get roles", { variant: "error" });
    }
  };

  // Build tabs based on permissions
  useEffect(() => {
    let tabIdx = 0;
    const localTabs = [];
    const localTabPanels = [];

    const tabSx = {
      textTransform: "capitalize",
      color: "#3f51b5",
    };

    if (hasPermission("ViewUsers")) {
      localTabs.push(<Tab label="Users" value={0} sx={tabSx} key="Users" />);
      localTabPanels.push(
        <TabPanel value={tab} index={tabIdx} key="Users">
          <UserTable permissions={permissions} rolesStatic={rolesStatic} />
        </TabPanel>
      );
      tabIdx += 1;
    }

    if (!isSsoUser() && hasPermission("ViewUserInvites")) {
      localTabs.push(
        <Tab label="Invites" value={1} sx={tabSx} key="Invites" />
      );
      localTabPanels.push(
        <TabPanel value={tab} index={tabIdx} key="Invites">
          <InviteTable rolesStatic={rolesStatic} />
        </TabPanel>
      );
      tabIdx += 1;
    }

    if (getFeatureFlag("api_permissions") && hasPermission("ViewRoles")) {
      localTabs.push(<Tab label="Roles" value={2} sx={tabSx} key="Roles" />);
      localTabPanels.push(
        <TabPanel value={tab} index={2} key="Roles">
          <RoleTable
            permissions={permissions}
            rolesStatic={rolesStatic}
            onRolesStaticChange={getRoles}
          />
        </TabPanel>
      );
    }

    setTabs(localTabs);
    setTabPanels(localTabPanels);
  }, [tab, permissions, rolesStatic]);

  useEffect(() => {
    getPermissions();
    getRoles();
  }, []);

  return (
    <MainContentContainer title="Users" docsUrl={DOCS.USERS.url}>
      <Box
        sx={{
          "& .MuiTabs-flexContainer": {
            borderBottom: `1px solid ${grey[400]}`,
          },
        }}
      >
        <Tabs
          sx={{ pb: "15px" }}
          value={tab}
          onChange={(event, newTab) => setTab(newTab)}
          indicatorColor="primary"
        >
          {tabs}
        </Tabs>
        {tabPanels}
      </Box>
    </MainContentContainer>
  );
}
