import { Stack } from "@mui/material";
import { endOfDay, startOfDay, sub } from "date-fns";
import { debounce, intersectionWith, uniqBy } from "lodash";
import { enqueueSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { MEASURE_TYPES } from "../../utils/constants";
import { get, hasPermission } from "../../utils/io";
import { Protected } from "../Authz/Protected";
import { usePagination } from "../hooks";
import MeasureListToolbar from "../Measures/MeasureToolbar";
import BindDevicesButton from "../Measures/MeasureToolbar/BindDevicesButton";
import BlockControlButton from "../Measures/MeasureToolbar/BlockControlButton";
import UnbindDevicesButton from "../Measures/MeasureToolbar/UnbindDevicesButton";
import MeasureValueTooltip from "../MeasureValueTooltip";
import StelDataGrid from "../StelDataGrid";

export const DEVICES_QUERY_CONFIG = Object.freeze({
  BOUND_HUB: "bound_hub",
  ON_HOLD_HUB: "on_hold_hub",
  BLOCKED_DEVICES: "blocked_devices",
});

function HubDevices(props) {
  const {
    hubId,
    onSuccessfulAction,
    toolbarButtons,
    queryConfig,
    additionalRefreshDependencies,
  } = props;
  const [devices, setDevices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedDevicesIds, setSelectedDevicesIds] = useState([]);
  const [refreshDevices, setRefreshDevices] = useState({});

  const {
    pagination: { page, pageSize },
    handlePageChange,
    handleRowPerPageChange,
    rowCount,
    setRowCount,
  } = usePagination(5);

  const selectedDevices = devices.filter((device) =>
    selectedDevicesIds.includes(device.id)
  );

  const handleRefreshDevices = () => setRefreshDevices({});

  const getDeviceFromRow = (params) =>
    devices.filter((device) => device.id === params.row.id) || [];

  const handleSuccessfulAction = () => {
    onSuccessfulAction();
    handleRefreshDevices();
  };

  const COLUMNS = [
    {
      field: "type",
      headerName: "Type",
      flex: 0.2,
      valueGetter: (params) => MEASURE_TYPES[params.row.type]?.label || "",
    },
    {
      field: "makeModel",
      flex: 0.25,
      headerName: "Make/Model",
      valueGetter: (params) =>
        `${params.row.make} ${params.row.model_display_name}`,
    },
    {
      field: "mac_address",
      flex: 0.25,
      headerName: "MAC Address",
    },
    {
      field: "last_used_time",
      flex: 0.3,
      headerName: "Last Used",
      valueGetter: (params) =>
        new Date(params.row.last_used_time).toLocaleString(),
    },
    {
      field: "last_measure",
      flex: 0.4,
      headerName: "Last Measure",
      valueGetter: (params) => JSON.stringify(params.row?.last_measure),
      renderCell: (params) => (
        <MeasureValueTooltip
          params={params}
          value={params.row?.last_measure || {}}
        />
      ),
    },
    ...(hasPermission("BindDevices") ||
    hasPermission("BlockDevices") ||
    hasPermission("UnbindDevices")
      ? [
          {
            field: "action",
            flex: 0.35,
            type: "actions",
            renderCell: (params) => (
              <Stack direction="row">
                {toolbarButtons.bindDevices && (
                  <Protected permission="BindDevices">
                    <BindDevicesButton
                      buttonText="BIND"
                      buttonProps={{ variant: "contained", size: "small" }}
                      bindingsToCreate={getDeviceFromRow(params).map(
                        ({ mac_address: macAddress }) => ({ macAddress, hubId })
                      )}
                      handleSuccessfulBind={handleSuccessfulAction}
                    />
                  </Protected>
                )}
                {toolbarButtons.unbindDevices && (
                  <Protected permission="UnbindDevices">
                    <UnbindDevicesButton
                      buttonText="UNBIND"
                      buttonProps={{
                        variant: "contained",
                        size: "small",
                        color: "error",
                      }}
                      devices={getDeviceFromRow(params)}
                      handleSuccessfulUnbind={handleSuccessfulAction}
                    />
                  </Protected>
                )}
                {toolbarButtons.blockDevices && (
                  <Protected permission="BlockDevices">
                    <BlockControlButton
                      buttonText="BLOCK"
                      buttonProps={{
                        variant: "contained",
                        size: "small",
                        color: "error",
                      }}
                      devices={getDeviceFromRow(params)}
                      handleSuccessfulBlock={handleSuccessfulAction}
                    />
                  </Protected>
                )}
                {toolbarButtons.unblockDevices && (
                  <Protected permission="BlockDevices">
                    <BlockControlButton
                      buttonText="UNBLOCK"
                      buttonProps={{
                        variant: "contained",
                        size: "small",
                        color: "error",
                      }}
                      blockDevices={false}
                      devices={getDeviceFromRow(params)}
                      handleSuccessfulBlock={handleSuccessfulAction}
                    />
                  </Protected>
                )}
              </Stack>
            ),
          },
        ]
      : []),
  ];

  const queryParams = useMemo(
    () => ({
      [DEVICES_QUERY_CONFIG.BOUND_HUB]: { bound_hub_id: hubId },
      [DEVICES_QUERY_CONFIG.ON_HOLD_HUB]: { device_hold_hub_id: hubId },
      [DEVICES_QUERY_CONFIG.BLOCKED_DEVICES]: {
        last_seen_hub_id: hubId,
        is_blocked: true,
        used_after: startOfDay(sub(new Date(), { months: 6 })).toISOString(),
        used_before: endOfDay(new Date()).toISOString(),
      },
    }),
    [hubId]
  );

  useEffect(() => {
    const getDevices = debounce(async () => {
      setLoading(true);
      const res = await get("/devices", {
        include_last_measure: true,
        offset: page * pageSize,
        limit: pageSize,
        ...queryParams[queryConfig],
      });
      if (res.status === 200) {
        const { devices: resDevices, total_count: totalCount } = res.data;
        setDevices(resDevices);
        setRowCount(totalCount);
      } else {
        enqueueSnackbar("Unable to retrieve devices", {
          variant: "error",
        });
      }
      setLoading(false);
    }, 300);
    getDevices();
    return getDevices.cancel;
  }, [
    hubId,
    page,
    pageSize,
    setRowCount,
    refreshDevices,
    queryParams,
    queryConfig,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ...additionalRefreshDependencies,
  ]);

  const bindingsToCreate = uniqBy(
    intersectionWith(
      devices,
      selectedDevicesIds,
      (device, selectedDevice) => device.id === selectedDevice
    ),
    (device) => device.mac_address
  ).map((device) => ({
    hubId,
    macAddress: device.mac_address,
  }));

  return (
    <StelDataGrid
      columns={COLUMNS}
      rows={devices}
      checkboxSelection
      disableSelectionOnClick
      loading={loading}
      pagination
      paginationMode="server"
      rowCount={rowCount}
      pageSize={pageSize}
      onPageChange={handlePageChange}
      onPageSizeChange={handleRowPerPageChange}
      rowsPerPageOptions={[5]}
      selectionModel={selectedDevicesIds}
      onSelectionModelChange={(selectionModel) =>
        setSelectedDevicesIds(selectionModel)
      }
      components={{ Toolbar: MeasureListToolbar }}
      componentsProps={{
        toolbar: {
          visibleToolbarItems: {
            resend: false,
            export: false,
            autoRefresh: false,
            ...toolbarButtons,
          },
          isDisabled: selectedDevicesIds.length === 0,
          bindDevicesButtonProps: {
            bindingsToCreate,
            handleSuccessfulBind: handleSuccessfulAction,
          },
          blockDevicesButtonProps: {
            devices: selectedDevices,
            handleSuccessfulBlock: handleSuccessfulAction,
          },
          unbindDevicesButtonProps: {
            devices: selectedDevices,
            handleSuccessfulUnbind: handleSuccessfulAction,
          },
        },
      }}
    />
  );
}

HubDevices.propTypes = {
  hubId: PropTypes.string.isRequired,
  onSuccessfulAction: PropTypes.func,
  toolbarButtons: PropTypes.shape({
    bindDevices: PropTypes.bool.isRequired,
    unbindDevices: PropTypes.bool.isRequired,
    blockDevices: PropTypes.bool.isRequired,
    unblockDevices: PropTypes.bool.isRequired,
  }),
  queryConfig: PropTypes.oneOf(Object.values(DEVICES_QUERY_CONFIG)).isRequired,
  additionalRefreshDependencies: PropTypes.arrayOf(PropTypes.shape({})),
};

HubDevices.defaultProps = {
  onSuccessfulAction: null,
  toolbarButtons: {
    bindDevices: true,
    unbindDevices: true,
    blockDevices: true,
    unblockDevices: true,
  },
  additionalRefreshDependencies: [],
};

export default HubDevices;
