import LoadingButton from "@mui/lab/LoadingButton";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { DOCS } from "../../../utils/constants";
import { post } from "../../../utils/io";
import ButtonWithTooltip from "../../ButtonWithTooltip";
import ConfirmBlockDeviceModal from "../../ConfirmBlockDeviceModal";
import showMultiResultsSnackbar from "./utils";

function BlockControlButton(props) {
  const {
    devices,
    disabled,
    handleSuccessfulBlock,
    buttonText,
    buttonProps,
    blockDevices,
  } = props;
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleCloseModal = () => setOpenModal(false);

  const handleBlockControlDevices = async () => {
    setLoading(true);
    const blockDeviceCalls = devices.map(({ id }) =>
      post(`/devices/${id}`, { is_blocked: blockDevices })
    );
    const resBlockDevices = await Promise.all(blockDeviceCalls);
    const blockStr = `${blockDevices ? "" : "un"}block`;
    const allSuccessful = showMultiResultsSnackbar(
      resBlockDevices,
      204,
      (successCount) => `${successCount} device(s) ${blockStr}ed`,
      (failureCount) => `${failureCount} device(s) failed to ${blockStr}`
    );
    if (allSuccessful) {
      handleCloseModal();
      if (handleSuccessfulBlock) handleSuccessfulBlock();
    }
    setLoading(false);
    return allSuccessful;
  };

  const tooltipInfo = blockDevices ? DOCS.BLOCK_DEVICE : DOCS.UNBLOCK_DEVICE;

  return (
    <>
      <ButtonWithTooltip
        description={tooltipInfo.description}
        url={tooltipInfo?.url}
        onClick={
          blockDevices ? () => setOpenModal(true) : handleBlockControlDevices
        }
        disabled={disabled}
        title={buttonText}
        component={LoadingButton}
        loading={loading}
        sx={{
          padding: "5px 10px",
          mr: "10px",
          textTransform: "capitalize",
        }}
        variant="outlined"
        {...buttonProps}
      />
      <ConfirmBlockDeviceModal
        open={openModal}
        onClose={handleCloseModal}
        onConfirm={handleBlockControlDevices}
        title="Block Devices"
        devices={devices}
      />
    </>
  );
}

BlockControlButton.propTypes = {
  devices: PropTypes.arrayOf(PropTypes.shape({})),
  disabled: PropTypes.bool,
  handleSuccessfulBlock: PropTypes.func,
  buttonText: PropTypes.string,
  buttonProps: PropTypes.shape({}),
  blockDevices: PropTypes.bool,
};

BlockControlButton.defaultProps = {
  devices: [],
  disabled: false,
  handleSuccessfulBlock: null,
  buttonText: "Block Devices",
  buttonProps: {},
  blockDevices: true,
};

export default BlockControlButton;
