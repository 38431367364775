import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  searchBar: {
    marginBottom: 20,
  },
  mrb15: {
    marginBottom: "15px !important",
  },
  mrr15: {
    marginRight: "15px !important",
  },
  formControl: {
    margin: theme.spacing(0, 1, 1, 1),
    minWidth: "150px !important",
  },
  menuItem: {
    textTransform: "capitalize",
  },
}));

export default useStyles;
