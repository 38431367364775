import { Box, Button, CircularProgress, Grid, TextField } from "@mui/material";
import { red } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";

import { post } from "../../utils/io";
import PasswordVerification from "../Auth/PasswordVerification";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    maxWidth: 300,
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #ccc",
    borderRadius: "4px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4),
  },
  item: {
    margin: theme.spacing(1, 0),
    width: "100%",
  },
  errText: {
    color: red[500],
    fontStyle: "italic",
    textAlign: "center",
    marginTop: 0,
  },
  progress: {
    marginRight: 10,
  },
  panel: {
    background: "#d3d3d347",
    padding: 15,
    borderRadius: 8,
  },
  note: {
    padding: "15px 20px",
    border: "1px solid grey",
    borderRadius: 4,
    marginTop: 15,
  },
  flex: {
    display: "flex",
  },
  mb10: {
    marginBottom: 10,
  },
}));

function VerifyUser() {
  const { hash } = useLocation();
  const history = useHistory();
  const classes = useStyles();

  const [isPasswordError, setIsPasswordError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isInvalidToken, setIsInvalidToken] = useState(false);
  const [isRequesting, setIsRequesting] = useState(false);
  const [input, setInput] = useState({
    password: "",
    confirmPassword: "",
  });
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [errMessage, setErrMessage] = useState("");

  const setPassword = (value) =>
    setInput((pre) => ({ ...pre, password: value }));

  const setConfirmPassword = (value) =>
    setInput((pre) => ({ ...pre, confirmPassword: value }));

  useEffect(() => {
    setIsLoading(true);

    async function tokenVerified() {
      const res = await post(`/users/invites/validate_token`, {
        confirmation_token: hash.slice(1),
      });
      if (res.status === 204) {
        setIsLoading(false);
        return;
      }
      setIsInvalidToken(true);
      setIsLoading(false);
    }
    tokenVerified();
  }, [history, hash]);

  const isDisableButton = () =>
    isRequesting ||
    !input.confirmPassword ||
    !input.password ||
    input.password !== input.confirmPassword ||
    isPasswordError;

  const onUpdateUser = async () => {
    const payload = {
      confirmation_token: hash.slice(1),
      password: input.password,
      first_name: firstName,
      last_name: lastName,
    };
    setIsRequesting(true);
    if (!firstName) return setErrMessage("First name is required");
    if (!lastName) return setErrMessage("Last name is required");
    setErrMessage("");
    const res = await post("/users/invites/accept", payload);
    setIsRequesting(false);
    if (res.status === 204) {
      return history.push("/");
    }
    return setErrMessage("Cannot update password");
  };

  return (
    <Grid className={classes.container}>
      {isLoading && <CircularProgress />}
      {!isLoading && isInvalidToken && (
        <div className={classes.panel}>
          <h2>This invite is no longer valid</h2>
          <p>
            Either the invite has been completed or cancelled. Please contact a
            member of the organization.
          </p>
        </div>
      )}
      {!isLoading && !isInvalidToken && (
        <Box className={classes.paper}>
          <div className={classes.form}>
            <h3>Select a password</h3>
            <TextField
              value={firstName}
              variant="outlined"
              name="first_name"
              required
              label="First name"
              size="small"
              onChange={(e) => setFirstName(e.target.value)}
              className={classes.item}
            />
            <TextField
              value={lastName}
              variant="outlined"
              name="last_name"
              required
              label="Last Name"
              size="small"
              onChange={(e) => setLastName(e.target.value)}
              className={(classes.item, classes.mb10)}
            />
            <PasswordVerification
              password={input.password}
              setPassword={setPassword}
              confirmPassword={input.confirmPassword}
              setConfirmPassword={setConfirmPassword}
              setError={setIsPasswordError}
            />
            {errMessage && <p className={classes.errText}>{errMessage}</p>}
            <Button
              onClick={onUpdateUser}
              disabled={isDisableButton()}
              color="primary"
              variant="contained"
            >
              {isRequesting && (
                <CircularProgress size={16} className={classes.progress} />
              )}{" "}
              Register
            </Button>
          </div>
        </Box>
      )}
    </Grid>
  );
}

export default VerifyUser;
