import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

import classNames from "classnames";
import { endOfDay, sub } from "date-fns";

import PropTypes from "prop-types";
import React, { useState } from "react";

import { FEATURE_FLAGS } from "../../../utils/constants";
import { getFeatureFlag } from "../../../utils/io";

import useStyles from "./styles";

const getMeasureStatus = () => [
  { value: "no endpoints" },
  { value: "succeeded" },
  { value: "failed" },
  { value: "in progress" },
  ...(getFeatureFlag(FEATURE_FLAGS.CLINICAL_VALIDATION)
    ? [{ value: "pending clinical validation" }, { value: "clinical rejected" }]
    : []),
];

const getOneMonthAgo = () => sub(new Date(), { months: 1 });
const getEndOfDay = () => endOfDay(new Date());
const emptySearchInputs = {
  macAddress: "",
  hubId: "",
  status: "",
  takenAfterDate: getOneMonthAgo(),
  takenBeforeDate: getEndOfDay(),
};

function MeasureSearchForm(props) {
  const {
    onSubmit,
    isSearchDisabled,
    searchCriterias,
    defaultSearchInputs,
    statusPendingValidation,
  } = props;
  const classes = useStyles();

  const baseSearchInputs = { ...emptySearchInputs, ...defaultSearchInputs };

  const [searchInputs, setSearchInputs] = useState({ ...baseSearchInputs });

  const onChangeSearchInputs = (e) => {
    const { name, value } = e.target;
    setSearchInputs({ ...searchInputs, [name]: value });
  };

  const onFormSubmit = async (e) => {
    e.preventDefault();
    onSubmit({ ...searchInputs });
  };

  const onClearFilter = async (e) => {
    e.preventDefault();
    setSearchInputs({ ...baseSearchInputs });
    onSubmit({ ...baseSearchInputs });
  };

  return (
    <form className={classes.searchBar} onSubmit={onFormSubmit}>
      <div className={classes.mrb15}>
        {searchCriterias.hubId && (
          <TextField
            label="Hub ID"
            name="hubId"
            onChange={onChangeSearchInputs}
            value={searchInputs.hubId}
            size="small"
            className={classNames(classes.mrr15)}
            variant="standard"
          />
        )}

        {searchCriterias.macAddress && (
          <TextField
            label="MAC Address"
            name="macAddress"
            onChange={onChangeSearchInputs}
            value={searchInputs.macAddress}
            className={classNames(classes.mrr15)}
            size="small"
            variant="standard"
          />
        )}

        {searchCriterias.status && (
          <FormControl
            className={classes.formControl}
            size="small"
            variant="standard"
          >
            <InputLabel>Delivery Status</InputLabel>
            <Select
              labelId="select-label"
              value={statusPendingValidation || searchInputs.status}
              label="Delivery Status"
              name="status"
              disabled={statusPendingValidation}
              className={classes.menuItem}
              onChange={onChangeSearchInputs}
            >
              {getMeasureStatus().map((item) => (
                <MenuItem
                  value={item.value}
                  className={classes.menuItem}
                  key={item.value}
                >
                  {item.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </div>

      <div>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            format="MM/dd/yyyy"
            label="Taken After"
            maxDate={new Date()}
            name="takenAfterDate"
            onChange={(val) =>
              setSearchInputs({ ...searchInputs, takenAfterDate: val })
            }
            value={searchInputs.takenAfterDate}
            renderInput={(params) => (
              <TextField
                {...params}
                className={classes.mrr15}
                size="small"
                variant="standard"
              />
            )}
          />
          <DatePicker
            format="MM/dd/yyyy"
            label="Taken Before"
            maxDate={new Date()}
            name="takenBeforeDate"
            onChange={(val) =>
              setSearchInputs({ ...searchInputs, takenBeforeDate: val })
            }
            value={searchInputs.takenBeforeDate}
            renderInput={(params) => (
              <TextField
                {...params}
                className={classes.mrr15}
                size="small"
                variant="standard"
              />
            )}
          />
        </LocalizationProvider>
        <Button
          className={classes.mrr15}
          color="primary"
          type="submit"
          variant="contained"
          disabled={isSearchDisabled}
        >
          Search
        </Button>
        <Button
          color="error"
          variant="contained"
          disabled={
            !searchInputs.hubId &&
            !searchInputs.macAddress &&
            !searchInputs.status
          }
          onClick={onClearFilter}
        >
          Clear
        </Button>
      </div>
    </form>
  );
}

MeasureSearchForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isSearchDisabled: PropTypes.bool,
  searchCriterias: PropTypes.objectOf(PropTypes.bool),
  defaultSearchInputs: PropTypes.shape({
    takenAfterDate: PropTypes.instanceOf(Date),
  }),
  statusPendingValidation: PropTypes.string,
};

MeasureSearchForm.defaultProps = {
  isSearchDisabled: false,
  searchCriterias: {
    hubId: true,
    macAddress: true,
    status: true,
  },
  defaultSearchInputs: {},
  statusPendingValidation: null,
};

export default MeasureSearchForm;
