import { Stack, Typography } from "@mui/material";
import { uniqueId } from "lodash";
import PropTypes from "prop-types";
import React from "react";

function TroubleshootOverviewCard({ values: items }) {
  return (
    <Stack
      sx={{
        background: "#F3F9FF",
        borderRadius: "4px",
        gap: "8px",
        padding: "16px",
      }}
    >
      {items.map((item) => (
        <Stack direction="row" spacing="64px">
          <Typography
            key={item.label}
            fontSize={14}
            fontWeight={600}
            minWidth={150}
          >{`${item.label}`}</Typography>
          {typeof item.value === "string" ? (
            <Typography
              key={uniqueId(item.value)}
              fontSize={14}
              color="#0000008F"
            >{`${item.value}`}</Typography>
          ) : (
            item.value
          )}
        </Stack>
      ))}
    </Stack>
  );
}

TroubleshootOverviewCard.propTypes = {
  values: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default TroubleshootOverviewCard;
