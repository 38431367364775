import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Chip, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";

import { ProtectedButton } from "../Authz/Protected";
import StelDataGrid from "../StelDataGrid";
import StelModal from "../StelModal";
import RoleForm from "./RoleForm";

export const ROLE_COLUMNS_TABLE = [
  {
    value: "name",
    label: "Role",
  },
  {
    value: "permissions",
    label: "Permissions",
  },
  {
    value: "action",
    label: "Action",
  },
];

export default function RoleTable({
  permissions,
  rolesStatic,
  onRolesStaticChange,
}) {
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);

  const columns = useMemo(() => {
    const valueColumns = ROLE_COLUMNS_TABLE.map((option) => {
      if (option.value === "permissions") {
        return {
          field: option.value,
          headerName: option.label,
          flex: 1,
          renderCell: (params) => (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                sx={{
                  marginRight: "10px",
                  maxWidth: 280,
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {params.row.permissions.length > 0
                  ? params.row.permissions[0]
                  : ""}
              </Typography>
              {params.value.length - 1 > 0 && (
                <Chip
                  label={`+${params.row.permissions.length - 1}`}
                  color="primary"
                  size="small"
                />
              )}
            </Box>
          ),
        };
      }
      if (option.value === "action") {
        return {
          field: option.value,
          headerName: option.label,
          renderCell: (params) => (
            <Button
              color="primary"
              variant="contained"
              size="small"
              onClick={() => {
                setSelectedRole(params.row);
              }}
            >
              Edit
            </Button>
          ),
        };
      }
      return {
        field: option.value,
        headerName: option.label,
        flex: 1,
      };
    });

    return valueColumns;
  }, []);

  useEffect(() => {
    if (rolesStatic?.length > 0) {
      setRoles(rolesStatic);
    }
  }, [rolesStatic]);

  return (
    <Box>
      <Box
        sx={{
          "&": {
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "20px",
          },
        }}
      >
        <ProtectedButton
          permission="CreateRoles"
          color="primary"
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => setSelectedRole({})}
        >
          Create Role
        </ProtectedButton>
      </Box>
      <Stack direction="row" justifyContent="flex-end">
        <StelDataGrid
          rowClickPermission="UpdateRoles"
          hideFooterSelectedRowCount
          hideFooterPagination
          hideFooter
          columns={columns}
          rows={roles}
        />
      </Stack>
      {selectedRole && (
        <StelModal
          open
          onClose={() => {
            setSelectedRole(null);
          }}
          title={selectedRole?.id ? "Edit Role" : "Create Role"}
          width={400}
        >
          <RoleForm
            role={selectedRole}
            permissions={permissions}
            onUpdateRole={() => {
              onRolesStaticChange();
              setSelectedRole(null);
            }}
          />
        </StelModal>
      )}
    </Box>
  );
}

RoleTable.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  rolesStatic: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
    })
  ).isRequired,
  onRolesStaticChange: PropTypes.func.isRequired,
};
