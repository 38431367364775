import { Box, Button, Stack, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import { enqueueSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { DEVICE_STATE_STATUS } from "../../utils/constants";
import { post, get } from "../../utils/io";
import HubAutoComplete from "../HubAutocomplete";

function DeviceCountText(props) {
  const { count } = props;
  return (
    <Box sx={{ color: "gray" }}>
      <Typography>{count} active devices registered to this hub</Typography>
    </Box>
  );
}

DeviceCountText.propTypes = {
  count: PropTypes.number.isRequired,
};

function useRegisteredDevices(hubInfo) {
  const [devices, setDevices] = useState([]);

  useEffect(() => {
    const getDevices = async () => {
      const { status, data } = await get("/devices", {
        registered_esn: hubInfo.hub_id,
        state_ne: { status: DEVICE_STATE_STATUS.EXPIRED.value },
        limit: 50,
      });
      if (status !== 200) {
        enqueueSnackbar("Unable to retrieve devices", { variant: "error" });
      } else {
        setDevices(data.devices);
      }
    };
    if (isEmpty(hubInfo)) {
      setDevices([]);
    } else {
      getDevices();
    }
  }, [hubInfo]);

  return { devices };
}

export default function MoveRegistrationsForm(props) {
  const { onClose, onSuccess, useFromHub, selectedDevices } = props;

  const defaultFormValues = useMemo(
    () => ({
      toHub: {},
      ...(useFromHub && { fromHub: {} }),
    }),
    [useFromHub]
  );

  const { handleSubmit, control, watch } = useForm({
    defaultValues: defaultFormValues,
  });

  const watchFromHub = watch("fromHub");
  const watchToHub = watch("toHub");
  const { devices: fromHubDevices } = useRegisteredDevices(watchFromHub);
  const { devices: toHubDevices } = useRegisteredDevices(watchToHub);

  const isFromHubSelected = isEmpty(watchFromHub);
  const disabled =
    isEmpty(watchToHub) ||
    (useFromHub
      ? isFromHubSelected || fromHubDevices.length === 0
      : selectedDevices.length === 0);

  const onSubmit = async (formData) => {
    const { toHub } = formData;
    const deviceIds = (useFromHub ? fromHubDevices : selectedDevices).map(
      (device) => device.id
    );
    const payload = {
      hub_id: toHub.hub_id,
      device_ids: deviceIds,
    };
    const moveRegistrationsResponse = await post(
      "/dexcom/registrations/move",
      payload
    );
    if (moveRegistrationsResponse.status !== 204) {
      enqueueSnackbar(moveRegistrationsResponse.data.detail, {
        variant: "error",
      });
    } else {
      enqueueSnackbar(
        `Registered ${deviceIds.length} devices to ${toHub.hub_id}`,
        {
          variant: "success",
        }
      );
      onSuccess();
      onClose();
    }
  };

  const filterHubOptions = (options, hubId) =>
    hubId ? options.filter((option) => option.hub_id !== hubId) : options;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {useFromHub && (
        <Box>
          <Box
            sx={{
              width: 170,
              fontWeight: 700,
              display: "flex",
              mb: "10px",
              alignItems: "center",
            }}
          >
            From Hub<Box sx={{ color: "red" }}> *</Box>
          </Box>
          <Controller
            name="fromHub"
            control={control}
            render={({ field: { onChange } }) => (
              <HubAutoComplete
                onChange={(_, value) => {
                  onChange(value);
                }}
                filterOptions={(options) =>
                  filterHubOptions(options, watchToHub?.hub_id)
                }
              />
            )}
          />
        </Box>
      )}
      {!isFromHubSelected && useFromHub && (
        <DeviceCountText count={fromHubDevices.length} />
      )}
      <Box
        sx={{
          width: 170,
          fontWeight: 700,
          display: "flex",
          mb: "10px",
          alignItems: "center",
          mt: "10px",
        }}
      >
        To Hub<Box sx={{ color: "red" }}> *</Box>
      </Box>
      <Controller
        name="toHub"
        control={control}
        render={({ field: { onChange } }) => (
          <HubAutoComplete
            onChange={(_, value) => {
              onChange(value);
            }}
            filterOptions={(options) =>
              filterHubOptions(options, watchFromHub?.hub_id)
            }
          />
        )}
      />
      {!isEmpty(watchToHub) && <DeviceCountText count={toHubDevices.length} />}
      <Stack flexDirection="row" justifyContent="flex-end" mt="10px">
        <Button
          color="primary"
          type="submit"
          variant="contained"
          disabled={disabled}
        >
          Save
        </Button>
      </Stack>
    </form>
  );
}

MoveRegistrationsForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  useFromHub: PropTypes.bool.isRequired,
  selectedDevices: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.object,
        PropTypes.array,
        PropTypes.bool,
      ])
    )
  ).isRequired,
};
