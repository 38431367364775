import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import classNames from "classnames";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { CARRIERS, ORDER_STATUS } from "../../utils/constants";
import { get, isAdmin } from "../../utils/io";
import { Protected } from "../Authz/Protected";
import MainContentContainer from "../Layouts/MainContentContainer";
import OrderModal from "./OrderModal";
import useStyles from "./styles";

const emptySearchInputs = {
  id: "",
  hubId: "",
  trackingId: "",
  carrier: "",
  status: "",
  orgAlias: "",
};

function OrderSearchForm({ onSubmit, isSearchDisabled }) {
  const classes = useStyles();

  const [searchInputs, setSearchInputs] = useState({ ...emptySearchInputs });

  const changeSearchInput = (e) => {
    const { name, value } = e.target;
    setSearchInputs({ ...searchInputs, [name]: value });
  };

  const submitForm = async (e) => {
    e.preventDefault();
    onSubmit({ ...searchInputs });
  };

  const clearFilters = async (e) => {
    e.preventDefault();
    setSearchInputs({ ...emptySearchInputs });
    onSubmit({ ...emptySearchInputs });
  };

  const isClearFiltersDisabled = () =>
    Object.values(searchInputs).reduce((prev, next) => prev && !next, true);

  return (
    <form onSubmit={submitForm}>
      <div className={classNames(classes.mrb15, classes.flexWrap)}>
        <TextField
          label="ID"
          onChange={changeSearchInput}
          value={searchInputs.id}
          size="small"
          variant="standard"
          className={classNames(classes.mrr15, classes.mrb15)}
          name="id"
        />
        <TextField
          label="Hub ID"
          onChange={changeSearchInput}
          value={searchInputs.hubId}
          size="small"
          variant="standard"
          className={classNames(classes.mrr15, classes.mrb15)}
          name="hubId"
        />
        {isAdmin() && (
          <TextField
            label="Organization Alias"
            onChange={changeSearchInput}
            value={searchInputs.orgAlias}
            size="small"
            variant="standard"
            className={classNames(classes.mrr15, classes.mrb15)}
            name="orgAlias"
          />
        )}
        <TextField
          label="Tracking #"
          onChange={changeSearchInput}
          value={searchInputs.trackingId}
          size="small"
          variant="standard"
          className={classNames(classes.mrr15, classes.mrb15)}
          name="trackingId"
        />
        <FormControl
          variant="standard"
          sx={{ minWidth: 120 }}
          className={classNames(classes.mrr15, classes.mrb15)}
        >
          <InputLabel>Carrier</InputLabel>
          <Select
            value={searchInputs.carrier}
            onChange={changeSearchInput}
            label="Carrier"
            name="carrier"
          >
            {CARRIERS.map((carrier) => (
              <MenuItem key={carrier.value} value={carrier.value}>
                {carrier.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl
          variant="standard"
          sx={{ minWidth: 120 }}
          className={classNames(classes.mrr15, classes.mrb15)}
        >
          <InputLabel>Status</InputLabel>
          <Select
            value={searchInputs.status}
            onChange={changeSearchInput}
            label="Status"
            name="status"
          >
            {Object.values(ORDER_STATUS).map((status) => (
              <MenuItem key={status} value={status}>
                {status.toLocaleUpperCase()}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          color="error"
          variant="contained"
          onClick={clearFilters}
          className={classes.mrr15}
          disabled={isClearFiltersDisabled()}
        >
          Clear Filters
        </Button>
        <Button
          color="primary"
          variant="contained"
          type="submit"
          onClick={submitForm}
          disabled={isSearchDisabled}
        >
          Search
        </Button>
      </div>
    </form>
  );
}

OrderSearchForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isSearchDisabled: PropTypes.bool,
};

OrderSearchForm.defaultProps = {
  isSearchDisabled: false,
};

function Orders() {
  const classes = useStyles();
  const [orders, setOrders] = useState([]);
  const [searchFields, setSearchFields] = useState(emptySearchInputs);
  const [isCreate, setIsCreate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [orderSelected, setOrderSelected] = useState({});

  const { enqueueSnackbar } = useSnackbar();

  const history = useHistory();

  const columns = [
    { field: "id", headerName: "ID", flex: 1 },
    ...(isAdmin()
      ? [
          {
            field: "org",
            headerName: "Org Alias",
            flex: 0.75,
            valueGetter: ({ value }) => value.alias,
          },
        ]
      : []),
    {
      field: "status",
      headerName: "Status",
      flex: 1.5,
      renderCell: (param) => (
        <div>
          <div
            className={classNames(
              classes.chip,
              classes[param.value.replace(" ", "_")]
            )}
          >
            {param.value}
          </div>
        </div>
      ),
    },
    {
      field: "created_time",
      headerName: "Created Time",
      flex: 1.2,
      valueGetter: ({ value }) => new Date(value).toLocaleString(),
    },
    {
      field: "created_by",
      headerName: "Created by",
      flex: 1,
      valueGetter: ({ value }) =>
        value?.first_name && value?.last_name
          ? `${value.first_name} ${value.last_name}`
          : `${value?.username || "-"}`,
    },
    {
      field: "number_of_hubs",
      headerName: "Requested Hubs",
      flex: 1,
      renderCell: (param) => (
        <div>
          <Chip color="primary" label={`${param.value}`} />
        </div>
      ),
    },
    ...(isAdmin()
      ? [
          {
            field: "hub_count",
            headerName: "Hub Count",
            flex: 0.75,
            renderCell: (param) => (
              <div>
                <Chip color="primary" label={`${param.value}`} />
              </div>
            ),
          },
        ]
      : []),
  ];

  useEffect(() => {
    async function getOrderList() {
      setLoading(true);
      const params = {
        order_id: searchFields.id,
        tracking_number: searchFields.trackingId,
        carrier: searchFields.carrier,
        hub_id: searchFields.hubId,
        status: searchFields.status,
      };
      const res = isAdmin()
        ? await get("admin/orders", {
            ...params,
            ...{ org_alias: searchFields.orgAlias },
          })
        : await get("/hub_orders", params);
      if (res.status === 200) {
        setLoading(false);
        return setOrders(res.data.orders);
      }
      setLoading(false);
      return setOrders([]);
    }
    getOrderList();
  }, [searchFields]);

  const onOpenOrderModal = () =>
    isAdmin() ? setIsCreate(true) : history.push("/hub-orders/request");

  const onCloseDetailOrder = async () => {
    setOrderSelected({});
    setLoading(true);
    const res = await get("admin/orders");
    if (res.status === 200) {
      setOrders(res.data.orders);
    }
    setLoading(false);
  };

  const onCloseOrderModal = () => {
    setIsCreate(false);
    onCloseDetailOrder();
  };

  const onRowClick = async (row) => {
    if (isAdmin()) {
      const res = await get(`admin/orders/${row.id}`);
      return res.status !== 200
        ? enqueueSnackbar("Unable to retrieve order", { variant: "error" })
        : setOrderSelected(res.data);
    }
    return history.push(`/hub-orders/${row.id}`);
  };

  return (
    <MainContentContainer title="Hub Orders" isEnterpriseOnly>
      <Grid container direction="column">
        <div className={classNames(classes.flex, classes.spaceBetween)}>
          <OrderSearchForm
            onSubmit={setSearchFields}
            isSearchDisabled={loading}
          />
        </div>

        <Protected permission="CreateHubOrders">
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              permission="CreateHubOrders"
              sx={{ height: "fit-content", mb: "15px" }}
              color="primary"
              variant="contained"
              onClick={onOpenOrderModal}
              startIcon=<AddIcon />
            >
              {isAdmin() ? "Create" : "Request"}
            </Button>
          </Box>
        </Protected>
        <DataGrid
          columns={columns}
          rows={orders}
          autoHeight
          loading={loading}
          onRowClick={onRowClick}
          getRowClassName={() => classes.pointer}
        />
        {isCreate && (
          <OrderModal isModal onCloseOrderModal={onCloseOrderModal} />
        )}
        {orderSelected.id && (
          <OrderModal
            isModal
            onCloseOrderModal={onCloseDetailOrder}
            row={orderSelected}
          />
        )}
      </Grid>
    </MainContentContainer>
  );
}
export default Orders;
