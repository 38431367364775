import HistoryIcon from "@mui/icons-material/History";
import ViewListIcon from "@mui/icons-material/ViewList";
import { capitalize } from "lodash";

const TROUBLESHOOT_TYPE = {
  HUB_POWER: "hub-power",
  HUB_CELLULAR: "hub-cellular",
  HUB_DEVICE_CONNECTIVITY: "device-hub-connectivity",
  HEALTH_DEVICE_ISSUE: "device-issue",
  ACTIVE_RETURN: "active-return",
  HISTORY: "history",
};

const TROUBLESHOOT_CATEGORIES = [
  {
    hub: {},
    label: "Hub Power",
    link: TROUBLESHOOT_TYPE.HUB_POWER,
    description: "Red and blue lights are off",
  },
  {
    hub: { isAnimation: true },
    label: "Hub Cellular",
    link: TROUBLESHOOT_TYPE.HUB_CELLULAR,
    description: "Red light is flashing",
  },
  {
    imageUrl: "/hub-device-connectivity.png",
    label: "Hub Device Connectivity",
    link: TROUBLESHOOT_TYPE.HUB_DEVICE_CONNECTIVITY,
    description: "Blue light doesn’t blink when taking a measurement",
  },
  {
    imageUrl: "/device-issue.png",
    label: "Health Device Issue",
    link: TROUBLESHOOT_TYPE.HEALTH_DEVICE_ISSUE,
    description: "Issue relate to the health devices",
  },
];

const TROUBLESHOOT_MANAGEMENT = [
  {
    icon: ViewListIcon,
    label: "Active Return",
    link: "active-return",
  },
  {
    icon: HistoryIcon,
    label: "History",
    link: "history",
  },
];

const TROUBLESHOOT_SUBMIT_BUTTON = {
  RESOLVED: {
    label: "Resolved With Patient",
    value: "resolved_with_patient",
  },
  ALERT_STEL: {
    label: "Alert To Stel",
    value: "escalated_to_stel",
  },
  RETURN: {
    label: "Return Hub",
    value: "initiated_return",
  },
  REPLACE_KIT: {
    label: "Replace Hub and Devices",
    value: "kit_replaced",
  },
  RETURN_ONLY: {
    label: "Return Only",
    value: "kit_returned",
  },
  REPLACE_HUB: {
    label: "Replace Hub",
    value: "hub_replaced",
  },
  SAVE_TICKET: {
    label: "Save",
    value: "save_ticker",
  },
  ACTIVE_RETURN_COMPLETE: {
    label: "Complete",
    value: "active_return_complete",
  },
  CANCEL_TICKET: {
    label: "Cancel Ticket",
    value: "cancel_ticker",
  },
};

const OPTIONS_RETURN_HUB_TO_LVHN = [
  {
    value: "hub_replaced",
    label: "Replace Hub",
    description: "Send a new hub and move bindings",
  },
  {
    value: "kit_replaced",
    label: "Replace Hub and Devices",
    description: "Patient returns kit with replacement",
  },
  {
    value: "kit_returned",
    label: "Return Only",
    description: "Patient returns kit with no replacement",
  },
];

const formatTroubleshootLabel = (s) =>
  s
    .split("_")
    .map((sPart) => (sPart === "to" ? sPart : capitalize(sPart)))
    .join(" ");

const FORMS_CHECKBOX_POWER = [
  {
    name: "outlet_power",
    title: "Patient confirmed outlet works",
  },
  {
    name: "hub_damage",
    title: "Hub damage reported",
  },
];

const FORMS_CHECKBOX_CELLULAR = [
  {
    name: "hub_blinking_red",
    title: "Patient confirmed outlet works",
  },
  {
    name: "hub_damage",
    title: "Hub damage reported",
  },
  {
    name: "location_changed",
    title: "New location attempted",
  },
];

const FORMS_CHECKBOX_CONNECTIVITY = [
  {
    name: "hub_solid_red",
    title: "Patient confirmed solid red light",
  },
  {
    name: "hub_rebooted",
    title: "Hub rebooted",
  },
  {
    name: "repair_attempted",
    title: "Attempted repairing",
  },
  {
    name: "measure_attempted",
    title: "Attempted another measurement",
  },
  {
    name: "devices_verified",
    title: "Confirmed Bindings / Blocked devices list",
  },
];

const FORMS_CHECKBOX_RETURN_INTAKE = [
  {
    name: "repair_attempted",
    title: "Tech team attempted repair",
  },
  {
    name: "confirmed_lights_blinking",
    title: "Tech confirmed lights blinking",
  },
];

const TROUBLESHOOT_TYPE_TO_FORM = Object.freeze({
  hub_power: FORMS_CHECKBOX_POWER,
  hub_cellular: FORMS_CHECKBOX_CELLULAR,
  device_hub_connectivity: FORMS_CHECKBOX_CONNECTIVITY,
});

const CONNECTIVITY_ISSUES = {
  ANY_DEVICE: "Hub not working with any devices",
  ONE_DEVICE: "Connectivity issue with one device",
};

const CHECKBOX_TITLES = {
  outlet_power: {
    title: "Patient confirmed outlet works",
  },
  hub_blinking_red: {
    title: "Patient confirmed outlet works",
  },
  hub_damage: {
    title: "Hub Damage reported",
  },
  location_changed: {
    title: "New location attempt",
  },
  hub_solid_red: {
    title: "Patient confirmed solid red light",
  },
  hub_rebooted: {
    title: "Hub rebooted",
  },
  repair_attempted: {
    title: "Attempted repairing",
  },
  measure_attempted: {
    title: "Attempted another measurement",
  },
  devices_verified: {
    title: "Confirmed Bindings / Blocked devices list",
  },
  confirmed_lights_blinking: {
    title: "Tech confirmed lights blinking",
  },
};

export {
  TROUBLESHOOT_MANAGEMENT,
  TROUBLESHOOT_CATEGORIES,
  TROUBLESHOOT_SUBMIT_BUTTON,
  TROUBLESHOOT_TYPE,
  CHECKBOX_TITLES,
  OPTIONS_RETURN_HUB_TO_LVHN,
  formatTroubleshootLabel,
  FORMS_CHECKBOX_POWER,
  FORMS_CHECKBOX_CELLULAR,
  FORMS_CHECKBOX_CONNECTIVITY,
  FORMS_CHECKBOX_RETURN_INTAKE as FORMS_CHECKBOX_RETURN_IN_TAKE,
  TROUBLESHOOT_TYPE_TO_FORM,
  CONNECTIVITY_ISSUES,
};
