import LoadingButton from "@mui/lab/LoadingButton";
import { enqueueSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { DOCS } from "../../../utils/constants";
import { post } from "../../../utils/io";
import ButtonWithTooltip from "../../ButtonWithTooltip";

function UnbindDevicesButton(props) {
  const { devices, disabled, handleSuccessfulUnbind, buttonText, buttonProps } =
    props;
  const [loading, setLoading] = useState(false);

  const handleUnbindDevices = async () => {
    setLoading(true);
    const res = await post("/bulk/device_bindings/unbind", {
      mac_addresses: devices.map((device) => device.mac_address),
    });
    if (res.status !== 204) {
      enqueueSnackbar(
        res.data?.detail ||
          "Failed to unbind devices, please contact support@stel.life",
        { variant: "error" }
      );
    } else {
      const deviceStr = `device${devices.length > 1 ? "s" : ""}`;
      enqueueSnackbar(`Unbound ${devices.length} ${deviceStr}`, {
        variant: "success",
      });
      handleSuccessfulUnbind();
    }
    setLoading(false);
  };

  return (
    <ButtonWithTooltip
      description={DOCS.UNBIND_DEVICE.description}
      url={DOCS.UNBIND_DEVICE.url}
      onClick={handleUnbindDevices}
      disabled={disabled}
      title={buttonText}
      component={LoadingButton}
      sx={{
        padding: "5px 10px",
        mr: "10px",
        textTransform: "capitalize",
      }}
      variant="outlined"
      loading={loading}
      {...buttonProps}
    />
  );
}

UnbindDevicesButton.propTypes = {
  devices: PropTypes.arrayOf(PropTypes.shape({})),
  disabled: PropTypes.bool,
  handleSuccessfulUnbind: PropTypes.func,
  buttonText: PropTypes.string,
  buttonProps: PropTypes.shape({}),
};

UnbindDevicesButton.defaultProps = {
  devices: [],
  disabled: false,
  handleSuccessfulUnbind: null,
  buttonText: "Unbind Devices",
  buttonProps: {},
};

export default UnbindDevicesButton;
