import React from "react";

export default function Glucose() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="10 0 22 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5 25.5V24M15.5 24H14.5V22.5C14.5 22.5 11.5 22.5 11 22.5C10.5 22.5 10 22 10 21.5C10 21 10 10 10 10C10.065 9.38414 10.5 9 11 9C11.5 9 19.5 9 20 9C20.5 9 21 9.5 21 10C21 10.5 21 21 21 21.5C21 22 20.5 22.5 20 22.5C19.5 22.5 16.5 22.5 16.5 22.5V24H15.5ZM11 11C11 10.5 11.2035 10 11.5 10C11.7965 10 19.1939 10 19.5 10C19.8061 10 20 10.5 20 11M11 16.5H20C20 16.5 20 17.5 20 19C20 20.5 17.5 21.5 17 21.5C16.5 21.5 14.5 21.5 14 21.5C13.5 21.5 11 20.5 11 19C11 17.5 11 16.5 11 16.5ZM15.5 11.5C15.5 11.5 16 12 16.5 13C17 14 17 14.5 16.5 15C16 15.5 15 15.5 14.5 15C14 14.5 14 14 14.5 13C15 12 15.5 11.5 15.5 11.5Z"
        stroke="#545454"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
