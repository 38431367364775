import { Check, Clear, Sync } from "@mui/icons-material";
import { blue } from "@mui/material/colors";
import { secondsToMilliseconds, subMinutes } from "date-fns";
import { camelCase, capitalize, mapKeys, snakeCase } from "lodash";
import React from "react";
import { DeliveryStatus } from "./constants";
// Probably will add more functions in the future so disabling default
// export check until then. Remove these comments once another util
// function is added in this file.
/* eslint-disable import/prefer-default-export */
export const formatDeliveryStatus = (data = []) =>
  data.reduce(
    (obj, item) => ({
      ...obj,
      measure_to_post_ids: [...obj.measure_to_post_ids, Object.keys(item)[0]],
      measure_to_post_status: {
        ...obj.measure_to_post_status,
        ...item,
      },
    }),
    {
      measure_to_post_ids: [],
      measure_to_post_status: {},
    }
  );

export function renderMeasureDeliveryStatusIcon(status) {
  if (status === DeliveryStatus.FAILED)
    return <Clear style={{ color: "red", fontSize: 20 }} />;
  if (status === DeliveryStatus.SUCCEEDED)
    return <Check style={{ color: "green", fontSize: 20 }} />;
  return <Sync style={{ color: blue[500], fontSize: 20 }} />;
}

export const addBufferToExpTime = (seconds, buffer = 1) =>
  subMinutes(secondsToMilliseconds(seconds), buffer);

export const base64ToObject = (s) => JSON.parse(atob(s));

export const formatXealthUrl = (environment) =>
  `Xealth ${capitalize(environment)}`;

export const toSnakeCase = (data) => mapKeys(data, (_, k) => snakeCase(k));

export const toCamelCase = (data) => mapKeys(data, (_, k) => camelCase(k));

export const getSelectedDevices = (devices, rows) =>
  devices.reduce((selectedDevices, deviceId) => {
    const deviceFound = rows.find((row) => row.id === deviceId);
    const device = deviceFound?.device || deviceFound;
    const isDeviceSelected = selectedDevices.some((d) => d.id === device.id);
    if (isDeviceSelected) return selectedDevices;
    return [
      ...selectedDevices,
      {
        id: device.id,
        macAddress: device.mac_address,
        make: device.make,
        model: device.model_display_name,
        hubId: device?.hubId || deviceFound?.hub?.hub_id || null,
      },
    ];
  }, []);
