import CheckIcon from "@mui/icons-material/Check";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Box, Button, Typography } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router";
import { MEASURE_TYPES } from "../../../utils/constants";
import { get } from "../../../utils/io";

function Measurement({ measure }) {
  const { systolic, diastolic, bpm } = measure.value;

  return (
    <Box
      sx={{ display: "flex", alignItems: "center", margin: 0, marginRight: 2 }}
    >
      {systolic && diastolic ? (
        <span
          style={{ width: "max-content" }}
        >{`${systolic}/${diastolic} ${bpm} bpm`}</span>
      ) : (
        Object.entries(measure.value).map(([field, value]) => {
          const measureTypeField =
            MEASURE_TYPES[measure.type]?.fields[field.toLowerCase()];
          const unit = measureTypeField?.unit || "";
          return (
            <span style={{ marginRight: 4, width: "max-content" }} key={field}>
              {value && `${value} ${unit}`}
            </span>
          );
        })
      )}
    </Box>
  );
}

Measurement.propTypes = {
  measure: PropTypes.shape({
    type: PropTypes.string,
    value: PropTypes.shape({
      systolic: PropTypes.number,
      diastolic: PropTypes.number,
      bpm: PropTypes.number,
    }),
  }).isRequired,
};
function LatestReadingCard({ measure }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "4px",
        padding: "0px 12px",
        borderLeft: "2px solid #0F71DE",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
          background: "#F3F9FF",
        }}
      >
        <Box
          sx={{
            width: "36px",
            height: "36px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CheckIcon fontSize="small" style={{ color: "#2E8F40" }} />
        </Box>
        <Typography fontSize={14} fontWeight={600}>
          {new Date(measure.timestamp).toLocaleString()}
        </Typography>
        <Typography fontSize={14} fontWeight={600}>
          {MEASURE_TYPES[measure.type]?.label}
        </Typography>
      </Box>

      <Box
        sx={{ display: "flex", flexDirection: "column", padding: "0px 8px" }}
      >
        <Box
          sx={{
            height: "36px",
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <Typography fontSize={14} fontWeight={600}>
            Device
          </Typography>
          <Typography>{`${measure.device.make} ${measure.device.model_name}`}</Typography>
        </Box>
        <Box
          sx={{
            height: "36px",
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <Typography fontSize={14} fontWeight={600}>
            MAC Address
          </Typography>
          <Typography>{measure.device.mac_address}</Typography>
        </Box>
        <Box
          sx={{
            height: "36px",
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <Typography fontSize={14} fontWeight={600}>
            Measurement
          </Typography>
          <Typography>
            <Measurement measure={measure} />
          </Typography>
        </Box>
        <Box
          sx={{
            height: "36px",
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <Typography fontSize={14} fontWeight={600}>
            Transmitting Hub
          </Typography>
          <Typography>{measure.transmitting_hub?.hub_id}</Typography>
        </Box>
      </Box>
    </Box>
  );
}

LatestReadingCard.propTypes = {
  measure: PropTypes.shape({
    type: PropTypes.string.isRequired,
    transmitting_hub: PropTypes.string.isRequired,
    value: PropTypes.shape({
      systolic: PropTypes.number,
      diastolic: PropTypes.number,
      bpm: PropTypes.number,
    }),
    device: PropTypes.shape({
      mac_address: PropTypes.string,
      make: PropTypes.string,
      model_name: PropTypes.string,
    }),
    timestamp: PropTypes.string.isRequired,
  }).isRequired,
};

function HubDeviceConnectivityLatestReadings() {
  const { hubId } = useParams();
  const [measures, setMeasures] = useState([]);

  const getMeasures = useCallback(async () => {
    const measureRes = await get("/measures", { esn: hubId, limit: 10 });
    if (measureRes.status === 200) {
      setMeasures(measureRes.data.measures);
    } else {
      enqueueSnackbar(measureRes.data.detail, {
        variant: "error",
      });
    }
  }, [hubId]);

  const onClickLoadLatestReadings = () => {
    getMeasures();
  };

  useEffect(() => {
    getMeasures();
  }, [getMeasures]);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        padding: "12px 24px",
        height: "calc(100vh - 104px)",
        overflow: "scroll",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography fontSize={20} fontWeight={600}>
          Latest Readings
        </Typography>
        <Button
          sx={{
            width: 36,
            minWidth: 36,
            height: 36,
            padding: "0",
          }}
          variant="outlined"
          color="primary"
          onClick={onClickLoadLatestReadings}
        >
          <RefreshIcon />
        </Button>
      </Box>

      {(measures || []).map((item) => (
        <LatestReadingCard key={item} measure={item} />
      ))}
    </Box>
  );
}

export default HubDeviceConnectivityLatestReadings;
