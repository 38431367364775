import { Box, Button, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import DocsTooltip from "../DocsTooltip";
import DropdownButton from "../DropdownButton";
import { TROUBLESHOOT_SUBMIT_BUTTON } from "./constants";

const OPTIONS_HUB_RETURN = [
  {
    value: "hub_replaced",
    label: "Replace Hub",
    description: "Send a new hub and move bindings",
  },
  {
    value: "kit_returned",
    label: "Return Only",
    description: "Patient returns kit with no replacement",
  },
];

function CustomMenuItem({ label, description }) {
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
      <Box>
        <Typography fontWeight={600}>{label}</Typography>
        <Typography fontSize={10} color="#00000099">
          {description}
        </Typography>
      </Box>
    </Box>
  );
}

CustomMenuItem.propTypes = {
  label: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

function TroubleshootButtonGroup(props) {
  const { handleFormSubmit, options, disableReturnButton } = props;
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "space-between",
        gap: "12px",
        flexWrap: "wrap",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "12px",
          flexWrap: "wrap",
          "@media (max-width: 900px)": {
            width: "100%",
            flexDirection: "column",
          },
        }}
      >
        <DocsTooltip
          description="An active return already exists for this hub"
          // Disables tooltip when no active return
          disableTouchListener={!disableReturnButton}
          disableHoverListener={!disableReturnButton}
          disableFocusListener={!disableReturnButton}
        >
          <span>
            <DropdownButton
              components={{
                button: {
                  variant: "outlined",
                  color: "primary",
                  sx: {
                    minWidth: "100%",
                  },
                },
              }}
              id="initiate-return"
              title="Initiate Return"
              options={options}
              customMenuItem={CustomMenuItem}
              onClick={(e) => handleFormSubmit(e.value)}
              disabled={disableReturnButton}
            />
          </span>
        </DocsTooltip>
        <Button
          variant="outlined"
          color="primary"
          onClick={() =>
            handleFormSubmit(TROUBLESHOOT_SUBMIT_BUTTON.ALERT_STEL.value)
          }
          sx={{
            minWidth: "fit-content",
            "@media (max-width: 900px)": {
              width: "100%",
            },
          }}
        >
          ALERT STEL (NO RETURN)
        </Button>
      </Box>
      <Button
        variant="contained"
        color="primary"
        type="submit"
        sx={{
          minWidth: "fit-content",
          boxShadow: "none !important",
          "@media (max-width: 900px)": {
            width: "100%",
          },
        }}
      >
        RESOLVED WITH PATIENT
      </Button>
    </Box>
  );
}

export default TroubleshootButtonGroup;

TroubleshootButtonGroup.propTypes = {
  handleFormSubmit: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      description: PropTypes.string,
    })
  ),
  disableReturnButton: PropTypes.bool,
};
TroubleshootButtonGroup.defaultProps = {
  options: OPTIONS_HUB_RETURN,
  disableReturnButton: false,
};
