import { Drawer, Stack, Typography } from "@mui/material";
import { debounce } from "lodash";
import { enqueueSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { get } from "../../utils/io";
import { formatTroubleshootLabel } from "../Troubleshoot/constants";
import TroubleshootDetail from "./TroubleshootDetail";
import TroubleshootTable from "./TroubleshootTable";

const filterEmptyValues = (obj) =>
  Object.fromEntries(Object.entries(obj).filter(([, value]) => value !== ""));

function TroubleshootList({ searchInputs }) {
  const { hubId } = useParams();
  const history = useHistory();
  const { pathname } = useLocation();
  const [rowSelected, setRowSelected] = useState({});
  const [tickets, setTickets] = useState([]);
  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: 10,
    totalCount: 0,
  });

  const isTroubleshootTickets = pathname === "/troubleshoot-tickets";

  const COLUMNS = [
    ...(isTroubleshootTickets
      ? [
          {
            field: "id",
            flex: 1,
            headerName: "ID",
            renderCell: (params) => (
              <Typography sx={{ textTransform: "uppercase", fontSize: "14px" }}>
                {params.row.id}
              </Typography>
            ),
          },
          {
            field: "hub_id",
            flex: 1,
            headerName: "Hub ID",
            renderCell: (params) => (
              <Typography sx={{ textTransform: "uppercase", fontSize: "14px" }}>
                {params.row.details.hub_id}
              </Typography>
            ),
          },
        ]
      : []),
    {
      field: "created_time",
      flex: 1,
      headerName: "Date",
      valueGetter: (params) =>
        new Date(params.row.created_time).toLocaleString(),
    },
    {
      field: "status",
      headerName: "Status",
      valueGetter: (params) =>
        formatTroubleshootLabel(params.row.details.status),
    },
    {
      field: "triage_type",
      flex: 1,
      headerName: "Issue Type",
      valueGetter: (params) =>
        formatTroubleshootLabel(params.row.details.triage.type),
    },
    {
      field: "triage_status",
      flex: 1,
      headerName: "Initial Resolution",
      valueGetter: (params) =>
        formatTroubleshootLabel(params.row.details.triage.status),
    },
    {
      field: "return_type",
      flex: 1,
      headerName: "Return Type",
      valueGetter: (params) =>
        formatTroubleshootLabel(params.row.details.triage?.return_type || "-"),
    },
    {
      field: "intake_resolution",
      flex: 1,
      headerName: "Resolution",
      valueGetter: (params) =>
        formatTroubleshootLabel(params.row.details?.intake?.resolution || "-"),
    },
    ...(isTroubleshootTickets
      ? [
          {
            field: "org_alias",
            flex: 1,
            headerName: "Org Alias",
            valueGetter: (params) =>
              formatTroubleshootLabel(params.row.org_alias),
          },
        ]
      : []),
  ];

  const onRowClick = (row) => {
    if (row && row.id && !isTroubleshootTickets) {
      const searchParamsString = new URLSearchParams({
        ticket: row.id,
      }).toString();
      return history.push(
        `/hubs/${hubId}/troubleshoot#history?${searchParamsString}`
      );
    }
    return setRowSelected(row);
  };

  useEffect(() => {
    const getTickets = debounce(async () => {
      try {
        const params = {
          limit: pagination.pageSize,
          offset: pagination.page * pagination.pageSize,
          ...filterEmptyValues({
            hub_id: isTroubleshootTickets
              ? searchInputs.hubId
              : hubId.substring(0, 10),
          }),
          ...(isTroubleshootTickets && {
            ...filterEmptyValues({
              ticket_id: searchInputs.ticketId,
              status: searchInputs.status,
              org_alias: searchInputs.orgAlias?.alias,
              triage_status: searchInputs.triageStatus,
            }),
            taken_after: searchInputs?.takenAfterDate?.toISOString(),
            taken_before: searchInputs?.takenBeforeDate?.toISOString(),
          }),
        };
        const res = await get(`/troubleshoot/tickets`, params);
        if (res.status === 200) {
          setTickets(res.data?.items || []);
          setPagination((prev) => ({
            ...prev,
            totalCount: res.data?.count || 0,
          }));
        } else {
          enqueueSnackbar(res.data?.detail || "Error fetching tickets", {
            variant: "error",
          });
        }
      } catch (error) {
        enqueueSnackbar("Failed to fetch tickets", { variant: "error" });
      }
    }, 300);

    getTickets();
    return () => getTickets.cancel();
  }, [
    hubId,
    isTroubleshootTickets,
    pagination.page,
    pagination.pageSize,
    searchInputs,
  ]);

  return (
    <Stack sx={[!isTroubleshootTickets ? { m: "24px" } : {}]} spacing={2}>
      {!isTroubleshootTickets && (
        <Typography sx={{ fontSize: 20, fontWeight: 600, mb: "10px" }}>
          Tickets
        </Typography>
      )}
      <TroubleshootTable
        title={null}
        columns={COLUMNS}
        rows={tickets}
        setPagination={setPagination}
        pagination={pagination}
        onRowClick={({ row }) => {
          onRowClick(row);
        }}
      />
      {!!rowSelected.id && (
        <Drawer
          open={!!rowSelected.id}
          anchor="right"
          onClose={() => setRowSelected({})}
        >
          <TroubleshootDetail
            ticket={rowSelected}
            onClose={() => setRowSelected({})}
            isTroubleshootTickets={isTroubleshootTickets}
          />
        </Drawer>
      )}
    </Stack>
  );
}

TroubleshootList.propTypes = {
  searchInputs: PropTypes.shape({
    hubId: PropTypes.string,
    ticketId: PropTypes.string,
    status: PropTypes.string,
    orgAlias: PropTypes.string,
    triageStatus: PropTypes.string,
    takenAfterDate: PropTypes.instanceOf(Date),
    takenBeforeDate: PropTypes.instanceOf(Date),
  }).isRequired,
};

export default TroubleshootList;
