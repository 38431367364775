import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  snackBarSuccess: {
    "& div": {
      background: theme.palette.success.dark,
    },
  },
  snackBarFail: {
    "& div": {
      background: theme.palette.error.dark,
    },
  },
  snackBarWarning: {
    "& div": {
      background: theme.palette.warning.dark,
    },
  },
}));

export default useStyles;
