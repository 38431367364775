import { DataGrid } from "@mui/x-data-grid";

import PropTypes from "prop-types";
import React from "react";
import { hasPermission } from "../utils/io";
import theme from "../utils/theme";

function StelDataGrid(props) {
  const { rowClickPermission, columnPermissions, ...dataGridProps } = props;

  const hasRowClickPermission =
    !rowClickPermission || hasPermission(rowClickPermission);
  if (!hasRowClickPermission) {
    dataGridProps.onRowClick = null;
  }

  if (columnPermissions) {
    const { columns } = dataGridProps;
    const permissionedCols = columns.filter(
      ({ field }) =>
        !columnPermissions[field] || hasPermission(columnPermissions[field])
    );
    dataGridProps.columns = permissionedCols;
  }

  return (
    <DataGrid
      sx={{
        "& .MuiDataGrid-columnHeaderTitleContainer": {
          color: theme.colors.grey,
        },
        "& .MuiDataGrid-cell": {
          color: theme.colors.black,
          cursor: dataGridProps.onRowClick ? "pointer" : "auto",
        },
      }}
      rowHeight={40}
      headerHeight={40}
      autoHeight
      {...dataGridProps}
    />
  );
}

StelDataGrid.propTypes = {
  rowClickPermission: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  columnPermissions: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])
  ),
};
StelDataGrid.defaultProps = {
  rowClickPermission: undefined,
  columnPermissions: undefined,
};

export default StelDataGrid;
