import { Link } from "@mui/material";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { DOCS } from "../../utils/constants";
import { isOrgActive, isSandbox, setSandbox } from "../../utils/io";
import theme from "../../utils/theme";
import SideNavbar from "./Navbar";
import useStyles from "./styles";

export const CONTENT_ROOT_ID = "content-root";

function MainLayout(props) {
  const classes = useStyles();
  const { children } = props;

  const switchSandbox = (isOn) => {
    setSandbox(isOn);
    window.location.reload();
  };

  return (
    <div className={classes.container}>
      <SideNavbar />
      <div className={classes.mainLayout} id={CONTENT_ROOT_ID}>
        {isSandbox() && (
          <div className={classNames(classes.sandboxBanner)}>
            {isOrgActive() ? (
              <>
                You are in sandbox mode, click{" "}
                <span
                  onClick={() => switchSandbox(false)}
                  aria-hidden="true"
                  className={classes.clickHereText}
                >
                  here
                </span>{" "}
                to return to production.{" "}
                <Link
                  href={DOCS.SANDBOX_MODE.url}
                  target="_blank"
                  rel="noreferrer"
                  color={theme.colors.stelBlue}
                >
                  Learn more.
                </Link>
              </>
            ) : (
              <>
                You are in sandbox mode, contact sales@stel.life to get started
                in production.{" "}
                <Link
                  href={DOCS.SANDBOX_MODE.url}
                  target="_blank"
                  rel="noreferrer"
                  color={theme.colors.stelBlue}
                >
                  Learn more.
                </Link>
              </>
            )}
          </div>
        )}

        {children}
      </div>
    </div>
  );
}

const defaultProps = {};
MainLayout.defaultProps = defaultProps;
MainLayout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default MainLayout;
