import {
  ArrowBackOutlined as ArrowBackOutlinedIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import {
  Box,
  Typography,
  Chip,
  FormControlLabel,
  Checkbox,
  Stack,
  Link,
  Divider,
  Button,
} from "@mui/material";
import { green, red, blue } from "@mui/material/colors";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { get } from "../../utils/io";
import {
  CONNECTIVITY_ISSUES,
  FORMS_CHECKBOX_RETURN_IN_TAKE,
  TROUBLESHOOT_TYPE_TO_FORM,
  formatTroubleshootLabel,
} from "../Troubleshoot/constants";
import TroubleshootOverviewCard from "../Troubleshoot/TroubleshootOverviewCard";

const renderChip = (label, color) => (
  <Chip
    sx={{
      backgroundColor: color[700],
      color: "#fff",
      fontSize: "14px",
      width: "fit-content",
    }}
    size="small"
    label={label}
  />
);

function TroubleshootDetail({ ticket, onClose, isTroubleshootTickets }) {
  const [deviceModel, setDeviceModel] = useState({});

  const { triage, intake } = ticket.details;
  const triageFormFields = TROUBLESHOOT_TYPE_TO_FORM[triage.type] || [];

  const overviewValues = [
    ...(isTroubleshootTickets
      ? [
          {
            label: "Hub ID",
            value: renderChip(ticket.details.hub_id, blue),
          },
        ]
      : []),
    ...(ticket?.org_alias
      ? [
          {
            label: "Org Alias",
            value: renderChip(ticket.org_alias, green),
          },
        ]
      : []),
    {
      label: "Issue Type",
      value: `${formatTroubleshootLabel(triage.type)}${
        triage.type === "device_hub_connectivity"
          ? ` - ${
              triage.context?.device_model_id
                ? CONNECTIVITY_ISSUES.ONE_DEVICE
                : CONNECTIVITY_ISSUES.ANY_DEVICE
            }`
          : ""
      }`,
    },
    ...(triage.context?.issue_type
      ? [
          {
            label: "Issue Sub-Type",
            value: formatTroubleshootLabel(triage.context.issue_type),
          },
        ]
      : []),
    ...(triage.context?.device_model_id
      ? [
          {
            label: "Problem Device Model",
            value: `${deviceModel.make} ${deviceModel.display_name}`,
          },
        ]
      : []),
    {
      label: "Triage Result",
      value: `${formatTroubleshootLabel(triage.status)}${
        triage.return_type
          ? ` - ${formatTroubleshootLabel(triage.return_type)}`
          : ""
      }`,
    },
    ...(triage.returnType
      ? [
          {
            label: "Return Type",
            value: formatTroubleshootLabel(triage.returnType),
          },
        ]
      : []),

    ...(intake?.replacement_hub_id
      ? [
          {
            label: "Replaced With Hub",
            value: (
              <Link href={`/hubs/${intake.replacement_hub_id}`}>
                <Typography sx={{ fontSize: "14px" }}>
                  {intake.replacement_hub_id}
                </Typography>
              </Link>
            ),
          },
        ]
      : []),
    ...(intake?.resolution
      ? [
          {
            label: "Intake Resolution",
            value: formatTroubleshootLabel(intake.resolution),
          },
        ]
      : []),
    {
      label: "Created By",
      value:
        ticket.created_by?.first_name?.length > 0 &&
        ticket.created_by?.last_name?.length > 0
          ? `${ticket.created_by.first_name} ${ticket.created_by.last_name}`
          : `${ticket.created_by?.username || ""}`,
    },
    {
      label: "Created On",
      value: new Date(ticket.created_time).toLocaleString(),
    },
    {
      label: "Last Updated",
      value: new Date(ticket.updated_time).toLocaleString(),
    },
  ];

  useEffect(() => {
    if (triage.context?.device_model_id) {
      const setMakeModels = async () => {
        const res = await get("/device_models");
        setDeviceModel(
          res.data.device_models.find(
            (model) => model.id === triage.context.device_model_id
          )
        );
      };
      setMakeModels();
    }
  }, [triage.context?.device_model_id]);

  return (
    <Stack
      sx={{ m: "24px", width: isTroubleshootTickets ? 500 : "auto" }}
      spacing="16px"
    >
      <Stack
        flexDirection="row"
        sx={{
          mb: "0px",
          gap: "12px",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "fex", gap: "12px", alignItems: "center" }}>
          {!isTroubleshootTickets && (
            <Button
              sx={{
                minWidth: "max-content",
                "& .MuiButton-startIcon": {
                  margin: 0,
                },
                padding: 0,
              }}
              onClick={onClose}
              startIcon={
                <ArrowBackOutlinedIcon
                  style={{ color: "#001737", fontSize: "26px" }}
                />
              }
            />
          )}
          <Typography sx={{ fontSize: "20px", fontWeight: 600 }}>
            {`Ticket ${ticket.id}`}
          </Typography>
          <Chip
            label={`${ticket?.details?.status || ""}`}
            size="small"
            sx={[
              {
                border: `1px solid ${red[700]}`,
                backgroundColor: red[100],
                color: red[700],
                fontSize: "14px",
                fontWeight: 600,
                textTransform: "capitalize",
                mt: "2px",
              },
              ticket?.details?.status === "open" && {
                border: `1px solid ${green[700]}`,
                backgroundColor: green[100],
                color: green[700],
              },
            ]}
            variant="outlined"
          />
        </Box>
        {isTroubleshootTickets && (
          <Button
            sx={{
              minWidth: "max-content",
              "& .MuiButton-startIcon": {
                margin: 0,
              },
              padding: 0,
            }}
            onClick={onClose}
            startIcon={
              <CloseIcon sx={{ color: "#001737", fontSize: "26px" }} />
            }
          />
        )}
      </Stack>
      <Box>
        <TroubleshootOverviewCard values={overviewValues || []} />
      </Box>
      <Divider />
      {triage?.form && (
        <DetailsSection
          title="Patient Triage Details"
          formFields={triageFormFields}
          formValues={triage.form}
          notes={triage.form.notes}
        />
      )}
      {intake?.form && (
        <>
          <Divider />
          <DetailsSection
            title="Return Intake Details"
            formFields={FORMS_CHECKBOX_RETURN_IN_TAKE}
            formValues={intake.form}
            notes={intake.form.notes}
          />
        </>
      )}
    </Stack>
  );
}

function DetailsSection({ title, formFields, formValues, notes }) {
  return (
    <Stack
      sx={{
        borderRadius: "4px",
        px: "16px",
      }}
    >
      <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
        {title}
      </Typography>
      {formFields.map((field) => (
        <FormControlLabel
          sx={{
            "& .MuiFormControlLabel-label": {
              fontSize: "14px",
            },
            "& .MuiFormControlLabel-label.Mui-disabled": {
              color: "#0000008F",
            },
            "& .MuiCheckbox-root.Mui-disabled": {
              color: "#0000008F",
            },
          }}
          key={field.name}
          label={field.title}
          control={
            <Checkbox
              size="small"
              name={field.name}
              checked={formValues[field.name] || false}
              disabled
            />
          }
        />
      ))}
      <Stack>
        <Typography sx={{ fontSize: "15px", fontWeight: 600 }}>
          Notes
        </Typography>
        <Typography sx={{ fontSize: "14px", color: "#0000008F" }}>
          {notes || "None"}
        </Typography>
      </Stack>
    </Stack>
  );
}

DetailsSection.propTypes = {
  title: PropTypes.string.isRequired,
  formFields: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string, title: PropTypes.string })
  ).isRequired,
  formValues: PropTypes.shape().isRequired,
  notes: PropTypes.string,
};
DetailsSection.defaultProps = {
  notes: "",
};

export default TroubleshootDetail;

TroubleshootDetail.propTypes = {
  ticket: PropTypes.shape().isRequired,
  onClose: PropTypes.func.isRequired,
  isTroubleshootTickets: PropTypes.bool,
};
TroubleshootDetail.defaultProps = {
  isTroubleshootTickets: false,
};
