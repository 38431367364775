import { TextField } from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import PropTypes from "prop-types";
import React, { useState } from "react";

const filteredOptions = createFilterOptions();

function CreatableAutocomplete(props) {
  const { options, onChange, onAddOption, value, size } = props;
  const [selectValue, setSelectValue] = useState(null);

  return (
    <Autocomplete
      value={value || selectValue}
      onChange={(_, newValue) => {
        if (newValue && newValue.inputValue) {
          // Case where new option is added by `filterOptions`
          const { inputValue } = newValue;
          setSelectValue(inputValue);
          onChange(inputValue);
          onAddOption(inputValue);
        } else {
          // Already existing option
          setSelectValue(newValue);
          onChange(newValue);
        }
      }}
      filterOptions={(opts, params) => {
        const filtered = filteredOptions(opts, params);
        const { inputValue } = params;

        const isExisting = opts.some((option) => option === inputValue);

        if (inputValue !== "" && !isExisting) {
          // Suggest creation of a new option
          filtered.push({ inputValue, label: `Add "${inputValue}"` });
        }

        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      options={options}
      fullWidth
      size={size}
      freeSolo
      forcePopupIcon={options.length > 0} // Show arrow icon when options available
      renderInput={(params) => <TextField {...params} label="Detail" />}
    />
  );
}

CreatableAutocomplete.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  onAddOption: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  size: PropTypes.string,
};

CreatableAutocomplete.defaultProps = {
  size: "medium",
};

export default CreatableAutocomplete;
