import { Button, CircularProgress, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { post } from "../../../utils/io";
import PasswordVerification from "../PasswordVerification";

import useStyles from "./styles";

function VerifyPassword() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const token = location.hash.substring(1);

  const [input, setInput] = useState({
    password: "",
    confirmPassword: "",
  });
  const [isPasswordError, setIsPasswordError] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [isRequesting, setIsRequesting] = useState(false);
  const [isInvalidToken, setIsInvalidToken] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const setPassword = (value) =>
    setInput((pre) => ({ ...pre, password: value }));

  const setConfirmPassword = (value) =>
    setInput((pre) => ({ ...pre, confirmPassword: value }));

  useEffect(() => {
    setIsLoading(true);
    async function tokenVerified() {
      const res = await post(`/users/password_reset/validate_token`, {
        confirmation_token: token,
      });
      if (res.status !== 204) {
        setIsInvalidToken(true);
      }
      setIsLoading(false);
    }
    tokenVerified();
  }, [token]);

  const isButtonDisabled = () =>
    isRequesting ||
    !input.confirmPassword ||
    !input.password ||
    input.password !== input.confirmPassword ||
    isPasswordError;

  const onSubmitForm = async () => {
    setIsRequesting(true);
    setErrMessage("");

    try {
      const payload = {
        new_password: input.password,
        confirmation_token: token,
      };
      const response = await post("/users/password_reset/complete", payload);
      setIsRequesting(false);
      if (response.status !== 204) {
        return setErrMessage(response.data[0] || "Failed to update password.");
      }
      history.push("/");
    } catch (error) {
      setErrMessage("Failed to update password.");
      setIsRequesting(false);
    }
    return null;
  };

  return (
    <Grid className={classes.container}>
      {isLoading && <CircularProgress />}
      {!isLoading && isInvalidToken && (
        <div className={classes.panel}>
          <h2>Your confirmation link is invalid or expired</h2>
          <p>Try requesting a password reset again.</p>
        </div>
      )}
      {!isLoading && !isInvalidToken && (
        <div className={classes.form}>
          <h3>Select a password</h3>
          <PasswordVerification
            password={input.password}
            setPassword={setPassword}
            confirmPassword={input.confirmPassword}
            setConfirmPassword={setConfirmPassword}
            setError={setIsPasswordError}
          />
          {errMessage && <p className={classes.errText}>{errMessage}</p>}
          <Button
            onClick={onSubmitForm}
            variant="contained"
            color="primary"
            className={classes.w100}
            disabled={isButtonDisabled()}
          >
            {isRequesting && (
              <CircularProgress size={16} className={classes.progress} />
            )}{" "}
            Submit
          </Button>
        </div>
      )}
    </Grid>
  );
}

export default VerifyPassword;
