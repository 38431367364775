import { Box, Button, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import StelModal from "../StelModal";

export default function ConfirmBlockDeviceModal(props) {
  const { onClose, onConfirm, open, title, width, devices } = props;

  return (
    <StelModal open={open} onClose={onClose} title={title} width={width}>
      <Stack sx={{ gap: "16px" }}>
        <Typography>
          Measurements taken with these devices will no longer be delivered to
          your endpoints. All past measurements taken with these devices will no
          longer be visible.
        </Typography>
        <Box sx={{ maxHeight: "200px", overflowY: "auto" }}>
          {devices?.map(
            ({
              id,
              mac_address: macAddress,
              make,
              model_display_name: model,
            }) => (
              <li key={id}>
                <strong>{macAddress}</strong> {make} {model}
              </li>
            )
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            color="error"
            variant="outlined"
            sx={{ marginRight: "16px" }}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button color="primary" variant="contained" onClick={onConfirm}>
            Confirm
          </Button>
        </Box>
      </Stack>
    </StelModal>
  );
}

ConfirmBlockDeviceModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  width: PropTypes.number,
  devices: PropTypes.arrayOf(
    PropTypes.shape({
      mac_address: PropTypes.string,
      make: PropTypes.string,
      model_display_name: PropTypes.string,
    })
  ).isRequired,
};

ConfirmBlockDeviceModal.defaultProps = {
  width: 450,
};
