import { Autocomplete, TextField } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { get } from "../utils/io";

function OrgAutocomplete(props) {
  const { onChange, variant } = props;
  const [orgs, setOrgs] = useState([]);
  const [input, setInput] = useState("");

  useEffect(() => {
    const getOrgs = async () => {
      const res = await get("admin/organizations", {
        alias: input,
        include_sandbox: false,
      });
      if (res.status !== 200) {
        return enqueueSnackbar({
          message: "Unable to retrieve organizations",
          variant: "error",
        });
      }
      return setOrgs(res.data.organizations);
    };
    getOrgs();
  }, [input]);

  return (
    <Autocomplete
      options={orgs}
      getOptionLabel={(option) =>
        option?.id ? `${option.name} (${option.alias})` : ""
      }
      onInputChange={(_, value, reason) =>
        reason !== "reset" && setInput(value)
      }
      onChange={(_, value) => onChange(value)}
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      renderInput={(params) => (
        <TextField {...params} label="Organization" variant={variant} />
      )}
      clearOnBlur={false}
      sx={{ minWidth: 250 }}
    />
  );
}

OrgAutocomplete.propTypes = {
  onChange: PropTypes.func.isRequired,
  variant: PropTypes.string,
};

OrgAutocomplete.defaultProps = {
  variant: "outlined",
};

export default OrgAutocomplete;
