import { Button, Stack } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { useForm } from "react-hook-form";
import RhfTextField from "../../form/RhfTextField";

function HubReturnsSearchForm(props) {
  const { disabled, onSubmit, intialSearchInputs } = props;

  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty },
  } = useForm({
    defaultValues: intialSearchInputs,
  });

  const resetForm = () => {
    reset();
    onSubmit();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack flexDirection="row">
        <RhfTextField
          label="Hub ID"
          name="hubId"
          size="small"
          variant="standard"
          control={control || null}
        />
        <Stack sx={{ ml: "10px" }} flexDirection="row" alignItems="center">
          <Button
            color="primary"
            type="submit"
            variant="contained"
            disabled={disabled}
          >
            Search
          </Button>
          <Button
            sx={{ ml: "10px" }}
            color="error"
            variant="contained"
            onClick={resetForm}
            disabled={!isDirty || disabled}
          >
            Clear
          </Button>
        </Stack>
      </Stack>
    </form>
  );
}

HubReturnsSearchForm.propTypes = {
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  intialSearchInputs: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string])
  ).isRequired,
};

HubReturnsSearchForm.defaultProps = {
  disabled: false,
};

export default HubReturnsSearchForm;
