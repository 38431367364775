import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import theme from "../../../utils/theme";
import { ProtectedComponent } from "../../Authz/Protected";
import useStyles from "./styles";

export default function NavLink(props) {
  const { title, path, icon: Icon, isShown } = props;

  const classes = useStyles();
  const location = useLocation();

  const show = isShown ? isShown() : true;

  return show ? (
    <Link
      sx={{
        cursor: "none",
      }}
      to={path}
      className={classNames(classes.link, classes.textStart, {
        [classNames(classes.bgLightBlue, classes.blue, classes.selectedItem)]:
          location.pathname === path,
      })}
    >
      <Icon
        sx={{
          mr: "8px",
          fontSize: theme.fontSize.large,
          marginLeft: "2.5px",
          color:
            location.pathname === path
              ? theme.colors.blue
              : theme.colors.darkGrey,
        }}
      />
      <span className={classNames(classes.navItem)}>{title}</span>
    </Link>
  ) : null;
}

NavLink.propTypes = {
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  icon: PropTypes.elementType.isRequired,
  isShown: PropTypes.func,
};

NavLink.defaultProps = {
  isShown: () => true,
};

export function ProtectedNavLink(props) {
  const { permission, ...navLinkProps } = props;
  return (
    <ProtectedComponent
      WrappedComponent={NavLink}
      permission={permission}
      {...navLinkProps}
    />
  );
}

ProtectedNavLink.propTypes = {
  permission: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
};
