import { Stack, Typography } from "@mui/material";
import { isEmpty, startCase } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { useFormContext, useWatch } from "react-hook-form";
import RhfTextField from "../../form/RhfTextField";
import ContractIcon from "../../icons/ContractIcon";
import { OPTIONS_RETURN_HUB_TO_LVHN } from "../constants";
import useDeviceModels from "../hooks/useDeviceModels";
import TroubleshootButtonGroup from "../TroubleshootButtonGroup";
import TroubleshootSelectDeviceModelGroup from "../TroubleshootSelectDeviceModelGroup";
import TroubleshootFormSelect from "./components/TroubleshootFormSelect";

const ISSUE_TYPES = ["connectivity", "broken", "other"];

const issueTypeOptions = ISSUE_TYPES.map((issueType) => ({
  label: startCase(issueType),
  value: issueType,
}));

function HealthDeviceIssueForm(props) {
  const { handleFormSubmit, disableReturnButton } = props;
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const deviceTypeValue = useWatch({
    name: "device_type",
    control,
  });
  const issueTypeValue = useWatch({
    name: "issue_type",
    control,
  });
  const makeIdFormValue = useWatch({
    name: "device_make_id",
    control,
  });
  const modelIdFormValue = useWatch({
    name: "device_model_id",
    control,
  });

  const { deviceTypeOptions, makeOptions, modelOptions } = useDeviceModels({
    deviceTypeValue,
    makeIdFormValue,
  });

  const modelSelected = modelOptions.find(
    (model) => model.value === modelIdFormValue
  );
  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={3} width="100%">
        <Stack spacing={3} width="40%">
          <Typography sx={{ fontSize: "20px", fontWeight: 600 }}>
            Select Device
          </Typography>
          <TroubleshootSelectDeviceModelGroup
            control={control}
            deviceType={deviceTypeValue}
            deviceMakeId={makeIdFormValue}
            deviceTypeOptions={deviceTypeOptions}
            makeOptions={makeOptions}
            modelOptions={modelOptions}
            errors={errors}
          />
          <TroubleshootFormSelect
            label="Issue Type"
            labelId="issue-type"
            options={issueTypeOptions}
            name="issue_type"
            control={control}
            sx={{ width: "100%" }}
          />
        </Stack>
        <Stack
          spacing={2}
          width="60%"
          justifyContent="center"
          alignItems="center"
        >
          {!modelSelected?.image ? (
            <>
              <ContractIcon />
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 500,
                  color: "#636D76",
                }}
              >
                No selected device
              </Typography>
            </>
          ) : (
            <img
              style={{
                width: "250px",
                maxHeight: "280px",
                objectFit: "contain",
              }}
              src={modelSelected.image.url}
              alt={modelSelected.image.key}
            />
          )}
        </Stack>
      </Stack>
      <RhfTextField
        error={!isEmpty(errors)}
        name="notes"
        control={control}
        label="Notes"
        size="small"
        rows={5}
        multiline
        variant="outlined"
        rules={{
          required: issueTypeValue === "other" ? "Notes is required" : null,
        }}
        errors={issueTypeValue === "other" ? errors : {}}
      />
      <TroubleshootButtonGroup
        handleFormSubmit={(mainKey) => handleFormSubmit?.(mainKey)}
        options={OPTIONS_RETURN_HUB_TO_LVHN}
        disableReturnButton={disableReturnButton}
      />
    </Stack>
  );
}

HealthDeviceIssueForm.propTypes = {
  handleFormSubmit: PropTypes.func,
  disableReturnButton: PropTypes.bool,
};

HealthDeviceIssueForm.defaultProps = {
  handleFormSubmit: () => {},
  disableReturnButton: false,
};
export default HealthDeviceIssueForm;
