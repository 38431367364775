import { Button, MenuItem, TextField, Box } from "@mui/material";
import PropTypes from "prop-types";
import React, { memo } from "react";
import { useForm, Controller } from "react-hook-form";
import RhfTextField from "../form/RhfTextField";
import OrgAutocomplete from "../OrgAutocomplete";
import StelDatePicker from "../StelDatePicker";

const TICKET_STATUS = ["open", "closed", "cancelled"];

const TRIAGE_STATUS = [
  {
    label: "Resolved with patient",
    value: "resolved_with_patient",
  },
  {
    label: "Initiated return",
    value: "initiated_return",
  },
  {
    label: "Escalated to stel",
    value: "escalated_to_stel",
  },
];

const CustomTextField = memo((params) => (
  <TextField
    {...params}
    size="small"
    variant="standard"
    sx={{ marginRight: "15px" }}
  />
));

CustomTextField.displayName = "CustomTextField";

function TroubleshootSearchForm({ onSubmit, disabled }) {
  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      orgAlias: "",
      hubId: "",
      ticketId: "",
      status: "",
      triageStatus: "",
      takenAfterDate: null,
      takenBeforeDate: null,
    },
  });

  const textFields = [
    { name: "hubId", label: "Hub ID" },
    { name: "ticketId", label: "Ticket ID" },
  ];

  const selectFields = [
    {
      name: "status",
      label: "Status",
      options: TICKET_STATUS.map((item) => ({ value: item, label: item })),
      minWidth: "150px",
    },
    {
      name: "triageStatus",
      label: "Initial Resolution",
      options: TRIAGE_STATUS,
      minWidth: "150px",
    },
  ];

  const dateFields = [
    { name: "takenAfterDate", label: "Created After" },
    { name: "takenBeforeDate", label: "Created Before" },
  ];

  return (
    <form style={{ marginBottom: 20 }} onSubmit={handleSubmit(onSubmit)}>
      <Box
        sx={{
          marginBottom: "15px",
          display: "flex",
          gap: "15px",
          flexWrap: "wrap",
        }}
      >
        <Controller
          name="orgAlias"
          control={control}
          label="Organization Alias"
          render={({ field }) => (
            <OrgAutocomplete onChange={field.onChange} variant="standard" />
          )}
        />
        {textFields.map(({ name, label }) => (
          <RhfTextField
            key={name}
            name={name}
            control={control}
            label={label}
            variant="standard"
          />
        ))}
        {selectFields.map(({ name, label, options, minWidth }) => (
          <RhfTextField
            key={name}
            name={name}
            control={control}
            select
            variant="standard"
            label={label}
            sx={{ minWidth }}
          >
            {options.map((item) => (
              <MenuItem
                key={item.value}
                value={item.value}
                sx={{ textTransform: "capitalize" }}
              >
                {item.label}
              </MenuItem>
            ))}
          </RhfTextField>
        ))}
      </Box>
      <Box sx={{ display: "flex", gap: "15px", alignItems: "center" }}>
        {dateFields.map(({ name, label }) => (
          <Controller
            key={name}
            name={name}
            control={control}
            render={({ field }) => (
              <StelDatePicker
                label={label}
                onChange={field.onChange}
                value={field.value}
                maxDate={new Date()}
                variant="standard"
              />
            )}
          />
        ))}
        <Box>
          <Button
            color="primary"
            type="submit"
            variant="contained"
            sx={{ marginRight: "15px" }}
            disabled={disabled}
          >
            Search
          </Button>
          <Button
            color="error"
            variant="contained"
            disabled={!isDirty || disabled}
            onClick={() => {
              reset({});
              onSubmit({});
            }}
          >
            Clear
          </Button>
        </Box>
      </Box>
    </form>
  );
}

TroubleshootSearchForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

TroubleshootSearchForm.defaultProps = {
  disabled: false,
};

export default TroubleshootSearchForm;
