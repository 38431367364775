import React, { useState } from "react";
import MainContentContainer from "../Layouts/MainContentContainer";
import TroubleshootList from "./TroubleshootList";
import TroubleShootsSearchForm from "./TroubleshootSearchForm";

function TroubleshootTickets() {
  const [searchInputs, setSearchInputs] = useState({});

  return (
    <MainContentContainer title="Support Tickets">
      <TroubleShootsSearchForm onSubmit={setSearchInputs} />
      <TroubleshootList searchInputs={searchInputs} />
    </MainContentContainer>
  );
}

export default TroubleshootTickets;
