import { Box } from "@mui/material";
import { blue, red } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import { enqueueSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { get, post, del } from "../../utils/io";
import { ProtectedButton } from "../Authz/Protected";

import StelDataGrid from "../StelDataGrid";
import StelModal from "../StelModal";
import InviteForm from "./InviteForm";

const useStyles = makeStyles(() => ({
  flex: {
    display: "flex",
  },
  mrb15: {
    marginBottom: "15px !important",
  },
  pointer: {
    cursor: "pointer",
  },
  cancelBtn: {
    padding: "3px 7px",
    borderRadius: 4,
    border: "1px solid",
    borderColor: red[200],
    lineHeight: "normal",
    color: red[500],
    "&:hover": {
      background: red[100],
    },
  },
  resendBtn: {
    padding: "3px 7px",
    borderRadius: 4,
    border: "1px solid",
    borderColor: blue[200],
    lineHeight: "normal",
    color: blue[500],
    "&:hover": {
      background: blue[100],
    },
  },
  mrr10: {
    marginRight: 10,
  },
}));

export default function InviteTable({ rolesStatic }) {
  const classes = useStyles();
  const [invites, setInvites] = useState([]);
  const [isModal, setIsModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const getInviteList = async () => {
    setLoading(true);
    const res = await get("/users/invites");
    if (res.status === 200) {
      setInvites(res.data.user_invites);
    } else {
      enqueueSnackbar("Failed to retrieve user invites");
    }
    setLoading(false);
  };

  useEffect(() => {
    getInviteList();
  }, []);

  const cancelInvite = (id) => async () => {
    const res = await del(`/users/invites/${id}`);
    if (res.status === 204) {
      enqueueSnackbar("Invite cancelled", { variant: "success" });
    }
    getInviteList();
  };

  const resendInvite = (id) => async () => {
    const res = await post(`/users/invites/${id}/resend`);
    if (res.status === 204) {
      enqueueSnackbar("Invite sent", { variant: "success" });
    }
  };

  const columns = [
    { field: "email", headerName: "Email", flex: 2 },
    {
      field: "created_time",
      headerName: "Created at",
      flex: 2,
      renderCell: (params) =>
        new Date(params.row.created_time).toLocaleString(),
    },
    {
      field: "actions",
      headerName: "",
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        // Convert p tags to MUI buttons
        /* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
        <div className={classes.flex}>
          <p
            onClick={cancelInvite(params.row.id)}
            className={classNames(classes.cancelBtn, classes.mrr10)}
          >
            Cancel
          </p>
          <p
            onClick={resendInvite(params.row.id)}
            className={classNames(classes.resendBtn)}
          >
            Resend
          </p>
        </div>
      ),
    },
  ];

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          mb: "15px",
        }}
      >
        <ProtectedButton
          permission="ManageUserInvites"
          variant="contained"
          color="primary"
          onClick={() => setIsModal(true)}
        >
          Invite User
        </ProtectedButton>
      </Box>
      <StelDataGrid
        columns={columns}
        rows={invites}
        columnPermissions={{ actions: "ManageUserInvites" }}
        hideFooterPagination
        loading={loading}
      />
      {isModal && (
        <StelModal
          open={isModal}
          onClose={() => setIsModal(false)}
          title="Invite User"
          width={400}
        >
          <InviteForm
            rolesStatic={rolesStatic}
            onClose={() => {
              setIsModal(false);
              getInviteList();
            }}
          />
        </StelModal>
      )}
    </>
  );
}

InviteTable.propTypes = {
  rolesStatic: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
    })
  ).isRequired,
};
