import { Button } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import StelModal from "../../StelModal";

import useStyles from "../OrderModal/styles";

export default function ChoicesModal(props) {
  const classes = useStyles();

  const { onClose, onConfirm, message, isDisplayed, title, width } = props;

  return (
    <StelModal open={isDisplayed} onClose={onClose} title={title} width={width}>
      <div>
        <p className={classes.textCenter}>{message}</p>
        <div className={classes.textCenter}>
          <Button
            color="error"
            variant="outlined"
            className={classes.mrr16}
            onClick={() => onClose()}
          >
            No
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => onConfirm()}
          >
            Yes
          </Button>
        </div>
      </div>
    </StelModal>
  );
}

ChoicesModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  isDisplayed: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  width: PropTypes.number,
};

ChoicesModal.defaultProps = {
  width: 450,
};
