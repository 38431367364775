import React from "react";

export default function Pillcap() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="10 0 22 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.319 10.7068H14.4741M10.0259 14.2499H16.3621V20.405H10.0259M12.4569 15.5171V16.9654M11.7586 16.2154H13.181M11.319 17.8447H14.2414M11.319 18.8275H14.2414"
        stroke="#545454"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.7759 8.45686V7.44824M15.4569 8.45686V7.44824M17.1379 8.45686V7.44824M18.7672 8.45686V7.44824M11.319 8.87065H19.5431V10.7069C19.5431 10.7069 19.7241 10.7069 20.2155 10.7069C20.7069 10.7069 20.8879 11.0431 20.8879 11.5862C20.8879 12.1293 20.8879 22.5775 20.8879 23.1465C20.8879 23.7155 20.5776 23.9999 20.2155 23.9999C19.8534 23.9999 11.2672 23.9999 10.7759 23.9999C10.2845 23.9999 10 23.6896 10 23.1465C10 22.6034 10 12.1034 10 11.5862C10 11.0689 10.181 10.7069 10.4397 10.7069C10.6983 10.7069 11.319 10.7069 11.319 10.7069V8.87065Z"
        stroke="#545454"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.1466 7.44827V8.48276M12.1466 8.48276H10.5948C10.5948 8.48276 10.5948 7.52586 10.5948 6.93103C10.5948 6.33621 10.9311 6 11.7328 6C12.5345 6 18.3017 6 19.1293 6C19.9569 6 20.2672 6.33621 20.2672 6.93103C20.2672 7.52586 20.2672 8.48276 20.2672 8.48276H12.1466Z"
        stroke="#545454"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
