import { Chip, FormControl, Input, InputLabel } from "@mui/material";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import useStyles from "./styles";

export default function TrackingNumberFormField(props) {
  const classes = useStyles();
  const {
    value,
    onChangeTrackingNumber,
    trackingNumberIds,
    isShowDupeTrackingNum,
    onRemoveTrackingNumberId,
    disabled,
  } = props;
  return (
    <div className={classes.fullWidth}>
      <div
        className={classNames(
          classes.bold,
          classes.flex,
          classes.alignCenter,
          classes.mb16
        )}
      >
        Tracking Numbers
      </div>
      <div
        className={classNames(classes.mb16, classes.border, classes.radius4)}
      >
        <div
          className={classNames(
            classes.flex,
            classes.alignCenter,
            classes.inputPadding,
            classes.borderBottom
          )}
        >
          <FormControl
            fullWidth
            variant="standard"
            className={classes.input}
            size="small"
            disabled={disabled}
          >
            <InputLabel htmlFor="standard-adornment-amount">
              Tracking #
            </InputLabel>
            <Input
              onKeyUp={onChangeTrackingNumber}
              onChange={onChangeTrackingNumber}
              value={value}
              name="trackingNumber"
              fullWidth
            />
          </FormControl>
        </div>
        <div
          className={classNames(
            classes.pd12,
            classes.scroll,
            classes.relative,
            classes.pdt30
          )}
        >
          {isShowDupeTrackingNum && (
            <div
              className={classNames(
                classes.absolute,
                classes.warning,
                classes.flex,
                classes.alignCenter,
                classes.spaceBetween
              )}
            >
              <p className={classes.text}>
                Tracking number has already been added
              </p>
            </div>
          )}
          {!trackingNumberIds.length ? (
            <p
              className={classNames(
                classes.flex,
                classes.alignCenter,
                classes.justifyCenter
              )}
            >
              No tracking number added
            </p>
          ) : (
            trackingNumberIds.map((trackingId) => (
              <Chip
                key={trackingId}
                label={trackingId}
                color="primary"
                variant="outlined"
                onDelete={onRemoveTrackingNumberId(trackingId)}
                className={classes.chip}
                disabled={disabled}
              />
            ))
          )}
        </div>
      </div>
    </div>
  );
}

TrackingNumberFormField.propTypes = {
  value: PropTypes.string,
  onChangeTrackingNumber: PropTypes.func.isRequired,
  trackingNumberIds: PropTypes.arrayOf(PropTypes.string),
  isShowDupeTrackingNum: PropTypes.bool.isRequired,
  onRemoveTrackingNumberId: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

TrackingNumberFormField.defaultProps = {
  value: "",
  trackingNumberIds: [],
  disabled: false,
};
