import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Protected, NotPermitted, Permitted } from "../Authz/Protected";
import HubAutocomplete from "../HubAutocomplete";
import StelModal from "../StelModal";

export default function BindHubModal(props) {
  const { onClose, open, title, handleBindDevices, width } = props;
  const [hub, setHub] = useState("");

  return (
    <StelModal open={open} onClose={onClose} title={title} width={width}>
      <Box>
        <Box sx={{ mb: "16px" }}>
          <Protected permission="ViewHubs">
            <Permitted>
              <Typography sx={{ mb: 1 }}>
                Select a hub to create a binding with this device(s).
              </Typography>
              <HubAutocomplete onChange={(_, value) => setHub(value)} />
            </Permitted>
            <NotPermitted>
              <Typography sx={{ mb: 1 }}>
                Enter a Hub ID to create a binding with this device(s).
              </Typography>
              <TextField
                onChange={(event) => setHub(event.target.value)}
                label="Hub ID"
                helperText="Enter 10 character ID"
                inputProps={{ minLength: 10, maxLength: 10 }}
              />
            </NotPermitted>
          </Protected>
        </Box>
        <Stack direction="row" justifyContent="flex-end">
          <Button
            color="error"
            variant="outlined"
            sx={{ mr: "16px" }}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => handleBindDevices(hub.hub_id)}
            disabled={!hub}
          >
            Confirm
          </Button>
        </Stack>
      </Box>
    </StelModal>
  );
}

BindHubModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  handleBindDevices: PropTypes.func.isRequired,
  width: PropTypes.number,
};

BindHubModal.defaultProps = {
  width: 450,
};
