import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Link } from "@mui/material";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";

import { useDropzone } from "react-dropzone";
import useStyles from "./styles";

export default function UploadFile(props) {
  const classes = useStyles();
  const {
    fileType,
    files = [],
    onSelectFile,
    onRemoveFile,
    isFullWidth,
    disabled,
    isVisibleFile,
  } = props;

  const onDropFile = useCallback(
    (value) => {
      onSelectFile(value);
    },
    [onSelectFile]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDropFile,
    accept: fileType,
    disabled,
  });

  const onRemove = (index) => () => {
    onRemoveFile(index);
  };

  return (
    <div>
      <div
        className={classNames(
          { [classes.fullWidth]: isFullWidth, [classes.disabled]: disabled },
          classes.uploadImage
        )}
        /* eslint-disable react/jsx-props-no-spreading */
        {...getRootProps()}
      >
        <input {...getInputProps()} multiple />
        <div
          className={classNames(
            classes.flex,
            classes.alignCenter,
            classes.flexCol,
            {
              [classes.flexRow]: isFullWidth,
            }
          )}
        >
          <p>Drop images here</p>
          <CloudUploadIcon
            className={classNames(classes.cloudIcon, {
              [classes.cloudIconSmall]: isFullWidth,
            })}
          />
        </div>
      </div>
      {isVisibleFile && (
        <div className={classNames(classes.flex, classes.imagesContainer)}>
          {files.map((img, index) => (
            <FileUpload key={img.name} image={img} onRemove={onRemove(index)} />
          ))}
        </div>
      )}
    </div>
  );
}

export function ImageView({ image, onRemove, disabled }) {
  const classes = useStyles();

  return (
    <div className={classes.file}>
      {!disabled && (
        <div
          className={classes.removeIcon}
          aria-hidden="true"
          onClick={onRemove}
        >
          <HighlightOffIcon />
        </div>
      )}
      <Link href={image} target="_blank">
        <div className={classes.imgContainer}>
          <div
            className={classes.img}
            style={{ backgroundImage: `url(${image})` }}
          />
        </div>

        <p className={classes.fileName}>{image.name}</p>
      </Link>
    </div>
  );
}

function FileUpload(props) {
  const { image, onRemove } = props;

  const [file, setFile] = useState({});
  useEffect(() => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setFile(reader.result);
    };
    if (image) {
      reader.readAsDataURL(image);
    }
  }, [setFile, image]);

  return <ImageView image={file} onRemove={onRemove} />;
}

FileUpload.propTypes = {
  onRemove: PropTypes.func.isRequired,
  image: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
};

ImageView.propTypes = {
  onRemove: PropTypes.func.isRequired,
  image: PropTypes.oneOfType([
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ),
    PropTypes.string,
  ]).isRequired,
  disabled: PropTypes.bool,
};
ImageView.defaultProps = {
  disabled: false,
};
UploadFile.propTypes = {
  onSelectFile: PropTypes.func.isRequired,
  onRemoveFile: PropTypes.func.isRequired,
  files: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    )
  ),
  fileType: PropTypes.string,
  isFullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  isVisibleFile: PropTypes.bool,
};
UploadFile.defaultProps = {
  files: [],
  isFullWidth: false,
  disabled: false,
  isVisibleFile: false,
  fileType: "",
};
