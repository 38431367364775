import { MenuItem } from "@mui/material";
import {
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
} from "@mui/x-data-grid";
import PropTypes from "prop-types";
import React from "react";

const getFileName = (fileName) =>
  typeof fileName === "function" ? fileName() : fileName;

function CustomExportMenuItem(props) {
  const { getData, fileType, fileName, label } = props;

  const downloadFile = () => {
    const data = getData();
    const blob = new Blob([data], { type: fileType });

    const hiddenElement = document.createElement("a");
    hiddenElement.download = getFileName(fileName);
    hiddenElement.href = URL.createObjectURL(blob);

    hiddenElement.click();
    hiddenElement.remove();
  };

  return <MenuItem onClick={downloadFile}>{label}</MenuItem>;
}

CustomExportMenuItem.propTypes = {
  getData: PropTypes.func.isRequired,
  fileType: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  fileName: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
};

export default function CustomExportButton(props) {
  const { disabled, fileName, exportDataGetter } = props;

  return (
    <GridToolbarExportContainer
      sx={{ fontSize: "14px", textTransform: "capitalize" }}
      disabled={disabled}
      variant="outlined"
    >
      {!exportDataGetter ? (
        <GridCsvExportMenuItem
          variant="outlined"
          size="medium"
          options={{ fileName: getFileName(fileName) }}
        />
      ) : (
        <CustomExportMenuItem
          fileName={fileName}
          fileType="text/csv"
          label="Download as CSV"
          getData={exportDataGetter}
        />
      )}
    </GridToolbarExportContainer>
  );
}

CustomExportButton.propTypes = {
  exportDataGetter: PropTypes.func,
  disabled: PropTypes.bool,
  fileName: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

CustomExportButton.defaultProps = {
  exportDataGetter: null,
  disabled: false,
  fileName: () => `measures_${new Date().toISOString()}.csv`,
};
