import {
  AccountCircle as AccountCircleIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
  Group as GroupIcon,
} from "@mui/icons-material";
import { Box, Menu, MenuItem } from "@mui/material";
import classNames from "classnames";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  getUserOrg,
  getUserName,
  isOrgUser,
  setSandbox,
  setUser,
} from "../../../utils/io";
import theme from "../../../utils/theme";
import NavLink from "./NavLink";
import useStyles from "./styles";

export default function NavFooter() {
  const classes = useStyles();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleExpandDrop = (e) => setAnchorEl(e.currentTarget);
  const handleCloseDrop = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <div
        className={classNames(
          classes.link,
          classes.textStart,
          classes.relative
        )}
        onClick={handleExpandDrop}
        aria-hidden="true"
      >
        <AccountCircleIcon
          sx={{
            mr: "8px",
            color: theme.colors.darkGrey,
            fontSize: theme.fontSize.large,
            marginLeft: "2.5px",
          }}
        />
        <span
          className={classNames(
            classes.flex,
            classes.spaceBetween,
            classes.w100,
            classes.navItem
          )}
        >
          <span>{getUserName()}</span>
          <ArrowForwardIosIcon
            className={classes.expandIcon}
            onClick={handleExpandDrop}
          />
        </span>
      </div>

      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleCloseDrop}
      >
        {[
          {
            title: "Change Password",
            handler: () => history.push("/change-password"),
          },
          {
            title: "Logout",
            handler: () => {
              setUser({});
              setSandbox(false);
              window.location.reload();
            },
          },
        ].map((item) => (
          <MenuItem
            onClick={item.handler}
            key={item.title}
            className={classes.menuItem}
          >
            {item.title}
          </MenuItem>
        ))}
      </Menu>

      <NavLink
        path="/organization"
        title={getUserOrg()}
        icon={GroupIcon}
        isShown={isOrgUser}
      />
    </Box>
  );
}
