import {
  AutoFixHigh as AutoFixHighIcon,
  MenuBook as MenuBookIcon,
  Science as ScienceIcon,
} from "@mui/icons-material";
import { Box, FormControlLabel, Stack, Switch } from "@mui/material";
import classNames from "classnames";
import React from "react";
import { DOCS } from "../../../utils/constants";
import {
  isOrgActive,
  isOrgUser,
  isSandbox,
  setSandbox,
} from "../../../utils/io";
import theme from "../../../utils/theme";
import DocsTooltip from "../../DocsTooltip";
import NavLink from "./NavLink";
import useStyles from "./styles";

export default function NavSectionThree() {
  const classes = useStyles();
  const sandboxFlag = isSandbox();
  const switchSandbox = (isOn) => {
    setSandbox(isOn);
    window.location.reload();
  };

  return (
    <Stack>
      <a
        href="https://stel.wiki"
        target="_blank"
        rel="noreferrer"
        className={classNames(classes.link, classes.textStart)}
      >
        <MenuBookIcon
          sx={{
            mr: "8px",
            fontSize: theme.fontSize.large,
            marginLeft: "2.5px",
          }}
        />
        <span className={classNames(classes.navItem)}>Documentation</span>
      </a>

      {isOrgUser() && (
        <Box
          className={classNames(
            classes.link,
            classes.textStart,
            classes.sandboxBtn,
            isOrgActive() ? classes.isOrg : classes.notOrg
          )}
          sx={{ overflow: "hidden", whiteSpace: "nowrap" }}
        >
          <ScienceIcon
            sx={{
              mr: "8px",
              fontSize: theme.fontSize.large,
              marginLeft: "2.5px",
            }}
          />
          <DocsTooltip
            description={DOCS.SANDBOX_MODE.description}
            docsUrl={DOCS.SANDBOX_MODE.url}
            placement="right"
          >
            <FormControlLabel
              labelPlacement="start"
              checked={sandboxFlag}
              onChange={(e) => switchSandbox(e.target.checked)}
              control={<Switch color="primary" size="small" />}
              label="Sandbox Mode"
              disabled={!isOrgActive()}
              className={classNames(classes.navItem)}
            />
          </DocsTooltip>
        </Box>
      )}

      {isSandbox() && isOrgUser() && (
        <NavLink
          icon={AutoFixHighIcon}
          title="Simulator"
          path="/simulate-measurements"
        />
      )}
    </Stack>
  );
}
