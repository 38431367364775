import {
  DataUsage as DataUsageIcon,
  DeviceHub as DeviceHubIcon,
  Link as LinkIcon,
  Person as PersonIcon,
  Router as RouterIcon,
  CastConnected as CastConnectedIcon,
  PlayCircleFilledWhite,
} from "@mui/icons-material";
import { Stack } from "@mui/material";
import React from "react";
import {
  isOrgUser,
  isSandbox,
  isEnableDeviceRegistration,
  isAdmin,
} from "../../../utils/io";
import NavLink, { ProtectedNavLink } from "./NavLink";

export default function NavSectionOne() {
  return (
    <Stack>
      {[
        {
          icon: PlayCircleFilledWhite,
          title: "Get Started",
          path: "/get-started",
          isShown: () => isSandbox(),
        },
        {
          icon: RouterIcon,
          title: "Hubs",
          path: "/hubs",
          permission: "ViewHubs",
        },
        {
          icon: DataUsageIcon,
          title: "Measures",
          path: "/measures",
          permission: "ViewMeasures",
        },
        {
          icon: DeviceHubIcon,
          title: "Hub Groups",
          path: "/hub-group",
          permission: "ViewHubGroups",
        },
        {
          icon: LinkIcon,
          title: "Endpoints",
          path: "/endpoints",
          permission: "ViewEndpoints",
          isShown: () => isOrgUser(),
        },
        {
          icon: PersonIcon,
          title: "Users",
          path: "/users",
          permission: "ViewUsers",
          isShown: () => isOrgUser(),
        },
        {
          icon: CastConnectedIcon,
          title: "Dexcom",
          path: "/devices",
          isShown: () => isEnableDeviceRegistration() || isAdmin(),
        },
      ].map((page) => {
        const { permission, ...rest } = page;
        if (permission) {
          return (
            <ProtectedNavLink
              permission={permission}
              key={page.path}
              {...rest}
            />
          );
        }
        return <NavLink key={page.path} {...rest} />;
      })}
    </Stack>
  );
}
