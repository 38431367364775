const env = process.env.REACT_APP_ENV || "local";

const host = {
  local: "http://localhost:8000/api/v2",
  dev: "https://api.dev.stellife.com/api/v2",
  test: "https://api.test.stellife.com/api/v2",
  prod: "https://api.stellife.com/api/v2",
};

const googleAnalyticTrackingId = {
  local: "G-1BSW3GLR3X",
  test: "G-1BSW3GLR3X",
  prod: "G-0F6FRZQ5LS",
};

const config = {
  env,
  host: host[env],
  googleAnalyticTrackingId: googleAnalyticTrackingId[env],
};

export default config;
