import React from "react";

export default function Wearable() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="10 0 22 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.1243 9.82476C21.5988 9.82476 22.2316 10.2993 22.2316 11.0112M21.1243 8.48013C22.7853 8.48013 23.6553 10.062 23.6553 11.0112M21.1243 7.0564C23.2599 7.0564 25 8.9547 25 11.0112"
        stroke="#545454"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.983 19.2373C15.2791 19.2373 14.5909 19.0286 14.0056 18.6374C13.4202 18.2463 12.964 17.6905 12.6946 17.0401C12.464 16.4831 12.3791 15.8783 12.4457 15.2825H13.0913C13.0913 15.2825 13.0913 15.2825 13.0913 15.2825H14.4568L15.6521 14.0125L16.0621 13.5768V14.1751V16.0993L16.5997 15.517L16.6701 15.4407H16.774H18.1186C18.2497 15.4407 18.3559 15.5469 18.3559 15.678C18.3559 15.809 18.2497 15.9153 18.1186 15.9153H16.8779L15.9992 16.8672L15.5876 17.3131V16.7062V14.7735L14.7321 15.6824L14.6618 15.7571H14.5593H13.0655C13.0751 16.1118 13.1494 16.4637 13.2866 16.7949C13.5075 17.3282 13.8816 17.784 14.3615 18.1047C14.8415 18.4254 15.4058 18.5966 15.983 18.5966V19.2373ZM13.2515 14.6497C13.3972 14.2627 13.6242 13.9092 13.9192 13.6142C14.3274 13.206 14.8475 12.928 15.4136 12.8154C15.9798 12.7028 16.5666 12.7606 17.0999 12.9815C17.6333 13.2024 18.0891 13.5765 18.4098 14.0565C18.7305 14.5364 18.9017 15.1007 18.9017 15.678H19.5424C19.5424 14.974 19.3336 14.2859 18.9425 13.7005C18.5514 13.1152 17.9955 12.659 17.3451 12.3896C16.6947 12.1202 15.9791 12.0497 15.2886 12.187C14.5982 12.3244 13.964 12.6634 13.4662 13.1612C13.0498 13.5775 12.7446 14.0894 12.5755 14.6497H13.2515Z"
        fill="#545454"
      />
      <line
        x1="15.6793"
        y1="5.54102"
        x2="16.6031"
        y2="5.54102"
        stroke="#545454"
        strokeWidth="0.5"
        strokeLinecap="round"
      />
      <line
        x1="15.6793"
        y1="6.8064"
        x2="16.6031"
        y2="6.8064"
        stroke="#545454"
        strokeWidth="0.5"
        strokeLinecap="round"
      />
      <line
        x1="15.6793"
        y1="8.07202"
        x2="16.6031"
        y2="8.07202"
        stroke="#545454"
        strokeWidth="0.5"
        strokeLinecap="round"
      />
      <line
        x1="15.6793"
        y1="23.1003"
        x2="16.6031"
        y2="23.1003"
        stroke="#545454"
        strokeWidth="0.5"
        strokeLinecap="round"
      />
      <line
        x1="15.6793"
        y1="24.3657"
        x2="16.6031"
        y2="24.3657"
        stroke="#545454"
        strokeWidth="0.5"
        strokeLinecap="round"
      />
      <line
        x1="15.6793"
        y1="25.6313"
        x2="16.6031"
        y2="25.6313"
        stroke="#545454"
        strokeWidth="0.5"
        strokeLinecap="round"
      />
      <line
        x1="13.6229"
        y1="5.25"
        x2="13.6229"
        y2="9.33757"
        stroke="#545454"
        strokeWidth="0.5"
        strokeLinecap="round"
      />
      <line
        x1="13.6229"
        y1="21.7019"
        x2="13.6229"
        y2="25.7895"
        stroke="#545454"
        strokeWidth="0.5"
        strokeLinecap="square"
      />
      <line
        x1="18.5269"
        y1="5.25"
        x2="18.5269"
        y2="9.33757"
        stroke="#545454"
        strokeWidth="0.5"
        strokeLinecap="round"
      />
      <line
        x1="18.5269"
        y1="21.7019"
        x2="18.5269"
        y2="25.7895"
        stroke="#545454"
        strokeWidth="0.5"
        strokeLinecap="square"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2655 11.0369H19.7006C20.1234 11.0369 20.4661 11.3796 20.4661 11.8024V19.2374C20.4661 19.6602 20.1234 20.003 19.7006 20.003H12.2655C11.8427 20.003 11.5 19.6602 11.5 19.2374V11.8024C11.5 11.3796 11.8427 11.0369 12.2655 11.0369ZM11 11.8024C11 11.1035 11.5666 10.5369 12.2655 10.5369H19.7006C20.3995 10.5369 20.9661 11.1035 20.9661 11.8024V14.2543H21.2825C21.5883 14.2543 21.8362 14.5021 21.8362 14.8079V16.2317C21.8362 16.5374 21.5883 16.7853 21.2825 16.7853H20.9661V19.2374C20.9661 19.9364 20.3995 20.503 19.7006 20.503H12.2655C11.5666 20.503 11 19.9364 11 19.2374V11.8024ZM20.9661 16.3108H21.2825C21.3262 16.3108 21.3616 16.2753 21.3616 16.2317V14.8079C21.3616 14.7642 21.3262 14.7288 21.2825 14.7288H20.9661V16.3108Z"
        fill="#545454"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4521 9.90378H18.5142C18.82 9.90378 19.0679 10.1517 19.0679 10.4574C19.0679 10.4843 19.066 10.5107 19.0623 10.5366H19.5395C19.5415 10.5104 19.5425 10.4841 19.5425 10.4574C19.5425 9.88956 19.0821 9.4292 18.5142 9.4292H13.4521C12.8842 9.4292 12.4238 9.88956 12.4238 10.4574C12.4238 10.4841 12.4248 10.5104 12.4268 10.5366H12.904C12.9003 10.5107 12.8984 10.4843 12.8984 10.4574C12.8984 10.1517 13.1463 9.90378 13.4521 9.90378Z"
        fill="#545454"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.452 21.1355H18.5141C18.8199 21.1355 19.0678 20.8876 19.0678 20.5819C19.0678 20.555 19.0659 20.5286 19.0622 20.5028H19.5394C19.5413 20.5289 19.5424 20.5553 19.5424 20.5819C19.5424 21.1497 19.082 21.6101 18.5141 21.6101H13.452C12.8841 21.6101 12.4237 21.1497 12.4237 20.5819C12.4237 20.5553 12.4247 20.5289 12.4267 20.5028H12.9039C12.9002 20.5286 12.8983 20.555 12.8983 20.5819C12.8983 20.8876 13.1462 21.1355 13.452 21.1355Z"
        fill="#545454"
      />
    </svg>
  );
}
