import { blue, grey, red } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  form: {
    border: `1px solid ${grey[300]}`,
    padding: 15,
    borderRadius: 4,
    maxWidth: 400,
  },
  mrb20: {
    marginBottom: "20px !important",
  },
  w100: {
    width: "100%",
  },
  mr0: {
    margin: 0,
  },
  textCenter: {
    textAlign: "center",
  },
  title: {
    padding: "0 15% 20px",
  },
  progress: {
    marginRight: 10,
  },
  errText: {
    color: red[500],
    fontStyle: "italic",
    textAlign: "center",
    marginTop: 0,
  },
  panel: {
    background: "#d3d3d347",
    padding: 15,
    borderRadius: 8,
  },
  note: {
    padding: "15px 20px",
    border: "1px solid grey",
    borderRadius: 4,
    marginTop: 15,
  },
  flex: {
    display: "flex",
  },
  alignCenter: {
    alignItems: "center",
  },
  infoIcon: {
    color: blue[500],
    fontSize: 32,
    marginRight: 15,
  },
  fs15: {
    fontSize: 15,
  },
}));

export default useStyles;
