import {
  AppBar,
  Avatar,
  FormControlLabel,
  Menu,
  MenuItem,
  Stack,
  Switch,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  isAdmin,
  isOrgActive,
  isOrgUser,
  isSandbox,
  isSsoUser,
  setSandbox,
  userIsLoggedIn,
} from "../utils/io";

const useStyles = makeStyles((theme) => ({
  navLink: {
    color: theme.palette.background.paper,
    margin: theme.spacing(0, 0, 0, 2),
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  navLinkActive: {
    color: theme.palette.background.paper,
    margin: theme.spacing(0, 0, 0, 2),
  },
  userInitials: {
    background: theme.palette.background.paper,
    color: theme.palette.text.primary,
    cursor: "pointer",
    margin: theme.spacing(0, 0, 0, 2),
  },
  mrlAuto: {
    marginLeft: "auto",
  },
  mrr10: {
    marginRight: 10,
  },
  mr0: {
    margin: 0,
  },
  sandboxBtn: {
    padding: 4,
    "padding-left": 11,
    color: theme.palette.common.black,
    backgroundColor: theme.palette.grey["100"],
    borderRadius: 8,
  },
  sandboxTooltip: {
    fontSize: 13,
  },
}));

const propTypes = {
  onLogout: PropTypes.func,
  sandboxFlag: PropTypes.bool,
};

const defaultProps = {
  onLogout: () => {},
  sandboxFlag: false,
};

function Header({ onLogout, sandboxFlag }) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const [anchorEl, setAnchorEl] = useState();

  if (userIsLoggedIn()) {
    const onDropdownClosed = () => {
      setAnchorEl();
    };

    const onLogoutSelected = () => {
      setAnchorEl();
      onLogout();
      history.push("/login");
    };

    const switchSandbox = (isOn) => {
      setSandbox(isOn);
      window.location.reload();
    };

    return (
      <AppBar>
        <Toolbar>
          <Typography variant="h6">Stel Life Management</Typography>
          <Link
            to="/hubs"
            className={classNames({
              [classes.navLinkActive]: location.pathname === "/hubs",
              [classes.navLink]: location.pathname !== "/hubs",
            })}
          >
            <Typography>Hubs</Typography>
          </Link>
          <Link
            to="/measures"
            className={classNames({
              [classes.navLinkActive]: location.pathname === "/measures",
              [classes.navLink]: location.pathname !== "/measures",
            })}
          >
            <Typography>Measures</Typography>
          </Link>
          {isOrgUser() && (
            <Link
              to="/hub-group"
              className={classNames({
                [classes.navLinkActive]: location.pathname === "/hub-group",
                [classes.navLink]: location.pathname !== "/hub-group",
              })}
            >
              <Typography>Hub Groups</Typography>
            </Link>
          )}

          {isOrgUser() && (
            <Link
              to="/endpoints"
              className={classNames({
                [classes.navLinkActive]: location.pathname === "/endpoints",
                [classes.navLink]: location.pathname !== "/endpoints",
              })}
            >
              <Typography>Endpoints</Typography>
            </Link>
          )}
          {isOrgUser() && (
            <Link
              to="/users"
              className={classNames({
                [classes.navLinkActive]: location.pathname === "/users",
                [classes.navLink]: location.pathname !== "/users",
              })}
            >
              <Typography>Users</Typography>
            </Link>
          )}
          {isAdmin() && (
            <Link
              to="/orders"
              className={classNames({
                [classes.navLinkActive]: location.pathname === "/orders",
                [classes.navLink]: location.pathname !== "/orders",
              })}
            >
              <Typography>Orders</Typography>
            </Link>
          )}

          <Stack
            direction="row"
            alignItems="center"
            className={classes.mrlAuto}
          >
            {isOrgUser() && (
              <>
                {isSandbox() && (
                  <Link
                    to="/simulate-measurements"
                    className={classNames(
                      {
                        [classes.navLinkActive]:
                          location.pathname === "/simulate-measurements",
                        [classes.navLink]:
                          location.pathname !== "/simulate-measurements",
                      },
                      classes.mrlAuto
                    )}
                  >
                    <Typography>Simulate Measures</Typography>
                  </Link>
                )}
                <a
                  href="https://stel.wiki"
                  target="_blank"
                  rel="noreferrer"
                  className={classes.navLink}
                >
                  <Typography>Docs</Typography>
                </a>
                <Tooltip
                  classes={{ tooltip: classes.sandboxTooltip }}
                  title="Contact sales@stel.life to get started in production."
                  enterDelay={10}
                >
                  <FormControlLabel
                    labelPlacement="start"
                    className={classNames(classes.mrr10, classes.sandboxBtn)}
                    checked={sandboxFlag}
                    onChange={(e) => switchSandbox(e.target.checked)}
                    control={<Switch color="default" />}
                    label={`Sandbox ${sandboxFlag ? "On" : "Off"}`}
                    disabled={!isOrgActive()}
                  />
                </Tooltip>
              </>
            )}

            <Avatar
              className={classNames(classes.userInitials)}
              onClick={(e) => setAnchorEl(e.currentTarget)}
            />
          </Stack>

          <Menu
            id="header-user-dropdown"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            getContentAnchorEl={null}
            onClose={onDropdownClosed}
            open={Boolean(anchorEl)}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            {!isSsoUser() && isOrgUser() && (
              <MenuItem onClick={() => history.push("/change-password")}>
                Change Password
              </MenuItem>
            )}
            <MenuItem onClick={onLogoutSelected}>Logout</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    );
  }

  return (
    <AppBar>
      <Toolbar>
        <Typography variant="h6">Stel Life Management</Typography>
        <a
          href="https://stel.wiki"
          target="_blank"
          rel="noreferrer"
          className={classNames(classes.navLink, classes.mrlAuto)}
        >
          <Typography>Docs</Typography>
        </a>
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
