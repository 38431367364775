import { Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { useFormContext, useWatch } from "react-hook-form";
import RhfTextField from "../../form/RhfTextField";
import ContractIcon from "../../icons/ContractIcon";
import { OPTIONS_RETURN_HUB_TO_LVHN } from "../constants";
import useDeviceModels from "../hooks/useDeviceModels";
import TroubleshootButtonGroup from "../TroubleshootButtonGroup";
import TroubleshootSelectDeviceModelGroup from "../TroubleshootSelectDeviceModelGroup";
import CheckboxGroup from "./components/CheckboxGroup";
import TroubleshootFormSelect from "./components/TroubleshootFormSelect";

const checkBoxList = [
  "hub_solid_red",
  "hub_rebooted",
  "repair_attempted",
  "measure_attempted",
  "devices_verified",
];

const CONNECTIVITY_ISSUES = [
  {
    value: "any_devices",
    label: "Hub not working with any devices",
  },
  {
    value: "1_device",
    label: "Connectivity issue with 1 device",
  },
];

function HubDeviceConnectivityForm(props) {
  const { handleFormSubmit, disableReturnButton } = props;
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const deviceTypeValue = useWatch({
    name: "device_type",
    control,
  });
  const connectivityIssues = useWatch({
    name: "connectivity_issues",
    control,
  });
  const makeIdFormValue = useWatch({
    name: "device_make_id",
    control,
  });
  const modelIdFormValue = useWatch({
    name: "device_model_id",
    control,
  });

  const { deviceTypeOptions, makeOptions, modelOptions } = useDeviceModels({
    deviceTypeValue,
    makeIdFormValue,
  });

  const isADevice = connectivityIssues === CONNECTIVITY_ISSUES[1].value;

  const modelSelected = modelOptions.find(
    (model) => model.value === modelIdFormValue
  );

  return (
    <Stack spacing={2}>
      <Typography sx={{ fontSize: "20px", fontWeight: 600 }}>
        Select Connectivity Issue
      </Typography>
      <Stack direction="row" spacing={3} width="100%">
        <Stack spacing={3} width="40%">
          <TroubleshootFormSelect
            label="Select Connectivity Issue *"
            labelId="connectivity-issue"
            options={CONNECTIVITY_ISSUES}
            name="connectivity_issues"
            control={control}
            rules={{
              required: "Connectivity Issue is required",
            }}
            errors={errors}
            sx={{ width: "100%" }}
          />
          {isADevice && (
            <TroubleshootSelectDeviceModelGroup
              control={control}
              deviceType={deviceTypeValue}
              deviceMakeId={makeIdFormValue}
              deviceTypeOptions={deviceTypeOptions}
              makeOptions={makeOptions}
              modelOptions={modelOptions}
              errors={errors}
            />
          )}
        </Stack>
        {isADevice && (
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{
              width: "60%",
              gap: "12px",
            }}
          >
            {!modelSelected?.image ? (
              <>
                <ContractIcon />
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 500,
                    color: "#636D76",
                  }}
                >
                  No selected device
                </Typography>
              </>
            ) : (
              <img
                style={{
                  width: "250px",
                  maxHeight: "280px",
                  objectFit: "contain",
                }}
                src={modelSelected.image.url}
                alt={modelSelected.image.key}
              />
            )}
          </Stack>
        )}
      </Stack>
      <Typography sx={{ fontSize: "20px", fontWeight: 600 }}>
        Patient Troubleshooting
      </Typography>
      <CheckboxGroup list={checkBoxList} control={control} />
      <RhfTextField
        name="notes"
        control={control}
        label="Notes"
        size="small"
        rows={5}
        multiline
        variant="outlined"
      />

      <TroubleshootButtonGroup
        handleFormSubmit={(mainKey) => handleFormSubmit?.(mainKey)}
        options={OPTIONS_RETURN_HUB_TO_LVHN}
        disableReturnButton={disableReturnButton}
      />
    </Stack>
  );
}

HubDeviceConnectivityForm.propTypes = {
  handleFormSubmit: PropTypes.func,
  disableReturnButton: PropTypes.bool,
};

HubDeviceConnectivityForm.defaultProps = {
  handleFormSubmit: () => {},
  disableReturnButton: false,
};

export default HubDeviceConnectivityForm;
