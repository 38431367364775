import React from "react";

export default function BloodPressure() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="10 0 22 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.2694 15.8633H18.3959C18.2367 15.8633 18.0918 15.9531 18.0204 16.0939L17.1041 17.9286L15.9285 12.8347C15.8877 12.6592 15.7387 12.5286 15.5571 12.5123C15.3775 12.5 15.2061 12.598 15.1347 12.7633L13.8061 15.8633H10.7285C10.4979 15.8633 10.3102 16.051 10.3102 16.2816C10.3102 16.5123 10.4979 16.7 10.7285 16.7H14.0816C14.249 16.7 14.4 16.6 14.4653 16.447L15.3979 14.2714L16.5469 19.251C16.5857 19.4225 16.7306 19.551 16.9061 19.5735C16.9224 19.5755 16.9387 19.5755 16.9551 19.5755C17.1122 19.5755 17.2571 19.4878 17.3306 19.3449L18.653 16.7021H21.2694C21.5 16.7021 21.6877 16.5143 21.6877 16.2837C21.6877 16.051 21.5 15.8633 21.2694 15.8633Z"
        fill="#545454"
      />
      <path
        d="M19.8286 7C18.3184 7 16.9612 7.95102 16 8.77551C15.0388 7.95102 13.6816 7 12.1714 7C8.76939 7 6 9.76735 6 13.1694C6 20.9633 15.4408 24.8918 15.8429 25.0551L15.9122 25.0837L15.9857 25.0857C16.1796 25.0857 19.5673 23.6102 22.2775 20.9939C24.7469 18.6061 26 15.9755 26 13.1694C26 9.76735 23.2306 7 19.8286 7ZM20.5959 21.3633C18.5878 23 16.5592 23.9612 15.998 24.2122C14.7245 23.6612 6.83673 19.9673 6.83673 13.1694C6.83673 10.2306 9.22857 7.83673 12.1714 7.83673C13.5469 7.83673 14.8571 8.86735 15.7224 9.64286L16.002 9.89388L16.2816 9.64286C17.1469 8.86735 18.4551 7.83673 19.8306 7.83673C22.7694 7.83673 25.1633 10.2306 25.1633 13.1694C25.1633 16.1388 23.6265 18.8959 20.5959 21.3633Z"
        fill="#545454"
      />
    </svg>
  );
}
