import PropTypes from "prop-types";
import React from "react";

function Troubleshoot(props) {
  const { className, color } = props;
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_85_4021)">
        <path
          d="M21.9999 20.59L17.3099 15.9C18.3699 14.55 18.9999 12.85 18.9999 11C18.9999 6.58 15.4199 3 10.9999 3C6.91995 3 3.55995 6.05 3.06995 10H5.08995C5.56995 7.17 8.02995 5 10.9999 5C14.3099 5 16.9999 7.69 16.9999 11C16.9999 14.31 14.3099 17 10.9999 17C8.57995 17 6.49995 15.56 5.54995 13.5H3.39995C4.44995 16.69 7.45995 19 10.9999 19C12.8499 19 14.5499 18.37 15.8999 17.31L20.5899 22L21.9999 20.59Z"
          fill={color}
        />
        <path
          d="M8.43 9.69L9.65 15H11.29L12.55 11.22L13.5 13.5H15.5V12H14.5L13.25 9H11.71L10.59 12.37L9.35 7H7.7L6.45 11H1V12.5H7.55L8.43 9.69Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_85_4021">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

Troubleshoot.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};
Troubleshoot.defaultProps = {
  className: "",
  color: "#001737",
};

export default Troubleshoot;
