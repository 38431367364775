import { Add } from "@mui/icons-material";
import { Box, Button, Chip, Stack } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import {
  ENTERPRISE_FEATURE_MESSAGE,
  HUB_RETURN_STATUS,
  SANDBOX_MODE_MESSAGE,
} from "../../utils/constants";
import { get, isAdmin, isOrgEnterprise, isSandbox, post } from "../../utils/io";
import { Protected } from "../Authz/Protected";
import { usePagination } from "../hooks";
import MainContentContainer from "../Layouts/MainContentContainer";
import StelDataGrid from "../StelDataGrid";
import StelModal from "../StelModal";
import HubReturnsSearchForm from "./HubReturnsSearchForm";
import OrgSelectionModal from "./OrgSelectionModal";

const emptySearchInputs = {
  hubId: "",
};

function HubReturns() {
  const history = useHistory();
  const [hubs, setHubs] = useState([]);
  const [isOrgSelectionModal, setIsOrgSelectionModal] = useState(false);
  const [modal, setModal] = useState({
    open: false,
    title: "",
    text: "",
  });
  const [searchInputs, setSearchInputs] = useState(emptySearchInputs);
  const [loading, setLoading] = useState(false);

  const {
    pagination,
    handlePageChange,
    handleRowPerPageChange,
    rowCount,
    setRowCount,
  } = usePagination();

  useEffect(() => {
    async function getListHubReturn() {
      setLoading(true);
      const res = await get("/hub_returns", {
        limit: pagination.pageSize,
        offset: pagination.page * pagination.pageSize,
        hub_id: searchInputs?.hubId || "",
      });
      if (res.status !== 200) {
        enqueueSnackbar({
          message: "Unable to retrieve hub returns",
          variant: "error",
        });
      } else {
        const { total_count: totalCount, hub_returns: hubReturns } = res.data;
        setRowCount(totalCount);
        setHubs(hubReturns);
      }
      setLoading(false);
    }
    getListHubReturn();
  }, [pagination, setRowCount, searchInputs]);

  const columns = [
    { field: "id", headerName: "ID", flex: 0.75 },
    {
      field: "created_time",
      headerName: "Created At",
      flex: 0.75,
      valueGetter: ({ value }) => new Date(value).toLocaleString(),
    },
    {
      field: "created_by",
      headerName: "Created By",
      flex: 0.75,
      valueGetter: ({ value }) =>
        value?.first_name && value?.last_name
          ? `${value.first_name} ${value.last_name}`
          : `${value?.username || "-"}`,
    },
    { field: "status", headerName: "Status", flex: 0.75 },
    {
      field: "hubs",
      headerName: "Hub Count",
      flex: 0.75,
      renderCell: ({ value }) => <Chip color="primary" label={value.length} />,
    },
    ...(isAdmin()
      ? [{ field: "org_alias", headerName: "Org Alias", flex: 0.75 }]
      : []),
  ];

  const adminCreateHubReturn = async (orgId) => {
    const res = await post("admin/hub_returns", { org_id: orgId });
    if (res.status !== 201) {
      return enqueueSnackbar({
        message: "Failed to create hub return",
        variant: "error",
      });
    }
    return history.push(`/hub-returns/${res.data.id}`);
  };

  const requestHubReturn = async () => {
    if (!isOrgEnterprise()) {
      return setModal({
        open: true,
        title: "Enterprise Feature",
        text: ENTERPRISE_FEATURE_MESSAGE,
      });
    }
    if (isSandbox()) {
      return setModal({
        open: true,
        title: "Switch to Production Mode",
        text: SANDBOX_MODE_MESSAGE,
      });
    }
    const res = await post("/hub_returns");
    if (res.status === 201) {
      return history.push(`/hub-returns/${res.data.id}`);
    }
    return enqueueSnackbar({
      message: "Failed to create hub return. Please contact support@stel.life.",
      variant: "error",
    });
  };

  const closeModal = () => setModal((pre) => ({ ...pre, open: false }));

  return (
    <MainContentContainer title="Hub Returns" isEnterpriseOnly>
      <StelModal
        open={modal.open}
        title={modal.title}
        onClose={closeModal}
        width={500}
      >
        <p>{modal.text}</p>
      </StelModal>
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        sx={{ mb: "10px" }}
      >
        <Stack>
          <HubReturnsSearchForm
            intialSearchInputs={emptySearchInputs}
            disabled={loading}
            onSubmit={setSearchInputs}
          />
        </Stack>
        <Protected permission="ManageHubReturns">
          <Box justifyContent="center">
            <Button
              onClick={
                isAdmin()
                  ? () => setIsOrgSelectionModal(true)
                  : requestHubReturn
              }
              startIcon={<Add />}
              variant="contained"
            >
              {isAdmin() ? "Create" : "Request"}
            </Button>
          </Box>
        </Protected>
      </Stack>
      <StelDataGrid
        columns={columns}
        rows={hubs}
        rowClickPermission="ManageHubReturns"
        onRowClick={({ row }) =>
          history.push(
            isAdmin() && row.status !== HUB_RETURN_STATUS.CREATED
              ? `/hub-returns/${row.id}/evaluate`
              : `/hub-returns/${row.id}`
          )
        }
        rowCount={rowCount}
        page={pagination.page}
        pageSize={pagination.pageSize}
        paginationMode="server"
        onPageChange={handlePageChange}
        onPageSizeChange={handleRowPerPageChange}
        rowsPerPageOptions={[20, 50, 100]}
        loading={loading}
      />
      {isAdmin() && (
        <OrgSelectionModal
          open={isOrgSelectionModal}
          handleClose={() => setIsOrgSelectionModal(false)}
          onCreate={adminCreateHubReturn}
        />
      )}
    </MainContentContainer>
  );
}
export default HubReturns;
